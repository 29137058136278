import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material"

const SignUpStyles = makeStyles(() => ({
    rightBox: {
        background: '#243042 ! important',
        height: '100vh'
    },
    image: {
        marginTop: "160px",
        marginLeft: "-200px",
        width: "85%",
    },   
    leftBox: {
        paddingTop: "100px",
        height: "100vh",
        justifyContent: 'center',
        display: 'flex',
    },
    header: {
        color: '#2D2D2D !important',
        textAlign: 'center',
    },
    form: {
        marginLeft: '-17px ! important'
    },  
    boxOne: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    boxTwo: {
        height: '100%',
        width: '30vw',
        [useTheme().breakpoints.down('sm')]: {
            width: '30vw',
        }
    },
    buttonStyles: {
        textAlign: 'center',
        paddingTop: '30px'
    },
    loginButton: {
        width: '28.8vw !important',
        height: "43px !important",
        background: `#0C75EB !important`,
        borderRadius: "8px !important",
        textTransform: "none !important",
        font: "15px Quicksand !important",
        fontWeight: '600',        
        '&:disabled': {
            opacity: '0.8'
        },
        [useTheme().breakpoints.down("lg")]: {
            font: "15px Quicksand !important",
            width: "100% !important",
        },
        "& .MuiLoadingButton-loadingIndicator": {
            color: "#FFFFFF",
        }
    },   
    optional: {
        color: "#C7CCD3",
        font: '12px Quicksand !important',
        fontWeight: 400,
    },
}))

export default SignUpStyles;
