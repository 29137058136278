import React, { useCallback, useEffect, useState } from 'react';
import UserProfileStyles from './UserProfileStyles';
import { Box, Typography, Checkbox, Breadcrumbs, Stack, Grid, Divider, Dialog, Radio, ListItemButton, FormControlLabel, RadioGroup, Menu, Skeleton, Button, DialogTitle, IconButton, Slide, SwipeableDrawer, Hidden } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CustomButton from '../../../../components/customButton/Button';
import OffBoardImg from "../../../../assets/svg/OffBoardPop.svg"
import GeneralDetails from './generaldetails';
import Documents from './document';
import Immigration from './Immigration';
import Timesheet from './timesheet';
import Skills from './skills';
import Dependents from './dependents';
import VacationHistory from './vacationHistory';
import { styled } from "@mui/material/styles";
import EmployeeAPI from '../../../../apis/admin/employees/EmployeesApi';
import { BlackToolTip, NoPermission, addErrorMsg, dateFormat, } from '../../../../utils/utils';
import Text from '../../../../components/customText/Text';
import { Link, useLocation, } from 'react-router-dom';
import UserProfileApi from '../../../../apis/admin/employees/UserProfileApi';
import { addSuccessMsg } from '../../../../utils/utils';
import LocalStorage from '../../../../utils/LocalStorage';
import verified from '../../../../assets/svg/Component87.svg';
import Verify from '../../../../assets/svg/verify_icon.svg';
import Pending from '../../../../assets/svg/Vector (1).svg';
import Rejected from '../../../../assets/svg/Rejected.svg';
import { useNavigate } from 'react-router-dom';
import ReusablePopup from '../../../../components/reuablePopup/ReusablePopup';
import CommonApi from '../../../../apis/CommonApi';
import ActivityLog from './ActivityLog';
import { blue, orange } from '../../../../theme';
import moment from 'moment';
import eVerifyPending from '../../../../assets/employee/pendingEverify.svg';
import pendingInfoEv from '../../../../assets/employee/pendingInfoEv.svg'
import UserDisable from './UserDisable';
import EmployeesApi from '../../../../apis/admin/employees/EmployeesApi';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import GreenVerif from "../../../../assets/svg/GreenVerify.svg"
import ReactApexChart from 'react-apexcharts';
import OffboardApi from '../../../../apis/admin/offboard/OffboardApi';
import warning from '../../../../assets/employee/info.svg';
import ViewEmergency from './generaldetails/ViewEmergency';
import AllSections from './document/AllSections';
import CircularProgress from '../../../../components/progressbar/CircularProgress';
import Sure from '../../../../assets/svg/ExpenseDeleteIcon.svg'
import offBoardSave from "../../../../assets/svg/offBoardSave.svg"
import FileSaver from 'file-saver';
import BankDetails from './payconfiguration/BankDetails';
import contactExists from '../../../../assets/employee/offboarding/contact-exists.svg';
import LoaderButton from '../../../../components/customButton/LoadingButton';
import deleteIcon from '../../../../assets/employee/deleteImg.svg';
import EmployeeActions from '../../../../components/employee/EmployeeActions';
import Initiated from "../../../../assets/svg/InitiatedImg.svg"
import OffboardingChecklistStyles from './OffboardingChecklistStyles';
import CancelOffboarding from '../../../../components/employee/CancelOffBoarding';
import LoadingButton from '../../../../components/customButton/LoadingButton';
import Input from '../../../../components/input/Input';
import Date from '../../../../components/datePicker/Date';
import Select from '../../../../components/select/Select';
import { ReactComponent as RadioIcon } from '../../../../assets/svg/RadioIcon.svg';
import { ReactComponent as RadioCheckedIcon } from '../../../../assets/svg/RadioCheckedIcon.svg';
import { ReactComponent as RadioCheckedDisabedIcon } from '../../../../assets/svg/RadioUnCheckDisabled.svg';
import { ReactComponent as RadioDisabledIcon } from '../../../../assets/svg/RadioDisabled.svg'
import { empty_name, isValid, validate_charWithSpace, validate_emptyField, validates_emailId, validate_docaaaNUmber, validate_doc1to17Number, valdoc6to9number, validate_doc6to12Number, validate_ssn, validate_i94number, validate_foriegnPassportNumber, validate_anumber, validate_sevisnumber, everify_visa, validate_everify_username, validate_everify_password, everify_middle_name, everify_employeeId } from "../../../../components/Validation";
import { addWarningMsg } from '../../../../utils/utils';
import CustomRadioGroup from '../../../settings/configurations/configComponents/radio/CustomRadio';
import BaseTextareaAutosize from '@mui/material/TextareaAutosize';
import Everify from './everify/Everify';
import ssnImage from '../../../../assets/employee/SSN.svg';
import successEnd from '../../../../assets/employee/everify-success.svg';
import backarrow from '../../../../assets/employee/arrow-left.svg';
import EverifyApi from '../../../../apis/admin/employees/EverifyApi';
import Search from '../../../../components/select/Select';

const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    width: 495px;
    font-family: 'Quicksand', Quicksand;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    border: none !important
    &:focus-visible {
        outline: 0;
      }
  `,
);


const dataArr = ["General Details", "Documents", "Bank Details",
    //  "Immigration", "Timesheet",
    "Skills", "E-Verify"
    //    "Dependents", "Vacation History"
];


export default function ControlledAccordions(props) {
    const ssnValidator = new RegExp(/^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/);
    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} timeout={500} style={{ position: 'fixed', bottom: 60, right: 50 }} />;
    });
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const classes = UserProfileStyles();
    const offboardChecklistClasses = OffboardingChecklistStyles()
    const location = useLocation();
    const param = new URLSearchParams(window.location.search);
    const employee_id = param.get('id');
    const id = employee_id ? employee_id : LocalStorage.getRedirectedModule() !== null ? (LocalStorage.getRedirectedModule() == 'employee_userprofile' || LocalStorage.getRedirectedModule() == 'employee' || LocalStorage.getRedirectedModule() == 'employee-profile') ? LocalStorage.getNotificationEmpId() : location.state.id : location.state.id
    const offBoardButton = location && location.state && location.state.offBoardButton
    const progress = location && location.state && location.state.progress
    const active = location && location.state && location.state.active
    const jump = location && location.state && location.state.jump
    const grButn = location && location.state && location.state.grButn
    const page = LocalStorage.getRedirectedModule() !== null ? (LocalStorage.getRedirectedModule() == 'employee_userprofile' || LocalStorage.getRedirectedModule() == 'employee' || LocalStorage.getRedirectedModule() == 'employee-profile') ? 1 : (location && location.state && location.state.page) : ''
    const stage = LocalStorage.getRedirectedModule() !== null ? (LocalStorage.getRedirectedModule() == 'employee_userprofile' || LocalStorage.getRedirectedModule() == 'employee' || LocalStorage.getRedirectedModule() == 'employee-profile') ? 'General Details' : (location && location.state && location.state.stage) : 'General Details'
    const [current, setCurrent] = useState("General Details");
    const [anchorEl, setAnchorEl] = useState(null);// eslint-disable-next-line
    const open = Boolean(anchorEl);
    const [lastWorkingDate, setLastWorkingDate] = useState('');
    const [lastWorkingDateCopy, setLastWorkingDateCopy] = useState('');
    const [error, setError] = useState('');
    const [closecaseLoader, setCloseCaseLoader] = useState(false);
    const [openReason, setOpenReason] = useState(false);
    const [closeCaseComplete, setCloseCaseComplete] = useState(false);
    const [openPayroll, setOpenPayroll] = useState(false);
    const [continueOffboard, setContinueOffboard] = useState(false);
    const [empExists, setEmpExistis] = useState(false);
    const [openDuplicate, setOpenDuplicate] = useState(false);
    const [verifyLoader, setVerifyLoader] = useState(false);
    const [resonforclosingCase, setResonsforclosingCase] = useState([]);
    const [loginLoader, setLoginLoader] = useState(false);
    const [caseNumber, setCaseNumber] = useState('');
    const [employee, setEmployee] = useState({
        basic_details: {
            first_name: "",
            middle_name: "",
            last_name: "",
            date_of_birth: "",
            gender: "",
            blood_group: "",
            marital_status: "",
            full_name: ""
        },
        contact_details: {
            contact_number: "",
            alternate_contact_number: "",
            email_id: "",
            alternate_email: "",
        },
        current_address: {
            address_line_one: "",
            zip_code: "",
            city: "",
            state_id: "",
            country_id: "",
            state_name: '',
            country_name: ''
        },
        emergency_contacts: [{
            id: "",
            relationship_id: "",
            name: "",
            email_id: "",
            contact_number: "",
            address_1: "",
            city: "",
            zip_code: "",
            state_id: "",
            country_id: ""
        }],
        employment_details: {
            reference_id: "",
            date_of_joining: "",
            employment_type_id: "",
            employment_category_id: "",
            ssn: "",
            is_usc: "",
            visa_type_id: "",
            reporting_manager_id: "",
            department_id: "",
            team_id: "",
            role_id: "",
            vendor_id: '',
            vendor_price: '',
            showFullSSN: false
        },
        profile_picture_url: "",
        enable_login: "",
        profile_progress: "",
        e_verified: ""
    });
    const [documentBList, setDocumentBList] = useState([]);
    const [EmployeeProfile, setEmployeeProfile] = useState();// eslint-disable-next-line
    const [loader, setLoader] = useState(false);
    const [popUpOpen, setPopUpOpen] = useState(false);
    const [show, setShow] = useState("");
    const [loading, setLoading] = useState(false);
    const [formEdit, setformEdit] = useState(false);
    const [openPopup, setOpenpopup] = useState(false)
    const [stepItem, setStepItem] = useState('')
    const [activityTotal, setActivityTotal] = useState()
    const [activityData, setActivityData] = useState([])// eslint-disable-next-line
    const [openNewReason, setOpenNewReason] = useState(false);
    const [activityFilter, setActivityFilter] = useState({
        limit: 100,
        page: 1,
        search: null,
        request_id: LocalStorage.uid(),
        employee_id: ''
    })
    const [offboardData, setOffboardData] = useState({
        last_working_day: {
            date: '',
            status: true
        },
        placement: {
            status: false,
            data: []
        },
        disable_user_access: {
            status: false
        },
        send_revocation: {
            email_sent_to: '',
            date: '',
            disabled: false,
            proof_of_document: {
                id: '',
                document_name: '',
                document_url: ''
            },
            status: false
        },
        delete_mail: {
            email_deleted_on: '',
            proof_of_document: {
                id: '',
                document_name: '',
                document_url: ''
            },
            status: false
        },
        expense_settlement: {
            reimbursement_amount: '',
            reimbursement_payment: '',
            deduction_amount: '',
            deduction_payment: '',
            status: false
        },
        settle_balance: {
            total_balance: '',
            pay_via: '',
            status: false
        },
        finish_once: false,
        type: 1
    });
    const delayList = require('../../../../utils/jsons/ListDelayReason.json')
    const citizenshipStatus = [
        { label: <Text blackHeader2>A Citizen of the united States</Text>, value: 1 },
        { label: <Text blackHeader2> A Noncitizen of the united states</Text>, value: 2 },
        { label: <Text blackHeader2> A Law full permanent Resident</Text>, value: 3 },
        { label: <Text blackHeader2> A Noncitizen Authorized to Work</Text>, value: 4 },
    ];
    //everify
    const listItemsEv = [
        { label: <Text blackHeader2>List A Document</Text>, value: 1 },
        { label: <Text blackHeader2>List B & C Document </Text>, value: 2 },

    ];
    const drivingLicenseEv = [
        { label: <Text blackHeader2>Driver's License</Text>, value: 'DRIVERS_LICENSE' },
        { label: <Text blackHeader2>State Issued ID Card </Text>, value: 'STATE_ID_CARD' },
    ];
    const dayItemsEv = [
        { label: <Text blackHeader2>Today</Text>, value: 1 },
        { label: <Text blackHeader2>1 Day Ago </Text>, value: 2 },
        { label: <Text blackHeader2>2 Days Ago</Text>, value: 3 },
    ];
    const [refreshToken, setrefreshToken] = useState(false)
    const [popUpEv, setPopupEv] = useState(false);
    const disabledEv = false;
    const [loginEvPopup, setloginEvPopup] = useState(false);
    const [enableSSN, setEnableSSN] = useState(false);
    const [ssnNumber, setSSNNumber] = useState('');
    const [currentStepEv, setCurrentStepEv] = useState(0);
    const [documentAList, setDocumentAList] = useState([]);
    const [documentCList, setDocumentClist] = useState([]);
    const [eye, setEye] = useState({
        password: false,
        confirmpassword: false
    });
    const [errorEv, setErrorEv] = useState({});
    const [ssnLoader, setSSNLoader] = useState(false);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [hiredBeforeThreeDays, sethiredBeforeThreeDays] = useState(false);
    const [openEnd, setOpenEnd] = useState(false);
    const [countriesList, setCountriesList] = useState([]);
    const [stateEv, setStateEv] = useState({
        request_id: LocalStorage.uid(),
        first_name: '',
        middle_name: '',
        last_name: '',
        other_last_name_used: '',
        email_address: '',
        client_company_name: '',
        expiration_date: '',
        date_of_birth: '',
        date_of_hire: '',
        alien_number: '',
        us_passport_number: '',
        issued_state_idproof: '',
        doc_number_aaa: '',
        doc_number_six_to_twelve_digits: '',
        ssn: '',
        list_one_document_type_code: '',
        list_two_document_type_code: '',
        list_three_document_type_code: '',
        issued_state_id: '',
        issued_country_id: '',
        reason_for_delay: '',
        ssn_document_optional: '',
        ssn_url: '',
        ssn_name: '',
        ssn_id: '',
        document_type: '',
        citizen_type: '',
        radioStartDate: 1,
        issued_idproof_type: '',
        radioc4Additional: '',
        radioc4Additional2: '',
        no_expiration_date: false,
        reason_for_delay_description: '',
        user_name: '',
        password_name: '',
        sevis_number: '',
        visa_number: '',
        i94_number: '',
        foreign_passport_number: '',
        case_closure_reason_code: '',
        other_reason_description: '',
        reason_for_new_case: '',
        new_case_description: '',
        currently_employed_reason_description: '',
        employeeID: employee.employment_details.reference_id
    });
    const [noErrorEvStep1, setnoErrorEvStep1] = useState(false);
    const [states, setStates] = useState([]);
    const reasonforNewCase = [
        {
            id: 'REHIRE',
            value: 'Rehire'
        },
        {
            id: 'INCORRECT_INFORMATION_ENTERED',
            value: 'Incorrect Information entered'
        },
        {
            id: 'DHS_ASKED_ME_TO_CREATE_A_NEW_CASE',
            value: 'DHS asked me to  create a new case'
        },
        {
            id: 'SSA_ASKED_ME_TO_CREATE_A_NEW_CASE',
            value: 'SSA asked me to create a new case'
        },
        {
            id: 'PREVIOUS_CASE_RESULT_RESUBMIT',
            value: 'Previous case result resubmit'
        },
        {
            id: 'OTHER',
            value: 'other'
        }
    ]

    const statesList = () => {
        CommonApi.getStatesList('', 'USA').then((res) => {
            if (res.data.statusCode === 1003) {
                setStates(res.data.data);
            }
        })
    }

    const conutiresDropdown = () => {
        CommonApi.getCountryListInitial(LocalStorage.getAccessToken()).then((res) => {
            if (res.data.statusCode === 1003) {
                setCountriesList(res.data.data);
            }
        })
    }

    useEffect(() => {
        statesList();
        conutiresDropdown();
        setErrorEv({})
        const { basic_details, contact_details, employment_details } = employee;
        const { first_name, last_name, middle_name, dob } = basic_details;
        const { email_id } = contact_details;
        const { ssn, reference_id } = employment_details;
        setStateEv((prevState) => ({
            ...prevState, first_name: first_name, last_name: last_name, middle_name: middle_name, date_of_birth: dob
            , ssn: ssn, email_address: email_id, date_of_hire: moment().format(dateFormat()), employeeID: reference_id, radioStartDate: 1
        }))
        setSSNNumber(ssn);
    }, [employee])

    useEffect(() => {
        if (
            stateEv.first_name === '' ||
            stateEv.last_name === '' ||
            stateEv.date_of_birth === '' ||
            stateEv.email_address === '' ||
            stateEv.client_company_name === '' ||
            stateEv.ssn === ''
        ) {
            setnoErrorEvStep1(false);
        } else {
            setnoErrorEvStep1(true);
        }
    }, [stateEv.first_name, stateEv.last_name, stateEv.date_of_birth, stateEv.middle_name, stateEv.email_address, stateEv.client_company_name, stateEv.ssn, stateEv])

    const caseCloseReasonApi = (case_number) => {
        EverifyApi.caseCloselist(case_number).then((res) => {
            if (res.data.statusCode === 1003) {
                setEnableSSN(false);
                setResonsforclosingCase(res.data.data);
            }
        })
    }

    const checkCase = () => {
        if (ssnValidator.test(ssnNumber)) {
            stateEv.ssn = ssnNumber
            setStateEv({ ...stateEv })
            setSSNLoader(true);
            EverifyApi.ssnduplicateCheck(ssnNumber, employee.id).then((res) => {
                setSSNLoader(false);
                if (res.data.statusCode === 1003) {
                    setCaseNumber(res.data.case_number);
                    if (res.data.duplicare_case_scenerio == 0) {
                        setPopupEv(true);
                        setEnableSSN(false);
                        setCurrentStepEv(1);
                    } else if (res.data.duplicare_case_scenerio == 1) {
                        caseCloseReasonApi(res.data.case_number);
                        setOpenDuplicate(true);
                    } else if (res.data.duplicare_case_scenerio == 2) {
                        // setPopupEv(true);
                        setOpenNewReason(true);
                        setEnableSSN(false);
                    } else if (res.data.duplicare_case_scenerio == 3) {
                        setEnableSSN(false);
                    }
                } else {
                    addErrorMsg(res.data.message);
                }
            })
        } else {
            if (ssnNumber == '') {
                setErrorEv({ ...errorEv, ssnNumber: 'This field is required' });
            } else {
                setErrorEv({ ...errorEv, ssnNumber: 'Invalid SSN, please check the format' });
            }
        }
    }

    const cancelCheckcase = () => {
        setEnableSSN(false);
        if (stateEv.ssn == '' || stateEv.ssn == null) {
            setSSNNumber('')
        }
        stateEv.user_name = ''
        stateEv.password_name = ''
        setStateEv({ ...stateEv })
    }

    const cancelButton = () => {
        setCurrentStepEv(1);
        setPopupEv(false);
        setErrorEv({});
        const { basic_details, contact_details, employment_details } = employee;
        const { first_name, last_name, middle_name, dob } = basic_details;
        const { email_id } = contact_details;
        const { ssn, reference_id } = employment_details;
        setStateEv((prevState) => ({
            ...prevState, first_name: first_name, last_name: last_name, middle_name: middle_name, date_of_birth: dob
            , ssn: ssn, email_address: email_id, date_of_hire: moment().format(dateFormat()), employeeID: reference_id, radioStartDate: 1
        }))
        setSSNNumber(ssn);

    }

    const documentTypesApi = (citizenId) => {
        EverifyApi.documentTypes(citizenId).then((res) => {
            if (res.data.statusCode === 1003) {
                setDocumentAList(res.data.data.List_A);
                setDocumentBList(res.data.data.List_B);
                setDocumentClist(res.data.data.List_C);
            } else {
                addWarningMsg(res.data.message);
            }
        })
    }

    const submitValidations = () => {
        const { first_name, last_name, middle_name, date_of_birth, ssn, email_address, client_company_name, list_one_document_type_code, us_passport_number, expiration_date, doc_number_aaa, doc_number_six_to_twelve_digits, i94_number, issued_country_id, foreign_passport_number
            , list_two_document_type_code, issued_state_id, issued_state_idproof, list_three_document_type_code, radioc4Additional, radioc4Additional2, document_type, citizen_type, alien_number
            , visa_number, sevis_number, issued_idproof_type, reason_for_delay_description, reason_for_delay, employeeID, other_last_name_used } = stateEv;
        let errors = {}
        const arrayCitizenShipValues = ['1', '2', '3', '4']
        errors.first_name = currentStepEv == 1 ? validate_charWithSpace(first_name) : '';
        errors.last_name = currentStepEv == 1 ? validate_charWithSpace(last_name) : '';
        errors.other_last_name_used = currentStepEv == 1 ? empty_name(other_last_name_used) : '';
        errors.middle_name = currentStepEv == 1 ? everify_middle_name(middle_name) : '';
        errors.date_of_birth = currentStepEv == 1 ? validate_emptyField(date_of_birth) : '';
        errors.email_address = currentStepEv == 1 ? validates_emailId(email_address) : '';
        errors.ssn = ssn == "" ? currentStepEv == 1 ? validate_emptyField(ssn) : validate_ssn(ssn) : '';
        errors.client_company_name = currentStepEv == 1 ? validate_charWithSpace(client_company_name) : '';
        errors.document_type = currentStepEv == 2 ? validate_emptyField(document_type) : '';
        errors.citizen_type = currentStepEv == 2 ? validate_emptyField(citizen_type) : '';
        errors.list_one_document_type_code = currentStepEv == 2 && (arrayCitizenShipValues.includes(citizen_type) && document_type == '1' && list_one_document_type_code == '') ? validate_emptyField(list_one_document_type_code) : '';
        errors.list_two_document_type_code = currentStepEv == 2 && (arrayCitizenShipValues.includes(citizen_type) && document_type == '2' && (list_two_document_type_code == '')) ? validate_emptyField(list_two_document_type_code) : '';
        errors.list_three_document_type_code = currentStepEv == 2 && (arrayCitizenShipValues.includes(citizen_type) && document_type == 2 && (list_three_document_type_code == '' || list_two_document_type_code == 'us_dl_or_id_card_4_2')) ? validate_emptyField(list_three_document_type_code) : '';
        errors.issued_state_id = currentStepEv == 2 && (arrayCitizenShipValues.includes(citizen_type) && document_type == 2 && (list_two_document_type_code == 'us_dl_or_id_card_1_2' || list_two_document_type_code == 'us_dl_or_id_card_2_2' || list_two_document_type_code == 'us_dl_or_id_card_3_2' || list_two_document_type_code == 'us_dl_or_id_card_4_2')) ? validate_emptyField(issued_state_id) : '';
        errors.issued_state_idproof = currentStepEv == 2 && (arrayCitizenShipValues.includes(citizen_type) && document_type == 2 && (list_two_document_type_code == 'us_dl_or_id_card_1_2' || list_two_document_type_code == 'us_dl_or_id_card_2_2' || list_two_document_type_code == 'us_dl_or_id_card_3_2' || list_two_document_type_code == 'us_dl_or_id_card_4_2')) ? validate_emptyField(issued_state_idproof) : '';
        errors.i94_number = currentStepEv == 2 && (citizen_type == 4 && ((document_type == 1 && (list_one_document_type_code == 'foreign_passport_with_arrive_dep_i94_4_1' || list_one_document_type_code == 'arrival_departure_i94_temp_i551_or_refugee_stamp_4_1') && radioc4Additional2 == 2)
            || (citizen_type == 4 && document_type == 2 && list_three_document_type_code !== '' && radioc4Additional2 == 2) || (document_type == 2 && list_two_document_type_code != '' && radioc4Additional == 2))) ? validate_emptyField(i94_number) : '';
        errors.issued_country_id = currentStepEv == 2 && ((citizen_type == 3 && list_one_document_type_code == 'foreign_passport_temp_i551_mrv_notation_3_1') ||
            (citizen_type == 4 && document_type == 1 && (list_one_document_type_code == 'foreign_passport_with_arrive_dep_i94_4_1' && (radioc4Additional2 == 2 || radioc4Additional2 == 1))) ||
            (citizen_type == 4 && document_type == 2 && list_three_document_type_code !== '' && radioc4Additional2 == 3) ||
            (citizen_type == 4 && (list_two_document_type_code != '' && radioc4Additional == '3')) ||
            (citizen_type == 4 && document_type == 1 && (list_one_document_type_code == 'arrival_departure_i94_temp_i551_or_refugee_stamp_4_1') && radioc4Additional2 == 2)
        ) ? validate_emptyField(issued_country_id) : '';
        errors.foreign_passport_number = currentStepEv == 2 && (citizen_type == '4' &&
            ((document_type == '1' && (list_one_document_type_code == 'foreign_passport_with_arrive_dep_i94_4_1') && (radioc4Additional2 == '2' || radioc4Additional2 == '1'))
                || (citizen_type == 4 && document_type == 2 && list_three_document_type_code !== '' && radioc4Additional2 == 3) || (list_one_document_type_code == 'arrival_departure_i94_temp_i551_or_refugee_stamp_4_1' && radioc4Additional2 == 2) || (document_type == 2 && list_two_document_type_code != '' && radioc4Additional == '3'))
        ) ? validate_emptyField(foreign_passport_number) : '';
        errors.alien_number = currentStepEv == 2 &&
            ((citizen_type == '3' && document_type == 1 && list_one_document_type_code !== '') ||
                (citizen_type == '3' && document_type == 1 && (list_two_document_type_code !== '')) || (citizen_type == 3 && document_type == 2 && list_three_document_type_code !== '') ||
                (citizen_type == 3 && document_type == 2 && (issued_idproof_type == 'DRIVERS_LICENSE' || issued_idproof_type == 'STATE_ID_CARD') && list_two_document_type_code == 'us_dl_or_id_card_3_2') ||
                (citizen_type == 4 && (document_type == 1 && ((list_one_document_type_code == 'arrival_departure_i94_temp_i551_or_refugee_stamp_4_1') && radioc4Additional2 == 1))) ||
                (citizen_type == 4 && ((list_one_document_type_code == 'employment_auth_doc_i766_4_1') && document_type == 1)) ||
                (citizen_type == 4 && document_type == 2 && list_three_document_type_code !== '' && radioc4Additional2 == 1) ||
                (citizen_type == 4 && list_one_document_type_code == 'foreign_passport_with_arrive_dep_i94_4_1' && radioc4Additional2 == 1) ||
                (citizen_type == 4 && document_type == 1 && list_two_document_type_code != '' && radioc4Additional == '1')) ? validate_emptyField(alien_number) : '';
        errors.doc_number_six_to_twelve_digits = currentStepEv == 2 && (citizen_type == '3' && document_type == '1' && list_one_document_type_code == 'foreign_passport_temp_i551_mrv_notation_3_1') ? validate_doc6to12Number(doc_number_six_to_twelve_digits) : '';
        errors.doc_number_aaa = currentStepEv == 2 && (citizen_type == 3 || citizen_type == 4) && (list_one_document_type_code == 'permanent_resident_alien_registration_i551_3_1' || list_one_document_type_code == 'employment_auth_doc_i766_4_1') ? validate_docaaaNUmber(doc_number_aaa) : '';
        errors.us_passport_number = currentStepEv == 2 && ((citizen_type == '1' || citizen_type == '2') && document_type == '1' && list_one_document_type_code !== '') ? valdoc6to9number(us_passport_number) : '';
        errors.expiration_date = currentStepEv == 2 && (((citizen_type == '1' || citizen_type == '2' || citizen_type == '4') && (document_type == '1' || document_type == 2) && (list_one_document_type_code == 'us_passport_or_card_1_1' || list_one_document_type_code == 'us_passport_or_card_2_1' || list_one_document_type_code == 'employment_auth_doc_i766_4_1' || list_one_document_type_code == 'foreign_passport_with_arrive_dep_i94_4_1' || (list_two_document_type_code == 'us_dl_or_id_card_1_2' || list_two_document_type_code == 'us_dl_or_id_card_2_2' || list_two_document_type_code == 'us_dl_or_id_card_3_2' || list_two_document_type_code == 'us_dl_or_id_card_4_2' || (list_one_document_type_code == 'arrival_departure_i94_temp_i551_or_refugee_stamp_4_1' && radioc4Additional2 == 2)))) ||
            (citizen_type == 3 && (document_type == 1 || document_type == 2) && (list_one_document_type_code == 'foreign_passport_temp_i551_mrv_notation_3_1' || list_one_document_type_code == 'arrival_departure_i94_temp_i551_or_refugee_stamp_3_1' || list_two_document_type_code == 'us_dl_or_id_card_3_2'))
        ) ? stateEv.no_expiration_date ? '' : validate_emptyField(expiration_date, stateEv.date_of_hire) : '';
        errors.radioc4Additional2 = currentStepEv == 2 && ((citizen_type == '4' && document_type == '1' && list_one_document_type_code !== 'employment_auth_doc_i766_4_1' && radioc4Additional2 == '') || (citizen_type == 4 && document_type == 2 && list_three_document_type_code !== '')) ? validate_emptyField(radioc4Additional2) : '';
        errors.issued_idproof_type = currentStepEv == 2 && ((arrayCitizenShipValues.includes(citizen_type)) && document_type == '2' && (stateEv.list_two_document_type_code == 'us_dl_or_id_card_1_2' || stateEv.list_two_document_type_code == 'us_dl_or_id_card_2_2' || stateEv.list_two_document_type_code == 'us_dl_or_id_card_3_2' || stateEv.list_two_document_type_code == 'us_dl_or_id_card_4_2')) ? validate_emptyField(issued_idproof_type) : ''
        errors.visa_number = (currentStepEv == 3 && citizen_type == 4 && (document_type == 1 || document_type == 2)) ? everify_visa(visa_number) : ''
        errors.sevis_number = (currentStepEv == 3 && citizen_type == 4) && ((document_type == 2) || (document_type == 1 && (list_one_document_type_code == 'arrival_departure_i94_temp_i551_or_refugee_stamp_4_1' || list_one_document_type_code == 'foreign_passport_with_arrive_dep_i94_4_1'))) ? validate_sevisnumber(sevis_number) : ''
        errors.reason_for_delay_description = currentStepEv == 3 && (hiredBeforeThreeDays && stateEv.reason_for_delay == 'OTHER') ? validate_emptyField(reason_for_delay_description) : ''
        errors.reason_for_delay = currentStepEv == 3 && hiredBeforeThreeDays ? validate_emptyField(reason_for_delay) : '';
        errors.employeeID = currentStepEv == 3 ? everify_employeeId(employeeID) : ''
        return errors;
    }

    const handleCreateReasonLvalidations = () => {
        const { reason_for_new_case, new_case_description } = stateEv
        let errors = {};
        errors.reason_for_new_case = currentStepEv == 0 ? validate_emptyField(reason_for_new_case) : '';
        errors.new_case_description = currentStepEv == 0 && stateEv.reason_for_new_case == 'OTHER' ? validate_emptyField(new_case_description) : '';
        return errors
    }

    const handleCreateEverify = () => {
        let errors = handleCreateReasonLvalidations()
        if (isValid(errors)) {
            setOpenNewReason(false);
            setCurrentStepEv(1);
            setPopupEv(true);
        } else {
            let s1 = { errorEv }
            s1 = errors
            setErrorEv(s1);
            addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
        }
    }

    const handleSubmitEverify = () => {
        let basicErrors = submitValidations();
        if (isValid(basicErrors)) {
            if (currentStepEv == 3) {
                const isBefore = moment(stateEv.expiration_date).isBefore(moment(stateEv.date_of_hire));
                if (isBefore) {
                    addErrorMsg('Document expiration date must be on or after the Employee’s First Day of Employment')
                } else {
                    createCase()
                }
            } else {
                setCurrentStepEv(currentStepEv + 1);
            }
        }
        else {
            let s1 = { errorEv }
            s1 = basicErrors
            setErrorEv(s1);
            addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
        }
    };

    const createCase = () => {
        setSubmitLoader(true);
        if (stateEv.doc_number_six_to_twelve_digits !== '') {
            stateEv['foreign_passport_number'] = stateEv.doc_number_six_to_twelve_digits
        }
        stateEv['i551_number'] = stateEv.citizen_type == '3' ? stateEv.doc_number_aaa : ''
        stateEv['i766_number'] = stateEv.citizen_type == '4' ? stateEv.doc_number_aaa : ''
        stateEv['no_expiration_date'] = stateEv.no_expiration_date == true ? stateEv.no_expiration_date : ''
        stateEv['other_last_name_used'] = stateEv.other_last_name_used == '' ? [] : [stateEv.other_last_name_used]
        stateEv['request_id'] = LocalStorage.uid()
        EverifyApi.storeCase(employee.id, stateEv).then((response) => {
            setSubmitLoader(false);
            if (response.data.statusCode == 1003) {
                setCurrentStepEv(1)
                // setFinishPopup(true);
                setOpenEnd(true);
                setPopupEv(false);
            } else {
                addErrorMsg(response.data.error_message ? response.data.error_message : response.data.message);
            }
        });
    }

    const handleRadio1ChangeEv = (event) => {
        if (stateEv.citizen_type !== '' && event.target.value !== '') {
            documentTypesApi(stateEv.citizen_type);
        }
        setStateEv({
            ...stateEv,
            document_type: event.target.value,
            issued_idproof_type: '',
            issued_state_id: '',
            issued_country_id: '',
            sevis_number: '',
            visa_number: '',
            i94_number: '',
            foreign_passport_number: '',
            expiration_date: '',
            alien_number: '',
            us_passport_number: '',
            doc_number_aaa: '',
            doc_number_six_to_twelve_digits: '',
            issued_state_idproof: '',
            list_two_document_type_code: '',
            list_three_document_type_code: '',
            list_one_document_type_code: ''
        });
        setErrorEv({});
    }

    const handleRadio2ChangeEv = (event) => {
        if (event.target.value == '1') {
            setStateEv({ ...stateEv, date_of_hire: moment().format(dateFormat()), radioStartDate: event.target.value, reason_for_delay: '', reason_for_delay_description: '' })
        } else if (event.target.value == '2') {
            setStateEv({ ...stateEv, date_of_hire: moment().subtract(1, 'days').format(dateFormat()), radioStartDate: event.target.value, reason_for_delay: '', reason_for_delay_description: '' })
        } else if (event.target.value == '3') {
            setStateEv({ ...stateEv, date_of_hire: moment().subtract(2, 'days').format(dateFormat()), radioStartDate: event.target.value, reason_for_delay: '', reason_for_delay_description: '' })
        }
        sethiredBeforeThreeDays(false);
    }

    const handleRadio3ChangeEv = (event) => {
        setStateEv({ ...stateEv, citizen_type: event.target.value, document_type: '' })
        if (stateEv.document_type !== '' && event.target.value !== '') {
            documentTypesApi(event.target.value)
        }
    }

    const handleRadio4ChangeEv = (event) => {
        setStateEv({
            ...stateEv,
            issued_idproof_type: event.target.value,
            issued_state_id: '',
            issued_country_id: '',
            sevis_number: '',
            visa_number: '',
            i94_number: '',
            foreign_passport_number: '',
            expiration_date: '',
            alien_number: '',
            us_passport_number: '',
            doc_number_aaa: '',
            doc_number_six_to_twelve_digits: '',
            issued_state_idproof: '',
        })
        setErrorEv({});
    }

    const handleRadio6ChangeEv = (event) => {
        setStateEv({
            ...stateEv,
            radioc4Additional2: event.target.value,
            i94_number: '',
            foreign_passport_number: '',
            alien_number: '',
            issued_country_id: ''
        })
        setErrorEv({})
    }

    const handleChangeExpiry = (event) => {
        if (event.target.checked) {
            setErrorEv((prevState) => ({ ...prevState, expiration_date: '' }))
            setStateEv({ ...stateEv, no_expiration_date: event.target.checked })
        }
        setStateEv({ ...stateEv, no_expiration_date: event.target.checked, expiration_date: "" })
    }

    // const deleteDocEv = () => {
    //     stateEv.ssn_id = ''
    //     stateEv.ssn_url = ''
    //     stateEv.ssn_name = ''
    //     setStateEv({ ...stateEv })
    // }

    const handleSsnNumber = (e, name) => {
        const value = e.target.value;
        const input = value.replace(/\D/g, '').substring(0, 9);


        const first = input.substring(0, 3);
        const middle = input.substring(3, 5);
        const last = input.substring(5, 9);
        if (name == 'ssnNumber') {
            if (input.length > 5) {
                var val = `${first}-${middle}-${last}`
                if (last == '0000') {
                    errorEv['ssn'] = 'Invalid SSN, please check the format '
                    setErrorEv({ ...errorEv })
                    return true
                }
                setSSNNumber(val)
            }
            else if (input.length > 3) {
                var val2 = `${first}-${middle}`
                if (middle == '00') {
                    errorEv['ssn'] = 'Invalid SSN, please check the format '
                    setErrorEv({ ...errorEv })
                    return true
                }
                setSSNNumber(val2)
            }
            else if (input.length >= 0) {
                if (first == '000' || first == '666' || first == '900') {
                    errorEv['ssn'] = 'Invalid SSN, please check the format '
                    setErrorEv({ ...errorEv })
                    return true
                }
                setSSNNumber(input);
            }
            handleValidateEv(e.target);
        }
        else {
            if (input.length > 5) {
                setStateEv({ ...stateEv, [e.target.name]: `${first}-${middle}-${last}` });
                if (last == '0000') {
                    errorEv['ssn'] = 'Invalid SSN, please check the format '
                    setErrorEv({ ...errorEv })
                    return true
                }
            }
            else if (input.length > 3) {
                setStateEv({ ...stateEv, [e.target.name]: `${first}-${middle}` });
                if (middle == '00') {
                    errorEv['ssn'] = 'Invalid SSN, please check the format '
                    setErrorEv({ ...errorEv })
                    return true
                }
            }
            else if (input.length >= 0) {
                setStateEv({ ...stateEv, [e.target.name]: input });
                if (first == '000' || first == '666' || first == '900') {
                    errorEv['ssn'] = 'Invalid SSN, please check the format '
                    setErrorEv({ ...errorEv })
                    return true
                }
            }
            handleValidateEv(e.target);
        }
    }

    const changeHandlerEv = (e) => {
        if (e.target.name === "ssn" || e.target.name == 'ssnNumber') {
            if (e.target.value.length <= 11) {
                handleSsnNumber(e, e.target.name)
            }
        } else if (e.target.name === "alien_number") {
            if (e.target.value.length <= 10) {
                let valAnumber = e.target.value.substring(0, 10)
                setStateEv({
                    ...stateEv,
                    [e.target.name]: valAnumber
                }, handleValidateEv(e.target))
            }
        } else if (e.target.name === 'sevis_number') {
            if (e.target.value.length <= 11) {
                let valSevisnumber = e.target.value.substring(0, 11)
                setStateEv({
                    ...stateEv,
                    [e.target.name]: valSevisnumber
                }, handleValidateEv(e.target))
            }
        } else if (e.target.name === 'visa_number') {
            if (e.target.value.length <= 8) {
                let valVisanumber = e.target.value.substring(0, 8)
                setStateEv({
                    ...stateEv,
                    [e.target.name]: valVisanumber
                }, handleValidateEv(e.target))
            }
        }
        else if (e.target.name === 'i94_number') {
            if (e.target.value.length <= 11) {
                let vali94number = e.target.value.substring(0, 11);
                setStateEv({
                    ...stateEv,
                    [e.target.name]: vali94number
                }, handleValidateEv(e.target))
            }
        }
        else if (e.target.name === 'foreign_passport_number') {
            if (e.target.value.length <= 12) {
                let valforeignpassportnumber = e.target.value.substring(0, 12);
                setStateEv({
                    ...stateEv,
                    [e.target.name]: valforeignpassportnumber
                }, handleValidateEv(e.target))
            }
        }
        else if (e.target.name === 'doc_number_aaa') {
            if (e.target.value.length <= 13) {
                let valdocaaanumber = e.target.value.substring(0, 13);
                setStateEv({
                    ...stateEv,
                    [e.target.name]: valdocaaanumber
                }, handleValidateEv(e.target))
            }
        }
        else if (e.target.name === 'doc_number_six_to_twelve_digits') {
            if (e.target.value.length <= 12) {
                let valdoc6to12number = e.target.value.substring(0, 12);
                setStateEv({
                    ...stateEv,
                    [e.target.name]: valdoc6to12number
                }, handleValidateEv(e.target))
            }
        }
        else if (e.target.name === 'us_passport_number') {
            if (e.target.value.length <= 9) {
                let valdoc6to9number = e.target.value.substring(0, 9);
                setStateEv({
                    ...stateEv,
                    [e.target.name]: valdoc6to9number
                }, handleValidateEv(e.target))
            }
        }
        else if (e.target.name === 'issued_state_idproof') {
            if (e.target.value.length <= 17) {
                let valdocDrivingNumber = e.target.value.substring(0, 17);
                setStateEv({
                    ...stateEv,
                    [e.target.name]: valdocDrivingNumber
                }, handleValidateEv(e.target))
            }
        } else if (e.target.name == 'employeeID') {
            setStateEv({
                ...stateEv,
                [e.target.name]: e.target.value
            }, handleValidateEv(e.target))
        } else if (e.target.name == 'list_two_document_type_code' || e.target.name == 'list_one_document_type_code') {
            setStateEv({
                ...stateEv,
                [e.target.name]: e.target.value,
                issued_idproof_type: '',
                expiration_date: '',
                alien_number: '',
                document_name: '',
                doc_number_aaa: '',
                doc_number_six_to_twelve_digits: '',
                radioc4Additional2: '',
                issued_country_id: ''
            }, handleValidateEv(e.target))
            setErrorEv({});
        } else if (e.target.name == 'list_three_document_type_code') {
            setStateEv({
                ...stateEv,
                [e.target.name]: e.target.value,
                alien_number: '',
                doc_number_aaa: '',
                doc_number_six_to_twelve_digits: '',
                radioc4Additional2: ''
            }, handleValidateEv(e.target))
            setErrorEv({});
        }
        else {
            setStateEv({
                ...stateEv,
                [e.target.name]: e.target.value
            }, handleValidateEv(e.target))
        }
    }

    const dateChangeEv = (e, name) => {
        let date = e == null ? '' : e.$d
        let event = {
            target: {
                name: name,
                value: date
            }
        }
        setStateEv({
            ...stateEv,
            [name]: date == '' ? '' : moment(date).format(dateFormat()),
        }, handleValidateEv(event.target))

    }
    const dateChangeEvAdditionalStep = (e, name) => {
        let date = e == null ? '' : e.$d
        let event = {
            target: {
                name: name,
                value: date
            }
        }
        const dateExtracted = moment(date);
        const today = moment().startOf('day');
        const getDate = moment()

        const twoDaysAgo = getDate.clone().subtract(2, 'days');
        const oneDayAgo = getDate.clone().subtract(1, 'days');
        const isToday = dateExtracted.isSame(today, 'day');
        const isTwoDaysAgo = dateExtracted.isSame(twoDaysAgo, 'day');
        const isOneDayAgo = dateExtracted.isSame(oneDayAgo, 'days')
        const isMoreThanThreeDaysAfter = dateExtracted.isBefore(twoDaysAgo, 'day');
        let dateCheck = '';
        if (isToday) {
            dateCheck = '1'
        } else if (isOneDayAgo) {
            dateCheck = '2'
        } else if (isTwoDaysAgo) {
            dateCheck = '3'
        } else if (isMoreThanThreeDaysAfter) {
            dateCheck = ''
        }

        let dateCheckBeforeThreeDays;
        if (isMoreThanThreeDaysAfter) {
            dateCheckBeforeThreeDays = true
        } else {
            dateCheckBeforeThreeDays = false
        }
        setStateEv({
            ...stateEv,
            [name]: date == '' ? '' : moment(date).format(dateFormat()), radioStartDate: dateCheck,
        }, handleValidateEv(event.target))
        sethiredBeforeThreeDays(dateCheckBeforeThreeDays)
    }

    const handleValidateEv = (input) => {
        switch (input.name || input.tagName) {
            case 'first_name':
                errorEv.first_name = validate_charWithSpace(input.value, 'first ')
                break
            case 'last_name':
                errorEv.last_name = validate_charWithSpace(input.value, 'last ')
                break
            case 'middle_name':
                errorEv.middle_name = everify_middle_name(input.value, 'middle ')
                break
            case 'email_address':
                errorEv.email_address = validates_emailId(input.value, 'employee email id ')
                break
            case 'date_of_birth':
                errorEv.date_of_birth = validate_emptyField(input.value)
                break
            case 'date_of_hire':
                errorEv.date_of_hire = validate_emptyField(input.value)
                break
            case 'expiration_date':
                errorEv.expiration_date = validate_emptyField(input.value);
                break
            case 'other_last_name_used':
                errorEv.other_last_name_used = empty_name(input.value);
                break
            case 'client_company_name':
                errorEv.client_company_name = validate_charWithSpace(input.value, 'client company ')
                break
            case 'list_one_document_type_code':
                errorEv.list_one_document_type_code = validate_emptyField(input.value, 'list A document ')
                break
            case 'list_two_document_type_code':
                errorEv.list_two_document_type_code = validate_emptyField(input.value, 'list B document ')
                break
            case 'list_three_document_type_code':
                errorEv.list_three_document_type_code = validate_emptyField(input.value, 'list C document ')
                break
            case 'issued_state_id':
                errorEv.issued_state_id = validate_emptyField(input.value, 'list B document ')
                break
            case 'us_passport_number':
                errorEv.us_passport_number = valdoc6to9number(input.value, 'document number ')
                break
            case 'ssn_document_optional':
                errorEv.ssn_document_optional = validate_emptyField(input.value)
                break
            case 'alien_number':
                errorEv.alien_number = validate_anumber(input.value, 'uscis number ')
                break
            case 'sevis_number':
                errorEv.sevis_number = validate_sevisnumber(input.value, 'sevis number')
                break
            case 'visa_number':
                errorEv.visa_number = everify_visa(input.value, 'VISA number')
                break
            case 'i94_number':
                errorEv.i94_number = validate_i94number(input.value, 'I94 number')
                break
            case 'foreign_passport_number':
                errorEv.foreign_passport_number = validate_foriegnPassportNumber(input.value, 'Foreign passport number')
                break
            case 'ssn':
                errorEv.ssn = validate_ssn(input.value)
                break
            case 'ssnNumber':
                errorEv.ssnNumber = validate_ssn(input.value)
                break
            case 'doc_number_aaa':
                errorEv.doc_number_aaa = validate_docaaaNUmber(input.value, 'Document Number')
                break
            case 'doc_number_six_to_twelve_digits':
                errorEv.doc_number_six_to_twelve_digits = validate_doc6to12Number(input.value, 'Document number')
                break
            case 'issued_state_idproof':
                errorEv.issued_state_idproof = validate_doc1to17Number(input.value, 'Document number')
                break
            case 'issued_country_id':
                errorEv.issued_country_id = validate_emptyField(input.value, 'country ');
                break;
            case 'reason_for_delay':
                errorEv.reason_for_delay = validate_emptyField(input.value, 'delay reason ');
                break;
            case 'reason_for_delay_description':
                errorEv.reason_for_delay_description = validate_emptyField(input.value, 'content reason ');
                break;
            case 'user_name':
                errorEv.user_name = validate_everify_username(input.value, 'User ID');
                break;
            case 'password_name':
                errorEv.password_name = validate_everify_password(input.value, 'password');
                break;
            case 'case_closure_reason_code':
                errorEv.case_closure_reason_code = validate_emptyField(input.value);
                break;
            case 'other_reason_description':
                errorEv.other_reason_description = validate_emptyField(input.value);
                break;
            case 'reason_for_new_case':
                errorEv.reason_for_new_case = validate_emptyField(input.value);
                break;
            case 'new_case_description':
                errorEv.new_case_description = validate_emptyField(input.value);
                break;
            case 'currently_employed_reason_description':
                errorEv.currently_employed_reason_description = validate_emptyField(input.value);
                break;
            case 'employeeID':
                errorEv.employeeID = everify_employeeId(input.value);
                break;
            default:
                break
        }
        setErrorEv({ ...errorEv });
    }

    const closecasevalidations = () => {
        const { case_closure_reason_code, other_reason_description, currently_employed_reason_description } = stateEv;
        let errors = {};
        errors.case_closure_reason_code = validate_emptyField(case_closure_reason_code);
        errors.other_reason_description = case_closure_reason_code == 'OTHER_FREE_TEXT' ? validate_emptyField(other_reason_description) : ''
        errors.currently_employed_reason_description = case_closure_reason_code == 'EMPLOYEE_CONTINUES_TO_WORK_AFTER_FNC' ? validate_emptyField(currently_employed_reason_description) : ''
        return errors;
    }

    const closeCase = () => {
        setCloseCaseLoader(true);
        let errors = closecasevalidations();
        if (isValid(errors)) {
            const data = {
                request_id: LocalStorage.uid(),
                case_number: caseNumber,
                case_closure_reason_code: stateEv.case_closure_reason_code,
                other_reason_description: stateEv.other_reason_description,
                currently_employed_reason_description: stateEv.currently_employed_reason_description
            }
            EverifyApi.caseCloseApi(data).then((res) => {
                setCloseCaseLoader(false);
                if (res.data.statusCode === 1003) {
                    setOpenReason(false);
                    if (closeCaseComplete) {
                        getEmployeeApi();
                        window.location.reload();
                    } else {
                        setOpenNewReason(true);
                    }
                } else {
                    addErrorMsg(res.data.error_message ? res.data.error_message : res.data.message);
                }
            })
        } else {
            setCloseCaseLoader(false);
            let s1 = { errorEv }
            s1 = errors
            setErrorEv(s1);
            addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
        }

    }

    const checkRefreshToken = (args) => {
        setVerifyLoader(true);
        EverifyApi.everifyLogin().then((res) => {
            setVerifyLoader(false);
            if (res.data.statusCode === 1003) {
                if (res.data.allow_login) {
                    setloginEvPopup(true);
                } else {
                    if (args == 4 || args == 3) {
                        setOpenNewReason(true)
                    } else {
                        setEnableSSN(true);
                    }
                    stateEv.client_company_name = res.data.everify_company_name
                    setStateEv({ ...stateEv })
                }
            } else {
                addErrorMsg(res.data.message);
            }
        })
        stateEv['citizen_type'] = ''
        stateEv['document_type'] = ''
        stateEv['case_closure_reason_code'] = ''
        stateEv['currently_employed_reason_description'] = ''
        stateEv['reason_for_new_case'] = ''
        stateEv['new_case_description'] = ''
        stateEv['other_reason_description'] = ''
        setStateEv({ ...stateEv });
        setErrorEv({});
        setSSNNumber(employee.employment_details.ssn);
    }

    const formValidations = () => {
        const { user_name, password_name } = stateEv
        let errors = {};
        errors.user_name = validate_everify_username(user_name);
        errors.password_name = validate_everify_password(password_name);
        setErrorEv({ ...errors });
        return errors;
    };

    const handleLogin = (e) => {
        setLoginLoader(true);
        let errors = formValidations();
        if (isValid(errors)) {
            let data = {
                username: stateEv.user_name,
                password: stateEv.password_name,
                request_id: LocalStorage.uid(),
            }
            EverifyApi.Login(data).then((response) => {
                if (response.data.statusCode == 1003) {
                    setLoginLoader(false);
                    if (closeCaseComplete) {
                        setloginEvPopup(false)
                        setOpenReason(true);
                        setCloseCaseComplete(false);
                    }
                    else if (refreshToken) {
                        setloginEvPopup(false);
                        setrefreshToken(false)
                    }
                    else {
                        setloginEvPopup(false)
                        setEnableSSN(true);
                        setSSNNumber(employee.employment_details.ssn);
                        stateEv.client_company_name = response.data.everify_company_name
                        setStateEv({ ...stateEv })
                    }
                    const { basic_details, contact_details, employment_details } = employee;
                    const { first_name, last_name, middle_name, dob } = basic_details;
                    const { email_id } = contact_details;
                    const { ssn, reference_id } = employment_details;
                    setStateEv((prevState) => ({
                        ...prevState, first_name: first_name, last_name: last_name, middle_name: middle_name, date_of_birth: dob
                        , ssn: ssn, email_address: email_id, date_of_hire: moment().format(dateFormat()), employeeID: reference_id, radioStartDate: 1
                    }))
                    setSSNNumber(ssn);
                } else {
                    setLoginLoader(false);
                    addErrorMsg(response.data.message);
                }
            });
        } else {
            setLoginLoader(false);
            let err = { error };
            err = errors;
            setError(err);
            addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
        }
    };

    //everify
    const [limitExceed, setLimitExceed] = useState(false);
    const [finishPopup, setFinishPopup] = React.useState(false);
    const [cancelPopup, setCancelPopup] = React.useState(false);
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        "& .MuiDialog-paper ": {
            borderRadius: "16px",
            width: "500px"
        },
        "& .MuiDialogContent-root": {
            // padding: theme.spacing(2)
            // padding: theme.spacing(3)
        },
        "& .MuiDialogActions-root": {
            // padding: theme.spacing(1)
            // padding: theme.spacing(5)
        }
    }));
    const typeSno = {
        1: {
            disable: 1,
            delete: 2,
            expense: 3,
            settle: 4,
            placement: '',
            send: '',
            // bills: ''
        },
        2: {
            placement: 1,
            disable: 2,
            send: 3,
            delete: 4,
            expense: 5,
            settle: 6,
            // bills: ''
        },
        3: {
            placement: 1,
            disable: 2,
            delete: 3,
            expense: 4,
            // bills: 4,
            settle: 5,
            send: ''
        }
    }
    const semicircle = {
        series: [employee.off_boarding_percentage],
        options: {
            chart: {
                type: 'radialBar',
                offsetY: 0,
                sparkline: {
                    enabled: false
                }
            },
            states: {
                hover: {
                    filter: {
                        type: 'none'
                    }
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: 'none'
                    }
                }
            },
            stroke: {
                lineCap: "round",
                curve: 'smooth',
            },
            fill: {
                type: 'solid',
                colors: [
                    employee.employment_details?.employment_type_id == 3 ?
                        (employee.off_boarding_percentage >= 20 && employee.off_boarding_percentage < 60 ? `${orange}` : employee.off_boarding_percentage >= 60 ? '#22C55E' : '') :
                        employee.employment_details.employment_type_id == 2 ?
                            (employee.off_boarding_percentage >= 16 && employee.off_boarding_percentage <= 33.33 ? `${orange}` : employee.off_boarding_percentage > 33.33 && employee.off_boarding_percentage <= 50 ? '#4ADE80' : employee.off_boarding_percentage > 50 ? '#22C55E' : '') :
                            (employee.off_boarding_percentage > 20 && employee.off_boarding_percentage < 75 ? `${orange}` : employee.off_boarding_percentage >= 75 && employee.off_boarding_percentage < 100 ? '#4ADE80' : employee.off_boarding_percentage == 100 ? '#22C55E' : '')]
            },
            plotOptions: {
                radialBar: {
                    startAngle: -90,
                    endAngle: 90,
                    track: {
                        background: "#e7e7e7",
                        strokeWidth: '97%',
                        margin: 5, // margin is in pixels    
                        borderRadius: 30,
                        startingShape: 'rounded',
                        endingShape: "rounded",
                    },
                    dataLabels: {
                        name: {
                            show: false
                        },
                        value: {
                            offsetY: 0,
                            fontSize: '22px',
                            color: '#fff',
                            fontWeight: `${700} !important`,
                            fontFamily: 'Quicksand !important'
                        }
                    }
                }
            },
            grid: {
                padding: {
                    top: -10
                }
            },
        },
    }

    useEffect(() => {
        if (location.state && location.state.from != undefined) {
            if (location.state.from == 'employmentDashboard') {
                setCurrent('General Details')
            }
        }
        else {
            setCurrent('General Details')
        }
        if (LocalStorage.getRedirectedModule() !== null && LocalStorage.getRedirectedModule() !== undefined && (LocalStorage.getRedirectedModule() == 'employee_userprofile' || LocalStorage.getRedirectedModule() == 'employee-profile')) {
            setCurrent('General Details');
        }
        else {
            setCurrent('General Details')
        }
        if (page == 'placementAdd') {
            setCurrent('Bank Details');
        } else if (page == 'i94') {
            setdocumentPosition('work');
        } else if (page == 'visa') {
            setdocumentPosition('visa');
        }
        else {
            setCurrent('General Details')
        }
        if (stage === 'General Details' || stage === 'Documents') {
            setCurrent('Documents');
        } else if (stage == 'i94') {
            setdocumentPosition('work');
        } else if (stage == 'visa') {
            setdocumentPosition('visa');
        } else {
            setCurrent('General Details');
        }
        if (employee.complete_profile !== '') {
            setCurrent(employee.complete_profile);
        }
        else {
            setCurrent('General Details')
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        // getEmployeeApi();
        if (location.state && location.state.from != undefined) {
            if (location.state.from == 'employmentDashboard') {
                setCurrent('General Details')
            }
        }
        // eslint-disable-next-line
    }, [location.state])

    useEffect(() => {
        if (LocalStorage.getRedirectedModule() !== null && LocalStorage.getRedirectedModule() !== undefined && (LocalStorage.getRedirectedModule() == 'employee_userprofile' || LocalStorage.getRedirectedModule() == 'employee-profile')) {
            setCurrent('General Details');
        }
        else {
            setCurrent('General Details')
        }
        // eslint-disable-next-line
    }, [LocalStorage.getRedirectedModule()])

    useEffect(() => {
        if (current == 'General Details') {
            getEmployeeApi()
        }
        // eslint-disable-next-line
    }, [current])

    useEffect(() => {
        props.setformEdit(formEdit)
        // eslint-disable-next-line
    }, [formEdit])

    useEffect(() => {
        if (activityFilter.search != null) {
            getActivity()
        }
        // eslint-disable-next-line
    }, [activityFilter])

    const [documentPosition, setdocumentPosition] = useState('education');

    const getActivity = (args) => {
        let data = {
            limit: activityFilter.limit,
            page: activityFilter.page,
            search: activityFilter.search,
            request_id: LocalStorage.uid(),
            employee_id: args ? args : employee.id ? employee.id : id
        }
        UserProfileApi.getActivity(data).then((response) => {
            if (response.data.statusCode == 1003) {
                setActivityData(response.data.data);
                setActivityTotal(response.data.pagination.total)
            }
        });
    }

    const downloadActivity = () => {
        let data = {
            request_id: LocalStorage.uid(),
            employee_id: employee.id
        }
        UserProfileApi.downloadActivity(data).then((response) => {
            if (response.data.statusCode == 1003) {
                let url = response.data.path
                let fileName = url.split('/temp/')
                FileSaver.saveAs(response.data.path, fileName[1])
            }
            else {
                addErrorMsg(response.data.message)
            }
        });
    }


    const getEmployeeOffboard = (last_working_date = '') => {
        OffboardApi.getOffBoardCheckList(location.state.id, last_working_date).then((response) => {
            if (response.data.statusCode == 1003) {
                setOffboardData(response.data.data[0])
            } else {
                addErrorMsg(response.data.message);
            }
        });
    }

    const closeCaseWithReason = () => {
        setOpenReason(false);
        setErrorEv({});
        stateEv['case_closure_reason_code'] = ''
        stateEv['other_reason_description'] = ''
        stateEv['currently_employed_reason_description'] = ''
        setStateEv({});
    }

    const captureNewCaseReason = () => {
        setOpenNewReason(false);
        stateEv['reason_for_new_case'] = '';
        stateEv['new_case_description'] = ''
        setStateEv({});
        setErrorEv({});
    }

    const getEmployeeApi = () => {
        setLoading(true);
        if (id !== '') {
            EmployeeAPI.getEmployee(id).then((response) => {
                setTimeout(() => {
                    if (response.data.statusCode == 1003) {
                        setLoading(false);
                        setEmployee({ ...response.data.data });
                        setSSNNumber(response.data.data.employment_details.ssn);
                        setEmployeeProfile(response.data.data.profile_picture_url);
                        if (jump) {
                            if (response.data.data.documents_pending) {
                                if (response.data.data.complete_profile == 'education_documents') {
                                    setCurrent('Documents'); setdocumentPosition('education')
                                }
                                else if (response.data.data.complete_profile == 'passport_document') {
                                    setCurrent('Documents'); setdocumentPosition('work')
                                }
                                else if (response.data.data.complete_profile == 'i94_document') {
                                    setCurrent('Documents'); setdocumentPosition('work')
                                }
                                else if (response.data.data.complete_profile == 'visa_document') {
                                    setCurrent('Documents'); setdocumentPosition('work')
                                }
                                else if (response.data.data.complete_profile == 'personal_documents') {
                                    setCurrent('Documents'); setdocumentPosition('visa')
                                }
                            }
                            else if (response.data.data.bank_pending) {
                                setCurrent('Bank Details'); setdocumentPosition('education')
                            }
                            else if (response.data.data.skills_pending) {
                                setCurrent('Skills'); setdocumentPosition('education')
                            } else {
                                setCurrent("General");
                            }
                        }
                        if (response.data.data.is_off_boarding_initiated === true) {
                            getEmployeeOffboard(response.data.data.last_working_day);
                        }
                        setTimeout(() => {
                            getActivity(response.data.data.id)
                        }, 300)
                    } else {
                        addErrorMsg(response.data.message);
                    }
                }, 400)
            });
        }
    }

    const disablePayroll = () => {
        setOpenPayroll(true);
    }

    const rehireHandler = () => {
        if (employee.enable_rehire) {
            const data = {
                request_id: LocalStorage.uid(),
                id: employee.id,
                email_id: employee.contact_details.email_id,
                contact_number: employee.contact_details.contact_number
            }
            EmployeesApi.checkDuplicate(data).then((res) => {
                if (res.data.statusCode == 1003) {
                    setLimitExceed(false);
                    if (res.data.valid) {
                        navigate('/rehire', { state: { full_name: employee.basic_details.full_name, id: employee.id, date: employee.last_working_day } })
                    } else {
                        setEmpExistis(true);
                    }
                } else {
                    setLimitExceed(false);
                    addErrorMsg(res.data.message);
                }
            })
        }
        else {
            setLimitExceed(true);
        }

    }

    const disablePayrollApi = () => {
        const data = {
            request_id: LocalStorage.uid(),
        }
        data['id'] = employee.id
        EmployeesApi.employeePayrollDisable(data).then((res) => {
            if (res.data.statusCode == 1003) {
                addSuccessMsg(res.data.message);
                setOpenPayroll(false);
                if (employee.enable_payroll === true) {
                    setEmployee({ ...employee, enable_payroll: false })
                } else if (employee.enable_payroll === false) {
                    setEmployee({ ...employee, enable_payroll: true })
                }
            } else {
                addErrorMsg(res.data.message);
            }
        })
    }


    const uploadProfile = (e) => {
        if (e.target.files[0].type.split('/').some(r => ['png', 'jpg', 'jpeg'].includes(r))) {
            const formData = new FormData();
            formData.append("files", e.target.files[0]);
            formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
            CommonApi.documentUpload("employee-profile", formData, LocalStorage.getAccessToken())
                .then((response) => {
                    if (response.data.statusCode == 1003) {
                        setEmployeeProfile(response.data.data.id);
                        ProfileUpdateApi(response.data.data.id)
                    } else {
                        setLoading(false)
                        addErrorMsg(response.data.message);
                    }
                });
        } else {
            addErrorMsg("Please upload files in PNG or JPG format only.");
        }
    }

    const ProfileUpdateApi = (args) => {
        setLoader(true);
        const data = {
            request_id: LocalStorage.uid(),
            id: location.state.id,
            documents: [
                {
                    new_document_id: args
                }
            ]
        }
        EmployeeAPI.profileUpload(location.state.id, data).then((res) => {
            if (res.data.statusCode == 1003) {
                setLoader(false);
                addSuccessMsg('Profile Uploaded Successfully');
                getEmployeeApi();
            } else {
                addErrorMsg(res.data.message);
            }
        })
    }

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        if (anchorEl) {
            handleClose()
        }
    };
    const handleClosePopUp = () => {
        setLastWorkingDate(lastWorkingDateCopy)
        setPopUpOpen(false);
        setError('');
    };

    // const everifyEmployee = () => {
    //     const data = {
    //         request_id: LocalStorage.uid()
    //     }
    //     UserProfileApi.everifyEmp(id, data).then((res) => {
    //         if (res.data.statusCode == 1003) {
    //             addSuccessMsg('Employee E-Verified Successfully');
    //             setEverifyPopUpOpen(false);
    //             getEmployeeApi();
    //         } else {
    //             addErrorMsg(res.data.message);
    //         }
    //     })
    // }

    const dateChange = (e, name) => {
        let date = e.$d // eslint-disable-next-line
        let event = {
            target: {
                name: name,
                value: date
            }
        }
        setLastWorkingDate(moment(date).format(dateFormat()))
        if (date == '') {
            setError('This field is required')
        } else {
            setError('');
        }
    }

    const handleSubmit = () => {
        if (!lastWorkingDate || lastWorkingDate === '') {
            setError('This field is required')
        } else {
            const last_working_day = moment(moment(lastWorkingDate, dateFormat()).format());
            const is_one_week_prior = last_working_day.diff(moment().startOf('day'), 'days') < 7;
            setError('');
            LocalStorage.removeRedirectedModule();
            LocalStorage.removeSubModule();
            LocalStorage.removeNotificationEmpId();
            if (is_one_week_prior) {
                setPopUpOpen(false);
                navigate(`/employees/user-profile/${employee.basic_details.full_name === "" ? "" : employee.basic_details.full_name.trim().split(/ +/).join('-')}/offboarding`, { state: { full_name: employee.basic_details.full_name, reference_id: employee.employment_details.reference_id, enable_login: employee.enable_login, avatar_url: employee.profile_picture_url, id: id, acc: 1, visa: employee.employment_details.visa_type, last_date: lastWorkingDate, expand: '', min_date: employee.min_last_working_day } })
                return;
            }
            setLoader(true)
            OffboardApi.getOffBoardCheckList(id, lastWorkingDate).then(response => {
                if (response.data.statusCode == 1003) {
                    setOffboardData(response.data.data[0])
                    handlefinishOffboard(response.data.data[0])
                } else {
                    addErrorMsg(response.data.message);
                }
            }).finally(() => { setLoader(false) })

        }
    }

    const handlefinishOffboard = (finishOffboardData) => {
        let data = { ...finishOffboardData, reference_id: LocalStorage.uid() }
        data.last_working_day.status = true
        data.last_working_day.date = lastWorkingDate
        OffboardApi.newstoreOffBoard(data).then((response) => {
            if (response.data.statusCode === 1003) {
                setLoader(false)
                setPopUpOpen(false)
                setTimeout(() => { setFinishPopup(true) }, 500)
            }
            else {
                setLoader(false)
                addErrorMsg(response.data.message)
            }
        })
    }

    const handleFinishPopup = () => {
        getEmployeeApi()
        setFinishPopup(false)
    }

    const navigateToOffboard = (args) => {
        LocalStorage.removeRedirectedModule();
        LocalStorage.removeSubModule();
        LocalStorage.removeNotificationEmpId();
        navigate(`/employees/user-profile/${employee.basic_details.full_name === "" ? "" : employee.basic_details.full_name.trim().split(/ +/).join('-')}/offboarding`, { state: { full_name: employee.basic_details.full_name, reference_id: employee.employment_details.reference_id, enable_login: employee.enable_login, avatar_url: employee.profile_picture_url, id: id, acc: 1, visa: employee.employment_details.visa_type, last_date: lastWorkingDate, expand: args, min_date: employee.min_last_working_day } })
    }

    const handleStatus = (args) => {
        if (args == 1) {
            setPopUpOpen(true)
            setShow(args)
            // const data = {
            //     request_id: LocalStorage.uid(),
            //     employee_id: id,
            // }
            // EmployeeAPI.employeeAccess(data).then((res) => {
            //     if (res.data.statusCode == 1003) {
            //         addSuccessMsg(res.data.message);
            //         handleClose();
            //         getEmployeeApi();
            //     } else {
            //         addErrorMsg(res.data.message);
            //     }
            // })
        }
        if (args == 0) {
            handleClose()
            setShow(args)
            setPopUpOpen(true)
        }
    }
    const [loaderOffboard, setLoaderOffboard] = useState(false);
    const handleAccess = () => {
        const data = {
            request_id: LocalStorage.uid(),
            employee_id: id,
        }
        setLoaderOffboard(true)
        EmployeeAPI.employeeAccess(data).then((res) => {
            if (res.data.statusCode == 1003) {
                addSuccessMsg(res.data.message);
                setPopUpOpen(false);
                getEmployeeApi();
                setLoaderOffboard(false)

            } else {
                setLoaderOffboard(false)

                addErrorMsg(res.data.message);
            }
        })
    }
    //disable user access
    // eslint-disable-next-line
    const DeactiveApi = () => {
        const data = {
            request_id: LocalStorage.uid(),
            employee_id: id
        }
        UserProfileApi.InactiveEmp(data).then((res) => {

        })
    }

    const navigate = useNavigate();
    const [everifyPopUpOpen, setEverifyPopUpOpen] = useState(false);

    const [activityAnchorEl, setActivityAnchorEl] = useState(null);

    const handleActivityClick = (event) => {
        setActivityAnchorEl(event.currentTarget);
    };

    const handleActivityClose = () => {
        setActivityAnchorEl(null);
    };
    const activityOpen = Boolean(activityAnchorEl);

    const checkDocumentComplete = (item) => {
        if (employee.employment_details.employment_type_id == 1) {

        }
        else {
            if (item == 'Documents') {
                return employee.documents_pending
            }
            else if (item == 'Bank Details') {
                return employee.bank_pending
            }
            else if (item == 'Skills') {
                return employee.skills_pending
            }
        }
    }

    const navigateToComponent = () => {
        if (employee.documents_pending) {
            if (employee.complete_profile == 'education_documents') {
                setCurrent('Documents'); setdocumentPosition('education')
            }
            else if (employee.complete_profile == 'passport_document') {
                setCurrent('Documents'); setdocumentPosition('work')
            }
            else if (employee.complete_profile == 'i94_document') {
                setCurrent('Documents'); setdocumentPosition('work')
            }
            else if (employee.complete_profile == 'visa_document') {
                setCurrent('Documents'); setdocumentPosition('work')
            }
            else if (employee.complete_profile == 'personal_documents') {
                setCurrent('Documents'); setdocumentPosition('visa')
            }
        }
        else if (employee.bank_pending) {
            setCurrent('Bank Details'); setdocumentPosition('education')
        }
        else if (employee.skills_pending) {
            setCurrent('Skills'); setdocumentPosition('education')
        }

    }

    const upgradePlan = () => {
        let planDetails = LocalStorage.getPlanCheckLocal()
        LocalStorage.setlocationPath(location.pathname)
        navigate('/billing', {
            state: {
                renew: true, status: (planDetails.is_subscription_cancelled && (planDetails.current_plan_slug == 'starter-monthly' || planDetails.current_plan_slug == 'starter-yearly')) ? 'upgrade' :
                    ((planDetails.current_plan_slug == 'advanced-monthly' || planDetails.current_plan_slug == 'advanced-yearly') && (planDetails.subscription_expiring || planDetails.subscription_expired || !planDetails?.is_plan_auto_renewed)) ? 'renew' :
                        (planDetails.current_plan_slug == 'advanced-monthly' || planDetails.current_plan_slug == 'advanced-yearly') ? 'advanceUpgrade' : (planDetails.current_plan_slug == 'starter-monthly' || planDetails.current_plan_slug == 'starter-yearly') ? 'activeUpgrade' : 'activeUpgrade',
                renewal: planDetails.subscription_expiring || planDetails.subscription_expired ? true : false,
                autoRenewAdvance: !planDetails?.is_plan_auto_renewed && planDetails.current_plan_slug == 'advanced-monthly' ? false : true, autoRenewStarterMonthly: planDetails?.is_plan_auto_renewed ? false : true,
                autoRenew: (!planDetails?.is_plan_auto_renewed && planDetails.current_plan_slug == 'advanced-monthly') ? true : false,
                autoRenewStarter: !planDetails?.is_plan_auto_renewed ? true : false
            }
        })
    }

    useEffect(() => {
        setLastWorkingDate(employee.last_working_day || '')
        setLastWorkingDateCopy(employee.last_working_day || '')
    }, [employee])

    const show_offboarding = useCallback(() => {
        if (!employee.is_off_boarding_initiated) {
            return false;
        }
        const last_working_day = moment(moment(employee.last_working_day, dateFormat()).format());
        return last_working_day.diff(moment(), 'days') <= 7;
    }, [employee.is_off_boarding_initiated, employee.last_working_day])

    const show_rehire_button = useCallback(() => {
        if (!employee.is_off_boarding_initiated) {
            return false;
        }
        const last_working_day = moment(moment(employee.last_working_day, 'DD/MM/YYYY').format());
        return last_working_day.diff(moment(), 'days') < 0;
    }, [employee.is_off_boarding_initiated, employee.last_working_day])


    return (
        <Box className={classes.mainContainer} px={5} py={1}>
            <Box mx={2} pl={3}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography component={Link} onClick={() => {
                        LocalStorage.removeRedirectedModule();
                        LocalStorage.removeFromPage()
                    }} to={'/employees'} className={classes.breadcrumbsLink}>Employees</Typography>
                    <Text sx={{ color: '#092333 !important', fontWeight: '700 !important' }} mediumBlack14>User Profile</Text>
                </Breadcrumbs>
            </Box>
            <Hidden lgDown>
                {(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_view" && item.is_allowed == true))) ?
                    <Box my={2} mx={2}>
                        <Grid container lg={12} columnSpacing={4}>
                            <Grid item container lg={3.2} md={3} sm={3} xs={12}>
                                <Box style={{ width: "100%", height: "78.5vh", borderRadius: "8px" }}>
                                    <Box px={3} py={1} pt={4}>
                                        <Box my={1} mt={2} style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                                            <CircularProgress
                                                percentage={employee.profile_progress}
                                                color={employee.profile_progress >= 76 ? 'green' : employee.profile_progress <= 75 && employee.profile_progress >= 51 ? 'yellow' : employee.profile_progress < 50 ? "#FFBF00" : ''}
                                                src={EmployeeProfile}
                                                name={EmployeeProfile == undefined || EmployeeProfile == null || EmployeeProfile == '' ? employee.basic_details && employee.basic_details.full_name[0] : ''}
                                                imgWidth='80px !important'
                                                imgHeight='80px !important'
                                                avatarWidth='80px !important'
                                                avatarHeight='80px !important'
                                                value={employee.profile_progress}
                                                size={88}
                                                edit={(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_edit" && item.is_allowed == true))) && employee.status == 'Active'}
                                                handleEdit={(e) => uploadProfile(e)}
                                                tooltip
                                                tooltipContent={
                                                    <Box p={'3px 0px'} width='100%'>
                                                        <Text mediumBoldWhite400>{`Profile Completion - ${employee.profile_progress}%`}</Text>
                                                        {
                                                            employee.profile_progress == 100 ? '' : <CustomButton addButtonTooltip sx={{ marginTop: '10px' }} onClick={() => { navigateToComponent() }}>Complete Profile</CustomButton>
                                                        }
                                                    </Box>
                                                }
                                            />
                                        </Box>
                                        <Grid item container lg={12} pt={1} spacing={0} alignItems='center'>
                                            <Grid item lg={12}>
                                                <Box display='flex' flexDirection='row' gap={1} justifyContent='center'>
                                                    <Text className={classes.profileName} >
                                                        <BlackToolTip arrow placement='top' title={employee.basic_details.full_name}>
                                                            {employee.basic_details.full_name.slice(0, 16)}{employee.basic_details.full_name.length > 16 ? "..." : ""}
                                                        </BlackToolTip>
                                                    </Text>
                                                    {employee.e_verified == 1 ? <img src={verified} alt='verified' /> : employee.e_verified == 0 ?
                                                        <BlackToolTip arrow placement='right' title={<Text smallWhite padding={'4px'}>This user is not E-Verified</Text>}>
                                                            <img src={eVerifyPending} alt='pending' />
                                                        </BlackToolTip>
                                                        : ''}
                                                </Box>
                                                {/* } */}
                                            </Grid>
                                        </Grid>
                                        <Grid item lg={12} pt={0}>
                                            <Text className={classes.profileId}>{employee.employment_details.reference_id}</Text>
                                        </Grid>
                                        <Grid item lg={12} xs={12} pt={0} p={1} textAlign='center' justifyContent='center'>
                                            <Box sx={{ cursor: 'pointer' }} className={classes.uploadBtn} onClick={handleActivityClick}>
                                                Activity log
                                            </Box>
                                        </Grid>
                                        <Grid item lg={12}>
                                            <Stack my={1} direction="row" justifyContent={"center"} spacing={2}>
                                                {
                                                    loading ?
                                                        <Skeleton width='150px' height='60px' animation='wave' /> :
                                                        show_rehire_button() ?
                                                            <LoaderButton loading={limitExceed} rehire onClick={() => rehireHandler()}>Re-hire</LoaderButton> :
                                                            <>
                                                                {
                                                                    employee.employment_type_id == 3 ?
                                                                        <Button className={classes.eVerifyBtnDisable}>
                                                                            <Stack direction='row' gap={1} sx={{ color: '#737373 !important' }}>
                                                                                {(employee.e_verified == 0 ? 'E-Verify' : employee.e_verified == 1 ? 'E-Verified' : employee.e_verified == 2 ? 'Pending' : employee.e_verified == 4 ? 'Re-Verify' : '')}
                                                                                {employee.e_verified !== 0 && employee.e_verified !== 4 && <img style={{ marginTop: '6px' }} width={'15px'} height={'15px'} src={employee.e_verified == 1 ? Verify : employee.e_verified == 2 ? Pending : employee.e_verified == 3 ? Rejected : null} alt='e_verify' />}
                                                                            </Stack>
                                                                        </Button> : !employee.visa_information_exists ?
                                                                            <BlackToolTip arrow title={
                                                                                <Box p='4px'>
                                                                                    <Text smallWhite>Please Finish the I-9 document verification & Visa document upload (for Non US citizen) before E-verifying the Employee</Text>
                                                                                </Box>
                                                                            }>
                                                                                <Button className={classes.disableButton}>
                                                                                    <Stack direction='row' gap={1}>
                                                                                        {(employee.e_verified == 0 ? 'E-Verify' : employee.e_verified == 1 ? 'E-Verified' : employee.e_verified == 2 ? 'Pending' : employee.e_verified == 3 ? 'Rejected' : employee.e_verified == 4 ? 'Re-Verify' : '')}
                                                                                        {employee.e_verified !== 0 && employee.e_verified !== 4 && <img style={{ marginTop: '6px' }} width={'15px'} height={'15px'} src={employee.e_verified == 1 ? Verify : employee.e_verified == 2 ? Pending : employee.e_verified == 3 ? Rejected : null} alt='e_verify' />}
                                                                                    </Stack>
                                                                                </Button>
                                                                            </BlackToolTip>
                                                                            :
                                                                            <LoadingButton loading={verifyLoader} className={classes.eVerifyBtn} sx={{ border: employee.e_verified == 1 ? '1px solid #4ABE43 !important' : employee.e_verified == 0 || employee.e_verified == 4 ? '1px solid #0C75EB !important' : '', backgroundColor: (employee.e_verified == 0 ? "#ffffff !important" : employee.e_verified == 1 ? "#4ABE43 !important" : employee.e_verified == 2 ? "#F59E0B !important" : employee.e_verified == 3 ? "#E51A1A !important" : ''), color: '#ffffff' }} onClick={() => employee.e_verified == 0 || employee.e_verified == 4 || employee.e_verified == 3 ? checkRefreshToken(employee.e_verified) : ''}>
                                                                                <Stack direction='row' gap={1} sx={{ color: (employee.e_verified == 0 ? "#0C75EB  !important" : employee.e_verified == 1 ? "#ffffff !important" : employee.e_verified == 2 ? "#ffffff !important" : employee.e_verified == 3 ? "#ffffff !important" : '') }}>
                                                                                    {(employee.e_verified == 0 ? 'E-Verify' : employee.e_verified == 1 ? 'E-Verified' : employee.e_verified == 2 ? 'Pending' : employee.e_verified == 3 ? 'Rejected' : employee.e_verified == 4 ? 'Re-Verify' : '')}
                                                                                    {employee.e_verified !== 0 && employee.e_verified !== 4 && <img style={{ marginTop: '6px' }} width={'15px'} height={'15px'} src={employee.e_verified == 1 ? Verify : employee.e_verified == 2 ? Pending : employee.e_verified == 3 ? Rejected : null} alt='e_verify' />}
                                                                                </Stack>
                                                                            </LoadingButton>
                                                                }
                                                            </>
                                                }
                                                {
                                                    loading ? <Skeleton width='45px' height='60px' animation='wave' /> :
                                                        (!show_rehire_button() && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_edit" && item.is_allowed == true))) &&
                                                            <Button variant="outlined" className={open ? classes.blueMenu : classes.moreBtn} onClick={handleClick} >
                                                                <MoreHorizIcon sx={{ fontSize: "18px", color: open ? `${blue}` : "#9D9E9F" }} />
                                                                <Menu
                                                                    id="basic-menu"
                                                                    anchorEl={anchorEl}
                                                                    open={open}
                                                                    onClose={handleClose}
                                                                    sx={{
                                                                        '& .MuiPaper-root': {
                                                                            border: '1px solid #EAECF0 !important',
                                                                            width: '150px !important',
                                                                            boxShadow: "#0000001F !important",
                                                                            borderRadius: '8px !important',
                                                                            padding: '0px 2px 0px 0px !important'
                                                                        },
                                                                    }}
                                                                >
                                                                    <EmployeeActions employee={employee} rolePermission={rolePermission} handleStatus={handleStatus} classes={classes} setCancelPopup={setCancelPopup} />
                                                                </Menu>
                                                            </Button>
                                                        )

                                                }
                                            </Stack>
                                        </Grid>

                                    </Box>
                                    <Box px={3}>
                                        <Divider />
                                    </Box>
                                    <Box py={3}>
                                        <Box px={3} sx={{
                                            height: "30vh",
                                            overflow: "auto",
                                            '&::-webkit-scrollbar': {
                                                width: '4px',
                                            },
                                            '&::-webkit-scrollbar-track': {
                                                '-webkit-box-shadow': 'inset 0 0 6px #ffffff',
                                            },
                                            '&::-webkit-scrollbar-thumb': {
                                                backgroundColor: '#C7CCD3',
                                                outline: '1px solid #C7CCD3',
                                                borderRadius: "4px",
                                            },
                                            "@media (min-height: 750px)": {
                                                height: '36vh'
                                            }
                                        }}>
                                            {
                                                dataArr.map((item, key) => (
                                                    <>
                                                        {
                                                            (employee.e_verified == 0 || employee.e_verified == 3) && item == 'E-Verify' ? '' :
                                                                <ListItemButton
                                                                    key={key}
                                                                    className={`${classes.listItems} ${current === item ? classes.listItemsActive : null}`}
                                                                    onClick={() => { if (current == item) { } else if (formEdit) { setOpenpopup(true); setStepItem(item) } else { setCurrent(item); setdocumentPosition('education') } }}
                                                                >
                                                                    <Grid container>
                                                                        <Grid item xs={10}>{item}</Grid>
                                                                        {
                                                                            checkDocumentComplete(item) ?
                                                                                <Grid item container justifyContent={'flex-end'} xs={2}>
                                                                                    <BlackToolTip arrow placement='right' title={'Pending'}>
                                                                                        <img src={eVerifyPending} alt='pending' />
                                                                                    </BlackToolTip>
                                                                                </Grid> : ''
                                                                        }
                                                                    </Grid>
                                                                </ListItemButton>
                                                        }
                                                    </>
                                                ))}
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={.1}></Grid>
                            <Grid item lg={8.4} md={8.4} sm={8} xs={12}>
                                {
                                    employee.e_verified == 3 ?
                                        <Grid item lg={12} xs={12} mb={2}>
                                            <Box display='flex' flexDirection='row' gap={1.5} sx={{ height: '60px', width: '100%', borderRadius: '8px', background: '#FDECCE', textAlign: 'center', alignItems: 'center', paddingLeft: '20px' }}>
                                                <Box pt={'4px'}>
                                                    <img src={warning} alt='warning' />
                                                </Box>
                                                <Box>
                                                    <Text mediumOrangeRehire>Please click on Rejected button to Re-Verify this Employee.</Text>
                                                </Box>
                                            </Box>
                                        </Grid> : ''
                                }
                                {
                                    true ? '' : <Grid item pb={2}>
                                        <Box display='flex' flexDirection='row' gap={1.5} sx={{ height: '60px', width: '100%', borderRadius: '8px', background: '#FDECCE', textAlign: 'center', alignItems: 'center', paddingLeft: '20px' }}>
                                            <Box pt={'4px'}>
                                                <img src={warning} alt='warning' />
                                            </Box>
                                            <Box>
                                                <Text mediumOrangeRehire> This employee has been re-hired recently, please re-confirm all the details </Text>
                                            </Box>
                                        </Box>
                                    </Grid>
                                }
                                <Box sx={{ width: '100%', height: true ? "78.5vh" : "70vh", borderRadius: "8px", overflow: 'auto', overflowX: 'hidden' }}>
                                    <Box>
                                        {
                                            current === "General Details" && employee.basic_details.first_name !== '' &&
                                            <Box m={0.5} mr={0.1}>
                                                <Box mb={2} sx={{ borderRadius: "8px", boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D" }}>
                                                    <GeneralDetails rolePermission={rolePermission} formEdit={formEdit} setformEdit={setformEdit} offBoardButton={offBoardButton} progress={progress} avatar_url={employee.profile_picture_url} active={active} grButn={grButn}
                                                        employee={employee} setEmployee={setEmployee} getIndex={getEmployeeApi} loading={loading} setLoading={setLoading} offBoardButton_enable={employee.is_off_boarding_initiated} offboardPercentage={employee.off_boarding_percentage}
                                                    />
                                                </Box>
                                                <Box sx={{ borderRadius: "8px", boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D" }}>
                                                    <ViewEmergency rolePermission={rolePermission} formEdit={formEdit} setformEdit={setformEdit} employee={employee} setEmployee={setEmployee} getIndex={getEmployeeApi} />
                                                </Box>
                                            </Box>
                                        }
                                        {
                                            current === "Documents" ?
                                                <Box m={0.5} mr={0.1} mb={0} sx={{ width: '100% !important', overflowX: 'hidden' }}>
                                                    <Box mb={2} sx={{ boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px" }}>
                                                        <Documents rolePermission={rolePermission} formEdit={formEdit} setformEdit={setformEdit} documentPosition={documentPosition} setdocumentPosition={setdocumentPosition} employee={employee} setEmployee={setEmployee} getIndex={getEmployeeApi} offBoardButton_enable={employee.is_off_boarding_initiated} progress={employee.profile_progress} />
                                                    </Box>
                                                    <Box sx={{ height: '70vh', width: '100%', overflowY: 'scroll', overflowX: 'hidden' }}>
                                                        <AllSections rolePermission={rolePermission} formEdit={formEdit} setformEdit={setformEdit} documentPosition={documentPosition} setdocumentPosition={setdocumentPosition} employee={employee} setEmployee={setEmployee} getIndex={getEmployeeApi} />
                                                    </Box>
                                                </Box>
                                                : null
                                        }
                                        {
                                            current === "Bank Details" ?
                                                <BankDetails rolePermission={rolePermission} getEmployeeApi={getEmployeeApi} getActivity={getActivity} employee={employee} formEdit={formEdit} setformEdit={setformEdit} offBoardButton_enable={employee.is_off_boarding_initiated} />
                                                : null
                                        }
                                        {
                                            current === "Immigration" ? <Immigration formEdit={formEdit} setformEdit={setformEdit} offBoardButton_enable={employee.is_off_boarding_initiated} progress={employee.profile_progress} /> : null
                                        }
                                        {
                                            current === "Timesheet" ? <Timesheet formEdit={formEdit} setformEdit={setformEdit} timesheet_enable={employee.is_configurable_timesheet_available} id={employee.id} offBoardButton_enable={employee.is_off_boarding_initiated} progress={employee.profile_progress} /> : null
                                        }
                                        {
                                            current === "Skills" ? <Skills rolePermission={rolePermission} formEdit={formEdit} employee={employee} getEmployeeApi={getEmployeeApi} getActivity={getActivity} setformEdit={setformEdit} offBoardButton_enable={employee.is_off_boarding_initiated} progress={employee.profile_progress} /> : null
                                        }
                                        {
                                            current === "Dependents" ? <Dependents formEdit={formEdit} setformEdit={setformEdit} offBoardButton_enable={employee.is_off_boarding_initiated} progress={employee.profile_progress} /> : null
                                        }
                                        {
                                            current === "Vacation History" ? <VacationHistory formEdit={formEdit} setformEdit={setformEdit} offBoardButton_enable={employee.is_off_boarding_initiated} progress={employee.profile_progress} /> : null
                                        }
                                        {
                                            current === 'E-Verify' ? <Box m={0.5} mr={0.1}>
                                                <Box mb={2} sx={{ borderRadius: "8px", boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D" }}>
                                                    <Everify employee={employee} state={stateEv} setState={setStateEv} setOpenReason={setOpenReason} getEmployeeApi={getEmployeeApi} resonforclosingCase={resonforclosingCase} setResonsforclosingCase={setResonsforclosingCase} setCaseNumber={setCaseNumber} caseNumber={caseNumber} closeCaseComplete={closeCaseComplete} setCloseCaseComplete={setCloseCaseComplete} setloginEvPopup={setloginEvPopup} handleCreateEverify={handleCreateEverify} setrefreshToken={setrefreshToken} />
                                                </Box>
                                            </Box> : null
                                        }
                                    </Box>
                                    <Box sx={{ height: '20vh', right: 100, bottom: 30, zIndex: 1000, position: 'fixed' }}>
                                        {
                                            show_offboarding() &&
                                            <Box display='flex' flexDirection='column' gap={2}>
                                                {
                                                    employee.enable_payroll === true ?
                                                        <CustomButton redPayroll onClick={disablePayroll}>Disable Payroll</CustomButton> :
                                                        <CustomButton disablePayroll>Disable Payroll</CustomButton>
                                                }
                                                <CustomButton offBoardButton onClick={() => setContinueOffboard(true)}>Off Boarding | {employee && employee.off_boarding_percentage}%</CustomButton>
                                            </Box>
                                        }
                                    </Box>
                                    <BootstrapDialog
                                        TransitionComponent={Transition}
                                        keepMounted
                                        aria-labelledby="customized-dialog-title"
                                        open={continueOffboard}
                                        onClose={() => setContinueOffboard(false)}
                                    >
                                        <DialogTitle sx={{ m: 0, p: 2, backgroundColor: "#3B4957" }} id="customized-dialog-title">
                                            <Text overViewAmount>Offboarding</Text>
                                        </DialogTitle>
                                        <IconButton
                                            aria-label="close"
                                            onClick={() => setContinueOffboard(false)}
                                            sx={{
                                                position: "absolute",
                                                right: 8,
                                                top: 8,
                                                boxShadow: 'none !important',
                                                "&:hover": {
                                                    boxShadow: 'none !important',
                                                }
                                            }}
                                        >
                                            <CloseRoundedIcon sx={{ color: "#FFFFFF" }} />
                                        </IconButton>
                                        <Box className={classes.bodyBox}>
                                            <Grid container>
                                                <Grid item lg={12} md={12} sm={12} display={"flex"} justifyContent={"center"} textAlign='center'>
                                                    <Box
                                                        sx={{
                                                            height: 126,
                                                            // transform: "rotate(-360deg)"
                                                        }}
                                                        textAlign='center'
                                                    >
                                                        <ReactApexChart options={semicircle.options} series={semicircle.series} type="radialBar" height={250} width={250} />
                                                    </Box>
                                                </Grid>
                                                <Grid item lg={12} md={12} sm={12} mb={2} display={"flex"} justifyContent={"center"} textAlign={"center"}>
                                                    <Text mediumBoldWhite400>  Off <br />Boarding <br />
                                                        {employee.off_boarding_percentage == 100 ? "Completed" : "Progress"}</Text>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Grid container p={'10px 15px 30px 15px'} sx={{ height: '330px', overflowY: 'scroll' }}>
                                            {
                                                (offboardData.type == 2 || offboardData.type == 3) &&
                                                <Grid item container lg={12} p={2} mt={1} className={offboardData.placement.status ? classes.activeRow : classes.disableRow}>
                                                    <Grid item lg={9} className={classes.headerRow}>
                                                        <Text BrowmnMnStepperText>{typeSno[offboardData.type].placement}. Placement End Dates</Text>
                                                    </Grid>
                                                    {
                                                        offboardData.placement.status ?
                                                            <>
                                                                <Grid item lg={1} ><img src={GreenVerif} style={{ display: "flex" }} alt="Verify" /></Grid>
                                                                <Grid item lg={2} className={classes.headerRow}><Text BrowmnMnStepperText sx={{ color: "#16A34A !important" }}>Done</Text></Grid>
                                                            </>
                                                            :
                                                            <Grid item lg={3}> <CustomButton popupSaveBlueHeight onClick={() => navigateToOffboard('placements')}>Start</CustomButton></Grid>
                                                    }


                                                </Grid>
                                            }

                                            <Grid item container lg={12} p={2} mt={1} className={offboardData.disable_user_access.status ? classes.activeRow : classes.disableRow}>
                                                <Grid item lg={9} className={classes.headerRow}><Text BrowmnMnStepperText>{typeSno[offboardData.type].disable}. Disable User Access Across Apps</Text></Grid>
                                                {
                                                    offboardData.disable_user_access.status ?
                                                        <>
                                                            <Grid item lg={1} ><img src={GreenVerif} style={{ display: "flex" }} alt="Verify" /></Grid>
                                                            <Grid item lg={2} className={classes.headerRow}><Text BrowmnMnStepperText sx={{ color: "#16A34A !important" }}>Done</Text></Grid>
                                                        </>
                                                        :
                                                        <Grid item lg={3}> <CustomButton popupSaveBlueHeight onClick={() => navigateToOffboard('disable')}>Start</CustomButton></Grid>
                                                }
                                            </Grid>
                                            {
                                                offboardData.type == 2 &&
                                                <Grid item container lg={12} mt={1} p={2} className={offboardData.send_revocation.status ? classes.activeRow : classes.disableRow}>
                                                    <Grid item lg={9} className={classes.headerRow}><Text BrowmnMnStepperText>{typeSno[offboardData.type].send}. Send Email to USCIS / University</Text></Grid>
                                                    {
                                                        offboardData.send_revocation.status ?
                                                            <>
                                                                <Grid item lg={1} ><img src={GreenVerif} style={{ display: "flex" }} alt="Verify" /></Grid>
                                                                <Grid item lg={2} className={classes.headerRow}><Text BrowmnMnStepperText sx={{ color: "#16A34A !important" }}>Done</Text></Grid>
                                                            </>
                                                            :
                                                            <Grid item lg={3}> <CustomButton popupSaveBlueHeight onClick={() => navigateToOffboard('recovation')}>Start</CustomButton></Grid>
                                                    }
                                                </Grid>
                                            }
                                            <Grid item container lg={12} mt={1} p={2} className={offboardData.delete_mail.status ? classes.activeRow : classes.disableRow}>
                                                <Grid item lg={9} className={classes.headerRow}><Text BrowmnMnStepperText>{typeSno[offboardData.type].delete}. Delete Mail ID</Text></Grid>
                                                {
                                                    offboardData.delete_mail.status ?
                                                        <>
                                                            <Grid item lg={1} ><img src={GreenVerif} style={{ display: "flex" }} alt="Verify" /></Grid>
                                                            <Grid item lg={2} className={classes.headerRow}><Text BrowmnMnStepperText sx={{ color: "#16A34A !important" }}>Done</Text></Grid>
                                                        </>
                                                        :
                                                        <Grid item lg={3}> <CustomButton popupSaveBlueHeight onClick={() => navigateToOffboard('delete')}>Start</CustomButton></Grid>
                                                }
                                            </Grid>
                                            <Grid item container lg={12} p={2} mt={1} className={offboardData.expense_settlement.status ? classes.activeRow : classes.disableRow}>
                                                <Grid item lg={9} className={classes.headerRow}><Text BrowmnMnStepperText>{typeSno[offboardData.type].expense}. Expense Settlement</Text></Grid>
                                                {
                                                    offboardData.expense_settlement.status ?
                                                        <>
                                                            <Grid item lg={1} ><img src={GreenVerif} style={{ display: "flex" }} alt="Verify" /></Grid>
                                                            <Grid item lg={2} className={classes.headerRow}><Text BrowmnMnStepperText sx={{ color: "#16A34A !important" }}>Done</Text></Grid>
                                                        </>
                                                        :
                                                        <Grid item lg={3}> <CustomButton popupSaveBlueHeight onClick={() => navigateToOffboard('expenseOff')}>Start</CustomButton></Grid>
                                                }
                                            </Grid>
                                            <Grid item container lg={12} p={2} mt={1} className={offboardData.settle_balance.status ? classes.activeRow : classes.disableRow}>
                                                <Grid item lg={9} className={classes.headerRow}><Text BrowmnMnStepperText>{typeSno[offboardData.type].settle}. Settle Balance</Text></Grid>
                                                {
                                                    offboardData.settle_balance.status ?
                                                        <>
                                                            <Grid item lg={1} ><img src={GreenVerif} style={{ display: "flex" }} alt="Verify" /></Grid>
                                                            <Grid item lg={2} className={classes.headerRow}><Text BrowmnMnStepperText sx={{ color: "#16A34A !important" }}>Done</Text></Grid>
                                                        </>
                                                        :
                                                        <Grid item lg={3}> <CustomButton popupSaveBlueHeight onClick={() => navigateToOffboard('settle')}>Start</CustomButton></Grid>
                                                }
                                            </Grid>


                                        </Grid>
                                    </BootstrapDialog>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    :
                    <>
                        {NoPermission()}
                    </>
                }
            </Hidden>
            <Hidden lgUp>
                <Grid container md={12} xs={12} justifyContent='center' mt={2}>
                    <Grid item container md={11} xs={12} sx={{
                        border: '1px solid #EAECF0', borderRadius: '12px', padding: '15px'
                    }}>
                        <Grid item container md={9} xs={8} columnSpacing={2}>
                            <Grid item md={2} xs={3}>
                                <CircularProgress
                                    percentage={employee.profile_progress}
                                    color={employee.profile_progress >= 76 ? 'green' : employee.profile_progress <= 75 && employee.profile_progress >= 51 ? 'yellow' : employee.profile_progress < 50 ? "#FFBF00" : ''}
                                    src={EmployeeProfile}
                                    name={EmployeeProfile == undefined || EmployeeProfile == null || EmployeeProfile == '' ? employee.basic_details && employee.basic_details.full_name[0] : ''}
                                    imgWidth='80px !important'
                                    imgHeight='80px !important'
                                    avatarWidth='80px !important'
                                    avatarHeight='80px !important'
                                    value={employee.profile_progress}
                                    size={88}
                                    edit={(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_edit" && item.is_allowed == true))) && employee.status == 'Active'}
                                    handleEdit={(e) => uploadProfile(e)}
                                    tooltip
                                    tooltipContent={
                                        <Box p={'3px 0px'} width='100%'>
                                            <Text mediumBoldWhite400>{`Profile Completion - ${employee.profile_progress}%`}</Text>
                                            {
                                                employee.profile_progress == 100 ? '' : <CustomButton addButtonTooltip sx={{ marginTop: '10px' }} onClick={() => { navigateToComponent() }}>Complete Profile</CustomButton>
                                            }
                                        </Box>
                                    }
                                />
                            </Grid>
                            <Grid item container md={6} xs={6} alignItems='center' ml={1}>
                                <Box alignItems='center'>
                                    <Box display='flex' flexDirection='row' gap={1} justifyContent='center' alignItems='center'>
                                        <Text className={classes.profileName}>
                                            <BlackToolTip arrow placement='top' title={employee.basic_details.full_name}>
                                                {employee.basic_details.full_name.slice(0, 14)}{employee.basic_details.full_name.length > 14 ? "..." : ""}
                                            </BlackToolTip>
                                        </Text>
                                        {employee.e_verified == 1 ? <img src={verified} alt='verified' /> : employee.e_verified == 0 ?
                                            <BlackToolTip arrow placement='right' title={<Text smallWhite padding={'4px'}>This user is not E-Verified</Text>}>
                                                <img src={eVerifyPending} alt='pending' />
                                            </BlackToolTip>
                                            : ''}
                                    </Box>
                                    <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                        <Text className={classes.profileId}>{employee.employment_details.reference_id}</Text>
                                        <Box sx={{ cursor: 'pointer' }} className={classes.uploadBtn} onClick={handleActivityClick}>
                                            Activity log
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item container md={3} xs={4} alignItems='center'>
                            <Stack my={1} direction="row" justifyContent={"center"} gap={1}>
                                {
                                    loading ?
                                        <Skeleton width='120px' height='60px' animation='wave' /> :
                                        show_rehire_button() ?
                                            <LoaderButton loading={limitExceed} rehire onClick={() => rehireHandler()}>Re-hire</LoaderButton> :
                                            <>
                                                {
                                                    employee.employment_type_id == 3 ?
                                                        <Button className={classes.eVerifyBtnDisable}>
                                                            <Stack direction='row' gap={1} sx={{ color: '#737373 !important' }}>
                                                                {(employee.e_verified == 0 ? 'E-Verify' : employee.e_verified == 1 ? 'E-Verified' : employee.e_verified == 2 ? 'Pending' : employee.e_verified == 4 ? 'Re-Verify' : '')}
                                                                {employee.e_verified !== 0 && employee.e_verified !== 4 && <img style={{ marginTop: '6px' }} width={'15px'} height={'15px'} src={employee.e_verified == 1 ? Verify : employee.e_verified == 2 ? Pending : employee.e_verified == 3 ? Rejected : null} alt='e_verify' />}
                                                            </Stack>
                                                        </Button> :
                                                        <LoadingButton loading={verifyLoader} className={classes.eVerifyBtn} sx={{ border: employee.e_verified == 1 ? '1px solid #4ABE43 !important' : employee.e_verified == 0 || employee.e_verified == 4 ? '1px solid #0C75EB !important' : '', backgroundColor: (employee.e_verified == 0 ? "#ffffff !important" : employee.e_verified == 1 ? "#4ABE43 !important" : employee.e_verified == 2 ? "#F59E0B !important" : employee.e_verified == 3 ? "#E51A1A !important" : ''), color: '#ffffff' }} onClick={() => employee.e_verified == 0 || employee.e_verified == 4 || employee.e_verified == 3 ? checkRefreshToken(employee.e_verified) : ''}>
                                                            <Stack direction='row' gap={1} sx={{ color: (employee.e_verified == 0 ? "#0C75EB  !important" : employee.e_verified == 1 ? "#ffffff !important" : employee.e_verified == 2 ? "#ffffff !important" : employee.e_verified == 3 ? "#ffffff !important" : '') }}>
                                                                {(employee.e_verified == 0 ? 'E-Verify' : employee.e_verified == 1 ? 'E-Verified' : employee.e_verified == 2 ? 'Pending' : employee.e_verified == 3 ? 'Rejected' : employee.e_verified == 4 ? 'Re-Verify' : '')}
                                                                {employee.e_verified !== 0 && employee.e_verified !== 4 && <img style={{ marginTop: '6px' }} width={'15px'} height={'15px'} src={employee.e_verified == 1 ? Verify : employee.e_verified == 2 ? Pending : employee.e_verified == 3 ? Rejected : null} alt='e_verify' />}
                                                            </Stack>
                                                        </LoadingButton>
                                                }
                                            </>
                                }
                                {
                                    loading ? <Skeleton width='45px' height='60px' animation='wave' /> :
                                        (!show_rehire_button() && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_edit" && item.is_allowed == true))) &&
                                            <Button variant="outlined" className={open ? classes.blueMenu : classes.moreBtn} onClick={handleClick} >
                                                <MoreHorizIcon sx={{ fontSize: "18px", color: open ? `${blue}` : "#9D9E9F" }} />
                                                <Menu
                                                    id="basic-menu"
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={handleClose}
                                                    sx={{
                                                        '& .MuiPaper-root': {
                                                            border: '1px solid #EAECF0 !important',
                                                            width: '150px !important',
                                                            boxShadow: "#0000001F !important",
                                                            borderRadius: '8px !important',
                                                            padding: '0px 2px 0px 0px !important'
                                                        },
                                                    }}
                                                >
                                                    <EmployeeActions employee={employee} rolePermission={rolePermission} handleStatus={handleStatus} classes={classes} setCancelPopup={setCancelPopup} />
                                                </Menu>
                                            </Button>
                                        )

                                }
                            </Stack>
                        </Grid>
                        <Grid item container md={12} xs={12} columnSpacing={2} pt={2}>
                            {
                                dataArr.map((item, key) => (
                                    <>
                                        {
                                            (employee.e_verified == 0 || employee.e_verified == 3) && item == 'E-Verify' ? '' :
                                                <Grid item md={3} xs={3}>
                                                    <Box display='flex' flexDirection='row' gap={1}>
                                                        {
                                                            current == item ?
                                                                <Text analyticsViewAll sx={{ cursor: 'pointer !important' }} onClick={() => { if (current == item) { } else if (formEdit) { setOpenpopup(true); setStepItem(item) } else { setCurrent(item); setdocumentPosition('education') } }}>{item}</Text> :
                                                                <Text mediumBoldBlack400 sx={{ cursor: 'pointer !important' }} onClick={() => { if (current == item) { } else if (formEdit) { setOpenpopup(true); setStepItem(item) } else { setCurrent(item); setdocumentPosition('education') } }}>{item}</Text>
                                                        }
                                                        {
                                                            checkDocumentComplete(item) ?
                                                                // <Grid item container justifyContent={'flex-end'} xs={2}>
                                                                <BlackToolTip arrow placement='right' title={'Pending'}>
                                                                    <img src={eVerifyPending} alt='pending' />
                                                                </BlackToolTip>
                                                                : ''
                                                        }
                                                    </Box>
                                                </Grid>
                                        }
                                    </>
                                ))}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container md={12} xs={12} pt={2} justifyContent='center'>
                    <Grid item container md={11} xs={12}>
                        <Box className={classes.responsiveContainer}>
                            {
                                current === "General Details" && employee.basic_details.first_name !== '' &&
                                <Box m={0.5} mr={0.5}>
                                    <Box mb={2} sx={{ borderRadius: "8px", boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D" }}>
                                        <GeneralDetails rolePermission={rolePermission} formEdit={formEdit} setformEdit={setformEdit} offBoardButton={offBoardButton} progress={progress} avatar_url={employee.profile_picture_url} active={active} grButn={grButn}
                                            employee={employee} setEmployee={setEmployee} getIndex={getEmployeeApi} loading={loading} setLoading={setLoading} offBoardButton_enable={employee.is_off_boarding_initiated} offboardPercentage={employee.off_boarding_percentage}
                                        />
                                    </Box>
                                    <Box sx={{ borderRadius: "8px", boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D" }}>
                                        <ViewEmergency rolePermission={rolePermission} formEdit={formEdit} setformEdit={setformEdit} employee={employee} setEmployee={setEmployee} getIndex={getEmployeeApi} />
                                    </Box>
                                </Box>
                            }
                            {
                                current === "Documents" ?
                                    <Box m={0.5} mr={0} mb={0}>
                                        <Box mb={2} sx={{ boxShadow: "0px 2px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D", borderRadius: "8px" }}>
                                            <Documents rolePermission={rolePermission} formEdit={formEdit} setformEdit={setformEdit} documentPosition={documentPosition} setdocumentPosition={setdocumentPosition} employee={employee} setEmployee={setEmployee} getIndex={getEmployeeApi} offBoardButton_enable={employee.is_off_boarding_initiated} progress={employee.profile_progress} />
                                        </Box>
                                        <Box sx={{ height: '70vh', width: '100%', overflowY: 'scroll' }}>
                                            <AllSections rolePermission={rolePermission} formEdit={formEdit} setformEdit={setformEdit} documentPosition={documentPosition} setdocumentPosition={setdocumentPosition} employee={employee} setEmployee={setEmployee} getIndex={getEmployeeApi} />
                                        </Box>
                                    </Box>
                                    : null
                            }
                            {
                                current === "Bank Details" ?
                                    <BankDetails rolePermission={rolePermission} getEmployeeApi={getEmployeeApi} getActivity={getActivity} employee={employee} formEdit={formEdit} setformEdit={setformEdit} offBoardButton_enable={employee.is_off_boarding_initiated} />
                                    : null
                            }
                            {
                                current === "Immigration" ? <Immigration formEdit={formEdit} setformEdit={setformEdit} offBoardButton_enable={employee.is_off_boarding_initiated} progress={employee.profile_progress} /> : null
                            }
                            {
                                current === "Timesheet" ? <Timesheet formEdit={formEdit} setformEdit={setformEdit} timesheet_enable={employee.is_configurable_timesheet_available} id={employee.id} offBoardButton_enable={employee.is_off_boarding_initiated} progress={employee.profile_progress} /> : null
                            }
                            {
                                current === "Skills" ? <Skills rolePermission={rolePermission} formEdit={formEdit} employee={employee} getEmployeeApi={getEmployeeApi} getActivity={getActivity} setformEdit={setformEdit} offBoardButton_enable={employee.is_off_boarding_initiated} progress={employee.profile_progress} /> : null
                            }
                            {
                                current === "Dependents" ? <Dependents formEdit={formEdit} setformEdit={setformEdit} offBoardButton_enable={employee.is_off_boarding_initiated} progress={employee.profile_progress} /> : null
                            }
                            {
                                current === "Vacation History" ? <VacationHistory formEdit={formEdit} setformEdit={setformEdit} offBoardButton_enable={employee.is_off_boarding_initiated} progress={employee.profile_progress} /> : null
                            }
                            {
                                current === 'E-Verify' ?
                                    <Everify employee={employee} state={stateEv} setState={setStateEv} setOpenReason={setOpenReason} getEmployeeApi={getEmployeeApi} resonforclosingCase={resonforclosingCase} setResonsforclosingCase={setResonsforclosingCase} setCaseNumber={setCaseNumber} caseNumber={caseNumber} closeCaseComplete={closeCaseComplete} setCloseCaseComplete={setCloseCaseComplete} setloginEvPopup={setloginEvPopup} setrefreshToken={setrefreshToken} />
                                    : null
                            }
                        </Box>
                        <Box sx={{ height: '20vh', right: 100, bottom: 30, zIndex: 1000, position: 'fixed' }}>
                            {
                                show_offboarding() &&
                                <Box display='flex' flexDirection='column' gap={2}>
                                    {
                                        employee.enable_payroll === true ?
                                            <CustomButton redPayroll onClick={disablePayroll}>Disable Payroll</CustomButton> :
                                            <CustomButton disablePayroll>Disable Payroll</CustomButton>
                                    }
                                    <CustomButton offBoardButton onClick={() => setContinueOffboard(true)}>Off Boarding | {employee && employee.off_boarding_percentage}%</CustomButton>
                                </Box>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Hidden>

            <ReusablePopup white iconHide fullWidth openPopup={popUpOpen} setOpenPopup={setPopUpOpen}>
                {show == 1 &&
                    <>
                        <Box my={1} sx={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                            <img src={OffBoardImg} alt="OffBoard" />
                        </Box>
                        <Box my={3} sx={{ textAlign: 'center' }}>
                            <Text my={1} popupHead1> Are You Sure?</Text>
                            <Text my={1} popupHead2>
                                The user access will be {employee.enable_login == 1 ? 'disabled' : 'enabled'}  for <span style={{ color: "#0C75EB" }}>{employee.basic_details.full_name}.</span>
                            </Text>
                        </Box>
                        <Box my={2} sx={{ width: '100%', display: 'flex', justifyContent: 'center', gap: '20px' }} >
                            <CustomButton popupCancel1 onClick={() => handleClosePopUp()}>
                                Cancel
                            </CustomButton>
                            <LoadingButton loading={loaderOffboard} popupSaveBlue onClick={() => handleAccess()} >
                                Yes
                            </LoadingButton>
                        </Box>
                    </>
                }
                {show == 0 &&
                    <UserDisable lastWorkingDate={lastWorkingDate} minDate={employee} dateChange={dateChange} error={error} handleSubmit={handleSubmit} handleClosePopUp={handleClosePopUp} setLastWorkingDate={setLastWorkingDate} loader={loader} />
                }
            </ReusablePopup>
            <SwipeableDrawer
                anchor={'right'}
                open={activityOpen}
                onClose={() => { handleActivityClose() }}
                // onOpen={toggleDrawer(true, state.view)}
                transitionDuration={300}
                sx={{
                    ".MuiDrawer-paper ": {
                        borderRadius: '8px 0px 0px 8px !important',
                    },
                    "& .MuiBackdrop-root.MuiModal-backdrop": {
                        backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                    }
                }
                }
            >
                <ActivityLog
                    activityTotal={activityTotal}
                    activityData={activityData}
                    activityFilter={activityFilter}
                    setActivityFilter={setActivityFilter}
                    downloadActivity={downloadActivity}
                    employee={employee} setLoading={setLoading} loading={loading} />
            </SwipeableDrawer>
            <ReusablePopup openPopup={everifyPopUpOpen} setPopUpOpen={setEverifyPopUpOpen} white iconHide fullWidth>
                <Grid container p={3} textAlign='center'>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Are You Sure?</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>Do you want to send the employees</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>information for E- verification.</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <CustomButton blueBorderOutlined onClick={() => { setEverifyPopUpOpen(false) }}>
                                Cancel
                            </CustomButton>
                            <CustomButton finishFilledQuicksand onClick={() => {
                                // everifyEmployee()
                                setEverifyPopUpOpen(false)
                                // setPopupEv(!popUpEv)
                                setloginEvPopup(true)
                            }} >
                                Yes
                            </CustomButton>
                        </Stack>
                    </Grid>
                </Grid>
            </ReusablePopup>
            <ReusablePopup openPopup={openPayroll} setopenPopup={setOpenPayroll} white iconHide fullWidth>
                <Grid container textAlign='center'>
                    <Grid item lg={12}>
                        <img src={OffBoardImg} alt='payroll' />
                    </Grid>
                    <Grid item lg={12} mt={2}>
                        <Text popupHead1> Are You Sure?</Text>
                    </Grid>
                    <Grid item lg={12} mt={2}>
                        <Text popupHead2> Do you want to disable the payroll for <span className={classes.blueText}>{employee.basic_details.full_name}</span></Text>
                    </Grid>
                    <Grid item lg={12} my={2} mt={3}>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', gap: '20px' }} >
                            <CustomButton popupCancelRed onClick={() => setOpenPayroll(false)}>
                                No
                            </CustomButton>
                            <CustomButton popupSaveBlueHeight onClick={disablePayrollApi}>Yes</CustomButton>
                        </Box>
                    </Grid>
                </Grid>
            </ReusablePopup>
            <ReusablePopup openPopup={openPopup} setopenPopup={setOpenpopup} white iconHide fullWidth>
                <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                    <img src={offBoardSave} alt='noactive' />
                </Grid>
                <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                    <Text offBoardHeadFont sx={{ fontSize: "18px !important" }}>Do you want to discontinue?</Text>
                </Grid>
                <Grid container justifyContent={'center'} alignContent={'center'}>
                    <Text offBoardBodyFont>Your progress will not be saved</Text>
                </Grid>
                <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                    <Stack direction={"row"} spacing={3}>
                        <CustomButton smallBlackOutline onClick={() => { setOpenpopup(false) }} >
                            Cancel
                        </CustomButton>
                        <CustomButton smallBlue redBackground onClick={() => { setformEdit(false); setCurrent(stepItem); setOpenpopup(false) }} >
                            Yes
                        </CustomButton>
                    </Stack>
                </Grid>
            </ReusablePopup>
            <ReusablePopup white iconHide openPopup={empExists} setOpenPopup={setEmpExistis} fixedWidth>
                <Grid container spacing={2} p={2} textAlign={'center'} justifyContent={'center'}>
                    <Grid item lg={12}>
                        <img src={contactExists} alt='rehire' />
                    </Grid>
                    <Grid item lg={12}>
                        <Text veryLargeLabel>Details already exist</Text>
                    </Grid>
                    <Grid item lg={12}>
                        <Text mediumOverView>The contact details already exist for<br /> another active user</Text>
                    </Grid>
                    <Grid item lg={9} mt={1}>
                        <CustomButton saveBtn400 onClick={() => setEmpExistis(false)}>Okay</CustomButton>
                    </Grid>
                </Grid>
            </ReusablePopup>
            <ReusablePopup openPopup={limitExceed} setOpenPopup={setLimitExceed} iconHide white fixedWidth>
                <Grid container justifyContent='center'>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={deleteIcon} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={3}>
                        <Text offBoardHeadFont sx={{ fontSize: "18px !important" }}>Limit Exceeded!</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} textAlign='center' pt={2}>
                        {
                            LocalStorage.getUserData().super_admin ?
                                <Text offBoardBodyFont>You have exceeded the maximum limit of<br /> adding employees in this plan. Please upgrade<br /> to add more Employees.</Text> :
                                <Text offBoardBodyFont>You have exceeded the maximum limit of<br /> adding employees in this plan. Please contact<br /> your super admin to upgrade.</Text>
                        }
                    </Grid>
                    <Grid container lg={11} justifyContent={'center'} textAlign='center' alignContent={'center'} mt={3}>
                        {
                            LocalStorage.getUserData().super_admin ?
                                <Grid item lg={8} container>
                                    <Grid item lg={6}>
                                        <CustomButton outlineBlue sx={{ width: '100px !important', height: '42px !important' }} onClick={() => { setLimitExceed(false) }}>Cancel</CustomButton>
                                    </Grid>
                                    <Grid item lg={6}>
                                        <CustomButton sx={{ width: '100px !important' }} blueButton onClick={() => upgradePlan()}>Upgrade</CustomButton>
                                    </Grid>
                                </Grid> :
                                <CustomButton blueButton onClick={() => { setLimitExceed(false) }}>
                                    Cancel
                                </CustomButton>
                        }
                    </Grid>
                </Grid>
            </ReusablePopup>

            <ReusablePopup openPopup={finishPopup} setOpenPopup={setFinishPopup} white iconHide >
                <Box my={3} width={'450px'}>
                    <Box my={1} sx={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                        <img src={Initiated} alt="completed" />
                    </Box>
                    <Grid container justifyContent={'center'} my={1}>
                        <Text my={1} offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>
                            Off boarding Initiated
                        </Text>

                    </Grid>
                    <Grid container justifyContent={'center'} my={1}>
                        <Text offBoardBodyFont sx={{ fontSize: '14px !important', textAlign: "center" }}>
                            Offboarding for <span style={{ color: "#0C75EB" }}>{employee.basic_details.full_name}</span> has been initiated. The offboarding progress will be displayed one week prior to the last working day.
                        </Text>
                    </Grid>

                    <Box my={2} className={offboardChecklistClasses.popupHead1} mt={4}>
                        <CustomButton popupSaveBlue sx={{ width: "335px !important" }} onClick={handleFinishPopup}>
                            Done
                        </CustomButton>
                    </Box>
                </Box>
            </ReusablePopup>
            <ReusablePopup openPopup={loginEvPopup} setOpenPopup={setloginEvPopup} white iconHide statusWidth onClose={() => setloginEvPopup(false)}>
                <Box my={3} display={'flex'} justifyContent={'center'} flexDirection={'column'}>
                    <Box my={1} rowGap={2} sx={{ width: "100%", height: '78px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <Text offBoardHeadFont sx={{ fontSize: '20px !important', fontWeight: `${700} !important` }} >USCIS Sign In</Text>
                        <Text offBoardBodyFont sx={{ fontSize: '16px !important', fontWeight: `${400} !important`, textAlign: "center", marginBottom: '16px' }}>
                            Please enter registered email id and password for E-Verify web agent account to continue. If you don’t have an E-Verify account please visit the <a style={{ color: '#0C75EB', textDecoration: '1px underline solid #0C75EB' }} href='https://idp.uscis.gov/enroll/everify' target="_blank" rel="noopener noreferrer">USCIS Site</a> and enroll as Web services employer agent
                        </Text>
                    </Box>
                    <Grid container spacing={3} mt={3}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Input sx={{ width: "100% !important" }}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    name: 'user_name',
                                    value: stateEv.user_name,
                                    inputProps: { minLength: 6, maxLength: 20 }
                                }}
                                handleChange={changeHandlerEv}
                                clientInput
                                labelText='User ID'
                                error={errorEv.user_name}
                            />
                            {
                                errorEv.user_name ?
                                    <Text red>{errorEv.user_name ? errorEv.user_name : ''}</Text> : ''
                            }
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Input
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    name: 'password_name',
                                    value: stateEv.password_name,
                                    type: eye.password ? 'text' : 'password',
                                    inputProps: { minLength: 8, maxLength: 32 }
                                }}
                                eyeIcon={eye.password}
                                eyeCloseIcon={eye.password == false}
                                eyeHandleChange={() => {
                                    setEye({
                                        ...eye,
                                        password: !eye.password
                                    })
                                }}
                                iconText
                                handleChange={changeHandlerEv}
                                labelText={'Enter Password'}
                                error={errorEv.password_name}
                            />{
                                errorEv.password_name ?
                                    <Text red>{errorEv.password_name ? errorEv.password_name : ''}</Text> : ''
                            }
                        </Grid>
                    </Grid>
                    <Box my={2} className={offboardChecklistClasses.popupHead1} mt={4}>
                        <LoadingButton loading={loginLoader} popupSaveBlue sx={{ width: "100% !important" }} onClick={() => handleLogin()}>
                            Login
                        </LoadingButton>
                    </Box>
                </Box>
            </ReusablePopup>
            <ReusablePopup openPopup={popUpEv} setOpenPopup={setPopupEv} white iconHide fullWidth onClose={() => setEnableSSN(false)}>
                <Grid container>
                    <Grid item lg={12} xs={12}>
                        <Box display='flex' flexDirection='row' gap={2} alignItems='center'>
                            <Box>
                                {
                                    currentStepEv === 2 || currentStepEv === 3 ?
                                        <img src={backarrow} alt='arrow' className={classes.cursor} onClick={() => { if (currentStepEv === 2) { setCurrentStepEv(1) } else if (currentStepEv === 3) { setCurrentStepEv(2) } }} /> : ''
                                }
                            </Box>
                            <Box><Text veryLargeLabel>E-Verify</Text></Box>
                        </Box>

                    </Grid>
                    <Grid item lg={12} xs={12}>
                        {currentStepEv === 1 &&
                            <Grid container spacing={2} my={1.5} alignItems='center'>
                                <Grid item lg={0.7} xs={0.7}>
                                    <BlackToolTip arrow placement='bottom' title={
                                        <Text smallWhite>To improve your profile completeness,<br /> please finish this step</Text>
                                    }>
                                        <img src={noErrorEvStep1 ? verified : pendingInfoEv} alt='pending' />
                                    </BlackToolTip>
                                </Grid>
                                <Grid item lg={11.3} xs={11.3}>
                                    <Text smallGreyFont>{noErrorEvStep1 ? 'Please verify the information before verifying the employee.' : 'Some information is missing please fill out the following information for e-verify the user.'}</Text>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                    {currentStepEv === 1 &&
                        <Grid item container>
                            <Grid item container my={2}>
                                <Text mediumBoldBlack>Basic Details</Text>
                                <Grid item container spacing={2} mt={2}>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'first_name',
                                                value: stateEv.first_name,
                                                inputProps: { maxLength: 33 }
                                            }}
                                            handleChange={changeHandlerEv}
                                            clientInput
                                            labelText='First Name'
                                            error={errorEv.first_name}
                                        />{
                                            errorEv.first_name ?
                                                <Text red>{errorEv.first_name ? errorEv.first_name : ''}</Text> : ''
                                        }
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'middle_name',
                                                value: stateEv.middle_name,
                                                inputProps: { minLength: 1, maxLength: 1 }
                                            }}
                                            clientInput
                                            handleChange={changeHandlerEv}
                                            labelText='Middle Name (Optional)'
                                            error={errorEv.middle_name}
                                        />{
                                            errorEv.middle_name ?
                                                <Text red>{errorEv.middle_name ? errorEv.middle_name : ''}</Text> : ''
                                        }
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'last_name',
                                                value: stateEv.last_name,
                                                inputProps: { maxLength: 33 }
                                            }}
                                            clientInput
                                            handleChange={changeHandlerEv}
                                            labelText='Last Name'
                                            error={errorEv.last_name}
                                        />{
                                            errorEv.last_name ?
                                                <Text red>{errorEv.last_name ? errorEv.last_name : ''}</Text> : ''
                                        }
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'other_last_name_used',
                                                value: stateEv.other_last_name_used,
                                                inputProps: { maxLength: 33 }
                                            }}
                                            clientInput
                                            handleChange={changeHandlerEv}
                                            labelText='Other Last Names Used (Optional)'
                                            error={errorEv.other_last_name_used}
                                        />{
                                            errorEv.other_last_name_used ?
                                                <Text red>{errorEv.other_last_name_used ? errorEv.other_last_name_used : ''}</Text> : ''
                                        }
                                    </Grid>
                                    <Grid item container lg={12} md={12} sm={12} xs={12}>
                                        <Grid item lg={5.8} md={5.8} sm={6} xs={6} >
                                            <Date
                                                labelText={'Date of Birth'}
                                                name='date_of_birth'
                                                height='54px'
                                                value={stateEv.date_of_birth}
                                                onChange={(value => dateChangeEv(value, 'date_of_birth'))}
                                                maxDate={moment().subtract(18, "years")}
                                                error={errorEv.date_of_birth ? errorEv.date_of_birth : ''}

                                            />{
                                                errorEv.date_of_birth ?
                                                    <Text red>{errorEv.date_of_birth ? errorEv.date_of_birth : ''}</Text> : ''
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'email_address',
                                                value: stateEv.email_address,

                                            }}
                                            clientInput
                                            handleChange={changeHandlerEv}
                                            labelText='Employee E-mail ID'
                                            error={errorEv.email_address}
                                        />{
                                            errorEv.email_address ?
                                                <Text red>{errorEv.email_address ? errorEv.email_address : ''}</Text> : ''
                                        }
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Input
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                name: 'client_company_name',
                                                value: stateEv.client_company_name,
                                            }}
                                            clientInput
                                            handleChange={changeHandlerEv}
                                            labelText='Client Company Name'
                                            error={errorEv.client_company_name}
                                        />{
                                            errorEv.client_company_name ?
                                                <Text red>{errorEv.client_company_name ? errorEv.client_company_name : ''}</Text> : ''
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item container spacing={3}>
                                <Grid item lg={12} xs={12}>
                                    <Text mediumBoldBlack>SSN</Text>
                                </Grid>
                                {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <FileInput
                                        name='ssn_document_optional'
                                        label={'SSN Document (Optional)'}
                                        error={errorEv.ssn_document_optional ? errorEv.ssn_document_optional : ''}
                                        FileName={stateEv ? stateEv.ssn_name : ''}
                                        handleChange={(e) => uploadDocsEv(e)}
                                        documentUrl={stateEv.ssn_url}
                                        isDisabled={false}
                                        handleDelete={() => deleteDocEv()}
                                        actionState={stateEv.ssn_name ? 1 : ''}
                                    />
                                    {
                                        errorEv.ssn_document_optional &&
                                        <Text red>{errorEv.ssn_document_optional ? errorEv.ssn_document_optional : ''}</Text>
                                    }
                                </Grid> */}
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Input
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            name: 'ssn',
                                            value: stateEv.ssn,
                                        }}
                                        clientInput
                                        disabled
                                        handleChange={changeHandlerEv}
                                        labelText='SSN Number'
                                        error={errorEv.ssn}
                                    />{
                                        errorEv.ssn ?
                                            <Text red>{errorEv.ssn ? errorEv.ssn : ''}</Text> : ''
                                    }
                                </Grid>
                            </Grid>
                            <Grid item container spacing={2} justifyContent={'end'} alignItems={'end'} mt={2}>
                                <Box display='flex' flexDirection='row' gap={2} alignItems='center'>
                                    <CustomButton cancelOutlineRed onClick={() => cancelButton()}>
                                        Cancel
                                    </CustomButton>
                                    <CustomButton saveNcontinueSmall onClick={handleSubmitEverify}>
                                        Continue
                                    </CustomButton>
                                </Box>
                            </Grid>
                        </Grid>
                    }
                    {currentStepEv === 2 &&
                        <Grid item container spacing={1}>
                            <Grid item lg={12} xs={12} mt={2}>
                                <Text mediumBoldBlack>Citizenship Status</Text>
                            </Grid>
                            <Grid item lg={12} xs={12}>
                                <CustomRadioGroup spacing={2} mediumGrey2 options={citizenshipStatus} value={stateEv.citizen_type} disabled={disabledEv} onChange={handleRadio3ChangeEv} widthProp='6' />
                                <Grid item container lg={12} xs={12} mt={2}>
                                    <Text mediumBoldBlack>Employer or Authorized Representative Review and Verification </Text>
                                    <Text my={2} blackHeader2>What document types are on {employee.basic_details.full_name} form I-9? </Text>
                                    <CustomRadioGroup mediumGrey2 options={listItemsEv} value={stateEv.document_type} onChange={handleRadio1ChangeEv} widthProp='12' />
                                    {
                                        errorEv.document_type &&
                                        <Text red>{errorEv.document_type ? errorEv.document_type : ''}</Text>
                                    }
                                </Grid>
                                {stateEv.document_type == 1 &&
                                    <Grid item container mt={1}>
                                        <Grid item lg={12} xs={12}>
                                            <Text mediumBoldBlack mb={1}>List A Document</Text>
                                        </Grid>
                                        <Grid item container>
                                            <Grid item lg={12} md={12} sm={12} xs={12} mt={1}>
                                                <Select
                                                    name='list_one_document_type_code'
                                                    value={stateEv.list_one_document_type_code}
                                                    onChange={changeHandlerEv}
                                                    options={documentAList}
                                                    label={'Select List A Document'}
                                                    error={errorEv.list_one_document_type_code ? errorEv.list_one_document_type_code : ''}
                                                />
                                                {
                                                    errorEv.list_one_document_type_code &&
                                                    <Text red>{errorEv.list_one_document_type_code ? errorEv.list_one_document_type_code : ''}</Text>
                                                }
                                            </Grid>
                                            {
                                                ((stateEv.list_one_document_type_code == 'us_passport_or_card_2_1' || stateEv.list_one_document_type_code == 'us_passport_or_card_1_1') && (stateEv.citizen_type == 1 || stateEv.citizen_type == 2) && stateEv.document_type == 1) &&
                                                <Grid item container spacing={2} mt={1}>
                                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                                        <Input
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                name: 'us_passport_number',
                                                                value: stateEv.us_passport_number,
                                                            }}
                                                            clientInput
                                                            handleChange={changeHandlerEv}
                                                            labelText='Document Number'
                                                            error={errorEv.us_passport_number}
                                                        />{
                                                            errorEv.us_passport_number ?
                                                                <Text red>{errorEv.us_passport_number ? errorEv.us_passport_number : ''}</Text> : ''
                                                        }
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                                        <Date
                                                            labelText={'Expiration Date'}
                                                            name='expiration_date'
                                                            height='54px'
                                                            value={stateEv.expiration_date}
                                                            onChange={(value => dateChangeEv(value, 'expiration_date'))}
                                                            error={errorEv.expiration_date ? errorEv.expiration_date : ''}
                                                        />{
                                                            errorEv.expiration_date ?
                                                                <Text red>{errorEv.expiration_date ? errorEv.expiration_date : ''}</Text> : ''
                                                        }
                                                    </Grid>
                                                </Grid>
                                            }
                                            {
                                                (stateEv.citizen_type == 3 || stateEv.citizen_type == '4') && stateEv.list_one_document_type_code !== '' && (stateEv.list_one_document_type_code == 'permanent_resident_alien_registration_i551_3_1' || stateEv.list_one_document_type_code == 'employment_auth_doc_i766_4_1') ?
                                                    <Grid item lg={6} md={6} sm={12} xs={12} mt={1} paddingRight='8px'>
                                                        <Input
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                name: 'doc_number_aaa',
                                                                value: stateEv.doc_number_aaa,
                                                            }}
                                                            clientInput
                                                            handleChange={changeHandlerEv}
                                                            labelText='Document Number'
                                                            error={errorEv.doc_number_aaa}
                                                        />{
                                                            errorEv.doc_number_aaa ?
                                                                <Text red>{errorEv.doc_number_aaa ? errorEv.doc_number_aaa : ''}</Text> : ''
                                                        }
                                                    </Grid>
                                                    : null
                                            }

                                            {(stateEv.citizen_type == 3 && stateEv.list_one_document_type_code == 'arrival_departure_i94_temp_i551_or_refugee_stamp_3_1') ?
                                                <Grid item lg={6} md={6} sm={12} xs={12} mt={1}>
                                                    <Date
                                                        labelText={'Expiration Date'}
                                                        name='expiration_date'
                                                        height='54px'
                                                        value={stateEv.expiration_date}
                                                        onChange={(value => dateChangeEv(value, 'expiration_date'))}
                                                        error={errorEv.expiration_date ? errorEv.expiration_date : ''}
                                                    />
                                                    {
                                                        errorEv.expiration_date ?
                                                            <Text red>{errorEv.expiration_date ? errorEv.expiration_date : ''}</Text> : ''
                                                    }
                                                </Grid>
                                                : null
                                            }
                                            {(stateEv.citizen_type == 3 && stateEv.list_one_document_type_code == 'foreign_passport_temp_i551_mrv_notation_3_1') ?
                                                <Grid item container spacing={2} mt={1}>
                                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                                        <Input
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                name: 'doc_number_six_to_twelve_digits',
                                                                value: stateEv.doc_number_six_to_twelve_digits,
                                                            }}
                                                            clientInput
                                                            handleChange={changeHandlerEv}
                                                            labelText='Document Number'
                                                            error={errorEv.doc_number_six_to_twelve_digits}
                                                        />{
                                                            errorEv.doc_number_six_to_twelve_digits ?
                                                                <Text red>{errorEv.doc_number_six_to_twelve_digits ? errorEv.doc_number_six_to_twelve_digits : ''}</Text> : ''
                                                        }
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                                        <Date
                                                            labelText={'Expiration Date'}
                                                            name='expiration_date'
                                                            height='54px'
                                                            value={stateEv.expiration_date}
                                                            onChange={(value => dateChangeEv(value, 'expiration_date'))}
                                                            error={errorEv.expiration_date ? errorEv.expiration_date : ''}
                                                        />
                                                        {
                                                            errorEv.expiration_date ?
                                                                <Text red>{errorEv.expiration_date ? errorEv.expiration_date : ''}</Text> : ''
                                                        }
                                                    </Grid>
                                                </Grid> : null}
                                            {(stateEv.citizen_type == 4 && stateEv.document_type == 1 && (((stateEv.list_one_document_type_code == 'arrival_departure_i94_temp_i551_or_refugee_stamp_4_1' && stateEv.radioc4Additional2 == 2) || stateEv.list_one_document_type_code == 'foreign_passport_with_arrive_dep_i94_4_1' || stateEv.list_one_document_type_code == 'employment_auth_doc_i766_4_1'))) ?
                                                <Grid item lg={6} md={6} sm={12} xs={12} mt={1}>
                                                    <Date
                                                        labelText={'Expiration Date'}
                                                        name='expiration_date'
                                                        height='54px'
                                                        value={stateEv.expiration_date}
                                                        onChange={(value => dateChangeEv(value, 'expiration_date'))}
                                                        error={errorEv.expiration_date ? errorEv.expiration_date : ''}
                                                    />
                                                    {
                                                        errorEv.expiration_date ?
                                                            <Text red>{errorEv.expiration_date ? errorEv.expiration_date : ''}</Text> : ''
                                                    }
                                                </Grid>
                                                : null
                                            }
                                        </Grid>
                                    </Grid>
                                }
                                {stateEv.document_type == 2 &&
                                    <Grid item container mt={1}>
                                        <Grid item lg={12} xs={12} mb={0.5}>
                                            <Text mediumBoldBlack>List B Document</Text>
                                        </Grid>
                                        <Grid item container spacing={2} mt={1}>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Select
                                                    name='list_two_document_type_code'
                                                    value={stateEv.list_two_document_type_code}
                                                    onChange={changeHandlerEv}
                                                    options={documentBList}
                                                    label={'Select List B Document'}
                                                    error={errorEv.list_two_document_type_code ? errorEv.list_two_document_type_code : ''}
                                                />
                                                {
                                                    errorEv.list_two_document_type_code &&
                                                    <Text red>{errorEv.list_two_document_type_code ? errorEv.list_two_document_type_code : ''}</Text>
                                                }
                                            </Grid>

                                            {(stateEv.list_two_document_type_code == 'us_dl_or_id_card_1_2' || stateEv.list_two_document_type_code == 'us_dl_or_id_card_2_2' || stateEv.list_two_document_type_code == 'us_dl_or_id_card_3_2' || stateEv.list_two_document_type_code == 'us_dl_or_id_card_4_2') &&
                                                <>
                                                    <Grid item lg={12} md={12} sm={12} xs={12}><Text blackHeader2>Are you entering a Driver’s License or a State Issued ID Card </Text>
                                                        <CustomRadioGroup mediumGrey2 options={drivingLicenseEv} value={stateEv.issued_idproof_type} name='issued_idproof_type' onChange={handleRadio4ChangeEv} widthProp='12' />
                                                        {
                                                            errorEv.issued_idproof_type &&
                                                            <Text red>{errorEv.issued_idproof_type ? errorEv.issued_idproof_type : ''}</Text>
                                                        }
                                                    </Grid>
                                                    {
                                                        stateEv.issued_idproof_type == '' ? '' :
                                                            <>
                                                                <Grid item container lg={12} md={12} sm={12} xs={12} spacing={2}>
                                                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                                                        <Text blackHeader2 mb={0.5}>Issued State</Text>
                                                                        <Select
                                                                            name='issued_state_id'
                                                                            value={stateEv.issued_state_id}
                                                                            onChange={changeHandlerEv}
                                                                            options={states}
                                                                            label={'Select Issued State'}
                                                                            error={errorEv.issued_state_id ? errorEv.issued_state_id : ''}
                                                                        />{
                                                                            errorEv.issued_state_id &&
                                                                            <Text red>{errorEv.issued_state_id ? errorEv.issued_state_id : ''}</Text>
                                                                        }
                                                                    </Grid>
                                                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                                                        <Text blackHeader2 mb={0.5}>Document Number</Text>
                                                                        <Input
                                                                            formControlProps={{
                                                                                fullWidth: true
                                                                            }}
                                                                            inputProps={{
                                                                                name: 'issued_state_idproof',
                                                                                value: stateEv.issued_state_idproof,
                                                                            }}
                                                                            clientInput
                                                                            handleChange={changeHandlerEv}
                                                                            labelText='Document Number'
                                                                            error={errorEv.issued_state_idproof}
                                                                        />{
                                                                            errorEv.issued_state_idproof ?
                                                                                <Text red>{errorEv.issued_state_idproof ? errorEv.issued_state_idproof : ''}</Text> : ''
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                                                    <Text blackHeader2 mb={0.5}>Expiration Date</Text>
                                                                    <Date
                                                                        labelText={'Expiration Date'}
                                                                        name='expiration_date'
                                                                        height='54px'
                                                                        value={stateEv.expiration_date}
                                                                        onChange={(value => dateChangeEv(value, 'expiration_date'))}
                                                                        error={errorEv.expiration_date ? errorEv.expiration_date : ''}
                                                                        disabled={stateEv.no_expiration_date === true}
                                                                    />{
                                                                        errorEv.expiration_date ?
                                                                            <Text red>{errorEv.expiration_date ? errorEv.expiration_date : ''}</Text> : ''
                                                                    }
                                                                </Grid>
                                                                <Grid item lg={12} md={12} sm={12} xs={12}  >
                                                                    <Grid container width={'100%'} height={'100%'} display={'flex'} alignItems={'end'}>
                                                                        <Checkbox size='medium' name='email' onChange={(e) => { handleChangeExpiry(e) }} value={stateEv.no_expiration_date} style={{
                                                                            boxShadow: 'none',
                                                                        }} />
                                                                        <Text largeGrey16 ml={0.5} mb={'11px'}>No Expiration date provided</Text>
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                    }
                                                </>
                                            }
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <Select
                                                    name='list_three_document_type_code'
                                                    value={stateEv.list_three_document_type_code}
                                                    onChange={changeHandlerEv}
                                                    options={documentCList}
                                                    label={'Select List C Document'}
                                                    error={errorEv.list_three_document_type_code ? errorEv.list_three_document_type_code : ''}
                                                />
                                                {
                                                    errorEv.list_three_document_type_code &&
                                                    <Text red>{errorEv.list_three_document_type_code ? errorEv.list_three_document_type_code : ''}</Text>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                                <Grid container spacing={2} mt={1}>
                                    {((stateEv.citizen_type == 4 && (stateEv.document_type == '1' || stateEv.document_type == 1) && stateEv.list_one_document_type_code !== '' && stateEv.list_one_document_type_code !== 'employment_auth_doc_i766_4_1') || (stateEv.citizen_type == 4 && stateEv.document_type == 2 && stateEv.list_three_document_type_code !== '')) ?
                                        <Grid item lg={12} md={12} sm={12} xs={12} >
                                            <Text blackHeader2>What document number is on the I-9</Text>
                                            <RadioGroup sx={{ width: "100%" }} row value={stateEv.radioc4Additional2} name={'additionalc42'} onChange={handleRadio6ChangeEv} flexDirection="column">
                                                <Grid container>
                                                    <Grid item lg={3} md={3} sm={3} xs={3} >
                                                        <FormControlLabel
                                                            key={1}
                                                            control={<Radio icon={disabledEv ? <RadioDisabledIcon /> : <RadioIcon />}
                                                                checkedIcon={disabledEv ? <RadioCheckedDisabedIcon /> : <RadioCheckedIcon />} />}
                                                            label={<Text black400 >A Number</Text>}
                                                            value={1}
                                                            disabledEv={disabledEv}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={4} md={4} sm={4} xs={4} >
                                                        <FormControlLabel
                                                            key={2}
                                                            control={<Radio icon={disabledEv ? <RadioDisabledIcon /> : <RadioIcon />}
                                                                checkedIcon={disabledEv ? <RadioCheckedDisabedIcon /> : <RadioCheckedIcon />} />}
                                                            label={<Text black400 >Form I-94 Number</Text>}
                                                            value={2}
                                                            disabledEv={disabledEv}
                                                        />
                                                    </Grid>
                                                    {
                                                        (stateEv.citizen_type == 4 && stateEv.document_type == 2 && stateEv.list_three_document_type_code !== '') ?
                                                            <Grid item lg={5} md={5} sm={5} xs={5} >
                                                                <FormControlLabel
                                                                    key={3}
                                                                    control={<Radio icon={disabledEv ? <RadioDisabledIcon /> : <RadioIcon />}
                                                                        checkedIcon={disabledEv ? <RadioCheckedDisabedIcon /> : <RadioCheckedIcon />} />}
                                                                    label={<Text black400 >Foreign Passport Number</Text>}
                                                                    value={3}
                                                                    disabledEv={disabledEv}
                                                                />
                                                            </Grid> : ''
                                                    }
                                                </Grid>
                                            </RadioGroup>
                                            {
                                                errorEv.radioc4Additional2 &&
                                                <Text red>{errorEv.radioc4Additional2 ? errorEv.radioc4Additional2 : ''}</Text>
                                            }
                                        </Grid> :
                                        null
                                    }

                                    {stateEv.citizen_type == '4' && ((stateEv.document_type == 1 && stateEv.list_one_document_type_code == 'arrival_departure_i94_temp_i551_or_refugee_stamp_4_1' && stateEv.radioc4Additional2 == 2) || (stateEv.citizen_type == 4 && stateEv.document_type == 2 && stateEv.list_three_document_type_code !== '' && stateEv.radioc4Additional2 == 2) ||
                                        (((stateEv.list_one_document_type_code == 'arrival_departure_i94_temp_i551_or_refugee_stamp_3_1' && stateEv.radioc4Additional2 == 2) || (stateEv.list_one_document_type_code == 'foreign_passport_temp_i551_mrv_notation_3_1' && stateEv.radioc4Additional2 == 2)) ||
                                            stateEv.radioc4Additional == '2' || (stateEv.list_one_document_type_code == 'foreign_passport_with_arrive_dep_i94_4_1' && stateEv.radioc4Additional2 == 2)
                                        )) ?
                                        <>
                                            <Grid item lg={6} md={6} sm={6} xs={6} mt={1}>
                                                <Text blackHeader2 mb={1}>I-94 Number</Text>
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        name: 'i94_number',
                                                        value: stateEv.i94_number,
                                                    }}
                                                    clientInput
                                                    handleChange={changeHandlerEv}
                                                    labelText='I-94 Number'
                                                    error={errorEv.i94_number}
                                                />
                                                {
                                                    errorEv.i94_number &&
                                                    <Text red>{errorEv.i94_number ? errorEv.i94_number : ''}</Text>
                                                }
                                            </Grid>
                                        </> : null
                                    }

                                    {(stateEv.citizen_type == 3 && stateEv.document_type !== '' && (stateEv.list_one_document_type_code !== '' || (stateEv.list_two_document_type_code !== '' && stateEv.list_three_document_type_code !== ''))) ||
                                        ((stateEv.citizen_type == '4' && stateEv.document_type == '1' && stateEv.radioc4Additional2 == 1) ||
                                            (stateEv.citizen_type == '4' && (stateEv.radioc4Additional == '1')) || (stateEv.citizen_type == 4 && stateEv.document_type == 2 && stateEv.list_three_document_type_code !== '' && stateEv.radioc4Additional2 == 1) || (stateEv.citizen_type == '4' && stateEv.document_type == '1' && (stateEv.list_one_document_type_code == 'employment_auth_doc_i766_4_1'))) ?
                                        <>
                                            <Grid item lg={6} md={6} sm={6} xs={6} mt={1}>
                                                <Text blackHeader2 mb={1}>A-Number/USCIS Number</Text>
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        name: 'alien_number',
                                                        value: stateEv.alien_number,
                                                    }}
                                                    clientInput
                                                    handleChange={changeHandlerEv}
                                                    labelText='A-Number/USCIS Number'
                                                    error={errorEv.alien_number}
                                                />
                                                {
                                                    errorEv.alien_number &&
                                                    <Text red>{errorEv.alien_number ? errorEv.alien_number : ''}</Text>
                                                }
                                            </Grid>
                                        </> : null
                                    }

                                    {stateEv.citizen_type == '4' &&
                                        ((stateEv.list_one_document_type_code == 'arrival_departure_i94_temp_i551_or_refugee_stamp_3_1' || stateEv.list_one_document_type_code == 'foreign_passport_temp_i551_mrv_notation_3_1') ||
                                            stateEv.radioc4Additional == '3' || (stateEv.document_type == 2 && stateEv.list_three_document_type_code !== '' && stateEv.radioc4Additional2 == 3) || (stateEv.document_type == 1 && stateEv.list_one_document_type_code == 'arrival_departure_i94_temp_i551_or_refugee_stamp_4_1' && stateEv.radioc4Additional2 == 2) || (stateEv.list_one_document_type_code == 'foreign_passport_with_arrive_dep_i94_4_1' && stateEv.radioc4Additional2 !== '')
                                        ) ?
                                        <>
                                            <Grid item lg={6} md={6} sm={6} xs={6} mt={1}>
                                                <Text blackHeader2 mb={1}>Foreign Passport Number</Text>
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        name: 'foreign_passport_number',
                                                        value: stateEv.foreign_passport_number,
                                                    }}
                                                    clientInput
                                                    handleChange={changeHandlerEv}
                                                    labelText='Foreign Passport Number'
                                                    error={errorEv.foreign_passport_number}
                                                />{
                                                    errorEv.foreign_passport_number ?
                                                        <Text red>{errorEv.foreign_passport_number ? errorEv.foreign_passport_number : ''}</Text> : ''
                                                }
                                            </Grid>

                                        </> : null
                                    }

                                    {((stateEv.citizen_type == 3 || stateEv.citizen_type == '4') && stateEv.document_type !== '' &&
                                        (
                                            stateEv.list_one_document_type_code == 'foreign_passport_temp_i551_mrv_notation_3_1' || (stateEv.list_one_document_type_code == 'arrival_departure_i94_temp_i551_or_refugee_stamp_3_1' && stateEv.citizen_type == '4')
                                            || (stateEv.document_type == '2' && stateEv.radioc4Additional == '3') || (stateEv.document_type == 1 && stateEv.list_one_document_type_code == 'arrival_departure_i94_temp_i551_or_refugee_stamp_4_1' && stateEv.radioc4Additional2 == 2) ||
                                            (stateEv.citizen_type == 4 && stateEv.document_type == 2 && stateEv.list_three_document_type_code !== '' && stateEv.radioc4Additional2 == 3) ||
                                            (stateEv.list_one_document_type_code == 'foreign_passport_with_arrive_dep_i94_4_1' && stateEv.radioc4Additional2 !== '')
                                        )) ?
                                        <Grid item lg={6} md={6} sm={6} xs={6} mt={1}>
                                            <Text blackHeader2 mb={1}>Country of Issuance</Text>
                                            <Select
                                                name='issued_country_id'
                                                value={stateEv.issued_country_id}
                                                onChange={changeHandlerEv}
                                                options={countriesList}
                                                label={'Select Issued Country'}
                                                error={errorEv.issued_country_id ? errorEv.issued_country_id : ''}
                                            />{
                                                errorEv.issued_country_id &&
                                                <Text red>{errorEv.issued_country_id ? errorEv.issued_country_id : ''}</Text>
                                            }
                                        </Grid>
                                        : null
                                    }
                                </Grid>

                            </Grid>
                            <Grid item container spacing={2} justifyContent={'end'} alignItems={'end'} mt={stateEv.list_two_document_type_code === "us_dl_or_id_card_1_2" ? 1 : 0}>
                                <Box display='flex' flexDirection='row' gap={2}>
                                    <CustomButton cancelOutlineRed onClick={() => cancelButton()}>
                                        Cancel
                                    </CustomButton>
                                    <CustomButton saveNcontinueSmall onClick={handleSubmitEverify}>
                                        Continue
                                    </CustomButton>
                                </Box>
                            </Grid>
                        </Grid>
                    }
                    {currentStepEv === 3 &&
                        <>
                            <Grid item container>
                                <Grid item lg={12} xs={12} mt={1.5} mb={1.5}>
                                    <Text mediumBoldBlack>Additional Case Details</Text>
                                </Grid>
                                <Grid lg={12} xs={12} my={1}>
                                    <Text blackHeader2>Select Employee's First Day of Employment</Text>
                                </Grid>
                                <CustomRadioGroup mediumGrey2 options={dayItemsEv} value={stateEv.radioStartDate} onChange={handleRadio2ChangeEv} widthProp='3' />
                            </Grid>
                            <Grid item container mt={2}>
                                <Text blackHeader2>Employee first day of Employment</Text>
                                <Grid container mt={2}>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Date
                                            labelText='First date of employment'
                                            name='date_of_hire'
                                            height='54px'
                                            minDate={moment('11/07/1986').format(dateFormat())}
                                            maxDate={moment().add(90, 'days').format(dateFormat())}
                                            value={stateEv.date_of_hire}
                                            onChange={(value => dateChangeEvAdditionalStep(value, 'date_of_hire'))}
                                            error={errorEv.date_of_hire ? errorEv.date_of_hire : ''}
                                        />{
                                            errorEv.date_of_hire ?
                                                <Text red>{errorEv.date_of_hire ? errorEv.date_of_hire : ''}</Text> : ''
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item container>
                                {
                                    (stateEv.citizen_type == 4 && (stateEv.document_type == 1 || stateEv.document_type == 2)) ?
                                        <Grid item container spacing={2} mt={1}>
                                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                                <Text blackHeader2 mb={1}>VISA Number</Text>
                                                <Input
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        name: 'visa_number',
                                                        value: stateEv.visa_number,
                                                    }}
                                                    clientInput
                                                    handleChange={changeHandlerEv}
                                                    labelText='VISA Number'
                                                    error={errorEv.visa_number}
                                                />{
                                                    errorEv.visa_number ?
                                                        <Text red>{errorEv.visa_number ? errorEv.visa_number : ''}</Text> : ''
                                                }
                                            </Grid>
                                            {
                                                stateEv.document_type == 1 && stateEv.list_one_document_type_code == 'employment_auth_doc_i766_4_1' ? '' :
                                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                                        <Text blackHeader2 mb={1}>SEVIS ID Number</Text>
                                                        <Input
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                name: 'sevis_number',
                                                                value: stateEv.sevis_number,
                                                            }}
                                                            clientInput
                                                            handleChange={changeHandlerEv}
                                                            labelText='SEVIS ID Number'
                                                            error={errorEv.sevis_number}
                                                        />
                                                        {
                                                            errorEv.sevis_number ?
                                                                <Text red>{errorEv.sevis_number ? errorEv.sevis_number : ''}</Text> : ''
                                                        }
                                                    </Grid>
                                            }
                                        </Grid> : ''
                                }
                                <Grid container spacing={2}>
                                    {(hiredBeforeThreeDays && stateEv.radioStartDate == '') &&
                                        <Grid item lg={8} md={8} sm={8} xs={8} mt={2}>
                                            <Text blackHeader2 my={1}>Select a Reason for Delay</Text>
                                            <Select
                                                name='reason_for_delay'
                                                value={stateEv.reason_for_delay}
                                                onChange={changeHandlerEv}
                                                options={delayList}
                                                label={'Select an opiton...'}
                                                error={errorEv.reason_for_delay ? errorEv.reason_for_delay : ''}
                                            />
                                            {
                                                errorEv.reason_for_delay &&
                                                <Text red>{errorEv.reason_for_delay ? errorEv.reason_for_delay : ''}</Text>
                                            }
                                        </Grid>
                                    }
                                    {(stateEv.reason_for_delay == 'OTHER' && hiredBeforeThreeDays) &&
                                        <Grid item lg={12} md={12} sm={12} xs={12} mt={1}>
                                            <Text blackHeader2 mb={1}>Reason for Delay</Text>
                                            <Textarea maxLength='140' className={errorEv.reason_for_delay_description ? classes.textAreaError : classes.textArea} style={{ outline: 'none' }} type="text" name="reason_for_delay_description" value={stateEv.reason_for_delay_description} aria-label="minimum height" minRows={2} sx={{ resize: "none" }} onChange={(e) => changeHandlerEv(e)} />
                                            {
                                                errorEv.reason_for_delay_description &&
                                                <Text red>{errorEv.reason_for_delay_description ? errorEv.reason_for_delay_description : ''}</Text>
                                            }
                                        </Grid>
                                    }
                                    <Grid item container lg={6} md={6} sm={6} xs={6} mt={2}>
                                        <Grid item lg={12} xs={12} mb={1}><Text blackHeader2>Employee ID</Text></Grid>
                                        <Grid item lg={12} xs={12}>
                                            <Input
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    name: "employeeID",
                                                    value: stateEv.employeeID,
                                                    inputProps: {
                                                        minLength: 1,
                                                        maxLength: 40
                                                    }
                                                }}
                                                disabled
                                                clientInput
                                                handleChange={changeHandlerEv}
                                                labelText={'Enter EMP ID'}
                                                error={error.employeeID}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item container spacing={2} justifyContent={'end'} alignItems={'end'} mt={2}>
                                <Box display='flex' flexDirection='row' gap={2}>
                                    <CustomButton cancelOutlineRed onClick={() => cancelButton()}>
                                        Cancel
                                    </CustomButton>
                                    <LoaderButton loading={submitLoader} saveNcontinueSmall onClick={handleSubmitEverify}>
                                        Finish
                                    </LoaderButton>
                                </Box>
                            </Grid>
                        </>
                    }
                </Grid>
            </ReusablePopup>
            <ReusablePopup openPopup={enableSSN} setOpenPopup={setEnableSSN} white iconHide fixedWidth>
                <Grid container textAlign='center'>
                    <Grid item lg={12} xs={12}>
                        <img src={ssnImage} alt='SSN' />
                    </Grid>
                    <Grid item lg={12} xs={12} my={2}>
                        <Text mediumBoldBlack>Enter Employee’s SSN Number</Text>
                    </Grid>
                    <Grid item lg={12} xs={12} my={3}>
                        <Input
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                name: 'ssnNumber',
                                value: ssnNumber
                            }}
                            handleChange={(e) => changeHandlerEv(e)}
                            clientInput
                            labelText='SSN Number'
                            error={errorEv.ssnNumber ? true : false}
                        />
                        <Box textAlign='start'>
                            <Text red>{errorEv.ssnNumber}</Text>
                        </Box>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <Box display='flex' flexDirection='row' gap={2} justifyContent='center'>
                            <CustomButton popupCancelRed onClick={() => cancelCheckcase()}>Cancel</CustomButton>
                            <LoadingButton loading={ssnLoader} addHeightButton sx={{ height: "43px !important" }} onClick={() => checkCase()}>Check Case</LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
            </ReusablePopup>
            <ReusablePopup openPopup={openEnd} setOpenPopup={setOpenEnd} fixedWidth iconHide white>
                <Grid container textAlign='center' p={2} rowSpacing={3}>
                    <Grid item lg={12} xs={12}>
                        <img src={successEnd} alt='successs' />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <Text largeGreen>Successfully Sent</Text>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <Text black14 sx={{ fontWeight: `${400} !important` }}>Details has been Successfully Sent to USCIS</Text>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <CustomButton saveNcontinueSmall onClick={() => { setOpenEnd(false); setPopupEv(false); getEmployeeApi(); }}>Done</CustomButton>
                    </Grid>
                </Grid>
            </ReusablePopup>
            <ReusablePopup openPopup={openReason} setOpenPopup={setOpenReason} white iconHide fullWidth onClose={() => closeCaseWithReason()}>
                <Grid container textAlign='center' p='15px 0px'>
                    <Grid item lg={12} xs={12} textAlign='start'>
                        <Text mediumBlack1><b>Note:</b> In order to create a new case, please close the existing case with valid reason</Text>
                    </Grid>
                    <Grid item lg={12} xs={12} pt={2} textAlign='start'>
                        <Text mediumBlack600>Reason For Closing case</Text>
                    </Grid>
                    <Grid item lg={12} xs={12} pt={1.5}>
                        <Search
                            name='case_closure_reason_code'
                            value={stateEv.case_closure_reason_code}
                            onChange={(e) => changeHandlerEv(e)}
                            options={resonforclosingCase}
                            label={'Choose the reason'}
                            error={errorEv.case_closure_reason_code}
                        />
                        {
                            errorEv.case_closure_reason_code ?
                                <Text red sx={{ textAlign: 'start' }}>{errorEv.case_closure_reason_code ? errorEv.case_closure_reason_code : ''}</Text> : ''
                        }
                    </Grid>
                    {
                        stateEv.case_closure_reason_code == 'OTHER_FREE_TEXT' ?
                            <Grid item lg={12} xs={12} pt={1.5}>
                                <Textarea onError={errorEv.other_reason_description} placeholder='Description' maxLength='140' className={errorEv.other_reason_description ? classes.textAreaError : classes.textArea} style={{ outline: 'none' }} type="text" name="other_reason_description" value={stateEv.other_reason_description} aria-label="minimum height" minRows={2} sx={{ resize: "none" }} onChange={(e) => changeHandlerEv(e)} />
                                {
                                    errorEv.other_reason_description ?
                                        <Text red sx={{ textAlign: 'start' }}>{errorEv.other_reason_description ? errorEv.other_reason_description : ''}</Text> : ''
                                }
                            </Grid> : ''
                    }
                    {
                        stateEv.case_closure_reason_code == 'EMPLOYEE_CONTINUES_TO_WORK_AFTER_FNC' ?
                            <Grid item lg={12} xs={12} pt={1.5}>
                                <Textarea onError={errorEv.currently_employed_reason_description} placeholder='Description' maxLength='140' className={errorEv.currently_employed_reason_description ? classes.textAreaError : classes.textArea} style={{ outline: 'none' }} type="text" name="currently_employed_reason_description" value={stateEv.currently_employed_reason_description} aria-label="minimum height" minRows={2} sx={{ resize: "none" }} onChange={(e) => changeHandlerEv(e)} />
                                {
                                    errorEv.currently_employed_reason_description ?
                                        <Text red sx={{ textAlign: 'start' }}>{errorEv.currently_employed_reason_description ? errorEv.currently_employed_reason_description : ''}</Text> : ''
                                }
                            </Grid> : ''
                    }
                    <Grid item lg={12} xs={12} textAlign='end' pt={2}>
                        <LoadingButton loading={closecaseLoader} blackCancelBtn1 onClick={() => closeCase()}>Close the case</LoadingButton>
                    </Grid>
                </Grid>
            </ReusablePopup>
            <ReusablePopup openPopup={openDuplicate} setOpenPopup={setOpenDuplicate} white iconHide fixedWidth>
                <Grid container textAlign='center' p={3} spacing={2}>
                    <Grid item lg={12} xs={12}>
                        <img src={OffBoardImg} alt="OffBoard" />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <Text popupHead1>Duplicate case detected</Text>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <Text offBoardBodyFont>It appears that a case with similar information already exists with case number <b>{caseNumber}</b>. Choose an action</Text>
                    </Grid>
                    <Grid item lg={12} xs={12} mt={1}>
                        <CustomButton blueButton onClick={() => { setOpenDuplicate(false); getEmployeeApi() }}>Continue with Existing case</CustomButton>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <CustomButton blackCancelBtn sx={{ width: '100% !important' }} onClick={() => { setOpenDuplicate(false); setOpenReason(true) }}>Create New Case</CustomButton>
                    </Grid>
                </Grid>
            </ReusablePopup>
            <ReusablePopup openPopup={openNewReason} setOpenPopup={setOpenNewReason} white iconHide fullWidth onClose={() => captureNewCaseReason()}>
                <Grid container textAlign='center' p={1} spacing={2}>
                    <Grid item lg={12} xs={12} textAlign='start'>
                        <Text mediumBlack1><b>Note:</b> In order to create a new case, please close the existing case with valid reason</Text>
                    </Grid>
                    <Grid item lg={12} xs={12} pt={2} textAlign='start'>
                        <Text mediumBlack600>Reason For New case</Text>
                    </Grid>
                    <Grid item lg={12} xs={12} pt={1.5}>
                        <Search
                            name='reason_for_new_case'
                            value={stateEv.reason_for_new_case}
                            onChange={(e) => changeHandlerEv(e)}
                            options={reasonforNewCase}
                            label={'Choose the reason'}
                            error={errorEv.reason_for_new_case}
                        />
                        {
                            errorEv.reason_for_new_case ?
                                <Text red sx={{ textAlign: 'start' }}>{errorEv.reason_for_new_case ? errorEv.reason_for_new_case : ''}</Text> : ''
                        }
                    </Grid>
                    {
                        stateEv.reason_for_new_case == 'OTHER' ?
                            <Grid item lg={12} xs={12} pt={1.5}>
                                <Textarea onError={errorEv.new_case_description} placeholder='Description' maxLength='140' className={errorEv.new_case_description ? classes.textAreaError : classes.textArea} style={{ outline: 'none' }} type="text" name="new_case_description" value={stateEv.new_case_description} aria-label="minimum height" minRows={2} sx={{ resize: "none" }} onChange={(e) => changeHandlerEv(e)} />
                                {
                                    errorEv.new_case_description ?
                                        <Text red sx={{ textAlign: 'start' }}>{errorEv.new_case_description ? errorEv.new_case_description : ''}</Text> : ''
                                }
                            </Grid> : ''
                    }
                    <Grid item lg={12} xs={12} textAlign='end' mt={6}>
                        <Box display='flex' flexDirection='row' gap={2} justifyContent='end'>
                            <CustomButton cancelOutlineRed onClick={() => captureNewCaseReason()}>Cancel</CustomButton>
                            <CustomButton save onClick={handleCreateEverify}>Create New case</CustomButton>
                        </Box>
                    </Grid>
                </Grid>
            </ReusablePopup>
            <CancelOffboarding cancelPopup={cancelPopup} setCancelPopup={setCancelPopup} employee={employee} offboardData={offboardData} getEmployeeApi={getEmployeeApi} />
        </Box >
    );
}