import { Backdrop, Box, Divider, Grid, Hidden } from '@mui/material'
import React, { useState } from 'react';
import warning from '../../assets/employee/info.svg';
import Text from '../../components/customText/Text';
import Downloadcloud from '../../assets/svg/download-cloud-blue.svg';
import FileInput from '../../components/muiFileInput/FileInput';
import OnboardStyles from './OnboardStyles';
import Button from '../../components/customButton/Button';
import { addErrorMsg, addSuccessMsg, addWarningMsg } from '../../utils/utils';
import { domain } from '../../config/Domain';
import LocalStorage from '../../utils/LocalStorage';
import onBoardLinkApiInstance from '../../apis/onBoardLink/onBoardLinkApi';
import Loader from '../../assets/gif/japfuLoader.gif';
import FileSaver from 'file-saver';

function I9andW4Documents({ mainStep, setMainStep, state, setState, getInviteViaLink, id }) {
    const classes = OnboardStyles();
    const [error, setError] = useState({
        i9_document_name: '',
        w4_document_name: ''
    });
    const [openBacKLoader, setOpenBackDrop] = useState(false);

    const uploadDocs = (e, args) => {
        if (e.target.name == 'document_name') {
            setOpenBackDrop(true);
            if (e.target.files[0].name.split('.').some(r => ['png', 'jpg', 'jpeg', 'pdf'].includes(r))) {
                if (((e.target.files[0].size / (1024 * 1024)).toFixed(2)) < '25') {
                    const formData = new FormData();
                    formData.append("files", e.target.files[0]);
                    onBoardLinkApiInstance.documentUploadInvite('bank-document', formData).then((res => {
                        setOpenBackDrop(false);
                        if (res.data.statusCode == 1003) {
                            if (args == 'i9') {
                                state.i9andw4.i9_document.id = res.data.data.id
                                state.i9andw4.i9_document.document_name = res.data.data.document_name;
                                state.i9andw4.i9_document.document_url = res.data.data.document_url;
                                error.i9_document_name = ''
                            } else if (args == 'w4') {
                                state.i9andw4.w4_document.id = res.data.data.id
                                state.i9andw4.w4_document.document_name = res.data.data.document_name;
                                state.i9andw4.w4_document.document_url = res.data.data.document_url;
                                error.w4_document_name = ''
                            }
                            setState({ ...state });
                            setError({ ...error })
                        } else {
                            setOpenBackDrop(false);
                            addErrorMsg(res.data.message);
                        }
                    }))
                } else {
                    setOpenBackDrop(false);
                    addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
                }
            } else {
                setOpenBackDrop(false);
                addErrorMsg('Please upload files in PDF or Image format only.')
            }
        }
    }
    const deleteDoc = (args) => {
        if (args == 'i9') {
            state.i9andw4.i9_document.document_name = ''
            state.i9andw4.i9_document.document_url = ''
            error['i9_document_name'] = 'Please upload the document(s)'
        } else if (args == 'W4') {
            state.i9andw4.w4_document.document_name = ''
            state.i9andw4.w4_document.document_url = ''
            error['w4_document_name'] = 'Please upload the document(s)'
        }
        setState({ ...state });
        setError({ ...error });
    }

    const handleSubmit = () => {
        if (state.i9andw4.i9_document.document_name == '' && state.i9andw4.w4_document.document_name == '') {
            error['i9_document_name'] = 'Please upload the document(s)'
            error['w4_document_name'] = 'Please upload the document(s)'
            setError({ ...error });
            addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
        } else if (state.i9andw4.i9_document.document_name == '') {
            error['i9_document_name'] = 'Please upload the document(s)';
            setError({ ...error });
            addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
        } else if (state.i9andw4.w4_document.document_name == '') {
            error['w4_document_name'] = 'Please upload the document(s)'
            setError({ ...error });
            addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
        }
        else {
            state['subdomain_name'] = domain
            state['request_id'] = LocalStorage.uid()
            onBoardLinkApiInstance.uploadDocsUpdate(id, state).then((res) => {
                if (res.data.statusCode == 1003) {
                    setMainStep(mainStep + 1);
                    getInviteViaLink(id);
                    addSuccessMsg(res.data.message);
                } else {
                    addErrorMsg(res.data.message);
                }
            })
        }
    }

    const downloadDocs = (args) => {
        if (args == 'i9') {
            FileSaver.saveAs(state.i9andw4.i9_download);
        } else if (args == 'w4') {
            FileSaver.saveAs(state.i9andw4.w4_download);
        }

    }

    return (
        <Grid container lg={9} md={9} sm={12} xs={12} justifyContent='center'>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box display='flex' flexDirection='row' gap={1.5} className={classes.information}>
                    <Box pt={'4px'}>
                        <img src={warning} alt='warning' />
                    </Box>
                    <Box>
                        <Text mediumOrangeRehire>
                        Please upload Form I-9 and W-4 in the respective fields. You can downlod the forms from given links. Check the samples to see how the form should be filled
                        </Text>
                    </Box>
                </Box>
            </Grid>
            <Grid item container lg={12} md={12} sm={12} xs={12} mt={'40px !important'} sx={{ minHeight: '50vh', maxWidth: '100%', alignItems: 'start', display: 'flex' }}>
                <Grid item container lg={5} md={5} sm={5} xs={12} rowSpacing={3}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box display='flex' flexDirection='row' gap={2} className={classes.cloudContainer} onClick={() => downloadDocs('i9')}>
                            <img src={Downloadcloud} alt='Downloadcloud' />
                            <Text mediumBlue400>Download Form I-9</Text>
                        </Box>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} pb={1} textAlign='start'>
                        <FileInput
                            name={"document_name"}
                            FileName={state.i9andw4.i9_document.document_name ? state.i9andw4.i9_document.document_name : ''}
                            handleChange={(e) => uploadDocs(e, 'i9')}
                            label={"Form I-9"}
                            isDisabled={false}
                            handleDelete={() => deleteDoc('i9')}
                            actionState={state.i9andw4.i9_document.document_name ? 1 : ''}
                            documentUrl={state.i9andw4.i9_document.document_url}
                            error={error.i9_document_name}
                        />
                        <Text red>{error.i9_document_name ? error.i9_document_name : ''}</Text>
                    </Grid>
                    <Text greysmallLabel>To view I-9 sample document <a href={state.i9andw4.i9_sample} target='_blank' rel='noreferrer' style={{ color: "#0C75EB", cursor: "pointer", textDecoration: '1px underline #0C75EB' }}>click here</a></Text>
                </Grid>
                <Hidden smDown>
                    <Grid item container lg={2} md={2} sm={2} xs={0} justifyContent='center'>
                        <Divider orientation="vertical" variant="middle" sx={{ border: '1px solid #E2E5E6 !important', height: '22vh' }} />
                    </Grid>
                </Hidden>
                <Grid item container lg={5} md={5} sm={5} xs={12} rowSpacing={3}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box display='flex' flexDirection='row' gap={2} className={classes.cloudContainer} onClick={() => downloadDocs('w4')}>
                            <img src={Downloadcloud} alt='Downloadcloud' />
                            <Text mediumBlue400>Download Form W-4</Text>
                        </Box>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} pb={1} textAlign='start'>
                        <FileInput
                            name={"document_name"}
                            FileName={state.i9andw4.w4_document.document_name ? state.i9andw4.w4_document.document_name : ''}
                            handleChange={(e) => uploadDocs(e, 'w4')}
                            label={"Form W-4"}
                            isDisabled={false}
                            handleDelete={() => deleteDoc('W4')}
                            actionState={state.i9andw4.w4_document.document_name ? 1 : ''}
                            documentUrl={state.i9andw4.w4_document.document_url}
                            error={error.w4_document_name}
                        />
                        <Text red>{error.w4_document_name ? error.w4_document_name : ''}</Text>
                    </Grid>
                    <Text greysmallLabel>To view W-4 sample document <a href={state.i9andw4.w4_sample} target='_blank' rel='noreferrer' style={{ color: "#0C75EB", cursor: "pointer", textDecoration: '1px underline #0C75EB' }}>click here</a></Text>
                </Grid>
            </Grid>
            <Grid item container lg={12} md={12} sm={12} xs={12}>
                <Grid item lg={7} md={7} sm={7} xs={5} textAlign='start'>
                    <Button blackCancel onClick={() => setMainStep(mainStep - 1)}>Back</Button>
                </Grid>
                <Grid item lg={5} md={5} sm={5} xs={7} textAlign='end'>
                    <Button saveNcontinueSmall onClick={handleSubmit}>Save & Continue</Button>
                </Grid>
            </Grid>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBacKLoader}
            >
                <img src={Loader} alt="Loader" width={'100px'} height={'100px'} />
            </Backdrop>
        </Grid>
    )
}

export default I9andW4Documents