import React, { useState, useEffect, useRef } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { Box, Avatar, Stack, Grid, Checkbox, SwipeableDrawer, CircularProgress, Divider, Backdrop, Chip, FormControlLabel, Breadcrumbs } from '@mui/material';
import crossIcon from '../../../assets/svg/crossIcon.svg';
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Skeleton from '@mui/material/Skeleton';
import Search from '../../../assets/svg/search1.svg';
import NewDownloadcloud from '../../../assets/svg/download-cloud-blue.svg';
import InviteViaLink from '../../../assets/svg/invite-via-link.svg';
import Onboart from '../../../assets/svg/onboard.svg';
import { addErrorMsg, addLoader, BlackToolTip, capitalizeAndAddSpace, dateFormat, NoDataFound, NoPermission, removeLoader } from '../../../utils/utils';
import { useNavigate } from "react-router-dom";
import Text from '../../../components/customText/Text';
import Button from '../../../components/customButton/Button';
import { ReactComponent as CloseIcon } from '../../../assets/svg/cross.svg';
import { ReactComponent as CheckedIcon } from '../../../assets/svg/CheckedIcon.svg';
import { ReactComponent as CheckBorderIcon } from '../../../assets/svg/CheckedBorderIcon.svg';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import LocalStorage from '../../../utils/LocalStorage';
import ClearIcon from '@mui/icons-material/Clear';
import Loader from '../../../assets/gif/japfuLoader.gif';
import PlacementDashboardStyles from '../placements/newDashboard/PlacementDashboardStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Date from '../../../components/datePicker/Date';
import nofilterplacement from '../../../assets/svg/nofilterplacement.svg';
import CircleIcon from '@mui/icons-material/Circle';
import moment from 'moment';
import { validate_emptyField } from '../../../components/Validation';
import FileSaver from 'file-saver';
import TimesheetApi from '../../../apis/admin/timesheets/TimesheetApi';
import Select from '../../../components/select/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import disableVerified from '../../../assets/client/disableVerified.svg';
import Component87 from '../../../assets/svg/Component87.svg';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#404040",
        padding: "6px 14px",
        minWidth: 100,
        border: "1px solid #404040"
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#404040",
        "&::before": {
            backgroundColor: "#404040",
            border: "1px solid #404040"
        }
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} timeout={500} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper ": {
        borderRadius: "16px",
    },
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2)
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1)
    }
}));

export default function TimesheetSummary() {

    const classes = PlacementDashboardStyles();
    const navigate = useNavigate();
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const [placements, setPlacements] = useState([]); // eslint-disable-next-line
    const [pagination, setPagination] = useState({ total: "6" });
    const [loading, setLoading] = useState(false); // eslint-disable-next-line
    const [open, setOpen] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [filterData, setFilterData] = useState({
        limit: 10,
        page: 1,
        search: "",
        status: [],
        start_date: [],
        month: moment().month() + 1,
        year: moment().year(),
        end_date: [],
        clients: [],
        recruiter: [],
        timesheet_cycle: [],
        sort_column: "created_at",
        sort_order: "desc"
    })
    const [filterState, setFilterState] = useState({
        start_date: [],
        end_date: [],
        clients: [],
        recruiter: [],
        timesheet_cycle: [],
    })
    const [datesState, setDatesState] = useState({
        start_range_one: "",
        end_range_one: "",
        start_range_two: "",
        end_range_two: "",
    })
    const [datesError, setDatesError] = useState({
        start_range_one: "",
        end_range_one: "",
        start_range_two: "",
        end_range_two: "",
    })// eslint-disable-next-line
    const [cycleDropdown, setCycleDropdown] = useState([]);
    const [openBacKLoader, setOpenBacKLoader] = useState(false);
    const [drawer, setDrawer] = useState(false); // eslint-disable-next-line
    const [recruitersTableShow, setRecruitersTableShow] = useState(false);// eslint-disable-next-line
    const [clientDropdown, setClientDropdown] = useState([]);// eslint-disable-next-line
    const [recruiters, setRecruiters] = useState([]);

    useEffect(() => {
        if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "timesheet_view" && item.is_allowed == true))) {
            getAllPlacements(filterData);
        }
        // eslint-disable-next-line
    }, [filterData]);

    const handleDownloadExport = () => {
        addLoader(true);
        TimesheetApi.exportTimesheetsSummary(filterData).then((response) => {
            removeLoader();
            if (response.data.statusCode == 1003) {
                FileSaver.saveAs(response.data.data[0].file_path, `Timesheet-Summary-Details`);
            }
            else {
                addErrorMsg(response.data.message)
            }
        });
    }

    const getAllPlacements = (filter) => {
        if (placements.length < 6) {
            setLoading(true);
        }
        TimesheetApi.summaryListing(filter).then((response) => {
            removeLoader();
            setOpenBacKLoader(false);
            if (response.data.statusCode == 1003) {
                setLoading(false);
                setPlacements(response.data.data);
                setPagination({ total: response.data.pagination.total });
            } else {
                setLoading(false);
                addErrorMsg(response.data.message);
            }
        })
        setDrawer(null);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleSearch = (e) => {
        // setOpenBacKLoader(true);
        setExpanded(false);
        filterData.limit = 10;
        filterData.page = 1;
        filterData.search = e.target.value;
        setFilterData({ ...filterData });
        setLoading(true);
        // addLoader(true);
    }

    const handleSearchClear = () => {
        // setOpenBacKLoader(true);
        setExpanded(false);
        filterData.limit = 10;
        filterData.page = 1;
        filterData.search = '';
        setLoading(true);
        setFilterData({ ...filterData });
        getAllPlacements(filterData);

    }

    const handleDeleteChip = (id, name) => {
        if (name === "start_date" || name === "end_date") {
            const newFilterState = { ...filterState };
            const newDatesState = { ...datesState };
            newFilterState[name] = [];
            if (name === "start_date") {
                newDatesState.start_range_one = "";
                newDatesState.end_range_one = "";
            }
            if (name === "end_date") {
                newDatesState.start_range_two = "";
                newDatesState.end_range_two = "";
            }
            setFilterState(newFilterState);
            setDatesState(newDatesState);
        } else {
            const newFilterState = { ...filterState };
            if (name === "recruiter") {
                const updatedArray = newFilterState[name].filter(item => item !== parseInt(id));
                newFilterState[name] = updatedArray;
            } else {
                const updatedArray = newFilterState[name].filter(item => item !== id);
                newFilterState[name] = updatedArray;
            }

            setFilterState(newFilterState);
        }
    };

    const handleChangeCheckBox = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        const updatedArray = [...filterState[name]]; // Create a new array

        const index = updatedArray.findIndex(item => item === value);
        if (index !== -1) {
            updatedArray.splice(index, 1); // Remove item if it exists
        } else {
            updatedArray.push(value); // Add item if it doesn't exist
        }

        setFilterState(prevState => ({
            ...prevState,
            [name]: updatedArray // Update the state with the new array
        }));
    }

    const handleChangeRecruiters = (e) => {
        e.preventDefault();
        const { name } = e.target;
        var val = Number(e.target.value)
        const updatedArray = [...filterState[name]]; // Create a new array

        const index = updatedArray.findIndex(item => item === val);
        if (index !== -1) {
            updatedArray.splice(index, 1); // Remove item if it exists
        } else {
            updatedArray.push(val); // Add item if it doesn't exist
        }

        setFilterState(prevState => ({
            ...prevState,
            [name]: updatedArray // Update the state with the new array
        }));
    }

    const clearAllFilter = () => {
        setExpanded(false);
        datesState.start_range_one = '';
        datesState.end_range_one = '';
        datesState.start_range_two = '';
        datesState.end_range_two = '';
        setDatesState({ ...datesState })

        datesError.start_range_one = '';
        datesError.end_range_one = '';
        datesError.start_range_two = '';
        datesError.end_range_two = '';
        setDatesError({ ...datesError })

        filterState.timesheet_cycle = [];
        filterState.clients = []
        filterState.recruiter = [];
        filterState.start_date = [];
        filterState.end_date = [];
        setFilterState({ ...filterState });

        filterData.timesheet_cycle = [];
        filterData.clients = []
        filterData.recruiter = [];
        filterData.start_date = [];
        filterData.end_date = [];
        setFilterData({ ...filterData });
        setDrawer(null);
        getAllPlacements(filterData);
    }

    const cancelFilter = () => {
        setDrawer(false);
    }

    const handleListItemClick = (param) => {
        setSelectedFilter(param);
    }

    const dateChange = (e, keyName) => {
        let date = e.$d;
        let event = { target: { name: keyName, value: date } }
        datesState[keyName] = moment(date).format(dateFormat());
        setDatesState({ ...datesState }, handleValidate(event));

        if (datesState.start_range_one !== "" && datesState.end_range_one !== "") {
            filterState.start_date = [datesState.start_range_one, datesState.end_range_one];
            setFilterState({ ...filterState });
            // filterData.start_date = [datesState.start_range_one, datesState.end_range_one];
            // setFilterData({ ...filterData });
        }
        if (datesState.start_range_two !== "" && datesState.end_range_two !== "") {
            filterState.end_date = [datesState.start_range_two, datesState.end_range_two];
            setFilterState({ ...filterState });
            // filterData.end_date = [datesState.start_range_two, datesState.end_range_two];
            // setFilterData({ ...filterData });
        }
    }

    const handleValidate = (e) => {
        const input = e.target
        switch (input.name || input.tagName) {
            case 'end_range_one':
                datesError.end_range_one = validate_emptyField(input.value)
                break;
            case 'end_range_two':
                datesError.end_range_two = validate_emptyField(input.value)
                break;
            default:
                break;
        }
        setDatesError({ ...datesError });
    }

    const handleApplyFilters = () => {
        setExpanded(false);
        if (datesState.start_range_one != "" && datesState.end_range_one == "" && datesState.start_range_two != "" && datesState.end_range_two == "") {
            datesError.end_range_one = "Please select the To date.";
            datesError.end_range_two = "Please select the To date.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select To date in Project Start/End Date");
            return false;
        }
        if (datesState.start_range_one != "" && datesState.end_range_one == "") {
            datesError.end_range_one = "Please select the To date.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select To date in Project Start Date");
            return false;
        }
        if (datesState.start_range_two != "" && datesState.end_range_two == "") {
            datesError.end_range_two = "Please select the To date.";
            setDatesError({ ...datesError });
            addErrorMsg("Please select To date in Project End Date");
            return false;
        }
        if (datesState.start_range_one !== "" && datesState.end_range_one !== "") {
            filterState.start_date = [datesState.start_range_one, datesState.end_range_one];
            setFilterState({ ...filterState });
            filterData.start_date = [datesState.start_range_one, datesState.end_range_one];
            // setFilterData({ ...filterData });
        }
        if (datesState.start_range_two !== "" && datesState.end_range_two !== "") {
            filterState.end_date = [datesState.start_range_two, datesState.end_range_two];
            setFilterState({ ...filterState });
            filterData.end_date = [datesState.start_range_two, datesState.end_range_two];
            // setFilterData({ ...filterData });
        }
        filterData.clients = filterState.clients;
        filterData.recruiter = filterState.recruiter;
        filterData.timesheet_cycle = filterState.timesheet_cycle;
        setFilterData({ ...filterData });
        getAllPlacements({ ...filterData, limit: 10, page: 1, })
    }

    const loadMoreData = () => {
        // addLoader(true);
        filterData.limit = Number(filterData.limit) + 6;
        setFilterData({ ...filterData });
    }

    const scrollBox = useRef(null);

    const handleScroll = () => {
        const { current } = scrollBox;
        if (current) {
            const { scrollTop, scrollHeight, clientHeight } = current;
            if (scrollTop + clientHeight >= scrollHeight - 5) {
                if (Number(pagination.total) >= filterData.limit) {
                    setOpenBacKLoader(true);
                    loadMoreData();
                }
            } else {
            }
        }
    };

    const years = [{
        id: 2023,
        value: '2023'
    },
    {
        id: 2024,
        value: '2024'
    }]

    const monthsOptions = [
        {
            id: 1,
            value: 'January'
        },
        {
            id: 2,
            value: 'Febraury'
        },
        {
            id: 3,
            value: 'March'
        },
        {
            id: 4,
            value: 'April'
        },
        {
            id: 5,
            value: 'May'
        },
        {
            id: 6,
            value: 'June'
        },
        {
            id: 7,
            value: 'July'
        },
        {
            id: 8,
            value: 'August'
        }, {
            id: 9,
            value: 'September'
        },
        {
            id: 10,
            value: 'October'
        },
        {
            id: 11,
            value: 'November'
        },
        {
            id: 12,
            value: 'December'
        }
    ]

    const getValueById = (id) => {
        const option = [{
            "id": "Weekly", "value": "Weekly"
        }, {
            "id": "Bi Weekly", "value": "Bi-Weekly"
        }, {
            "id": "Semi Monthly", "value": "Semi-Monthly"
        }, {
            "id": "Monthly", "value": "Monthly"
        }].find(option => option.id === id);
        return option ? option.value : '';
    };

    return (
        <Box className={classes.flexBox} style={{ paddingLeft: '65px' }}>
            {
                false ? ''
                    // <RecruitersTable setRecruitersTableShow={setRecruitersTableShow} recruiters={recruiters} handleRecFilter={handleRecFilter} />
                    :
                    <Box sx={{ width: "90%" }} >
                        <Box style={{ padding: "20px 10px 10px 10px" }}>
                            <Grid container lg={12} md={12} sm={12} xs={12}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Text mediumGrey sx={{ cursor: 'pointer' }} onClick={() => navigate('/timesheet')}>Timesheet</Text>
                                        <Text mediumBlack>Timesheet Summary</Text>
                                    </Breadcrumbs>
                                </Grid>
                                {
                                    (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "timesheet_view" && item.is_allowed == true))) ?
                                        <Grid item container lg={12} md={12} sm={12} xs={12} pt={2}>
                                            <Grid item lg={8} md={8} sm={8} xs={8} pt={.3}>
                                                <div style={{ height: "52px", border: "1.5px solid #E2E5E6", width: "368px", borderRadius: "6px", display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                                                    <input
                                                        type="text"
                                                        value={filterData.search}
                                                        className={classes.EmployeesSearchInput}
                                                        onChange={handleSearch}
                                                        placeholder="Search by Name / Placement ID"
                                                    />
                                                    <Box sx={{ paddingRight: "15px !important" }}>
                                                        {
                                                            filterData.search !== "" ?
                                                                <ClearIcon sx={{ cursor: "pointer" }} onClick={() => handleSearchClear()} />
                                                                :
                                                                <img src={Search} alt="Search" />
                                                        }
                                                    </Box>
                                                </div>
                                            </Grid>

                                            <Grid item container lg={3} md={3} xs={3} display={'flex'} spacing={2} justifyContent={'end'}>
                                                <Grid item xs={6}>
                                                    <Select
                                                        disableClearable
                                                        options={years}
                                                        label={'Select Year'}
                                                        value={filterData.year}
                                                        onChange={(e) => { setLoading(true); setFilterData({ ...filterData, year: e.target.value, limit: 10 }) }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Select
                                                        disableClearable
                                                        options={monthsOptions}
                                                        label={'Select Month'}
                                                        value={filterData.month}
                                                        onChange={(e) => { setLoading(true); setFilterData({ ...filterData, month: e.target.value, limit: 10 }) }}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid item lg={1} md={1} sm={1} xs={1} pl={2}>
                                                <button title='Export' style={{ height: '40px !important' }} type="button" className={classes.borderButton1}
                                                    onClick={() => handleDownloadExport()}
                                                >
                                                    <img src={NewDownloadcloud} alt="Userplus" />
                                                </button>
                                            </Grid>
                                        </Grid>
                                        : ""
                                }
                            </Grid>
                        </Box>
                        {
                            (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "timesheet_view" && item.is_allowed == true))) ?
                                <Box style={{ padding: "10px" }}>

                                    <Box style={{ display: "flex", justifyContent: "space-between", fontSize: "14px", fontWeight: "600", alignItems: "center", borderBottom: "1px solid #F3EFEF", padding: "16px 12px" }} >
                                        <Grid container lg={12} md={12} sm={12} xs={12} textAlign='center' alignItems='center'>
                                            <Grid item lg={4} md={4} sm={4} xs={4.5} textAlign={'left'} letterSpacing={'0.5px'}>Employee Name
                                            </Grid>
                                            <Grid item lg={3} md={2} sm={2} xs={2} className={classes.headerNames}>Placement </Grid>
                                            <Grid item lg={1.5} md={1.5} sm={1} xs={1} className={classes.headerNames} >TS Cycle</Grid>
                                            <Grid item lg={1.5} md={1.5} sm={1} xs={1} className={classes.headerNames} >Total Hours</Grid>
                                            <Grid item lg={1.5} md={1.5} sm={1} xs={1} className={classes.headerNames} >Total Pay</Grid>
                                        </Grid>
                                    </Box>

                                    {
                                        loading ?
                                            [1, 2, 3, 4].map((item) => (
                                                <Stack key={item} direction="row" my={3} px={4} py={2} spacing={2} sx={{ width: "100%", boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05)", borderRadius: "8px" }}>
                                                    <Box sx={{ width: "59%", display: "flex", alignItems: "center", gap: 2, borderRight: "1px solid rgba(226, 229, 230, 1)" }}>
                                                        <Skeleton variant="circular" sx={{ width: "64px", height: "56px" }} />
                                                        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                            <Box>
                                                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: "10rem" }} />
                                                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                            </Box>
                                                            <Box sx={{ width: "20%", alignSelf: "center" }}>
                                                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: "4rem" }} />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <Box sx={{ width: "41%", display: "flex", alignItems: "center", gap: 2 }}>
                                                        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                        </Box>
                                                    </Box>
                                                </Stack>
                                            )) :
                                            <>
                                                {
                                                    placements.length > 0 ?
                                                        <Box sx={{
                                                            height: "60vh", overflowY: 'scroll', alignItems: 'start', justifyContent: 'start', paddingRight: '6px',
                                                            "@media (min-height: 850px)": {
                                                                height: '69.5vh'
                                                            }
                                                        }} ref={scrollBox} onScroll={handleScroll}>
                                                            {
                                                                placements.map((place, key) => (
                                                                    <Accordion
                                                                        key={key}
                                                                        className={classes.newCustomAccordion}
                                                                        expanded={expanded === `panel${key}`} onChange={handleChange(`panel${key}`)}>
                                                                        <AccordionSummary
                                                                            aria-controls="panel1bh-content"
                                                                            id="panel1bh-header"
                                                                            className={classes.AccordionSummary}
                                                                        >
                                                                            <Grid container lg={12} md={12} sm={12} xs={12} textAlign='center' alignItems='center'>
                                                                                <Grid item lg={4} md={4} sm={4} xs={4.5} textAlign='start'>
                                                                                    <Box display='flex' alignItems='center' gap={2}>
                                                                                        <Box sx={{ position: 'relative', display: 'flex' }}>
                                                                                            <CircularProgress variant="determinate" value={place.profile_progress} size="3.8rem" thickness={2} sx={{ backgroundColor: "#F2F2F2", color: place.profile_progress >= 76 ? 'green' : place.profile_progress <= 75 && place.profile_progress >= 51 ? 'yellow' : place.profile_progress < 50 ? "#FFBF00" : '', borderRadius: "100%", }} />
                                                                                            <Box
                                                                                                sx={{
                                                                                                    top: 0,
                                                                                                    left: 0,
                                                                                                    bottom: 0,
                                                                                                    right: 0,
                                                                                                    position: 'absolute',
                                                                                                    display: 'flex',
                                                                                                    alignItems: 'center',
                                                                                                    justifyContent: 'center',
                                                                                                }}
                                                                                            >
                                                                                                <Box sx={{ display: "flex", padding: "3px", borderRadius: "50%", backgroundColor: "#ffffff" }}>
                                                                                                    <HtmlTooltip
                                                                                                        placement="bottom"
                                                                                                        arrow
                                                                                                        title={
                                                                                                            <React.Fragment>
                                                                                                                <Box>
                                                                                                                    <Typography className={classes.profileTooltipText}>
                                                                                                                        {`Profile Completion - ${place.profile_progress}%`}
                                                                                                                    </Typography>
                                                                                                                </Box>
                                                                                                            </React.Fragment>
                                                                                                        }
                                                                                                    >
                                                                                                        <Avatar
                                                                                                            alt={place.employee_name}
                                                                                                            src={place.profile_picture_url}
                                                                                                            sx={{ width: 50, height: 50, }}
                                                                                                        />
                                                                                                    </HtmlTooltip>
                                                                                                </Box>
                                                                                            </Box>
                                                                                        </Box>
                                                                                        <Box>
                                                                                            <Text smallBoldBlack noWrap>
                                                                                                {place.employee_name === "" ? "--" : capitalizeAndAddSpace(place.employee_name).length > 16 ?
                                                                                                    <HtmlTooltip title={capitalizeAndAddSpace(place.employee_name)} placement="right" arrow>
                                                                                                        {capitalizeAndAddSpace(place.employee_name).slice(0, 16) + (capitalizeAndAddSpace(place.employee_name).length > 16 ? "..." : "")}
                                                                                                    </HtmlTooltip>
                                                                                                    :
                                                                                                    `${place.employee_name}`
                                                                                                }
                                                                                                <HtmlTooltip title={place.employee_e_verified !== 1 ? "Profile Has not E-Verified" : "Profile Has Been E-Verified"} placement="right" arrow>
                                                                                                    <img src={place.employee_e_verified !== 1 ? disableVerified : Component87} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                                                                                </HtmlTooltip></Text>
                                                                                            <Text mediumLabel noWrap style={{ margin: '4px 0' }}>  {place.employee_reference_id ? place.employee_reference_id : "--"}</Text>
                                                                                        </Box>
                                                                                    </Box>

                                                                                </Grid>
                                                                                <Grid item lg={3} md={2} sm={2} xs={2} textAlign={"start"}>
                                                                                    <Text smallBoldBlack noWrap >
                                                                                        {place.client_name === "" ? "--" : capitalizeAndAddSpace(place.client_name).length > 16 ?
                                                                                            <HtmlTooltip title={capitalizeAndAddSpace(place.client_name)} placement="right" arrow>
                                                                                                {capitalizeAndAddSpace(place.placement_reference_id) + ` ( ` + capitalizeAndAddSpace(place.client_name).slice(0, 16) + (capitalizeAndAddSpace(place.client_name).length > 16 ? "..." : "") + ` )`}
                                                                                            </HtmlTooltip>
                                                                                            :
                                                                                            `${capitalizeAndAddSpace(place.placement_reference_id)} ( ${place.client_name} )`
                                                                                        }
                                                                                    </Text>
                                                                                </Grid>
                                                                                <Grid item lg={1.5} md={1} sm={1} xs={1} textAlign={"start"}>
                                                                                    <Text smallBoldBlack noWrap >{place.timesheet_cycle ? getValueById(place.timesheet_cycle) : "--"}</Text>
                                                                                </Grid>
                                                                                <Grid item lg={1.5} md={1} sm={1} xs={1} textAlign={"start"}>
                                                                                    <Text smallBoldBlack noWrap >{place.total_hours ? place.total_hours : "--"}</Text>
                                                                                </Grid>
                                                                                <Grid item lg={1.5} md={1} sm={1} xs={1} textAlign={"start"}>
                                                                                    <Text smallBoldBlack noWrap >{LocalStorage.getCurrencySymbol()}{place.total_amount ? place.total_amount : "--"}</Text>
                                                                                </Grid>
                                                                                <Grid item lg={.5} md={1.5} sm={1} xs={1} className={classes.headerNames} >
                                                                                    <Box p={1}>
                                                                                        {
                                                                                            expanded === `panel${key}` ?
                                                                                                <KeyboardArrowUpIcon style={{ cursor: 'pointer' }} onChange={handleChange(`panel${key}`)} />
                                                                                                :
                                                                                                <KeyboardArrowDownIcon style={{ cursor: 'pointer' }} onChange={handleChange(`panel${key}`)} />
                                                                                        }
                                                                                    </Box>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </AccordionSummary>
                                                                        <AccordionDetails>
                                                                            <Box className={classes.AccordionDetails} pt={1}>
                                                                                <Box width={'100%'}>
                                                                                    <Typography className={classes.text1}>
                                                                                        Week - 1
                                                                                    </Typography>
                                                                                    <Text blue14 my={1} sx={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => { navigate('/timesheet/view', { state: { id: place.placement_id, start_date: place.start_date, end_date: place.end_date, weekly: true, weekStart: 0, weekEnd: 7, hours: place.weekly[0].hours } }) }} >
                                                                                        {place.weekly.length > 0 ? place.weekly[0].hours : "--"}
                                                                                    </Text>
                                                                                </Box>
                                                                                <Box width={'100%'}>
                                                                                    <Typography className={classes.text1}>
                                                                                        Week - 2
                                                                                    </Typography>
                                                                                    <Text blue14 my={1} sx={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => { navigate('/timesheet/view', { state: { id: place.placement_id, start_date: place.start_date, end_date: place.end_date, weekly: true, weekStart: 8, weekEnd: 15, hours: place.weekly[1].hours } }) }} >
                                                                                        {place.weekly.length > 0 ? place.weekly[1].hours : "--"}
                                                                                    </Text>
                                                                                </Box>
                                                                                <Box width={'100%'}>
                                                                                    <Typography className={classes.text1}>
                                                                                        Week - 3
                                                                                    </Typography>
                                                                                    <Text blue14 my={1} sx={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => { navigate('/timesheet/view', { state: { id: place.placement_id, start_date: place.start_date, end_date: place.end_date, weekly: true, weekStart: 16, weekEnd: 23, hours: place.weekly[2].hours } }) }} >
                                                                                        {place.weekly.length > 0 ? place.weekly[2].hours : "--"}
                                                                                    </Text>
                                                                                </Box>
                                                                                <Box width={'100%'}>
                                                                                    <Typography className={classes.text1}>
                                                                                        Week - 4
                                                                                    </Typography>
                                                                                    <Text blue14 my={1} sx={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => { navigate('/timesheet/view', { state: { id: place.placement_id, start_date: place.start_date, end_date: place.end_date, weekly: true, weekStart: 24, weekEnd: 31, hours: place.weekly[3].hours } }) }} >
                                                                                        {place.weekly.length > 0 ? place.weekly[3].hours : "--"}
                                                                                    </Text>
                                                                                </Box>
                                                                                <Box width={'100%'}>
                                                                                    <Typography className={classes.text1}>
                                                                                        Week - 5
                                                                                    </Typography>
                                                                                    <Text blue14 my={1} sx={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => { navigate('/timesheet/view', { state: { id: place.placement_id, start_date: place.start_date, end_date: place.end_date, weekly: true, weekStart: 32, weekEnd: 39, hours: place.weekly[4].hours } }) }} >
                                                                                        {place.weekly.length > 0 ? place.weekly[4].hours : "--"}
                                                                                    </Text>
                                                                                </Box>
                                                                                {
                                                                                    place.weekly.length > 5
                                                                                        ?
                                                                                        <Box width={'100%'}>
                                                                                            <Typography className={classes.text1}>
                                                                                                Week - 6
                                                                                            </Typography>
                                                                                            <Text blue14 my={1} sx={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => { navigate('/timesheet/view', { state: { id: place.placement_id, start_date: place.start_date, end_date: place.end_date, weekly: true, weekStart: 40, weekEnd: 47, hours: place.weekly[4].hours } }) }} >
                                                                                                {place.weekly.length > 0 ? place.weekly[5].hours : "--"}
                                                                                            </Text>
                                                                                        </Box> : ''
                                                                                }

                                                                                <Box width={'100%'} >
                                                                                    <Text blue14 my={1} sx={{ cursor: 'pointer' }} onClick={() => { navigate('/timesheet/view', { state: { id: place.placement_id, start_date: place.start_date, end_date: place.end_date, weekly: false, weekStart: 48, weekEnd: 55, hours: place.weekly[4].hours } }) }} >
                                                                                        Monthly View
                                                                                    </Text>

                                                                                </Box>
                                                                            </Box>
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                ))
                                                            }
                                                        </Box>
                                                        :
                                                        placements.length == 0 ?
                                                            <Box>
                                                                {NoDataFound("No Records Found", 'There are no records here to show.')}
                                                            </Box>
                                                            : ''
                                                }
                                            </>
                                    }
                                </Box> :
                                <>
                                    {NoPermission()}
                                </>
                        }
                    </Box>
            }
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBacKLoader}
            // onClick={handleClose}
            >
                <img src={Loader} alt="Loader" width={'100px'} height={'100px'} />
            </Backdrop>

            <BootstrapDialog
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth={true}
                maxWidth={"md"}
            >

                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                        boxShadow: 'none !important',
                        "&:hover": {
                            boxShadow: 'none !important',
                            background: 'none !important'
                        }
                    }}
                >
                    <img src={crossIcon} alt="cross" />
                    {/* <CloseIcon sx={{ color: "rgba(38, 38, 38, 1)" }} /> */}
                </IconButton>
                <DialogContent sx={{ margin: "50px", }}>
                    <Grid container spacing={0}>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box style={{ height: "400px", width: "400px", display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <Box sx={{ textAlign: "center" }}>
                                    <Box sx={{ margin: "12px 0px" }}>
                                        <img src={InviteViaLink} alt='invite-via-link' style={{ height: "200px" }} />
                                    </Box>
                                    <Box sx={{ margin: "8px 0px" }}>
                                        {/* <Typography sx={{ fontSize: "12px", fontFamily: "Quicksand", fontWeight: "400", color: "rgba(38, 38, 38, 1)", textAlign: "center", wordWrap: "break-word", }}>
                                            Send the employee an invite link to add them to <br /> the organization.
                                        </Typography> */}
                                        <Text mediumLabel>Send the employee an invite link to add them to <br /> the organization.</Text>
                                    </Box>
                                    <Box sx={{ margin: "4px 0px" }}>
                                        <button
                                            onClick={() => navigate("/employees/add")}
                                            type="button"
                                            className={classes.inviteLinkBtn}
                                        // style={{
                                        //     margin: "12px 0px", all: "unset", cursor: "pointer", textAlign: "center", fontSize: "15px", fontFamily: "Quicksand CY, sans-serif", fontWeight: "400", width: "150px", height: "40px", border: "1.5px solid rgba(12, 117, 235, 1)", borderRadius: "6px", color: "rgba(12, 117, 235, 1)",
                                        //     "&::hover": {
                                        //         backgroundColor:'rgba(12, 117, 235, 1)',color:'#FFFFFF'
                                        // } }}
                                        >
                                            Invite via link
                                        </button>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box style={{ height: "400px", width: "400px", display: 'flex', justifyContent: "center", alignItems: "center", borderLeft: "1.5px solid rgba(199, 204, 211, 1)" }}>
                                <Box sx={{ textAlign: "center" }}>
                                    <Box sx={{ margin: "16px 0px" }}>
                                        <img src={Onboart} alt='onboart' style={{ height: "200px" }} />
                                    </Box>
                                    <Box sx={{ margin: "6px 0px" }}>
                                        {/* <Typography sx={{ fontSize: "12px", fontFamily: "Quicksand CY, sans-serif", fontWeight: "400", color: "rgba(38, 38, 38, 1)", textAlign: "center", wordWrap: "break-word", }}>
                                        Onboard an employee manually, to add them to <br />the organization.
                                        </Typography> */}
                                        <Text mediumLabel> Onboard an employee manually, to add them to <br />the organization.</Text>
                                    </Box>
                                    <Box sx={{ margin: "20px 0px", }}>
                                        <button
                                            type="button"
                                            className={classes.onboardBtn}
                                            onClick={() => { navigate('/employees/onboard'); LocalStorage.removeEmployeeId(); LocalStorage.removeVisaID(); LocalStorage.removeFullName() }}
                                        >
                                            Onboard
                                        </button>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
            </BootstrapDialog>

            <SwipeableDrawer
                anchor={'right'}
                open={drawer}
                onClose={() => { setDrawer(null) }}
                transitionDuration={300}
                sx={{
                    ".MuiDrawer-paper ": {
                        borderRadius: '8px 0px 0px 8px !important',
                    },
                    "& .MuiBackdrop-root.MuiModal-backdrop": {
                        backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                    }
                }
                }
            >
                {
                    drawer === "filter" ?
                        <Box width={'660px'} height={'100vh'} >
                            <Box height={'10vh'} borderBottom={'1px solid #EAECF0'} display={'flex'} alignItems={'center'} pl={4} >
                                <Text headerBlack>Filters</Text>
                            </Box>

                            <Box display={'flex'} height={'7vh'} borderBottom={'1px solid #EAECF0'} alignItems={'center'} justifyContent={'space-between'} pr={'26px'} gap={1}>
                                <Box display={'flex'} height={'60px'} alignItems={'center'} width={'90%'} pl={1} pr={'26px'} gap={1} sx={{ overflow: "auto", "&::-webkit-scrollbar": { display: "none !important" } }}>

                                    {
                                        filterState.start_date.length > 1 &&
                                        <Chip
                                            sx={{ border: "1px solid #E5E8E9" }}
                                            key={1}
                                            icon={<CircleIcon sx={{ color: "#EA5151 !important" }} className={classes.circularIcon} />}
                                            label={<Text smallBlack>{filterState.start_date[0]} - {filterState.start_date[1]}</Text>}
                                            variant="outlined"
                                            onDelete={() => handleDeleteChip('', "start_date")}
                                            deleteIcon={<CloseIcon />} />
                                    }

                                    {
                                        filterState.end_date.length > 1 &&
                                        <Chip
                                            sx={{ border: "1px solid #E5E8E9" }}
                                            key={1}
                                            icon={<CircleIcon sx={{ color: "#FF7A00 !important" }} className={classes.circularIcon} />}
                                            label={<Text smallBlack>{filterState.end_date[0] + filterState.end_date[1]}</Text>}
                                            variant="outlined"
                                            onDelete={() => handleDeleteChip('', "end_date")}
                                            deleteIcon={<CloseIcon />} />
                                    }
                                    {
                                        clientDropdown.map((item, key) => (
                                            filterState.clients.includes(item.id) &&
                                            <Chip
                                                sx={{ border: "1px solid #E5E8E9" }}
                                                key={key}
                                                icon={<CircleIcon sx={{ color: "#9DE22D !important" }} className={classes.circularIcon} />}
                                                label={<Text smallBlack>{item.value}</Text>}
                                                variant="outlined"
                                                onDelete={() => handleDeleteChip(item.id, "clients")}
                                                deleteIcon={<CloseIcon />} />
                                        ))
                                    }
                                    {
                                        recruiters.map((item, key) => (
                                            filterState.recruiter.includes(item.id) &&
                                            <Chip
                                                sx={{ border: "1px solid #E5E8E9" }}
                                                key={key}
                                                icon={<CircleIcon sx={{ color: "#2DC890 !important" }} className={classes.circularIcon} />}
                                                label={<Text smallBlack>{item.display_name ? item.display_name : "--"}</Text>}
                                                variant="outlined"
                                                onDelete={() => handleDeleteChip(item.id, "recruiter")}
                                                deleteIcon={<CloseIcon />}
                                            />
                                        ))
                                    }
                                    {
                                        cycleDropdown.map((item, key) => (
                                            filterState.timesheet_cycle.includes(item.id) &&
                                            <Chip key={key}
                                                sx={{ border: "1px solid #E5E8E9" }}
                                                icon={<CircleIcon sx={{ color: "#7643A3 !important" }} className={classes.circularIcon} />}
                                                label={<Text smallBlack>{item.value}</Text>}
                                                variant="outlined"
                                                onDelete={() => handleDeleteChip(item.id, "timesheet_cycle")}
                                                deleteIcon={<CloseIcon />}
                                            />
                                        ))
                                    }
                                </Box>
                                {
                                    (filterState.start_date.length > 0 || filterState.end_date.length > 0 || filterState.recruiter.length > 0 || filterState.clients.length > 0 || filterState.timesheet_cycle.length > 0) ?
                                        <Button startIcon={<CloseIcon />} onClick={() => clearAllFilter()} clearAll >Clear All</Button>
                                        :
                                        ''
                                }
                            </Box>

                            <Box display={'flex'} width={'100%'} border={'1px solid #EAECF0'} height={'73vh'} >
                                <Box display={'flex'} flexDirection={'column'} height={'100%'} width={'43%'} borderRight={'1px solid #EAECF0'} pt={0} >
                                    <List component="nav" aria-label="secondary mailbox folder">
                                        <ListItem className={selectedFilter == 1 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(1)} secondaryAction={filterState.start_date.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#EA5151" }}><Text mediumWhite400>{filterState.start_date.length > 0 ? "1" : ""}</Text></Box> : ""}>
                                            <ListItemText primary={selectedFilter == 1 ? <Text smallBlue1 ml={3}>Project Start Date</Text> : <Text mediumBlack ml={3}>Project Start Date</Text>} />
                                        </ListItem>
                                        <Divider />
                                        <ListItem className={selectedFilter == 2 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(2)} secondaryAction={filterState.end_date.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#FF7A00" }}><Text mediumWhite400>{filterState.end_date.length > 0 ? "1" : ""}</Text></Box> : ""}>
                                            <ListItemText primary={selectedFilter == 2 ? <Text ml={3} smallBlue1>Project End Date</Text> : <Text ml={3} mediumBlack>Project End Date</Text>} />
                                        </ListItem>
                                        <Divider className={classes.divider} />
                                        <ListItem className={selectedFilter == 3 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(3)} secondaryAction={filterState.clients.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#9DE22D" }}><Text mediumWhite400>{filterState.clients.length}</Text></Box> : ""}>
                                            <ListItemText primary={selectedFilter == 3 ? <Text ml={3} smallBlue1>Clients</Text> : <Text ml={3} mediumBlack>Clients</Text>} />
                                        </ListItem>
                                        <Divider />
                                        <ListItem className={selectedFilter == 4 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(4)} secondaryAction={filterState.recruiter.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#2DC890" }}><Text mediumWhite400>{filterState.recruiter.length}</Text></Box> : ""}>
                                            <ListItemText primary={selectedFilter == 4 ? <Text ml={3} smallBlue1>Recruiter</Text> : <Text ml={3} mediumBlack>Recruiter</Text>} />
                                        </ListItem>
                                        <Divider />
                                        <ListItem className={selectedFilter == 5 ? classes.listItemActive : classes.listItem} onClick={() => handleListItemClick(5)} secondaryAction={filterState.timesheet_cycle.length > 0 ? <Box className={classes.startDateBox} sx={{ background: "#7643A3" }}><Text mediumWhite400>{filterState.timesheet_cycle.length}</Text></Box> : ""}>
                                            <ListItemText primary={selectedFilter == 5 ? <Text ml={3} smallBlue1>Timesheet Cycle</Text> : <Text ml={3} mediumBlack>Timesheet Cycle</Text>} />
                                        </ListItem>
                                        <Divider />
                                    </List>

                                </Box>
                                <Box display={'flex'} sx={{ overflow: "auto" }} flexDirection={'column'} height={'70vh'} width={'55%'} pl={'25px !important'} pt={2}>

                                    {
                                        selectedFilter == 1 ?
                                            <Grid container alignItems={"center"}>
                                                <Grid item lg={5.5} md={5} sm={5}>
                                                    <Date
                                                        labelText={`From`}
                                                        name='start_range_one'
                                                        value={datesState.start_range_one}
                                                        onChange={(value) => dateChange(value, 'start_range_one')}
                                                        height='56px'
                                                    />
                                                </Grid>
                                                <Grid item lg={1} md={1} sm={1} textAlign={"center"}>
                                                    &#8594;
                                                </Grid>
                                                <Grid item lg={5.5} md={5} sm={5}>
                                                    <Date
                                                        labelText={`To`}
                                                        name='end_range_one'
                                                        value={datesState.end_range_one}
                                                        onChange={(value) => dateChange(value, 'end_range_one')}
                                                        height='56px'
                                                        minDate={moment(datesState.start_range_one).format(dateFormat())}
                                                        disabled={datesState.start_range_one == ""}
                                                        error={datesError.end_range_one}
                                                    />
                                                    <Box sx={{ height: "0px" }}>{datesError.end_range_one ? <Text red>{datesError.end_range_one ? datesError.end_range_one : ''}</Text> : ''}</Box>
                                                </Grid>
                                            </Grid> :
                                            selectedFilter == 2 ?
                                                <Grid container alignItems={"center"}>
                                                    <Grid item lg={5.5} md={5} sm={5}>
                                                        <Date
                                                            labelText={`From`}
                                                            name='start_range_two'
                                                            value={datesState.start_range_two}
                                                            onChange={(value) => dateChange(value, 'start_range_two')}
                                                            height='56px'
                                                        />
                                                    </Grid>
                                                    <Grid item lg={1} md={1} sm={1} textAlign={"center"}>
                                                        &#8594;
                                                    </Grid>
                                                    <Grid item lg={5.5} md={5} sm={5}>
                                                        <Date
                                                            labelText={`To`}
                                                            name='end_range_two'
                                                            value={datesState.end_range_two}
                                                            onChange={(value) => dateChange(value, 'end_range_two')}
                                                            height='56px'
                                                            minDate={moment(datesState.start_range_two).format(dateFormat())}
                                                            disabled={datesState.start_range_two == ""}
                                                            error={datesError.end_range_two}
                                                        />
                                                        <Box sx={{ height: "0px" }}>{datesError.end_range_two ? <Text red>{datesError.end_range_two ? datesError.end_range_two : ''}</Text> : ''}</Box>
                                                    </Grid>
                                                </Grid> :
                                                selectedFilter == 3 ?
                                                    <>
                                                        {
                                                            clientDropdown.length > 0 && clientDropdown.map((item, index) => (
                                                                <Grid container alignItems={"center"} pb={2}>
                                                                    <FormControlLabel
                                                                        key={index}
                                                                        control={
                                                                            <Checkbox
                                                                                size="small"
                                                                                name={"clients"}
                                                                                value={item.id}
                                                                                onChange={(e) => { handleChangeCheckBox(e) }}
                                                                                // onClick = {handleCheckClick()}
                                                                                icon={<CheckBorderIcon />}
                                                                                checkedIcon={<CheckedIcon />}
                                                                                checked={filterState.clients.includes(item.id)} />}
                                                                        label={<Text mediumBlack nowrap>{item.value === "" ? "--" : item.value && item.value.length > 16 ?
                                                                            <BlackToolTip title={item.value} placement="right" arrow>
                                                                                {item.value.slice(0, 16) + (item.value.length > 16 ? "..." : "")}
                                                                            </BlackToolTip>
                                                                            :
                                                                            item.value}</Text>}
                                                                    />
                                                                </Grid>
                                                            ))
                                                        }
                                                    </>
                                                    : selectedFilter == 4 ?
                                                        <>
                                                            {
                                                                recruiters.length > 0 && recruiters.map((item, index) => (
                                                                    <Grid container alignItems={"center"} pb={2}>
                                                                        <Grid container alignItems={"center"} item lg={10} md={10} sm={10}>
                                                                            <FormControlLabel
                                                                                key={index}
                                                                                control={
                                                                                    <Checkbox
                                                                                        size="small"
                                                                                        name={"recruiter"}
                                                                                        value={item.id}
                                                                                        onChange={handleChangeRecruiters}
                                                                                        icon={<CheckBorderIcon />}
                                                                                        checkedIcon={<CheckedIcon />}
                                                                                        checked={filterState.recruiter.includes(item.id)} />}
                                                                                label={<Text mediumBlack nowrap>{
                                                                                    item.display_name === "" ? "--" : item.display_name && item.display_name.length > 16 ?
                                                                                        <BlackToolTip title={item.display_name} placement="right" arrow>
                                                                                            {item.display_name.slice(0, 16) + (item.display_name.length > 16 ? "..." : "")}
                                                                                        </BlackToolTip>
                                                                                        :
                                                                                        item.display_name
                                                                                }</Text>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid container alignItems={"center"} item lg={2} md={2} sm={2}>
                                                                            <Text smallBlue1>{item.count}</Text>
                                                                        </Grid>
                                                                    </Grid>
                                                                ))
                                                            }
                                                        </>
                                                        : selectedFilter == 5 ?
                                                            <>
                                                                {
                                                                    cycleDropdown.length > 0 && cycleDropdown.map((item, index) => (
                                                                        <Grid container alignItems={"center"} pb={2}>
                                                                            {
                                                                                item.id != 5 &&
                                                                                <>
                                                                                    <FormControlLabel
                                                                                        key={index}
                                                                                        control={
                                                                                            <Checkbox
                                                                                                size="small"
                                                                                                name={"timesheet_cycle"}
                                                                                                value={item.id}
                                                                                                onChange={(e) => handleChangeCheckBox(e)}
                                                                                                icon={<CheckBorderIcon />}
                                                                                                checkedIcon={<CheckedIcon />}
                                                                                                checked={filterState.timesheet_cycle.includes(item.id)} />}
                                                                                        label={<Text mediumBlack >{item.value}</Text>}
                                                                                    />
                                                                                </>
                                                                            }

                                                                        </Grid>
                                                                    ))
                                                                }
                                                            </> :
                                                            <Box sx={{ height: "60vh", width: "100%", display: "flex", alignItems: "center" }}>
                                                                <Grid container>
                                                                    <Grid item lg={12} md={12} sm={12} textAlign={"center"}>
                                                                        <img src={nofilterplacement} alt="nofilterplacement" style={{ paddingTop: "15px" }} />
                                                                    </Grid>
                                                                    <Grid item lg={12} md={12} sm={12} textAlign={"center"} >
                                                                        <Text sx={{ font: "18px Quicksand !important", fontWeight: `${700} !important`, color: "#262626 !important" }}>Gear Up!</Text>
                                                                    </Grid>
                                                                    <Grid item lg={12} md={12} sm={12} textAlign={"center"}>
                                                                        <Text sx={{ font: "14px Quicksand !important", fontWeight: `${400} !important`, color: "#737373 !important" }}>Select filters to scale our data peaks</Text>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                        // nofilterplacement
                                    }
                                </Box>
                            </Box>

                            <Box display={'flex'} alignItems={'center'} justifyContent={'end'} gap={1} height={'73px'} pr={'26px'}>
                                <Button cancelSmall onClick={() => cancelFilter()}>Cancel</Button>
                                <Button saveSmall onClick={() => handleApplyFilters()} >Apply Filters</Button>
                            </Box>
                        </Box >
                        : null
                }

            </SwipeableDrawer>
        </Box >
    );
}