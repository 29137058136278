import React, { useEffect, useState } from 'react'
import { NoPermission, addErrorMsg, addLoader, addSuccessMsg, capitalizeAndAddSpace, dateFormat, removeLoader } from '../../../../utils/utils';
import EmployeesApi from '../../../../apis/admin/employees/EmployeesApi';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PlacementViewStyles from './PlacementViewStyles';
import { Box, Typography, Breadcrumbs, Stack, Grid, Divider, ListItemButton, Menu, MenuItem, Skeleton, Slide, DialogContent, Dialog, SwipeableDrawer, Hidden } from '@mui/material';
import Text from '../../../../components/customText/Text';
import CircularProgress from '../../../../components/progressbar/CircularProgress';
import Button from '../../../../components/customButton/Button';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Verify from '../../../../assets/svg/verify_icon.svg';
import placementPop from '../../../../assets/svg/placementPop.svg';
import placementEnd from '../../../../assets/svg/placementEnd.svg';
import { blue } from '../../../../theme';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import ClientDetails from './ClientDetails';
import PlacementDetails from './PlacementDetails';
import PayRateConfiguration from './PayRateConfiguration';
import TimesheetConfiguration from './TimesheetConfiguration';
import Date from '../../../../components/datePicker/Date';
import moment from 'moment';
import CommonApi from '../../../../apis/CommonApi';
import LocalStorage from '../../../../utils/LocalStorage';
import PlacementApi from '../../../../apis/admin/placements/PlacementApi';
import { validate_emptyField } from '../../../../components/Validation';
import LoadingButton from '../../../../components/customButton/LoadingButton';
import ActivityLog from '../../employees/userprofile/ActivityLog';
import FileSaver from 'file-saver';
import offBoardSave from "../../../../assets/svg/offBoardSave.svg"
import Pending from '../../../../assets/svg/Vector (1).svg';
import Rejected from '../../../../assets/svg/Rejected.svg';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#404040",
        padding: "6px 14px",
        minWidth: 100,
        border: "1px solid #404040"
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#404040",
        "&::before": {
            backgroundColor: "#404040",
            border: "1px solid #404040"
        }
    },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper ": {
        borderRadius: "16px",
        width: "500px"
    }
}));// eslint-disable-next-line
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function PlacementView(props) {

    const { setformEdit, formEdit } = props;

    const location = useLocation();
    const param = new URLSearchParams(window.location.search);
    const id = param.get('id');
    const employee_id = param.get('employee_id')
    const placementId = id ? id : LocalStorage.getRedirectedModule() == 'placement' ? LocalStorage.getNotificationId() : location.state.placement_id
    const empId = employee_id ? employee_id : LocalStorage.getRedirectedModule() == 'placement' ? LocalStorage.getNotificationEmpId() : location.state.id
    const navigate = useNavigate();
    const classes = PlacementViewStyles();
    const [loading, setLoading] = useState(false);
    const [employee, setEmployee] = useState({});
    const dataArr = ["Client Details", "Placement Details", "Pay Rate Configuration", "Timesheet Configuration"];
    const [current, setCurrent] = useState("Client Details");
    const [EmployeeProfile, setEmployeeProfile] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);// eslint-disable-next-line
    const open = Boolean(anchorEl);
    const [endPlacementOpen, setEndPlacementOpen] = useState(false);
    const [endPlacementSubmitOpen, setEndPlacementSubmitOpen] = useState(false);
    const [endDate, setEndDate] = useState("");
    const [lastWorkingDay, setLastWorkingDay] = useState('')
    const [error, setError] = useState({});
    const [saveLoader, setSaveLoader] = useState(false);
    const [activityTotal, setActivityTotal] = useState()
    const [activityData, setActivityData] = useState([]);
    const [activityLoad, setActivityLoad] = useState(false);
    const [activityFilter, setActivityFilter] = useState({
        limit: 100,
        page: 1,
        search: "",
        request_id: LocalStorage.uid(),
        id: placementId ? placementId : ""
    })
    const [activityAnchorEl, setActivityAnchorEl] = useState(null);
    const activityOpen = Boolean(activityAnchorEl);
    const [navigateOpen, setNavigateOpen] = useState(false);
    // eslint-disable-next-line
    const [status, setStatus] = useState(null);
    const [statusPlacement, setStatusPlacement] = useState(null);
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';

    useEffect(() => {
        if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "placement_view" && item.is_allowed == true))) {
            getEmployeeApi();
            getPlacementData(placementId);
        }
        // eslint-disable-next-line
    }, []);


    const getEmployeeApi = () => {
        setLoading(true);
        if (empId !== '') {
            EmployeesApi.getEmployee(empId).then((response) => {
                setTimeout(() => {
                    if (response.data.statusCode == 1003) {
                        setLoading(false);
                        setEmployee({ ...response.data.data });
                        setEmployeeProfile(response.data.data.profile_picture_url);
                    } else {
                        setLoading(false);
                        addErrorMsg(response.data.message);
                    }
                }, 400)
            });
        }
    }

    const getPlacementData = (id) => {
        PlacementApi.getPlacementIndex("placement-details", id).then((res) => {
            if (res.data.statusCode === 1003) {
                setEndDate(res.data.data.employee_details.end_date);
                setStatus(res.data.data.employee_details.status);
                setStatusPlacement(res.data.data.employee_details.placement_status)
            }
        })
    }

    const uploadProfile = (e) => {
        if (e.target.files[0].type.split('/').some(r => ['png', 'jpg', 'jpeg'].includes(r))) {
            const formData = new FormData();
            formData.append("files", e.target.files[0]);
            formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
            setLoading(true);
            CommonApi.documentUpload("employee-profile", formData, LocalStorage.getAccessToken())
                .then((response) => {
                    if (response.data.statusCode == 1003) {
                        ProfileUpdateApi(response.data.data.id)
                    } else {
                        setLoading(false);
                        addErrorMsg(response.data.message);
                    }
                });
        } else {
            addErrorMsg("Upload Valid File(png,jpg,jpeg).");
        }
    }

    const ProfileUpdateApi = (args) => {
        const data = {
            request_id: LocalStorage.uid(),
            id: empId,
            documents: [
                {
                    new_document_id: args
                }
            ]
        }
        EmployeesApi.profileUpload(empId, data).then((res) => {
            if (res.data.statusCode == 1003) {
                setLoading(false);
                addSuccessMsg('Profile Uploaded Successfully');
                getEmployeeApi();
            } else {
                setLoading(false);
                addErrorMsg(res.data.message);
            }
        })
    }

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        if (anchorEl) {
            handleClose()
        }
    };

    const dateChange = (e) => {
        if (e === null) {
            var date = "";
        } else {// eslint-disable-next-line
            var date = e.$d;
        }
        setEndDate(moment(date).format(dateFormat()));
        let event = { target: { name: 'endDate', value: date } }
        handleValidate(event);
    }

    const handleValidate = (e) => {
        let input = e.target;
        switch (input.name || input.tagName) {
            case "endDate":
                error.endDate = validate_emptyField(input.value);
                break;
            default:
                break;
        }
        setError({ ...error });
    }

    const handleEndPlacementSubmit = () => {
        if (endDate == "" || endDate == "Invalid date") {
            error['endDate'] = "This field is required";
            setError({ ...error });
            return false;
        } else {
            if (lastWorkingDay !== '' && moment(endDate, dateFormat()).diff(moment(lastWorkingDay, dateFormat()), 'days') > 0) {
                error['endDate'] = "Please enter placement date prior to last working date";
                setError({ ...error });
                return false;
            } else {
                let actualDOB = moment(endDate, dateFormat()).format('YYYY-MM-DD');
                if (moment(actualDOB).isAfter(moment())) {
                    var data = {
                        request_id: LocalStorage.uid(),
                        placement_id: placementId,
                        end_date: endDate
                    }
                    setSaveLoader(true);
                    PlacementApi.placementEnd(data).then((response) => {
                        setSaveLoader(false);
                        if (response.data.statusCode === 1003) {
                            setEndPlacementOpen(false);
                            setEndPlacementSubmitOpen(true);
                        } else {
                            addErrorMsg(response.data.message);
                        }
                    })
                } else {
                    addErrorMsg("End date must be future date ");
                }
            }
        }
    }

    const handleActivityClick = (event) => {
        setActivityAnchorEl(event.currentTarget);
        getActivity();
    };

    const getActivity = () => {
        let data = {
            limit: activityFilter.limit,
            page: activityFilter.page,
            search: activityFilter.search,
            request_id: LocalStorage.uid(),
            id: activityFilter.id
        }
        setActivityLoad(true);
        PlacementApi.placementActivityLogs(data).then((response) => {
            setActivityLoad(false);
            if (response.data.statusCode === 1003) {
                setActivityData(response.data.data);
                setActivityTotal(response.data.pagination.total)
            } else {
                addErrorMsg(response.data.message);
            }
        })
    }

    const handleActivityClose = () => {
        setActivityAnchorEl(null);
    };

    const downloadActivity = () => {
        PlacementApi.downloadPlacementActivity(placementId).then((response) => {
            if (response.data.statusCode == 1003) {
                let url = response.data.data[0].file_path
                let fileName = url.split('/exports/')
                FileSaver.saveAs(response.data.data[0].file_path, fileName[1]);
            }
            else {
                addErrorMsg(response.data.message)
            }
        });
    }

    const handleNavigate = () => {
        if (formEdit) {
            setNavigateOpen(true);
        } else {
            navigate("/placements")
        }
    }


    useEffect(() => {
        if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "placement_view" && item.is_allowed == true))) {
            getActivity()
        }
        // eslint-disable-next-line
    }, [activityFilter])

    const [tab, setTab] = useState('');
    const handleTabChange = (item) => {
        if (formEdit) {
            setTab(item);
            setNavigateOpen(true);
        } else {
            setTab('');
            setCurrent(item)
        }
    }

    const handleFinalCancel = () => {
        if (formEdit && tab !== "") {
            setCurrent(tab); setformEdit(false); setNavigateOpen(false);
        } else {
            setTab('');
            navigate('/placements'); setformEdit(false); setNavigateOpen(false);
        }
    }

    const [startDate, setStartDate] = useState("");
    const [updateDate, setUpdateDate] = useState(false);

    const handleOpenEndDate = () => {
        addLoader(true);
        PlacementApi.getPlacementIndex("placement-details", placementId).then((res) => {
            if (res.data.statusCode === 1003) {
                removeLoader();
                setError({});
                setEndDate(res.data.data.employee_details.end_date);
                setLastWorkingDay(res.data.data.employee_details.employee_last_working_day)
                setStartDate(res.data.data.employee_details.start_date);
                setStatus(res.data.data.employee_details.status);
                setStatusPlacement(res.data.data.employee_details.placement_status)
                if (res.data.data.employee_details.end_date !== "") {
                    setUpdateDate(true);
                } else {
                    setUpdateDate(false);
                }
                setEndPlacementOpen(true);
            }
        })
    }

    return (
        <Box className={classes.mainContainer} px={5} py={1}>
            <Box mx={2} pl={3}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography onClick={() => { handleNavigate() }} className={classes.breadcrumbsLink}>All Placements</Typography>
                    <Text sx={{ color: '#092333 !important', fontWeight: '700 !important' }} mediumBlack14>Placement Details</Text>
                </Breadcrumbs>
            </Box>
            <Hidden lgDown>
                {
                    (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "placement_view" && item.is_allowed == true))) ?
                        <Box my={2} mx={2}>
                            <Grid container columnSpacing={4}>
                                <Grid item container lg={3} md={3} sm={4} xs={12}>
                                    <Box style={{ width: "100%", height: "78.5vh", borderRadius: "8px" }}>
                                        <Box px={3} py={1} pt={4}>
                                            <Box my={1} mt={2} style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                                                {
                                                    loading ?
                                                        <Skeleton variant="circular" width={50} height={50} /> :
                                                        <CircularProgress
                                                            percentage={employee && employee.profile_progress}
                                                            color={employee && (employee.profile_progress >= 76 ? 'green' : employee.profile_progress <= 75 && employee.profile_progress >= 51 ? 'yellow' : employee.profile_progress < 50 ? "#FFBF00" : '')}
                                                            src={EmployeeProfile}
                                                            name={EmployeeProfile == undefined || EmployeeProfile == null || EmployeeProfile == '' ? employee && employee.basic_details && employee.full_name[0] : ''}
                                                            imgWidth='80px !important'
                                                            imgHeight='80px !important'
                                                            avatarWidth='80px !important'
                                                            avatarHeight='80px !important'
                                                            value={employee && employee.profile_progress}
                                                            size={88}
                                                            edit={true}
                                                            handleEdit={(e) => uploadProfile(e)}
                                                            tooltip
                                                            hideEdit={employee && (employee.status == "Active" && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_edit" && item.is_allowed == true))) ? true : false)}
                                                            tooltipContent={
                                                                employee &&
                                                                <Box p={'3px 0px'} width='100%'>
                                                                    <Text mediumBoldWhite400>{`Profile Completion - ${employee && employee.profile_progress}%`}</Text>
                                                                    {
                                                                        employee.profile_progress == 100 ? '' :
                                                                            <Button addButtonTooltip sx={{ marginTop: '10px' }} component={Link}
                                                                                onClick={() => {
                                                                                    LocalStorage.removeRedirectedModule();
                                                                                    LocalStorage.removeSubModule();
                                                                                    LocalStorage.removeNotificationEmpId();
                                                                                }}
                                                                                to={`/employees/user-profile/${employee.full_name === "" ? "" : employee.full_name && employee.full_name.trim().split(/ +/).join('-')}`}
                                                                                state={{ id: employee.id, full_name: employee.full_name, e_verify: employee.e_verified, reference_id: employee.reference_id, avatar_url: employee.profile_picture_url, enable_login: employee.enable_login, stage: employee.drafted_stage, progress: employee.profile_progress, from: 'employmentDashboard' }}
                                                                            >
                                                                                Complete Profile</Button>
                                                                    }
                                                                </Box>
                                                            }
                                                        />
                                                }

                                            </Box>

                                            {
                                                loading ?
                                                    <Grid item lg={12} md={12} sm={12} xs={12} textAlign={"-webkit-center"}><Skeleton variant="rounded" animation="wave" width={150} height={25} /></Grid> :
                                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                                        {
                                                            employee &&
                                                            <Box display='flex' flexDirection='row' gap={1} justifyContent='center'>
                                                                <Text className={classes.profileName} noWrap>{employee &&
                                                                    capitalizeAndAddSpace(employee.full_name).length > 16 ?
                                                                    <HtmlTooltip title={capitalizeAndAddSpace(employee.full_name)} placement="right" arrow>
                                                                        {capitalizeAndAddSpace(employee.full_name).slice(0, 16) + (capitalizeAndAddSpace(employee.full_name).length > 16 ? "..." : "")}
                                                                    </HtmlTooltip>
                                                                    :
                                                                    capitalizeAndAddSpace(employee.full_name)}</Text>
                                                            </Box>
                                                        }
                                                    </Grid>
                                            }
                                            <Grid item lg={12} pt={0}>
                                                {loading ? <Grid item pt={1} lg={12} md={12} sm={12} xs={12} textAlign={"-webkit-center"}><Skeleton animation="wave" variant="rounded" width={100} height={20} /></Grid> :
                                                    <Text className={classes.profileId}>{employee && employee?.employment_details?.reference_id}</Text>
                                                }
                                            </Grid>
                                            <Grid item lg={12} xs={12} pt={0} p={1} textAlign='center' justifyContent='center'>
                                                <Box sx={{ cursor: 'pointer' }} className={classes.uploadBtn} onClick={(event) => handleActivityClick(event)}>
                                                    Activity log
                                                </Box>

                                            </Grid>
                                            <Stack my={1} direction="row" justifyContent={"center"} spacing={2}>
                                                {
                                                    loading ?
                                                        <Skeleton width='150px' height='60px' animation='wave' /> :
                                                        employee && (employee.e_verified == 1 || employee.e_verified == 4) ?
                                                            <Button className={classes.eVerifyBtn} sx={{ border: '1px solid #22C55E !important', backgroundColor: "#22C55E !important" }}>
                                                                <Stack direction='row' gap={1} sx={{ color: "#FFFFFF  !important", alignItems: "center" }}>
                                                                    E-Verified
                                                                    <img style={{ marginTop: '0px' }} width={'15px'} height={'15px'} src={Verify} alt='e_verify' />
                                                                </Stack>
                                                            </Button> :
                                                            employee && employee.e_verified == 0 ?
                                                                <Button className={classes.notVerified} sx={{ border: '1px solid #0C75EB !important', backgroundColor: "#0C75EB !important" }}>
                                                                    Not E-Verified
                                                                </Button>
                                                                :
                                                                employee && employee.e_verified == 3 ?
                                                                    <Button className={classes.notVerified} sx={{ border: 'none !important', backgroundColor: "#E51A1A !important" }}>
                                                                        <Stack direction='row' gap={1} sx={{ color: "#FFFFFF  !important", alignItems: "center" }}>
                                                                            Rejected
                                                                            <img style={{ marginTop: '0px' }} width={'15px'} height={'15px'} src={Rejected} alt='e_verify' />
                                                                        </Stack>
                                                                    </Button>
                                                                    :
                                                                    <Button className={classes.notVerified} sx={{ border: 'none !important', backgroundColor: "#F59E0B !important" }}>
                                                                        <Stack direction='row' gap={1} sx={{ color: "#FFFFFF  !important", alignItems: "center" }}>
                                                                            Pending
                                                                            <img style={{ marginTop: '0px' }} width={'15px'} height={'15px'} src={Pending} alt='e_verify' />
                                                                        </Stack>
                                                                    </Button>
                                                }
                                                {
                                                    loading ? <Skeleton width='45px' height='60px' animation='wave' /> :
                                                        (statusPlacement !== "In Active" && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "placement_edit" && item.is_allowed == true))) &&
                                                            <Button variant="outlined" className={open ? classes.blueMenu : classes.moreBtn} onClick={handleClick} >
                                                                <MoreHorizIcon sx={{ fontSize: "18px", color: open ? `${blue}` : "#9D9E9F" }} />
                                                                <Menu
                                                                    id="basic-menu"
                                                                    anchorEl={anchorEl}
                                                                    open={open}
                                                                    onClose={handleClose}
                                                                    sx={{
                                                                        '& .MuiPaper-root': {
                                                                            border: '1px solid #EAECF0 !important',
                                                                            width: '150px !important',
                                                                            boxShadow: "#0000001F !important",
                                                                            borderRadius: '8px !important',
                                                                            padding: '0px 2px 0px 0px !important'
                                                                        },
                                                                    }}
                                                                >
                                                                    <MenuItem className={classes.inactive} onClick={() => { handleOpenEndDate() }}><Text smallBlackBold>End Placement</Text></MenuItem>
                                                                </Menu>
                                                            </Button>
                                                        )
                                                }
                                            </Stack>
                                        </Box>
                                        <Box px={3}>
                                            <Divider />
                                        </Box>
                                        <Box py={3}>
                                            <Box px={3} sx={{
                                                height: "30vh",
                                                overflow: "auto",
                                                '&::-webkit-scrollbar': {
                                                    width: '4px',
                                                },
                                                '&::-webkit-scrollbar-track': {
                                                    '-webkit-box-shadow': 'inset 0 0 6px #ffffff',
                                                },
                                                '&::-webkit-scrollbar-thumb': {
                                                    backgroundColor: '#C7CCD3',
                                                    outline: '1px solid #C7CCD3',
                                                    borderRadius: "4px",
                                                },
                                                "@media (min-height: 750px)": {
                                                    height: '36vh'
                                                }

                                            }}>
                                                {
                                                    loading ?
                                                        [1, 2, 3, 4].map((item) => (
                                                            <Skeleton variant="rounded" height={40} width={250} sx={{ margin: "10px 0px" }} />
                                                        ))
                                                        :
                                                        <>


                                                            {
                                                                dataArr.map((item, key) => (
                                                                    <ListItemButton
                                                                        key={key}
                                                                        className={`${classes.listItems} ${current === item ? classes.listItemsActive : null}`}
                                                                        onClick={() => handleTabChange(item)}
                                                                    >
                                                                        <Grid container>
                                                                            <Grid item xs={10}>{item}</Grid>
                                                                        </Grid>
                                                                    </ListItemButton>
                                                                ))}
                                                        </>
                                                }
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item lg={9} md={9} sm={8} xs={12}>
                                    <Box sx={{ width: '100%', height: "78vh", borderRadius: "8px", overflow: 'auto', boxShadow: current == "Client Details" ? "" : "0px 0px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D" }}>

                                        {
                                            current == "Client Details" ?
                                                <ClientDetails status={statusPlacement} setformEdit={setformEdit} formEdit={formEdit} id={placementId ? placementId : ""} HtmlTooltip={HtmlTooltip} />
                                                :
                                                current == "Placement Details" ?
                                                    <PlacementDetails status={statusPlacement} setformEdit={setformEdit} id={placementId ? placementId : ""} />
                                                    :
                                                    current == "Pay Rate Configuration" ?
                                                        <PayRateConfiguration status={statusPlacement} setformEdit={setformEdit} employeeType={employee.employment_type_id ? employee.employment_type_id : ''} id={placementId ? placementId : ""} />
                                                        :
                                                        current == "Timesheet Configuration" ?
                                                            < TimesheetConfiguration status={statusPlacement} setformEdit={setformEdit} id={placementId ? placementId : ""} /> : ""
                                        }
                                    </Box>
                                </Grid>


                                <BootstrapDialog
                                    TransitionComponent={Transition}
                                    keepMounted
                                    aria-labelledby="customized-dialog-title"
                                    open={endPlacementOpen}
                                    fullWidth={true}
                                    maxWidth={"md"}
                                    onClose={() => setEndPlacementOpen(false)}
                                >
                                    <DialogContent sx={{ padding: "30px", }}>
                                        <Grid container alignContent={'center'} >
                                            <Grid item lg={12} md={12} sm={12} xs={12} textAlign={"center"}>
                                                <img src={placementPop} alt='placementPop' />
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12} pt={2} textAlign={"center"}>
                                                <Text offBoardHeadFont sx={{ fontSize: "18px !important" }}>Are You Sure?</Text>
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12} textAlign={"center"} my={1}>
                                                <Text popupHead2>{updateDate ? "Do you want to update the end date for this placement?" : "Do you want to end this placement? please pick the end date below"}</Text>
                                            </Grid>
                                            <Grid item lg={1.5} md={1} sm={1} xs={1}></Grid>
                                            <Grid item lg={9} md={9} sm={9} xs={9} pt={2}>
                                                <Date
                                                    labelText={`End Date`}
                                                    name='endDate'
                                                    value={endDate}
                                                    minDate={startDate !== "" && (moment(startDate, dateFormat()).isAfter(moment()) ? startDate : moment().add(1, 'days').format(dateFormat()))}
                                                    maxDate={lastWorkingDay}
                                                    onChange={(value) => dateChange(value)}
                                                    height='56px'
                                                    error={error.endDate}
                                                />
                                                {error.endDate ? <Text red>{error.endDate ? error.endDate : ''}</Text> : ''}
                                            </Grid>
                                            <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                                                <Stack direction={"row"} spacing={3}>
                                                    <Button outlineBlue className={classes.smallBlueButton} onClick={() => { setEndPlacementOpen(false); }} >
                                                        Cancel
                                                    </Button>
                                                    <LoadingButton saveBtn className={classes.smallBlueButton} loading={saveLoader} onClick={() => handleEndPlacementSubmit()}>Continue</LoadingButton>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </DialogContent>
                                </BootstrapDialog>
                                {/* endPlacementSubmitOpen */}

                                <BootstrapDialog
                                    TransitionComponent={Transition}
                                    keepMounted
                                    aria-labelledby="customized-dialog-title"
                                    open={endPlacementSubmitOpen}
                                    fullWidth={true}
                                    maxWidth={"md"}
                                >
                                    <DialogContent sx={{ padding: "30px", }}>
                                        <Grid container justifyContent={'center'} alignContent={'center'} p={3}>
                                            <Grid item lg={12} md={12} sm={12} xs={12} textAlign={"center"}>
                                                <img src={placementEnd} alt='placementEnd' />
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12} pt={2} textAlign={"center"}>
                                                <Text offBoardHeadFont sx={{ fontSize: "18px !important" }}>Successfully Updated</Text>
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12} textAlign={"center"}>
                                                <Text offBoardBodyFont>You have successfully updated the end date for placement, If you want make any changes you can view End date in Placement Details</Text>
                                            </Grid>
                                            <Grid item lg={.5} md={1} sm={1} xs={1}></Grid>
                                            <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                                                <Button className={classes.smallBlueButton} smallBlue onClick={() => navigate("/placements")}>Go to Home</Button>
                                            </Grid>
                                        </Grid>
                                    </DialogContent>
                                </BootstrapDialog>

                                <BootstrapDialog
                                    TransitionComponent={Transition}
                                    keepMounted
                                    aria-labelledby="customized-dialog-title"
                                    open={navigateOpen}
                                    fullWidth={true}
                                    maxWidth={"md"}
                                >
                                    <DialogContent sx={{ margin: "50px", }}>
                                        <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                                            <img src={offBoardSave} alt='noactive' />
                                        </Grid>
                                        <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                                            <Text offBoardHeadFont sx={{ fontSize: "18px !important" }}>Do you want to discontinue?</Text>
                                        </Grid>
                                        <Grid container justifyContent={'center'} alignContent={'center'}>
                                            <Text offBoardBodyFont>Your progress will not be saved</Text>
                                        </Grid>
                                        <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                                            <Stack direction={"row"} spacing={3}>
                                                <Button smallBlackOutline onClick={() => { setNavigateOpen(false) }} >
                                                    Cancel
                                                </Button>
                                                <Button smallBlue redBackground onClick={() => handleFinalCancel()} >
                                                    Yes
                                                </Button>
                                            </Stack>
                                        </Grid>
                                    </DialogContent>
                                </BootstrapDialog>
                            </Grid>
                        </Box>
                        :
                        <>
                            {NoPermission()}
                        </>
                }
            </Hidden>
            <Hidden lgUp>
                <Grid container md={12} xs={12} justifyContent='center' mt={2}>
                    <Grid item container md={11} xs={12} sx={{
                        border: '1px solid #EAECF0', borderRadius: '12px', padding: '15px'
                    }}>
                        <Grid item container md={9} xs={8} columnSpacing={2}>
                            <Grid item md={2} xs={3}>
                                {
                                    loading ?
                                        <Skeleton variant="circular" width={50} height={50} /> :
                                        <CircularProgress
                                            percentage={employee && employee.profile_progress}
                                            color={employee && (employee.profile_progress >= 76 ? 'green' : employee.profile_progress <= 75 && employee.profile_progress >= 51 ? 'yellow' : employee.profile_progress < 50 ? "#FFBF00" : '')}
                                            src={EmployeeProfile}
                                            name={EmployeeProfile == undefined || EmployeeProfile == null || EmployeeProfile == '' ? employee && employee.basic_details && employee.full_name[0] : ''}
                                            imgWidth='80px !important'
                                            imgHeight='80px !important'
                                            avatarWidth='80px !important'
                                            avatarHeight='80px !important'
                                            value={employee && employee.profile_progress}
                                            size={88}
                                            edit={true}
                                            handleEdit={(e) => uploadProfile(e)}
                                            tooltip
                                            hideEdit={employee && (employee.placement_status == "Active" && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "employee_edit" && item.is_allowed == true))) ? true : false)}
                                            tooltipContent={
                                                employee &&
                                                <Box p={'3px 0px'} width='100%'>
                                                    <Text mediumBoldWhite400>{`Profile Completion - ${employee && employee.profile_progress}%`}</Text>
                                                    {
                                                        employee.profile_progress == 100 ? '' :
                                                            <Button addButtonTooltip sx={{ marginTop: '10px' }} component={Link}
                                                                onClick={() => {
                                                                    LocalStorage.removeRedirectedModule();
                                                                    LocalStorage.removeSubModule();
                                                                    LocalStorage.removeNotificationEmpId();
                                                                }}
                                                                to={`/employees/user-profile/${employee.full_name === "" ? "" : employee.full_name && employee.full_name.trim().split(/ +/).join('-')}`}
                                                                state={{ id: employee.id, full_name: employee.full_name, e_verify: employee.e_verified, reference_id: employee.reference_id, avatar_url: employee.profile_picture_url, enable_login: employee.enable_login, stage: employee.drafted_stage, progress: employee.profile_progress, from: 'employmentDashboard' }}
                                                            >
                                                                Complete Profile</Button>
                                                    }
                                                </Box>
                                            }
                                        />
                                }
                            </Grid>
                            <Grid item container md={6} xs={6} alignItems='center' ml={1}>
                                <Box alignItems='center'>
                                    <Box display='flex' flexDirection='row' gap={1} justifyContent='center' alignItems='center'>
                                        {
                                            employee &&
                                            <Text className={classes.profileName} noWrap>{employee &&
                                                capitalizeAndAddSpace(employee.full_name).length > 16 ?
                                                <HtmlTooltip title={capitalizeAndAddSpace(employee.full_name)} placement="right" arrow>
                                                    {capitalizeAndAddSpace(employee.full_name).slice(0, 16) + (capitalizeAndAddSpace(employee.full_name).length > 16 ? "..." : "")}
                                                </HtmlTooltip>
                                                :
                                                capitalizeAndAddSpace(employee.full_name)}</Text>
                                        }
                                    </Box>
                                    <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                                        <Text className={classes.profileId}>{employee && employee?.employment_details?.reference_id}</Text>
                                        <Box mt={.5} sx={{ cursor: 'pointer' }} className={classes.uploadBtn} onClick={(event) => handleActivityClick(event)}>
                                            Activity log
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item container md={3} xs={4} alignItems='center'>
                            <Stack my={1} direction="row" justifyContent={"center"} gap={1}>
                                {
                                    loading ?
                                        <Skeleton width='150px' height='60px' animation='wave' /> :
                                        <Button className={classes.eVerifyBtn} sx={{ border: '1px solid #22C55E !important', backgroundColor: "#22C55E !important" }}>
                                            <Stack direction='row' gap={1} sx={{ color: "#FFFFFF  !important", alignItems: "center" }}>
                                                E-Verified
                                                <img style={{ marginTop: '0px' }} width={'15px'} height={'15px'} src={Verify} alt='e_verify' />
                                            </Stack>
                                        </Button>
                                }
                                {
                                    loading ? <Skeleton width='45px' height='60px' animation='wave' /> :
                                        (statusPlacement !== "In Active" && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "placement_edit" && item.is_allowed == true))) &&
                                            <Button variant="outlined" className={open ? classes.blueMenu : classes.moreBtn} onClick={handleClick} >
                                                <MoreHorizIcon sx={{ fontSize: "18px", color: open ? `${blue}` : "#9D9E9F" }} />
                                                <Menu
                                                    id="basic-menu"
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={handleClose}
                                                    sx={{
                                                        '& .MuiPaper-root': {
                                                            border: '1px solid #EAECF0 !important',
                                                            width: '150px !important',
                                                            boxShadow: "#0000001F !important",
                                                            borderRadius: '8px !important',
                                                            padding: '0px 2px 0px 0px !important'
                                                        },
                                                    }}
                                                >
                                                    <MenuItem className={classes.inactive} onClick={() => { handleOpenEndDate() }}><Text smallBlackBold>End Placement</Text></MenuItem>
                                                </Menu>
                                            </Button>
                                        )
                                }
                            </Stack>
                        </Grid>
                        <Grid item container md={12} xs={12} columnSpacing={2} pt={2}>
                            {
                                dataArr.map((item, key) => (
                                    <>
                                        <Grid item md={3} xs={3}>
                                            <Box display='flex' flexDirection='row' gap={1}>
                                                {
                                                    current == item ?
                                                        <Text analyticsViewAll sx={{ cursor: 'pointer !important' }} onClick={() => handleTabChange(item)}>{item}</Text> :
                                                        <Text mediumBoldBlack400 sx={{ cursor: 'pointer !important' }} onClick={() => handleTabChange(item)}>{item}</Text>
                                                }
                                            </Box>
                                        </Grid>
                                    </>
                                ))}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container md={12} xs={12} pt={2} justifyContent='center'>
                    <Grid item container md={11} xs={12}>
                        <Box className={classes.responsiveContainer} sx={{ boxShadow: current == "Client Details" ? "" : "0px 0px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D" }}>
                            {
                                current == "Client Details" ?
                                    <ClientDetails status={statusPlacement} setformEdit={setformEdit} formEdit={formEdit} id={placementId ? placementId : ""} HtmlTooltip={HtmlTooltip} />
                                    :
                                    current == "Placement Details" ?
                                        <PlacementDetails status={statusPlacement} setformEdit={setformEdit} id={placementId ? placementId : ""} />
                                        :
                                        current == "Pay Rate Configuration" ?
                                            <PayRateConfiguration status={statusPlacement} setformEdit={setformEdit} employeeType={employee.employment_type_id ? employee.employment_type_id : ''} id={placementId ? placementId : ""} />
                                            :
                                            current == "Timesheet Configuration" ?
                                                < TimesheetConfiguration status={statusPlacement} setformEdit={setformEdit} id={placementId ? placementId : ""} /> : ""
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Hidden>
            <SwipeableDrawer
                anchor={'right'}
                open={activityOpen}
                onClose={() => { handleActivityClose() }}
                // onOpen={toggleDrawer(true, state.view)}
                transitionDuration={300}
                sx={{
                    ".MuiDrawer-paper ": {
                        borderRadius: '8px 0px 0px 8px !important',
                    },
                    "& .MuiBackdrop-root.MuiModal-backdrop": {
                        backgroundColor: 'rgba(0, 0, 0, 0.75) !important'
                    }
                }
                }
            >
                <ActivityLog
                    placeholder={"Client Details / Billing / Placement / Pay Rate / Timesheet"}
                    activityTotal={activityTotal}
                    activityData={activityData}
                    activityFilter={activityFilter}
                    setActivityFilter={setActivityFilter}
                    downloadActivity={downloadActivity}
                    employee={{ id: placementId ? placementId : "" }}
                    setLoading={setActivityLoad} loading={activityLoad} />
            </SwipeableDrawer>
        </Box >
    )
}

export default PlacementView