import { Button as MuiButton } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ButtonStyles from "./ButtonStyles";

/* fun starts here */
function Button(props) {
    // assign useStyles to classes
    const classes = ButtonStyles();

    //based on props automatically it will take styles
    const { className, children,
        save,
        smallSave, mediumInvite, onboardBtn, brownMnRedCancel, brownMnSave,
        brownMnMediumSave, addNew, EditBlue, outlineBlue, outlineBlueSmall, saveBtn, cancelBtn,
        browseBtn, browseBtnUpload,
        blackCancel, addButton, lightBlue, blueButton, BorderBlueButton, popupSaveBlue, popupCancel,
        add,
        remove,
        cancel,
        addHeightButton,
        greyButton,
        iconRemoveBtn,
        clearAll,
        cancelSmall,
        exportSmall,
        saveSmall, clearall, no,
        deleteBtn, popupDelete, popupCancelHeight, editButton,
        viewButton, reopenBtn, cancelBtnBorder, disable, outlineBlueAuto, outlineBlueAutoAdd, saveBtnSmall, cancelOutline,
        approveBtn, blueOutlineBtn, blueBtnSave, sendBtn, blackCancelBtn, selectButton, closeBtn, addComment,
        smallBlueOutlineBtn, addHeighDisabletButton, disableSaveBtn,
        largeCancelText, yesNoBtn,
        outlineBlueMedium, popupCancel1, offBoardButton,
        saveNcontinue, redHover,
        blackCancel100,
        addButtonDisable, editButtonDisable, redPayroll,
        deleteButton, closeButton, saveVerySmall, blueHoverBtn,
        outlineRedCancel, saveExtraSmall, addTitleBtn, noButton, lightblueBtn, createLg, cancelLg,
        addNewDisable, saveLoaderDisable, rejectDisable, addTitleBtnDisable, saveBtnDisable, popupSaveBlueDisable, outlineBlueMedium1,
        addNewFull, disableAddNewFull, blueBorderOutlined, blueNext, addButtonmd, popupSaveBlueHeight, offBoardDisable, disablePayroll, rehire,
        popupCancelRed,
        cancelOutlineQuicksand,
        finishFilledQuicksand,
        finishLater,
        smallBlue,
        smallBlackOutline,
        smallBlueOutline,
        redBackground,
        redBackgroundWidth,
        cancelOutlineRed, saveNcontinueSmall, borderlessSkip,
        finishFilledQuicksandLargeWidth,
        addButtonTooltip,
        redBorder,
        finishFilledQuicksandLargeWidth350,
        cancelOutlineBlue,
        clearAllRed, blackCancel1, dropdownAdd,
        blueboldCancel, whiteboldButton,
        redboldCancel, blueboldCancelClient, analyticsView, save14, smallBorderBlue, scrollBtn, saveBtn400,
        lightBluesmall,
        manageSubscription,
        upgradePlan,
        CreditsButton,
        closeButtonMivi,
        viewPlans, submitButtonEverify,
        disablegreyOutline,
        ...otherProps
    } = props;

    /**
     * When you called this function just you have to pass defined props for styles
     * ----------
     * if prop is not available create new class in CustomButtonStyles file for styles and add that class in
     * >props
     * >btnClasses
     * >Button.propTypes
     * ------------
     * ------------
     * @parem children
     * -------------
     * content of the button excepted type `node`
     * */

    // Assigning the styles to variables
    const btnClasses = classNames({
        [className]: className,
        [classes.save]: save,
        [classes.smallSave]: smallSave,
        [classes.mediumInvite]: mediumInvite,
        [classes.onboardBtn]: onboardBtn,
        [classes.outlineBlueMedium1]: outlineBlueMedium1,
        [classes.brownMnRedCancel]: brownMnRedCancel,
        [classes.brownMnSave]: brownMnSave,
        [classes.brownMnMediumSave]: brownMnMediumSave,
        [classes.addNew]: addNew,
        [classes.EditBlue]: EditBlue,
        [classes.outlineBlue]: outlineBlue,
        [classes.outlineBlueSmall]: outlineBlueSmall,
        [classes.saveBtn]: saveBtn,
        [classes.cancelBtn]: cancelBtn,
        [classes.browseBtn]: browseBtn,
        [classes.browseBtnUpload]: browseBtnUpload,
        [classes.blackCancel]: blackCancel,
        [classes.addButton]: addButton,
        [classes.addButtonmd]: addButtonmd,
        [classes.lightBlue]: lightBlue,
        [classes.blueButton]: blueButton,
        [classes.BorderBlueButton]: BorderBlueButton,
        [classes.popupSaveBlue]: popupSaveBlue,
        [classes.popupCancel]: popupCancel,
        [classes.addHeightButton]: addHeightButton,
        [classes.add]: add,
        [classes.remove]: remove,
        [classes.cancel]: cancel,
        [classes.greyButton]: greyButton,
        [classes.browseBtn]: browseBtn,
        [classes.browseBtnUpload]: browseBtnUpload,
        [classes.iconRemoveBtn]: iconRemoveBtn,
        [classes.clearAll]: clearAll,
        [classes.cancelSmall]: cancelSmall,
        [classes.exportSmall]: exportSmall,
        [classes.saveSmall]: saveSmall,
        [classes.clearall]: clearall,
        [classes.no]: no,
        [classes.deleteBtn]: deleteBtn,
        [classes.popupDelete]: popupDelete,
        [classes.cancelBtnBorder]: cancelBtnBorder,
        [classes.popupCancelHeight]: popupCancelHeight,
        [classes.editButton]: editButton,
        [classes.viewButton]: viewButton,
        [classes.reopenBtn]: reopenBtn,
        [classes.disable]: disable,
        [classes.outlineBlueAuto]: outlineBlueAuto,
        [classes.outlineBlueAutoAdd]: outlineBlueAutoAdd,
        [classes.saveBtnSmall]: saveBtnSmall,
        [classes.cancelOutline]: cancelOutline,
        [classes.approveBtn]: approveBtn,
        [classes.blueOutlineBtn]: blueOutlineBtn,
        [classes.blueBtnSave]: blueBtnSave,
        [classes.sendBtn]: sendBtn,
        [classes.blackCancelBtn]: blackCancelBtn,
        [classes.selectButton]: selectButton,
        [classes.closeBtn]: closeBtn,
        [classes.addComment]: addComment,
        [classes.popupCancel1]: popupCancel1,
        [classes.offBoardButton]: offBoardButton,
        [classes.smallBlueOutlineBtn]: smallBlueOutlineBtn,
        [classes.offBoardButton]: offBoardButton,
        [classes.smallBlueOutlineBtn]: smallBlueOutlineBtn,
        [classes.addHeighDisabletButton]: addHeighDisabletButton,
        [classes.disableSaveBtn]: disableSaveBtn,
        [classes.largeCancelText]: largeCancelText,
        [classes.yesNoBtn]: yesNoBtn,
        [classes.outlineBlueMedium]: outlineBlueMedium,
        [classes.redHover]: redHover,
        [classes.saveNcontinue]: saveNcontinue,
        [classes.closeButton]: closeButton,
        [classes.deleteButton]: deleteButton,
        [classes.saveVerySmall]: saveVerySmall,
        [classes.blueHoverBtn]: blueHoverBtn,
        [classes.outlineRedCancel]: outlineRedCancel,
        [classes.saveExtraSmall]: saveExtraSmall,
        [classes.addTitleBtn]: addTitleBtn,
        [classes.noButton]: noButton,
        [classes.deleteButton]: deleteButton,
        [classes.cancelLg]: cancelLg,
        [classes.createLg]: createLg,
        [classes.lightblueBtn]: lightblueBtn,
        [classes.addButtonDisable]: addButtonDisable,
        [classes.editButtonDisable]: editButtonDisable,
        [classes.addNewDisable]: addNewDisable,
        [classes.saveLoaderDisable]: saveLoaderDisable,
        [classes.rejectDisable]: rejectDisable,
        [classes.addTitleBtnDisable]: addTitleBtnDisable,
        [classes.saveBtnDisable]: saveBtnDisable,
        [classes.popupSaveBlueDisable]: popupSaveBlueDisable,
        [classes.addNewFull]: addNewFull,
        [classes.disableAddNewFull]: disableAddNewFull,
        [classes.blueBorderOutlined]: blueBorderOutlined,
        [classes.blueNext]: blueNext,
        [classes.popupSaveBlueHeight]: popupSaveBlueHeight,
        [classes.offBoardDisable]: offBoardDisable,
        [classes.redPayroll]: redPayroll,
        [classes.disablePayroll]: disablePayroll,
        [classes.popupCancelRed]: popupCancelRed,
        [classes.rehire]: rehire,
        [classes.cancelOutlineQuicksand]: cancelOutlineQuicksand,
        [classes.finishFilledQuicksand]: finishFilledQuicksand,
        [classes.finishLater]: finishLater,
        [classes.smallBlue]: smallBlue,
        [classes.smallBlackOutline]: smallBlackOutline,
        [classes.smallBlueOutline]: smallBlueOutline,
        [classes.redBackground]: redBackground,
        [classes.redBackgroundWidth]: redBackgroundWidth,
        [classes.cancelOutlineRed]: cancelOutlineRed,
        [classes.saveNcontinueSmall]: saveNcontinueSmall,
        [classes.borderlessSkip]: borderlessSkip,
        [classes.finishFilledQuicksandLargeWidth]: finishFilledQuicksandLargeWidth,
        [classes.addButtonTooltip]: addButtonTooltip,
        [classes.redBorder]: redBorder,
        [classes.finishFilledQuicksandLargeWidth350]: finishFilledQuicksandLargeWidth350,
        [classes.cancelOutlineBlue]: cancelOutlineBlue,
        [classes.clearAllRed]: clearAllRed,
        [classes.blackCancel1]: blackCancel1,
        [classes.blackCancel100]: blackCancel100,
        [classes.dropdownAdd]: dropdownAdd,
        [classes.blueboldCancel]: blueboldCancel,
        [classes.whiteboldButton]: whiteboldButton,
        [classes.redboldCancel]: redboldCancel,
        [classes.blueboldCancelClient]: blueboldCancelClient,
        [classes.analyticsView]: analyticsView,
        [classes.save14]: save14,
        [classes.smallBorderBlue]: smallBorderBlue,
        [classes.scrollBtn]: scrollBtn,
        [classes.saveBtn400]: saveBtn400,
        [classes.lightBluesmall]: lightBluesmall,
        [classes.manageSubscription]: manageSubscription,
        [classes.upgradePlan]: upgradePlan,
        [classes.CreditsButton]: CreditsButton,
        [classes.closeButtonMivi]: closeButtonMivi,
        [classes.viewPlans]: viewPlans,
        [classes.submitButtonEverify]: submitButtonEverify,
        [classes.disablegreyOutline]: disablegreyOutline,
    });

    return (
        <MuiButton {...otherProps} className={btnClasses}>
            {children}
        </MuiButton>
    );
}

export default Button;

Button.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    smallSave: PropTypes.bool,
    mediumInvite: PropTypes.bool,
    onboardBtn: PropTypes.bool,
    outlineBlueMedium1: PropTypes.bool,
    brownMnRedCancel: PropTypes.bool,
    brownMnSave: PropTypes.bool,
    brownMnMediumSave: PropTypes.bool,
    blackCancel: PropTypes.bool,
    addButton: PropTypes.bool,
    addButtonmd: PropTypes.bool,
    lightBlue: PropTypes.bool,
    blueButton: PropTypes.bool,
    save: PropTypes.bool,
    addNew: PropTypes.bool,
    EditBlue: PropTypes.bool,
    outlineBlue: PropTypes.bool,
    outlineBlueSmall: PropTypes.bool,
    saveBtn: PropTypes.bool,
    cancelBtn: PropTypes.bool,
    browseBtn: PropTypes.bool,
    browseBtnUpload: PropTypes.bool,
    BorderBlueButton: PropTypes.bool,
    popupSaveBlue: PropTypes.bool,
    popupCancel: PropTypes.bool,
    addHeightButton: PropTypes.bool,
    add: PropTypes.bool,
    remove: PropTypes.bool,
    cancel: PropTypes.bool,
    greyButton: PropTypes.bool,
    iconRemoveBtn: PropTypes.bool,
    clearAll: PropTypes.bool,
    cancelSmall: PropTypes.bool,
    exportSmall: PropTypes.bool,
    saveSmall: PropTypes.bool,
    clearall: PropTypes.bool,
    no: PropTypes.bool,
    deleteBtn: PropTypes.bool,
    popupDelete: PropTypes.bool,
    cancelBtnBorder: PropTypes.bool,
    popupCancelHeight: PropTypes.bool,
    editButton: PropTypes.bool,
    viewButton: PropTypes.bool,
    reopenBtn: PropTypes.bool,
    disable: PropTypes.bool,
    outlineBlueAuto: PropTypes.bool,
    outlineBlueAutoAdd: PropTypes.bool,
    saveBtnSmall: PropTypes.bool,
    cancelOutline: PropTypes.bool,
    approveBtn: PropTypes.bool,
    blueOutlineBtn: PropTypes.bool,
    blueBtnSave: PropTypes.bool,
    sendBtn: PropTypes.bool,
    blackCancelBtn: PropTypes.bool,
    selectButton: PropTypes.bool,
    closeBtn: PropTypes.bool,
    addComment: PropTypes.bool,
    popupCancel1: PropTypes.bool,
    offBoardButton: PropTypes.bool,
    smallBlueOutlineBtn: PropTypes.bool,
    addHeighDisabletButton: PropTypes.bool,
    disableSaveBtn: PropTypes.bool,
    largeCancelText: PropTypes.bool,
    yesNoBtn: PropTypes.bool,
    outlineBlueMedium: PropTypes.bool,
    redHover: PropTypes.bool,
    saveNcontinue: PropTypes.bool,
    closeButton: PropTypes.bool,
    deleteButton: PropTypes.bool,
    saveVerySmall: PropTypes.bool,
    blueHoverBtn: PropTypes.bool,
    outlineRedCancel: PropTypes.bool,
    saveExtraSmall: PropTypes.bool,
    addTitleBtn: PropTypes.bool,
    noButton: PropTypes.bool,
    cancelLg: PropTypes.bool,
    createLg: PropTypes.bool,
    lightblueBtn: PropTypes.bool,
    addButtonDisable: PropTypes.bool,
    editButtonDisable: PropTypes.bool,
    addNewDisable: PropTypes.bool,
    saveLoaderDisable: PropTypes.bool,
    rejectDisable: PropTypes.bool,
    addTitleBtnDisable: PropTypes.bool,
    saveBtnDisable: PropTypes.bool,
    popupSaveBlueDisable: PropTypes.bool,
    addNewFull: PropTypes.bool,
    disableAddNewFull: PropTypes.bool,
    blueBorderOutlined: PropTypes.bool,
    blueNext: PropTypes.bool,
    popupSaveBlueHeight: PropTypes.bool,
    offBoardDisable: PropTypes.bool,
    redPayroll: PropTypes.bool,
    disablePayroll: PropTypes.bool,
    popupCancelRed: PropTypes.bool,
    rehire: PropTypes.bool,
    cancelOutlineQuicksand: PropTypes.bool,
    finishFilledQuicksand: PropTypes.bool,
    finishLater: PropTypes.bool,
    redBackground: PropTypes.bool,
    redBackgroundWidth: PropTypes.bool,
    smallBlueOutline: PropTypes.bool,
    smallBlackOutline: PropTypes.bool,
    smallBlue: PropTypes.bool,
    saveNcontinueSmall: PropTypes.bool,
    cancelOutlineRed: PropTypes.bool,
    borderlessSkip: PropTypes.bool,
    finishFilledQuicksandLargeWidth: PropTypes.bool,
    addButtonTooltip: PropTypes.bool,
    redBorder: PropTypes.bool,
    finishFilledQuicksandLargeWidth350: PropTypes.bool,
    cancelOutlineBlue: PropTypes.bool,
    clearAllRed: PropTypes.bool,
    blackCancel1: PropTypes.bool,
    blackCancel100: PropTypes.bool,
    dropdownAdd: PropTypes.bool,
    blueboldCancel: PropTypes.bool,
    whiteboldButton: PropTypes.bool,
    redboldCancel: PropTypes.bool,
    blueboldCancelClient: PropTypes.bool,
    analyticsView: PropTypes.bool,
    save14: PropTypes.bool,
    smallBorderBlue: PropTypes.bool,
    scrollBtn: PropTypes.bool,
    saveBtn400: PropTypes.bool,
    lightBluesmall: PropTypes.bool,
    manageSubscription: PropTypes.bool,
    upgradePlan: PropTypes.bool,
    CreditsButton: PropTypes.bool,
    closeButtonMivi: PropTypes.bool,
    viewPlans: PropTypes.bool,
    submitButtonEverify: PropTypes.bool,
    disablegreyOutline: PropTypes.bool
};

