import { Box, Breadcrumbs, Grid, Skeleton, Tab, Tabs, Typography, } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Text from '../../../components/customText/Text'
import AppIntegrationsStyles from './AppIntegrationsStyles'
import { styled } from "@mui/styles";
import InstalledAppsApi from '../../../apis/InstalledAppsApi';
import Button from '../../../components/customButton/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useLocation, useNavigate } from 'react-router-dom';
import LocalStorage from '../../../utils/LocalStorage';
import noApp from "../../../assets/svg/noApps.svg";
import AddIcon from '@mui/icons-material/Add';
import { addErrorMsg, BlackToolTip, NoPermission } from '../../../utils/utils';
import infoCircle from '../../../assets/images/info-circle.png';
import verified from '../../../assets/svg/Component87.svg';
import warning from '../../../assets/svg/orange-warning-zoho.svg';
import arrow from '../../../assets/svg/orange-right-arrow.svg';
import ReusablePopup from '../../../components/reuablePopup/ReusablePopup';

const ConfigurationTab = ['All Apps', 'Installed Apps'];

// custom Tabs container
const TabsContainer = styled(Box)({
    borderBottom: '1px solid #C7CCD3',
    display: 'inline-block', // Display as inline-block
    width: 'auto', // Set width to fit content
    overflowX: 'auto', // Enable horizontal scrolling if needed
    whiteSpace: 'nowrap', // Prevent line breaks
});

// custom Tabs
const CustomTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
        borderBottom: '1px solid #0C75EB !important',
    },
});

// custom Tab
const CustomTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    fontFamily: "Quicksand !important",
    color: "#737373 !important",
    fontSize: "15px !important",
    fontWeight: `${600} !important`,
    padding: "8px 10px !important",
    textTransform: 'none !important',
    minWidth: "130px !important",
    // minWidth: 0,
    '&.Mui-selected': {
        color: '#0C75EB !important',
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#d1eaff !important',
    },
}));


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ width: "100%" }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function AppIntegrations(props) {

    const classes = AppIntegrationsStyles();
    const location = useLocation();
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const navigate = useNavigate();
    const [tab, setTab] = useState(0);
    const [data, setData] = useState([]);
    const [countData, setCountData] = useState({
        migrated_count: '',
        total_count: '',
        error_count: 0,
        not_migrated_count: ''
    })
    const [loading, setLoading] = useState(false);
    const [disconnect, setDisconnect] = useState(false);

    useEffect(() => {
        if ((location.state != undefined && location.state != null) || (location && location.state && location.state.from == 'Installed')) {
            setTab(1);
            getAllApps('connected');
        } else {
            setTab(0);
            getAllApps('');
        }
        LocalStorage.removeAppIntegrationId();
        countApi();
        // eslint-disable-next-line
    }, [])

    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
        getAllApps(newValue == 1 ? 'connected' : "");
    }

    const countApi = () => {
        InstalledAppsApi.getCountList().then((res) => {
            if (res.data.statusCode == 1003) {
                // LocalStorage.showProgress(res.data.data.show_progress);
                LocalStorage.showProgress(res.data?.data?.show_progress)
                props.setCountData({ ...props.countData, show_progress: res.data?.data?.show_progress })
                setCountData(res.data.data);
            }
        })
    }

    const getAllApps = (param) => {
        setLoading(true);
        InstalledAppsApi.getApps(param).then((res) => {
            setLoading(false);
            if (res.data.statusCode === 1003) {
                setData(res.data.data);
            }
        })
    }

    const handleNavigate = (item) => {
        LocalStorage.setAppIntegrationId(item.id)
        if (item.connection_status == 'not_connected') {
            navigate("/app-integrations/selected-app");
        } else {
            if (item.connection_status == 'connected' && item.redirect_to == "organization") {
                navigate("/app-integrations/selected-app/configure", { state: { id: item.id } })
            } else {
                navigate("/app-integrations/selected-app");
            }
        }
    }

    // const disconnectApiBtn = (item) => {
    //     LocalStorage.setAppIntegrationId(item.id)
    //     if (item.redirect_to == "authorization") {
    //         navigate("/app-integrations/selected-app/configure", { state: { id: item.id } });
    //     } else {
    //         navigate("/app-integrations/selected-app")
    //     }
    // }

    // eslint-disable-next-line
    const handleDeregister = (item) => {
        LocalStorage.setAppIntegrationId(item.id);
        navigate("/app-integrations/de-register", { state: { id: item.id } })
    }

    const handleEdit = (item, args) => {
        LocalStorage.setAppIntegrationId(item.id);
        if (args == 'Error') {
            navigate('/app-integrations/edit-app-integrations', { state: { id: item.id, from: 'Error' } })
        } else {
            navigate("/app-integrations/edit-app-integrations", { state: { id: item.id } })
        }
    }

    const disconnectApi = () => {
        InstalledAppsApi.updateDisconnect(data).then((res) => {
            if (res.data.statusCode == 1003) {
                navigate('/app-integrations/selected-app');
                setDisconnect(false);
            } else {
                addErrorMsg(res.data.message);
            }
        })
    }

    return (
        <Box className={classes.mainBox}>
            <Box mx={2}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography onClick={() => { navigate("/myProfile") }} className={classes.breadcrumbsLink}>Profile</Typography>
                    <Text sx={{ color: '#092333 !important', fontWeight: '700 !important' }} mediumBlack14>App Integrations</Text>
                </Breadcrumbs>
            </Box>
            {
                LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "app_integrations_view" && item.is_allowed == true)) ?
                    <>
                        <Grid container item lg={12} md={12} sm={12} xs={12} pt={2}>
                            <Box sx={{ borderBottom: '1px', borderColor: 'divider', width: "100%" }}>
                                <TabsContainer>
                                    <CustomTabs value={tab} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto">
                                        {
                                            ConfigurationTab.map((item, key) => (
                                                <CustomTab key={key} label={item} {...a11yProps(0)} />
                                            ))
                                        }
                                    </CustomTabs>
                                </TabsContainer>
                                <Text pt={1} largeLabel sx={{ fontWeight: `${400} !important` }}>Third party applications to make the best use of Japfu</Text>
                            </Box>
                        </Grid>
                        <CustomTabPanel value={tab} index={0} >
                            <Grid container columnGap={3}>
                                {
                                    loading ?
                                        [1, 2, 3, 4].map((item) => (
                                            <Grid item lg={2.8} md={3.5} sm={5} xs={12} pt={3}>
                                                <Box className={classes.cardBox}>
                                                    <Grid container>
                                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                                            <Skeleton variant="rounded" width={100} height={25} />
                                                        </Grid>
                                                        <Grid item lg={6} md={6} sm={6} xs={6} textAlign={"-webkit-right"}>
                                                            <Skeleton variant="rounded" width={50} height={25} />
                                                        </Grid>
                                                        <Grid item lg={5} md={6} sm={8} xs={8} pt={1}>
                                                            <Skeleton variant="rounded" width={150} height={15} />
                                                        </Grid>
                                                        <Grid item lg={12} md={12} sm={12} xs={12} pt={1}>
                                                            <Skeleton variant="rounded" width={230} height={15} />
                                                        </Grid>
                                                        <Grid item lg={12} md={12} sm={12} xs={12} pt={1}>
                                                            <Skeleton variant="rounded" width={100} height={30} />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        )) :
                                        <>
                                            {
                                                data.length > 0 ?
                                                    <>
                                                        {
                                                            data.map((item) => (
                                                                <Grid item lg={2.8} md={3.5} sm={5} xs={12} pt={3}>
                                                                    <Box className={classes.cardBox}>
                                                                        <Grid container columnSpacing={1}>
                                                                            <Grid item lg={5} md={5} sm={5} xs={5}>
                                                                                <img src={item.logo_url} alt="logo" height={"32px"} width={"82px"} />
                                                                            </Grid>
                                                                            <Grid item lg={6} md={6} sm={6} xs={6} textAlign={"-webkit-right"}>
                                                                                <Box className={classes.invoicingBox}>Invoicing</Box>
                                                                            </Grid>
                                                                            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                                                                                <BlackToolTip arrow placement="top" title={
                                                                                    <Text mediumWhite400>App data Integrations are subjected to your Zoho Books plan. Please refer Help documentation for more info.</Text>
                                                                                }>
                                                                                    <img src={infoCircle} alt="infoCircle" height={"16px"} style={{ marginBottom: "0px", cursor: "pointer" }} />
                                                                                </BlackToolTip>
                                                                            </Grid>
                                                                            <Grid item lg={12} md={12} sm={12} xs={12} pt="4px">
                                                                                <Text mediumGreyHeader sx={{ font: "10px Quicksand !important" }}>{item.subject}</Text>
                                                                            </Grid>
                                                                            <Grid item lg={12} md={12} sm={12} xs={12} pt={1}>
                                                                                <Text mediumGreyHeader sx={{ font: "9px Quicksand !important" }}>{item.description}</Text>
                                                                            </Grid>
                                                                            <Grid item container lg={12} md={12} sm={12} xs={12} pt={2}>
                                                                                {
                                                                                    item.app_name == 'Zoho Books' ?
                                                                                        <>
                                                                                            {
                                                                                                item.connection_status == 'not_connected' ?
                                                                                                    <Grid item lg={12} md={12} sm={12} xs={12} container justifyContent='end'>
                                                                                                        <Button onClick={() => { handleNavigate(item) }} saveBtn className={classes.configureButton} classes={{ endIcon: classes.endIcon }} endIcon={<ArrowForwardIosIcon sx={{ fontSize: "15px !important" }} />}>Configure</Button>
                                                                                                    </Grid> :
                                                                                                    (item.connection_status == 'connected') && (item.redirect_to == 'organization' || item.redirect_to == 'authorization') ?
                                                                                                        <Grid item lg={12} md={12} sm={12} xs={12} container justifyContent='end'>
                                                                                                            <Button saveBtn className={classes.configureButton} onClick={() => handleNavigate(item)}>{item.redirect_to == 'authorization' ? 'Disconnect' : 'Configure'}</Button>
                                                                                                        </Grid> :
                                                                                                        item.connection_status == 'connected' ?
                                                                                                            <Grid item lg={12} md={12} sm={12} xs={12} container alignItems='center'>
                                                                                                                {/* <Grid item lg={6} md={5} sm={5} xs={12}> */}
                                                                                                                <Box display='flex' flexDirection='row' gap={1} sx={{ height: '22px', minWidth: '100px', padding: '4px', border: '1px solid #0C75EB', borderRadius: '8px', alignItems: 'center' }}>
                                                                                                                    <Text mediumBlue>Count : {countData.migrated_count} of {countData.total_count}</Text>
                                                                                                                </Box>
                                                                                                                {/* </Grid> */}
                                                                                                            </Grid> :
                                                                                                            <Grid item lg={12} md={12} sm={12} xs={12} container alignItems='center'>
                                                                                                                {/* <Grid item lg={6} md={5} sm={5} xs={12} textAlign='start'> */}
                                                                                                                <Box display='flex' flexDirection='row' textAlign='start' gap={1} sx={{ height: '22px', minWidth: '100px', padding: '4px', border: '1px solid #0C75EB', borderRadius: '8px', alignItems: 'center' }}>
                                                                                                                    <Text mediumBlue>Count : {countData.migrated_count} of {countData.total_count}</Text>
                                                                                                                </Box>
                                                                                                                {/* </Grid> */}
                                                                                                                <Grid item lg={5} md={5} sm={5} xs={12} textAlign='end'>
                                                                                                                    <Button onClick={() => { handleNavigate(item) }} saveBtn className={classes.configureButton} classes={{ endIcon: classes.endIcon }} endIcon={<ArrowForwardIosIcon sx={{ fontSize: "15px !important" }} />}>Configure</Button>
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                            }
                                                                                        </> :
                                                                                        <Grid item lg={12} md={12} sm={12} xs={12} container alignItems='center'>
                                                                                            <Grid item lg={4} md={7} sm={7} xs={12}>
                                                                                                <Button onClick={() => { handleNavigate(item) }} saveBtn className={classes.configureButton} classes={{ endIcon: classes.endIcon }} endIcon={<ArrowForwardIosIcon sx={{ fontSize: "15px !important" }} />}>Configure</Button>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                }

                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </Grid>
                                                            ))
                                                        }
                                                    </> :
                                                    ""
                                            }
                                        </>
                                }
                            </Grid >
                        </CustomTabPanel >
                        <CustomTabPanel value={tab} index={1} >
                            <Grid container columnGap={3}>
                                {
                                    loading ?
                                        [1, 2, 3, 4].map((item) => (
                                            <Grid item lg={2.8} md={3.5} sm={5} xs={12} pt={3}>
                                                <Box className={classes.cardBox}>
                                                    <Grid container>
                                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                                            <Skeleton variant="rounded" width={100} height={25} />
                                                        </Grid>
                                                        <Grid item lg={6} md={6} sm={6} xs={6} textAlign={"-webkit-right"}>
                                                            <Skeleton variant="rounded" width={50} height={25} />
                                                        </Grid>
                                                        <Grid item lg={5} md={6} sm={8} xs={8} pt={1}>
                                                            <Skeleton variant="rounded" width={150} height={15} />
                                                        </Grid>
                                                        <Grid item lg={12} md={12} sm={12} xs={12} pt={1}>
                                                            <Skeleton variant="rounded" width={230} height={15} />
                                                        </Grid>
                                                        <Grid item lg={12} md={12} sm={12} xs={12} pt={1}>
                                                            <Skeleton variant="rounded" width={100} height={30} />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        )) :
                                        <>
                                            {
                                                data.length > 0 ?
                                                    <>
                                                        {
                                                            data.map((item) => (
                                                                <Grid item lg={2.8} md={3.5} sm={5} xs={12} pt={3}>
                                                                    <Box className={classes.cardBox}>
                                                                        <Grid container columnSpacing={1}>
                                                                            <Grid item lg={5} md={5} sm={5} xs={5}>
                                                                                <img src={item.logo_url} alt="logo" height={"32px"} width={"82px"} />
                                                                            </Grid>
                                                                            <Grid item lg={6} md={6} sm={6} xs={6} textAlign={"-webkit-right"}>
                                                                                <Box className={classes.invoicingBox}>Invoicing</Box>
                                                                            </Grid>
                                                                            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                                                                                <BlackToolTip arrow placement="top" title={
                                                                                    <Text mediumWhite400>App data Integrations are subjected to your Zoho Books plan. Please refer Help documentation for more info.</Text>
                                                                                }>
                                                                                    <img src={infoCircle} alt="infoCircle" height={"16px"} style={{ marginBottom: "0px", cursor: "pointer" }} />
                                                                                </BlackToolTip>
                                                                            </Grid>
                                                                            {
                                                                                countData.error_count !== 0 ?
                                                                                    <Grid item container alignItems='center' lg={12} my={2} onClick={() => { handleEdit(item, 'Error') }}>
                                                                                        <Box display='flex' justifyContent='space-between' alignItems='center' flexDirection='row' gap={1.5} sx={{ height: '35px', width: '100%', borderRadius: '8px', background: '#FFEFDD', textAlign: 'center', padding: '0px 10px', cursor: 'pointer' }}>
                                                                                            <Box display='flex' justifyContent='space-between' flexDirection='row' gap={1.5}>
                                                                                                <Box>
                                                                                                    <img src={warning} alt='warning' />
                                                                                                </Box>
                                                                                                <Box>
                                                                                                    <Text mediumOverView>Error in Integration</Text>
                                                                                                </Box>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <img src={arrow} alt='arrow' />
                                                                                            </Box>
                                                                                        </Box>
                                                                                    </Grid> : ''
                                                                            }
                                                                            <Grid item lg={6} md={7} sm={8} xs={12} pt="4px">
                                                                                <Text mediumGreyHeader sx={{ font: "10px Quicksand !important" }}>{item.subject}</Text>
                                                                            </Grid>
                                                                            <Grid item lg={12} md={12} sm={12} xs={12} pt={1}>
                                                                                <Text mediumGreyHeader sx={{ font: "9px Quicksand !important" }}>{item.description}</Text>
                                                                            </Grid>
                                                                            <Grid container item lg={12} md={12} sm={12} xs={12} pt={2} justifyContent={"space-between"} alignItems='center'>
                                                                                <Box display='flex' flexDirection='row' gap={'5px'} alignItems='center'>
                                                                                    <img src={verified} alt='verified' />
                                                                                    <Text mediumOverView>Connected</Text>
                                                                                </Box>
                                                                                {
                                                                                    (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "app_integrations_delete" && item.is_allowed == true))) ?
                                                                                        <Button onClick={() => { handleEdit(item) }} saveBtn className={classes.deRegButton} classes={{ endIcon: classes.endIcon }} endIcon={<ArrowForwardIosIcon sx={{ fontSize: "15px !important" }} />}>Deregister</Button> : ''
                                                                                }
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </Grid>
                                                            ))
                                                        }
                                                    </> :
                                                    <Grid container justifyItems={"center"} pt={5}>
                                                        <Grid item md={12} sm={12} container justifyContent={"center"} alignItems={"center"} pt={5}>
                                                            <img src={noApp} alt="noApp" />
                                                        </Grid>
                                                        <Grid item md={12} sm={12} textAlign={"center"} pt={3}>
                                                            <Text smallLabelblack16>There is no installed apps to configure. Try adding new apps. </Text>
                                                        </Grid>
                                                        <Grid item md={12} sm={12} textAlign={"center"} pt={4}>
                                                            <Button onClick={() => { setTab(0); getAllApps(""); }} sx={{ width: "191px !important" }} saveBtn startIcon={<AddIcon />}>Add New Apps</Button>
                                                        </Grid>
                                                    </Grid>
                                            }
                                        </>
                                }

                            </Grid >
                        </CustomTabPanel >
                    </>
                    :
                    <>
                        {NoPermission()}
                    </>
            }
            <ReusablePopup openPopup={disconnect} setOpenPopup={setDisconnect} white iconHide fixedWidth>
                <Box textAlign='center'>
                    <Box sx={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                        {/* <img src={viewData.status == 'In Active' ? Sure : deactivateImg} alt="warning" /> */}
                    </Box>
                    <Box my={3}>
                        <Text popupHead1>
                            Are You Sure?
                        </Text>
                        <Text largeLabel sx={{ fontWeight: '400 !important' }} my={3}>
                            Do you want to disconnect
                        </Text>
                    </Box>
                    <Grid container lg={12} xs={12} justifyContent={'center'} alignContent={'center'} mt={1} spacing={2}>
                        <Grid item lg={5} xs={6} textAlign='right'>
                            <Button onClick={() => { setDisconnect(false) }} redBorder>Cancel</Button>
                        </Grid>
                        <Grid item lg={5} xs={6} textAlign='center'>
                            <Button onClick={() => disconnectApi()} blueButton>Yes</Button>
                        </Grid>
                    </Grid>
                </Box>
            </ReusablePopup>
        </Box >
    )
}

export default AppIntegrations 