import React, { useState, useEffect, useRef } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { Box, Avatar, Stack, Grid, Divider, Menu, Backdrop } from '@mui/material';
import { styled } from "@mui/material/styles";
import Skeleton from '@mui/material/Skeleton';
import Component87 from '../../../assets/svg/Component87.svg';
import Search from '../../../assets/svg/search1.svg';
import { addErrorMsg, addLoader, capitalizeAndAddSpace, NoDataFound, removeLoader } from '../../../utils/utils';
import { Link } from "react-router-dom";
import Text from '../../../components/customText/Text';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import LocalStorage from '../../../utils/LocalStorage';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ClearIcon from '@mui/icons-material/Clear';
import Loader from '../../../assets/gif/japfuLoader.gif';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PlacementDashboardStyles from './PlacementDashboardStyles';
import PlacementApi from '../../../apis/admin/placements/PlacementApi';


const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#404040",
        padding: "6px 14px",
        minWidth: 100,
        border: "1px solid #404040"
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#404040",
        "&::before": {
            backgroundColor: "#404040",
            border: "1px solid #404040"
        }
    },
}));

export default function PlacementDashboard() {

    const classes = PlacementDashboardStyles();
    const [placements, setPlacements] = useState([]);
    const [pagination, setPagination] = useState({ total: "6" });
    const [loading, setLoading] = useState(false); // eslint-disable-next-line
    const [expanded, setExpanded] = React.useState(false);
    const [filterData, setFilterData] = useState({
        limit: 10,
        page: 1,
        search: "",
        status: [],
        start_date: [],
        end_date: [],
        clients: [],
        recruiter: [],
        timesheet_cycle: [],
        employee_id: LocalStorage.getUserData().login_id,
        sort_column: "created_at",
        sort_order: "desc"
    })
    const [openBacKLoader, setOpenBacKLoader] = useState(false);

    useEffect(() => {
        getAllPlacements(filterData);
        // eslint-disable-next-line
    }, []);

    const getAllPlacements = (filter) => {

        if (placements.length < 6) {
            setLoading(true);
        }
        PlacementApi.getPlacementListingConsultant(filter).then((response) => {
            removeLoader();
            setOpenBacKLoader(false);
            if (response.data.statusCode == 1003) {
                setLoading(false);
                setPlacements(response.data.data);
                setPagination({ total: response.data.pagination.total });
            } else {
                setLoading(false);
                addErrorMsg(response.data.message);
            }
        })

    }


    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleSearch = (e) => {
        // setOpenBacKLoader(true);
        setExpanded(false);
        filterData.limit = 10;
        filterData.page = 1;
        filterData.search = e.target.value;
        setFilterData({ ...filterData });
        setLoading(true);
        addLoader(true);
        getAllPlacements(filterData);

    }

    const handleSearchClear = () => {
        // setOpenBacKLoader(true);
        setExpanded(false);
        filterData.limit = 10;
        filterData.page = 1;
        filterData.search = '';
        setFilterData({ ...filterData });
        getAllPlacements(filterData);

    }

    const [anchorEl1, setAnchorEl1] = useState(null);
    const openStatus = Boolean(anchorEl1);

    const handleStatusOpen = (event) => {
        setAnchorEl1(event.currentTarget);
    }

    const handleStatusClose = (param) => {
        setAnchorEl1(null);
        setExpanded(false);
        // setOpenBacKLoader(true);
        if (param === undefined) {
            filterData.limit = 10;
            filterData.page = 1;
            filterData.status = [];
            setFilterData({ ...filterData });
            setLoading(true);
            getAllPlacements(filterData);
        } else {
            filterData.limit = 10;
            filterData.page = 1;
            filterData['status'] = [param]
            setFilterData({ ...filterData });
            setLoading(true);
            getAllPlacements(filterData);
        }
    }


    const loadMoreData = () => {
        // addLoader(true);
        filterData.limit = Number(filterData.limit) + 6;
        setFilterData({ ...filterData });
        getAllPlacements(filterData);

    }


    const scrollBox = useRef(null);

    const handleScroll = () => {
        const { current } = scrollBox;
        if (current) {
            const { scrollTop, scrollHeight, clientHeight } = current;
            if (scrollTop + clientHeight >= scrollHeight - 5) {
                if (Number(pagination.total) >= filterData.limit) {
                    setOpenBacKLoader(true);
                    loadMoreData();
                }
            } else {
            }
        }
    };

    return (
        <Box className={classes.flexBox} style={{ paddingLeft: '65px' }}>

            <Box sx={{ width: "90%" }} >
                <Box style={{ padding: "20px 10px 10px 10px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div>
                            <Text mediumViewAmt>All Placements</Text>
                        </div>
                        <div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "16px", paddingRight: "5px" }}>
                                <div style={{ height: "44px", border: "1.5px solid #E2E5E6", width: "368px", borderRadius: "6px", display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                                    <input
                                        type="text"
                                        value={filterData.search}
                                        className={classes.EmployeesSearchInput}
                                        onChange={handleSearch}
                                        placeholder="Search by Name / ID"
                                    />
                                    <Box sx={{ paddingRight: "15px !important" }}>
                                        {
                                            filterData.search !== "" ?
                                                <ClearIcon sx={{ cursor: "pointer" }} onClick={() => handleSearchClear()} />
                                                :
                                                <img src={Search} alt="Search" />
                                        }
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
                <Box style={{ padding: "10px" }}>

                    <Box style={{ display: "flex", justifyContent: "space-between", fontSize: "14px", fontWeight: "600", alignItems: "center", borderBottom: "1px solid #F3EFEF", padding: "16px 12px" }} >
                        <Grid container lg={12} md={12} sm={12} xs={12} textAlign='center' alignItems='center'>
                            <Grid item lg={4} md={4} sm={4} xs={4} className={classes.headerNames}>Client Name</Grid>
                            {/* <Grid item lg={2} md={2} sm={2} xs={2} className={classes.headerNames}>Employment Type</Grid> */}
                            <Grid item lg={2} md={2} sm={2} xs={1} className={classes.headerNames} >Start Date</Grid>
                            <Grid container item lg={2.5} md={2.5} sm={2} xs={2}>
                                <Grid item lg={6} md={6} sm={6} xs={6} className={classes.headerEndDate} >End Date</Grid>
                                <Grid aria-controls="status-menu" item lg={6} md={6} sm={6} xs={6} className={classes.headerStatus} onClick={handleStatusOpen} >Status
                                    {
                                        openStatus ?
                                            <KeyboardArrowUpIcon sx={{ color: filterData.status.length > 0 ? "#2BEA2B !important" : "" }} />
                                            :
                                            <KeyboardArrowDownIcon sx={{ color: filterData.status.length > 0 ? "#2BEA2B !important" : "" }} />
                                    }
                                </Grid>
                                <Menu
                                    anchorEl={anchorEl1}
                                    id="status-menu"
                                    open={openStatus}
                                    onClose={() => { setAnchorEl1(null) }}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            overflow: "visible",
                                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                            mt: 0.5,
                                            width: "170px",
                                            "& .MuiMenuItem-root:hover": {
                                                backgroundColor: "#EAECF0 !important",
                                            },
                                        }
                                    }}
                                    transformOrigin={{ horizontal: "left", vertical: "top" }}
                                    anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                                >
                                    <MenuItem onClick={() => { handleStatusClose() }} className={classes.menuItem}>All</MenuItem> <Divider className={classes.divider} />
                                    <MenuItem onClick={() => { handleStatusClose("Active") }} className={filterData.status.length > 0 && filterData.status[0] == "Active" ? classes.activeMenuItem : classes.menuItem}> Active Placements</MenuItem> <Divider className={classes.divider} />
                                    <MenuItem onClick={() => { handleStatusClose("In Active") }} className={filterData.status.length > 0 && filterData.status[0] == "In Active" ? classes.activeMenuItem : classes.menuItem}> Inactive Placements</MenuItem>
                                </Menu>
                            </Grid>
                            <Grid item lg={3} md={3} sm={3} xs={3} display={"flex"} justifyContent={"center"}>
                                Action
                                {/* <Text offBoardBodyFont>
                                    Count
                                    <HtmlTooltip
                                        placement="bottom"
                                        arrow
                                        title={
                                            <React.Fragment>
                                                <Box>
                                                    <Typography className={classes.profileTooltipText}>
                                                        Total Placements count is <br />based on the filters applied
                                                    </Typography>
                                                </Box>
                                            </React.Fragment>
                                        }
                                    >
                                        <img src={infoCircle} alt="infoCircle" height={"17px"} style={{ marginLeft: "3px", marginBottom: "-4px", cursor: "pointer" }} />
                                    </HtmlTooltip>
                                    {` - ${pagination ? pagination.total ? pagination.total : "" : ""} Placements`}</Text> */}
                            </Grid>
                        </Grid>
                    </Box>

                    {
                        loading ?
                            [1, 2, 3, 4].map((item) => (
                                <Stack key={item} direction="row" my={3} px={4} py={2} spacing={2} sx={{ width: "100%", boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05)", borderRadius: "8px" }}>
                                    <Box sx={{ width: "59%", display: "flex", alignItems: "center", gap: 2, borderRight: "1px solid rgba(226, 229, 230, 1)" }}>
                                        <Skeleton variant="circular" sx={{ width: "64px", height: "56px" }} />
                                        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <Box>
                                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: "10rem" }} />
                                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                            </Box>
                                            <Box sx={{ width: "20%", alignSelf: "center" }}>
                                                <Skeleton variant="text" sx={{ fontSize: '1rem', width: "4rem" }} />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box sx={{ width: "41%", display: "flex", alignItems: "center", gap: 2 }}>
                                        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                        </Box>
                                    </Box>
                                </Stack>
                            )) :
                            <>
                                {
                                    placements.length > 0 ?
                                        <Box sx={{
                                            height: "64vh", overflowY: 'scroll', alignItems: 'start', justifyContent: 'start',
                                            paddingLeft: '5px', paddingRight: '5px',
                                            "@media (min-height: 850px)": {
                                                height: '70vh'
                                            }
                                        }} ref={scrollBox} onScroll={handleScroll}>
                                            {
                                                placements.map((place, key) => (
                                                    <Accordion
                                                        key={key}
                                                        className={classes.newCustomAccordion}
                                                        expanded={expanded === `panel${key}`} onChange={handleChange(`panel${key}`)}>
                                                        <AccordionSummary
                                                            aria-controls="panel1bh-content"
                                                            id="panel1bh-header"
                                                            className={classes.AccordionSummary}
                                                        >
                                                            <Grid container lg={12} md={12} sm={12} xs={12} textAlign='center' alignItems='center'>
                                                                <Grid item lg={4} md={4} sm={4} xs={2} textAlign={"start"} >
                                                                    <Box display='flex' alignItems='center' gap={2} >

                                                                        <Box sx={{ display: "flex", padding: "3px", borderRadius: "50%", backgroundColor: "#ffffff" }}>

                                                                            <Avatar
                                                                                alt={place.client_name[0]}
                                                                                src={place.client_logo_url ? place.client_logo_url : place.client_name[0]}
                                                                                sx={{ width: 50, height: 50, backgroundColor: '#e5b256 !important' }}
                                                                            />
                                                                        </Box>

                                                                        <Box ml={-1}>
                                                                            {
                                                                                place.status == 'In Active' ?
                                                                                    <Text mediumBoldBlack style={{ margin: '4px 0' }} noWrap>
                                                                                        {place.client_name === "" ? "--" : capitalizeAndAddSpace(place.client_name).length > 16 ?
                                                                                            <HtmlTooltip title={capitalizeAndAddSpace(place.client_name)} placement="right" arrow>
                                                                                                {capitalizeAndAddSpace(place.client_name).slice(0, 16) + (capitalizeAndAddSpace(place.client_name).length > 16 ? "..." : "")}
                                                                                            </HtmlTooltip>
                                                                                            :
                                                                                            capitalizeAndAddSpace(place.client_name)
                                                                                        }
                                                                                        <HtmlTooltip title="Profile Has Been E-Verified" placement="right" arrow>
                                                                                            <img src={Component87} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                                                                        </HtmlTooltip>
                                                                                    </Text>
                                                                                    :
                                                                                    <Text mediumBoldBlack noWrap>
                                                                                        {place.client_name === "" ? "--" :
                                                                                            capitalizeAndAddSpace(place.client_name).length > 16 ?
                                                                                                <HtmlTooltip title={capitalizeAndAddSpace(place.client_name)} placement="right" arrow>
                                                                                                    {capitalizeAndAddSpace(place.client_name).slice(0, 16) + (capitalizeAndAddSpace(place.client_name).length > 16 ? "..." : "")}
                                                                                                </HtmlTooltip>
                                                                                                :
                                                                                                capitalizeAndAddSpace(place.client_name)
                                                                                        }
                                                                                    </Text>
                                                                            }
                                                                            <Text mediumLabel noWrap style={{ margin: '4px 0' }}>  {place.employee_reference_id ? place.employee_reference_id : "--"} &#128900; {place.placement_reference_id ? place.placement_reference_id : "--"}</Text>

                                                                        </Box>
                                                                    </Box>
                                                                </Grid>
                                                                {/* <Grid item lg={2} md={2} sm={2} xs={2} textAlign={"start"}>
                                                                    <Text smallBoldBlack noWrap >Consultant</Text>
                                                                </Grid> */}
                                                                <Grid item lg={2} md={2} sm={2} xs={1} textAlign={"start"}>
                                                                    <Text smallBoldBlack noWrap >{place.start_date ? place.start_date : "--"}</Text>
                                                                </Grid>
                                                                <Grid container lg={3} md={3} sm={3} xs={3} textAlign={"start"}>
                                                                    <Stack
                                                                        direction="row"
                                                                        useFlexGap
                                                                        width='80%'
                                                                        divider={<Divider orientation="vertical" flexItem style={{ height: '50px' }} />}
                                                                        spacing={3}
                                                                        textAlign='center' alignItems='center'
                                                                    >
                                                                        <Grid item lg={4} md={4} sm={6} xs={6} textAlign={"start"}>
                                                                            <Text smallBoldBlack noWrap >{place.end_date ? place.end_date : "Current"}</Text>
                                                                        </Grid>

                                                                        <Grid ml={4} item container lg={8} md={8} sm={6} xs={6} textAlign='center' justifyContent={'end'}>
                                                                            {
                                                                                place.status == 'In Active' ?
                                                                                    <Box className={classes.inActiveBox}>
                                                                                        <Text mediumBoldWhite>Inactive</Text>
                                                                                    </Box> :
                                                                                    <Box className={classes.activeBox}>
                                                                                        <Text mediumBoldWhite>Active</Text>
                                                                                    </Box>
                                                                            }
                                                                        </Grid>
                                                                    </Stack>

                                                                </Grid>

                                                                <Grid item lg={2.2} md={3}>
                                                                    {
                                                                        <Typography
                                                                            onClick={() => { LocalStorage.removeEmployeeVerifyId(); LocalStorage.removeRedirectedModule(); LocalStorage.removeNotificationId(); }}
                                                                            component={Link}
                                                                            to={`/placements/view-placement`}
                                                                            state={{ id: place.employee_id, placement_id: place.id }}
                                                                            className={classes.linkText}
                                                                        >
                                                                            View
                                                                        </Typography>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </AccordionSummary>
                                                    </Accordion>
                                                ))
                                            }
                                        </Box>
                                        :
                                        placements.length == 0 ?
                                            <Box>
                                                {NoDataFound("To fetch the data. Please add placements", 'No Placement Yet')}
                                            </Box>
                                            : ''
                                }
                            </>
                    }
                </Box>
            </Box>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBacKLoader}
            // onClick={handleClose}
            >
                <img src={Loader} alt="Loader" width={'100px'} height={'100px'} />
            </Backdrop>
        </Box >
    );
}