import { Box, Card, CardContent, CardHeader, Grid, Step, Stepper, Divider, Slide } from '@mui/material'
import React from 'react'
import Text from '../../../components/customText/Text';
import Button from '../../../components/customButton/Button';
import { BrownMnCustomisedConnector, BrownMnColorlibStepLabel, clientStepper, blue } from '../../../theme';
import { useState } from 'react';
import Input from '../../../components/input/Input';
import minus from '../../../assets/client/minus-circle.svg';
import ReusablePopup from '../../../components/reuablePopup/ReusablePopup';
import success from '../../../assets/client/clientSuccess.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { isValid, isValidMulti, validate_charWithSpace, validate_city, validate_emptyField, validate_contact_number, validate_zipcode, validates_emailId, empty_name, validate_optional_contact, address_validation, validate_empty_address, validate_Extension_Optional, client_name } from '../../../components/Validation';
import { useEffect } from 'react';
import CommonApi from '../../../apis/CommonApi';
import LocalStorage from '../../../utils/LocalStorage';
import ClientStyles from './ClientStyles';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { NoPermission, addErrorMsg, addLoader, addSuccessMsg, addWarningMsg, capitalize, checkZipcode, removeLoader } from '../../../utils/utils';
import FileInput from '../../../components/muiFileInput/FileInput';
import ClientsApi from '../../../apis/admin/clients/ClientsApi';
import ToggleSwitch from '../../../components/toggle/CustomToggle';
import cancelImg from '../../../assets/svg/OffBoardPop.svg';
import LoaderButton from '../../../components/customButton/LoadingButton';
import { useZipcodeAPICallMulti } from '../ZipcodeCommon';
import { ErrorMessages } from '../../../utils/ErrorMessages';
import { Buffer } from 'buffer';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Text>{children}</Text>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function AddClient() {// eslint-disable-next-line
    const usMobileNumberValidator = new RegExp(/^[a-zA-Z@~`!@#$%^&* ()_=+\\';:"\/?>.<,-]*$/);
    const classes = ClientStyles();
    const navigate = useNavigate();
    const theme = useTheme();
    const location = useLocation();
    const flowCheck = location && location.state && location.state.flow
    const [activeStep, setActiveStep] = useState(0);
    const [value, setValue] = useState(0);
    const [openCancel, setOpenCancel] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [state, setState] = useState({
        id: '',
        name: '',
        reference_id: '',
        logo_path_id: '',
        logo_name: '',
        logo_url: '',
        address: [
            {
                address_line_one: "",
                address_line_two: "",
                city: "",
                state_id: "",
                state_name: "",
                country_id: '',
                country_name: "",
                country: null,
                zip_code: "",
            },
        ]
    })

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const [error, setError] = useState({});
    const [erro, setErro] = useState([]);
    const [open, setOpen] = useState(false);
    const [placementOpen, setPlacementOpen] = useState(false);
    const [communicationError, setCommunicationError] = useState([]);

    useEffect(() => {
        if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "client_create" && item.is_allowed == true))) {
            clientIDApi();
        }
        // eslint-disable-next-line         
    }, [])

    const clientIDApi = () => {
        CommonApi.prefix("client").then((res) => {
            if (res.data.statusCode == 1003) {
                state['reference_id'] = res.data.data
                setState({ ...state });
            } else {
                addErrorMsg(res.data.message);
            }
        });
    };

    const [contacts, setContacts] = useState([
        {
            id: "",
            first_name: "",
            middle_name: "",
            last_name: "",
            email_id: "",
            telephone_number: "",
            telephone_number_ext: "",
            mobile_number: "",
            is_primary: true
        }
    ])

    const uploadDocs = (e) => {
        if (e.target.files[0].type.split('/').some(r => ['png', 'jpg', 'jpeg'].includes(r))) {
            if (((e.target.files[0].size / (1024 * 1024)).toFixed(2)) < '25') {
                const formData = new FormData();
                formData.append('files', e.target.files[0]);
                formData.append("tenant_id", LocalStorage.getUserData().tenant_id);
                addLoader(true);
                CommonApi.documentUpload("company-logo", formData, LocalStorage.getAccessToken())
                    .then((res) => {
                        if (res.data.statusCode === 1003) {
                            removeLoader();
                            state.logo_path_id = res.data.data.id
                            state.logo_name = e.target.files[0].name
                            state.logo_url = res.data.data.document_url
                            setState({ ...state })
                        } else {
                            removeLoader();
                            addErrorMsg(res.data.message);
                        }
                    });
            } else {
                addErrorMsg('File size must be 25 MB or less. Please upload a smaller file');
            }
        } else {
            addErrorMsg("Please upload files in PNG or JPG format only.");
        }
    }

    const deleteDocs = (index) => {
        state.logo_path_id = ''
        state.logo_name = ''
        state.logo_url = ''
        setState({ ...state })
    }

    const changeHandler = (e) => {
        if (e.target.name == 'name') {
            setState({
                ...state,
                [e.target.name]: capitalize(e.target.value)
            }, handleValidate(e))
        }
        else {
            setState({
                ...state,
                [e.target.name]: e.target.value
            }, handleValidate(e))
        }
    }

    const handleValidate = (e) => {
        let input = e.target;
        switch (input.name || input.tagName) {
            case "name":
                error.name = client_name(input.value, 'Client ');
                break;
            default:
                break;
        }
        let s1 = { ...error };
        setError(s1);
    }


    const validateAll = () => {
        let { name } = state;
        let errors = {};
        errors.name = client_name(name, 'Client ');
        return errors;
    }

    const validateContacts = () => {
        let errorsus = {};
        let err = [];
        contacts.map((value) => {
            errorsus = {};
            errorsus.first_name = validate_charWithSpace(value.first_name, 'first ');
            errorsus.last_name = validate_charWithSpace(value.last_name, 'last ');
            errorsus.email_id = validates_emailId(value.email_id);
            errorsus.mobile_number = validate_optional_contact(value.mobile_number, "mobile");
            errorsus.telephone_number_ext = validate_Extension_Optional(value.telephone_number_ext);
            errorsus.middle_name = empty_name(value.middle_name, 'middle ');
            errorsus.telephone_number = validate_contact_number(value.telephone_number, "Phone Number");
            return err.push(errorsus);
        });
        return err;
    };

    const companyIndex = (args) => {
        ClientsApi.companyIndexApi(args, 'client').then((res) => {
            if (res.data.statusCode === 1003) {
                setState({ ...res.data.data })
            }
        })
    }

    const handleSubmit = async() => {
        let errors = validateAll();
        let commErrors = await companyCommAddressValidations();
        let contactErrors = validateContacts();
        if (activeStep == 0) {
            if (isValid(errors) && isValidMulti(commErrors)) {
                state['request_id'] = LocalStorage.uid();
                state['company_id'] = LocalStorage.getClientID();
                state['encoded_name'] = Buffer.from(state.name).toString('base64')
                setButtonLoader(true);
                if (state.id !== '' && state.id !== null && state.id !== undefined) {                   
                    ClientsApi.updateCompany(state, 'client').then((res) => {
                        if (res.data.statusCode === 1003) {
                            setButtonLoader(false);
                            addSuccessMsg(res.data.message);
                            setActiveStep(activeStep + 1);
                            setValue(value + 1);
                        } else {
                            setButtonLoader(false);
                            addErrorMsg(res.data.message);
                        }
                    })
                } else {
                    setButtonLoader(true);
                    ClientsApi.storeCompany(state, 'client').then((res) => {
                        if (res.data.statusCode === 1003) {
                            setButtonLoader(false);
                            addSuccessMsg(res.data.message);
                            LocalStorage.setClientID(res.data.data.id);
                            setActiveStep(activeStep + 1);
                            setValue(value + 1);
                        } else {
                            setButtonLoader(false);
                            addErrorMsg(res.data.message);
                        }
                    })
                }
            } else {
                let s1 = { error };
                s1 = errors;
                let s2 = { error };
                s2 = commErrors;
                setError(s1);
                setCommunicationError(s2);
                addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
            }
        } else if (activeStep == 1) {
            if (isValidMulti(contactErrors)) {
                const data = {
                    request_id: LocalStorage.uid(),
                    company_id: LocalStorage.getClientID(),
                    contacts: contacts
                }
                ClientsApi.storeContact(data, 'client').then((res) => {
                    if (res.data.statusCode === 1003) {
                        if (flowCheck == 'placement') {
                            setPlacementOpen(true);
                        } else {
                            setOpen(true);
                        }
                    } else {
                        addErrorMsg(res.data.message);
                    }
                })
            } else {
                let s4 = { erro };
                s4 = contactErrors;
                setErro(s4);
                addWarningMsg('One or more fields are invalid. Please check the highlighted areas and correct the errors to proceed.');
            }
        }
    }

    const back = () => {
        if (activeStep == 0) {
            setOpenCancel(true);
        } else if (activeStep == 1) {
            companyIndex(LocalStorage.getClientID());
            setActiveStep(activeStep - 1);
            setValue(value - 1)
        }
        else {
            setActiveStep(activeStep - 1);
            setValue(value - 1)
        }
    }

    const addContact = (action, index) => {
        let obj = {
            id: '',
            first_name: "",
            middle_name: "",
            last_name: "",
            email_id: "",
            telephone_number: "",
            ext: "",
            mobile_number: "",
            is_primary: false
        };
        if (action == "Add") {
            contacts.push(obj);
        }
        else if (action == 'Remove') {
            if (contacts[index].id == '' || contacts[index].id == undefined || contacts[index].id == null) {
                if (contacts[index].is_primary == true) {
                    if (index == 1) {
                        contacts[0].is_primary = true;
                    } else {
                        contacts[index - 1].is_primary = true;
                    }
                }
                contacts.splice(index, 1);
            } else {
                const data = {
                    request_id: LocalStorage.uid(),
                    company_id: LocalStorage.getClientID()
                }
                ClientsApi.destroyContact('client', contacts[index].id, data).then((res) => {
                    if (res.data.statusCode == 1003) {
                        contacts.splice(index, 1);
                        setContacts([...contacts])
                    }
                    else {
                        addErrorMsg(res.data.message);
                    }
                })
            }
        }
        setContacts([...contacts])
    }

    const handleValidateContact = (e, index) => {
        let input = e.target;
        let error = erro.length > 0 ? (erro ? erro[index] : erro) : erro;
        for (var k = 0; k <= index; k++) {
            erro.push({});
        }
        let s2 = erro.length > 0 ? [...erro] : [{ ...erro }];
        switch (input.name || input.tagName) {
            case "mobile_number":
                if (input.value == '' && contacts[index].telephone_number == '') {
                    error.mobile_number = ''
                } else if (input.value !== '' && input.value == contacts[index]['telephone_number']) {
                    error.mobile_number = "mobile number and phone number should Not be Same"
                } else {
                    error.mobile_number = usMobileNumberValidator.test(input.value) ? '' : validate_optional_contact(input.value, "mobile");
                }
                break;
            case "telephone_number":
                if (input.value == '' && contacts[index].mobile_number == '') {
                    error.telephone_number = ''
                } else if (input.value !== '' && input.value == contacts[index]['mobile_number']) {
                    error.telephone_number = "mobile number and phone number should Not be Same"
                } else {
                    error.telephone_number = usMobileNumberValidator.test(input.value) ? '' : validate_contact_number(input.value, 'phone number')
                }
                break;
            case "telephone_number_ext":
                error.telephone_number_ext = validate_Extension_Optional(input.value);
                break;
            case "first_name":
                error.first_name = validate_charWithSpace(input.value, 'first ');
                break;
            case "middle_name":
                error.middle_name = empty_name(input.value, 'middle ');
                break;
            case "last_name":
                error.last_name = validate_charWithSpace(input.value, 'last ');
                break;
            case "email_id":
                error.email_id = validates_emailId(input.value);
                break;
            default:
                break;
        }
        setErro(s2);
    };

    const { zipcodeAPICallMulti } = useZipcodeAPICallMulti(state, setState, communicationError, setCommunicationError);

    const communicationChangeHandler = (e, value, index) => {
        state.address[index][e.target.name] = e.target.value;
        setState(state, handleValidateCommunicationAdd(e.target, index));
        if (e.target.name == 'zip_code') {
            zipcodeAPICallMulti(e.target.value, index, 'address')
        }
    };


    const handleValidateCommunicationAdd = (e, index) => {
        let input = e;
        let error =
            communicationError.length > 0
                ? communicationError
                    ? communicationError[index]
                    : communicationError
                : communicationError;
        for (var k = 0; k <= index; k++) {
            communicationError.push({});
        }
        let s1 =
            communicationError.length > 0
                ? [...communicationError]
                : [{ ...communicationError }];
        switch (input.name || input.tagName) {
            case "address_line_one":
                error.address_line_one = address_validation(input.value);
                break;
            case "address_line_two":
                error.address_line_two = validate_empty_address(input.value);
                break;
            case "city":
                error.city = validate_city(input.value);
                break;
            case "state_id":
                error.state_id = validate_emptyField(input.value);
                break;
            case "country_id":
                error.country_id = validate_emptyField(input.value);
                break;
            case "zip_code":
                error.zip_code = validate_zipcode(input.value, state.address[index].country_id);
                break;
            default:
                break;
        }
        setCommunicationError(s1);
    };

    const companyCommAddressValidations = async () => {
        let errors = [];
        
        const validationPromises = state.address.map(async (value) => {
            let error = {};
            error.address_line_one = address_validation(value.address_line_one);
            error.address_line_two = validate_empty_address(value.address_line_two);
            error.state_id = validate_emptyField(value.state_id);
            error.city = validate_city(value.city);
            error.zip_code = await checkZipcode(value.zip_code);  // Await the async operation
            
            return error;
        });
    
        // Wait for all the validation promises to resolve
        errors = await Promise.all(validationPromises);
        
        // Set the state with the accumulated errors
        setCommunicationError(errors);
    
        return errors;
    };

    const handleChange = (e, index) => {
        const targetName = e.target.name;
        if (targetName == "telephone_number" || targetName == "mobile_number") {
            convertFormat(e, index)
        }
        else if (targetName == 'is_primary') {
            for (let i = 0; i < contacts.length; i++) {
                if (i === index) {
                    contacts[i]['is_primary'] = e.target.checked;
                } else {
                    contacts[i]['is_primary'] = !e.target.checked
                }
            }
            setContacts([...contacts]);
            handleValidateContact(e, index);
        }
        else if (e.target.name == 'first_name' || e.target.name == 'last_name' || e.target.name == 'middle_name') {
            contacts[index][e.target.name] = capitalize(e.target.value);
            setContacts([...contacts]);
            handleValidateContact(e, index)
        }
        else {
            contacts[index][e.target.name] = e.target.value;
            setContacts([...contacts]);
            handleValidateContact(e, index)
        }
    };

    const convertFormat = (e, index) => {
        const value = e.target.value;
        const name = e.target.name
        const input = value.replace(/\D/g, '').substring(0, 10);
        // Divide numbers in 3 parts :"(123) 456-7890" 
        const first = name == 'mobile_number' || name == 'telephone_number' ? input.substring(0, 3) : input.substring(0, 3);
        const middle = name == 'mobile_number' || name == 'telephone_number' ? input.substring(3, 6) : input.substring(3, 5);
        const last = name == 'mobile_number' || name == 'telephone_number' ? input.substring(6, 10) : input.substring(5, 9);

        if (input.length > (name == 'mobile_number' || name == 'telephone_number' ? 6 : 5)) {
            contacts[index][e.target.name] = `${first}-${middle}-${last}`
            setContacts([...contacts], handleValidateContact(e, index))
        }
        else if (input.length > 3) {
            contacts[index][e.target.name] = `${first}-${middle}`
            setContacts([...contacts], handleValidateContact(e, index))
        }
        else if (input.length >= 0) {
            contacts[index][e.target.name] = input
            setContacts([...contacts], handleValidateContact(e, index))
        }
    }

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="left" ref={ref} {...props} />;
    });

    const stepLabels = ['Client Details', 'Contact Details']
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';

    return (
        <Grid container justifyContent='center' pb={5}>
            {
                (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "client_create" && item.is_allowed == true))) ?
                    <>
                        <Grid item container lg={7} md={7} justifyContent='center' position='fixed' zIndex='1' sx={{ background: '#FFFFFF' }}>
                            <Grid item lg={6} md={6} pt={2} textAlign='center' p={'30px 0px !important'}>
                                <Stepper activeStep={activeStep}
                                    connector={<BrownMnCustomisedConnector />}
                                >
                                    {
                                        stepLabels.map((item) => (
                                            <Step>
                                                <BrownMnColorlibStepLabel StepIconComponent={clientStepper}>
                                                    <Text BrowmnMnStepperText>{item}</Text>
                                                </BrownMnColorlibStepLabel>
                                            </Step>
                                        ))
                                    }
                                </Stepper>
                            </Grid>
                        </Grid>
                        <Grid item lg={6} md={8} pt={8}>
                            <SwipeableViews index={value} axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} onChangeIndex={handleChangeIndex}>
                                <TabPanel value={value} index={0} dir={theme.direction}>
                                    <Card sx={{ padding: '10px !important', boxShadow: '0px 0px 20px 1px #0000000D !important', borderRadius: '12px !important' }}>
                                        <CardHeader sx={{ padding: '20px 0px 0px 28px !important' }} title={
                                            <Text headerBlack>Client Details</Text>
                                        } />
                                        <CardContent
                                            TransitionComponent={Transition}
                                            sx={{ padding: '30px 30px 30px 30px !important' }}
                                        >
                                            {
                                                activeStep == 0 &&
                                                <Grid item container spacing={3} lg={12} md={12} sm={12} xs={12}>
                                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                                        <Input
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                name: 'name',
                                                                value: state.name,
                                                                inputProps: { maxLength: 50 },
                                                            }}
                                                            handleChange={changeHandler}
                                                            clientInput
                                                            labelText={'Client Name'}
                                                            error={error.name}
                                                            helperText={
                                                                error.name &&
                                                                <span className={classes.helperTextError}>{error.name}</span>
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                                        <Input
                                                            formControlProps={{
                                                                fullWidth: true
                                                            }}
                                                            inputProps={{
                                                                name: 'reference_id',
                                                                value: state.reference_id,
                                                            }}
                                                            disabled={true}
                                                            handleChange={changeHandler}
                                                            clientInput
                                                            labelText='Client ID'
                                                        />
                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                                        <Box pt={'11px'}>
                                                            <FileInput
                                                                name='logo_path_id'
                                                                FileName={state.logo_name}
                                                                handleChange={(e) => uploadDocs(e)}
                                                                label='Client Logo (Optional)'
                                                                handleDelete={deleteDocs}
                                                                actionState={state.logo_name ? 1 : ''}
                                                                documentUrl={state.logo_url}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    {
                                                        state.address.map((item, index) => (
                                                            <>
                                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                                    <Input
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        inputProps={{
                                                                            name: 'address_line_one',
                                                                            value: item.address_line_one,
                                                                            inputProps: { maxLength: 225 },
                                                                        }}
                                                                        handleChange={(e, val) => { communicationChangeHandler(e, val, index) }}
                                                                        clientInput
                                                                        labelText='Address Line 1'
                                                                        error={communicationError.length > 0 &&
                                                                            communicationError[index] && communicationError[index].address_line_one
                                                                        }
                                                                        helperText={
                                                                            communicationError.length > 0 &&
                                                                            communicationError[index] && communicationError[index].address_line_one &&
                                                                            <span className={classes.helperTextError}>{communicationError[index].address_line_one}</span>
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                                    <Input
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        inputProps={{
                                                                            name: 'address_line_two',
                                                                            value: item.address_line_two,
                                                                            inputProps: { maxLength: 225 },
                                                                        }}
                                                                        handleChange={(e, val) => { communicationChangeHandler(e, val, index) }}
                                                                        clientInput
                                                                        labelText='Address Line 2 (Optional)'
                                                                        error={communicationError.length > 0 &&
                                                                            communicationError[index] && communicationError[index].address_line_two
                                                                        }
                                                                        helperText={
                                                                            communicationError.length > 0 &&
                                                                            communicationError[index] && communicationError[index].address_line_two &&
                                                                            <span className={classes.helperTextError}>{communicationError[index].address_line_two}</span>
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                                                    <Input
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        inputProps={{
                                                                            name: 'zip_code',
                                                                            value: item.zip_code,
                                                                            disabled: item.country_id === '',
                                                                            inputProps: { maxLength: 5 }
                                                                        }}
                                                                        handleChange={(e, val) => { communicationChangeHandler(e, val, index) }}
                                                                        clientInput
                                                                        labelText={'Zipcode'}
                                                                        error={
                                                                            communicationError.length > 0 &&
                                                                            communicationError[index] && communicationError[index].zip_code && communicationError[index].zip_code
                                                                        }
                                                                        helperText={
                                                                            communicationError.length > 0 &&
                                                                            communicationError[index] && communicationError[index].zip_code &&
                                                                            <span className={classes.helperTextError}>{communicationError[index].zip_code == ErrorMessages.fieldRequired ? communicationError[index].zip_code : ErrorMessages.invalidZipcode }</span>
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                                                    <Input
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        inputProps={{
                                                                            name: 'city',
                                                                            value: item.city,
                                                                            inputProps: { maxLength: 50 },
                                                                        }}
                                                                        handleChange={(e, val) => { communicationChangeHandler(e, val, index) }}
                                                                        clientInput
                                                                        labelText='City'
                                                                        error={
                                                                            communicationError.length > 0 &&
                                                                            communicationError[index] && communicationError[index].city
                                                                        }
                                                                        helperText={
                                                                            communicationError.length > 0 &&
                                                                            communicationError[index] && communicationError[index].city &&
                                                                            <span className={classes.helperTextError}>{communicationError[index].city}</span>
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                                                    <Input
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        inputProps={{
                                                                            name: 'state_name',
                                                                            value: item.state_name || '',
                                                                        }}
                                                                        disabled={true}
                                                                        clientInput
                                                                        labelText='State'
                                                                    />
                                                                </Grid>
                                                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                                                    <Input
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        inputProps={{
                                                                            name: 'country_name',
                                                                            value: item.country_name || '',
                                                                        }}
                                                                        disabled={true}
                                                                        clientInput
                                                                        labelText='Country'
                                                                    />
                                                                </Grid>


                                                            </>
                                                        ))
                                                    }

                                                </Grid>
                                            }
                                        </CardContent>
                                    </Card>
                                </TabPanel>
                                <TabPanel value={value} index={1} dir={theme.direction}>
                                    <Card sx={{ padding: '10px !important', boxShadow: '0px 0px 20px 1px rgba(0, 0, 0, 0.05)', borderRadius: '12px !important' }}>
                                        <CardHeader sx={{ padding: '15px 0px 0px 25px !important' }} title={
                                            <Text headerBlack>Contact Details {contacts.length == 1 && <span className={classes.optional}>(Primary)</span>}</Text>
                                        } />
                                        <CardContent
                                            TransitionComponent={Transition}
                                            sx={{ padding: '20px 30px 30px 30px !important' }}
                                        >
                                            {
                                                activeStep == 1 &&
                                                contacts.map((item, index) => (
                                                    <Grid container spacing={3} pt={index > 0 ? '15px' : ''}>
                                                        {
                                                            index > 0 &&
                                                            <><Grid item container lg={12} md={4} mt={2}>
                                                                <Grid item lg={8} md={8}>
                                                                    <Text largeBlack>Contact Details - {index + 1}</Text>
                                                                </Grid>
                                                                <Grid item lg={4} md={4} textAlign='end'>
                                                                    <img src={minus} alt='Minus' style={{ cursor: 'pointer' }} onClick={() => addContact('Remove', index)} />
                                                                </Grid>
                                                            </Grid><Divider className={classes.dividerContact} /></>
                                                        }
                                                        <Grid item lg={6} md={6}>
                                                            <Input
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                inputProps={{
                                                                    name: 'first_name',
                                                                    value: item.first_name,
                                                                    inputProps: { maxLength: 33 }
                                                                }}
                                                                handleChange={(e) => handleChange(e, index)}
                                                                clientInput
                                                                labelText='First Name'
                                                                error={
                                                                    erro.length > 0 &&
                                                                    erro[index] && erro[index].first_name
                                                                }
                                                                helperText={
                                                                    erro.length > 0 &&
                                                                    erro[index] && erro[index].first_name &&
                                                                    <span className={classes.helperTextError}>{erro[index].first_name}</span>
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item lg={6} md={6}>
                                                            <Input
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                inputProps={{
                                                                    name: 'middle_name',
                                                                    value: item.middle_name,
                                                                    inputProps: { maxLength: 33 }
                                                                }}
                                                                error={
                                                                    erro.length > 0 &&
                                                                    erro[index] && erro[index].middle_name
                                                                }
                                                                handleChange={(e) => handleChange(e, index)}
                                                                clientInput
                                                                labelText='Middle Name (Optional)'
                                                                helperText={
                                                                    erro.length > 0 &&
                                                                    erro[index] && erro[index].middle_name &&
                                                                    <span className={classes.helperTextError}>{erro[index].middle_name}</span>
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item lg={6} md={6}>
                                                            <Input
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                inputProps={{
                                                                    name: 'last_name',
                                                                    value: item.last_name,
                                                                    inputProps: { maxLength: 33 }
                                                                }}
                                                                handleChange={(e) => handleChange(e, index)}
                                                                clientInput
                                                                labelText='Last Name'
                                                                error={
                                                                    erro.length > 0 &&
                                                                    erro[index] && erro[index].last_name
                                                                }
                                                                helperText={
                                                                    erro.length > 0 &&
                                                                    erro[index] && erro[index].last_name &&
                                                                    <span className={classes.helperTextError}>{erro[index].last_name}</span>
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item lg={6} md={6}>
                                                            <Input
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                inputProps={{
                                                                    name: 'email_id',
                                                                    value: item.email_id,
                                                                }}
                                                                handleChange={(e) => handleChange(e, index)}
                                                                clientInput
                                                                labelText='Email ID'
                                                                error={
                                                                    erro.length > 0 &&
                                                                    erro[index] && erro[index].email_id
                                                                }
                                                                helperText={
                                                                    erro.length > 0 &&
                                                                    erro[index] && erro[index].email_id &&
                                                                    <span className={classes.helperTextError}>{erro[index].email_id}</span>
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item lg={6} md={6}>
                                                            <Input
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                inputProps={{
                                                                    name: 'telephone_number',
                                                                    value: item.telephone_number,
                                                                    inputProps: { maxLength: 12 },
                                                                }}
                                                                handleChange={(e) => handleChange(e, index)}
                                                                clientInput
                                                                labelText='Phone Number'
                                                                error={
                                                                    erro.length > 0 &&
                                                                    erro[index] && erro[index].telephone_number
                                                                }
                                                                helperText={
                                                                    erro.length > 0 &&
                                                                    erro[index] && erro[index].telephone_number &&
                                                                    <span className={classes.helperTextError}>{erro[index].telephone_number}</span>
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item lg={6} md={6}>
                                                            <Input
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                inputProps={{
                                                                    name: 'telephone_number_ext',
                                                                    value: item.telephone_number_ext,
                                                                    inputProps: { maxLength: 6 },
                                                                }}
                                                                handleChange={(e) => handleChange(e, index)}
                                                                clientInput
                                                                labelText='Extension (Optional)'
                                                                error={
                                                                    erro.length > 0 &&
                                                                    erro[index] && erro[index].telephone_number_ext
                                                                }
                                                                helperText={
                                                                    erro.length > 0 &&
                                                                    erro[index] && erro[index].telephone_number_ext &&
                                                                    <span className={classes.helperTextError}>{erro[index].telephone_number_ext}</span>
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item lg={6} md={6}>
                                                            <Input
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                inputProps={{
                                                                    name: 'mobile_number',
                                                                    value: item.mobile_number,
                                                                    inputProps: { maxLength: 12 },
                                                                }}
                                                                handleChange={(e) => handleChange(e, index)}
                                                                clientInput
                                                                labelText='Mobile Number (Optional)'
                                                                error={
                                                                    erro.length > 0 &&
                                                                    erro[index] && erro[index].mobile_number
                                                                }
                                                                helperText={
                                                                    erro.length > 0 &&
                                                                    erro[index] && erro[index].mobile_number &&
                                                                    <span className={classes.helperTextError}>{erro[index].mobile_number}</span>
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item container lg={12} m='10px 0px 10px 10px !important'>
                                                            <Grid item lg={12}>
                                                                <ToggleSwitch name='is_primary' isActive={item.is_primary} disable={contacts.length == 1} switchChange={(e) => handleChange(e, index)} sx={{ height: '24px !important' }} label={<Text largeLabel sx={{ paddingLeft: '10px' }}>Make This as Primary Contact</Text>} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                ))
                                            }
                                            {
                                                contacts.length <= 4 &&
                                                <Grid item lg={12} m={'25px 0px 0px 0px !important'}>
                                                    <Button lightBlue onClick={() => addContact('Add')}>Add New Contact</Button>
                                                </Grid>
                                            }
                                        </CardContent>
                                    </Card>
                                </TabPanel>
                            </SwipeableViews>
                            <Grid item container p={'10px 20px 0px 20px'}>
                                <Grid item lg={6} md={6}>
                                    <Button blackCancelBtn onClick={back}>{activeStep == 0 ? 'Cancel' : 'Back'}</Button>
                                </Grid>
                                <Grid item lg={6} md={6} textAlign='end'>
                                    <LoaderButton loading={buttonLoader} saveNcontinueSmall onClick={handleSubmit}>{activeStep == 1 ? 'Finish' : 'Save & Continue'}</LoaderButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                    :
                    <Grid container>
                        <Box sx={{ marginLeft: "100px" }}>
                            <Text mediumViewAmt >Add Client</Text>
                        </Box>
                        {NoPermission()}
                    </Grid>
            }

            <ReusablePopup iconHide openPopup={open} setOpenPopup={setOpen} white statusWidth>
                <Box textAlign='center' p={'0px 20px 0px 20px'}>
                    <img src={success} alt='success' />
                    <Text veryLargeLabel sx={{ paddingTop: '25px !important' }}>Succesfully Added!</Text>
                    <Text mediumLabel sx={{ padding: '10px 0px 30px 0px !important' }}>You Have Successfully Added Client <span style={{ color: `${blue}` }}>{state && state.name}</span>.</Text>
                    <Button onClick={() => navigate('/clients')} blueButton>Go To Home</Button>
                </Box>
            </ReusablePopup>
            <ReusablePopup iconHide openPopup={openCancel} setOpenPopup={setOpenCancel} white fixedWidth>
                <Box textAlign='center' p={'0px 20px 0px 20px'}>
                    <img src={cancelImg} alt='success' />
                    <Text popupHead1 sx={{ paddingTop: '25px !important' }}>Are you Sure?</Text>
                    <Text mediumOverView sx={{ padding: '10px 0px 30px 0px !important' }}>Do you want to cancel adding this client?<br /> Please be aware that any data you've<br /> entered may be lost.</Text>

                    <Grid item container lg={12} columnSpacing={2} justifyContent='center'>
                        <Grid item lg={5}>
                            <Button onClick={() => setOpenCancel(false)} redBorder>No</Button>
                        </Grid>
                        <Grid item lg={5}>
                            <Button onClick={() => navigate('/clients', { state: { page: 'Client' } })} blueButton>Yes</Button>
                        </Grid>
                    </Grid>
                </Box>
            </ReusablePopup>
            <ReusablePopup iconHide openPopup={placementOpen} setOpenPopup={setPlacementOpen} white statusWidth>
                <Box textAlign='center' p={'0px 20px 0px 20px'}>
                    <img src={success} alt='success' />
                    <Text veryLargeLabel sx={{ paddingTop: '25px !important' }}>Succesfully Added!</Text>
                    <Text mediumLabelColor className={flowCheck == 'placement' ? classes.padding8 : classes.padding}>You have successfully added Client <span style={{ color: `${blue}`, textTransform: 'capitalize' }}>{state && state.name}</span>.</Text>
                    {
                        (flowCheck === 'placement') && <Text mediumLabelColor className={classes.paddingTop}>You can now add placement to this client</Text>
                    }
                    <Button onClick={() => { flowCheck == 'placement' ? navigate('/placements/placementsInfo') : navigate('/clients') }} blueButton>{flowCheck == 'placement' ? 'Go To Placement' : 'Go To Home'}</Button>
                </Box>
            </ReusablePopup>
        </Grid >
    )
}

export default AddClient