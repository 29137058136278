import React from "react";
import { FormControl, FormHelperText, IconButton, Select } from "@mui/material";
import classNames from "classnames";
import CustomSelectStyles from "./CustomSelectStyles";
import PropTypes from "prop-types";
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';
import MenuItem from "@mui/material/MenuItem";
import InputLabel from '@mui/material/InputLabel';
import clear from '../../assets/employee/bluecross.svg';
import { Box } from "@mui/material";

const Menu = {
  disableScrollLock: true,
  PaperProps: {
    style: {
      font: "13px Quicksand",
      maxHeight: '200px',
      opacity: 1,
      // overflow: 'auto',
      whiteSpace: "normal",
      paddingBottom: "0px !important",
      marginTop: '2px',
      boxShadow: '0px 0px 15px 0px #0000001F',
    },
    elevation: 0,
    padding: '0px !important'
  },
  sx: {

    "& .MuiList-root": {
      padding: '0px !important',
    },
    "&::-webkit-scrollbar": {
      display: "none !important"
    },
    "& .MuiMenuItem-root": {
      padding: "17px 16px !important",
      font: "13px  Quicksand !important",
      fontWeight: `${400} !important`,
      color: "#262626 !important",
      height: '50px !important',
      borderBottom: '1px solid #EAECF0 !important',
      "&:hover": {
        background: '#0001F !important'
      },
    },

    "& .Mui-selected": {
      backgroundColor: 'white !important'
    }
  }
}
function CustomSelect(props) {
  const classes = CustomSelectStyles();
  const {
    icon,
    name,
    value,
    onChange,
    children,
    className,
    defaultSelect,
    borderSelect,
    yearSelect,
    borderBlueSelectWidth,
    borderWhiteSelect,
    defaultYearSelect,
    navYearSelect,
    scrollTrue,
    defaultSelectOrg,
    handleClearClick,
    clearShow,
    backgroundBlue,
    whiteSelect,
    cardSelect,
    cardSelectGray,
    options,
    commonSelect,
    commonSelectBorderBlue,
    viewDrop,
    viewDrop1,
    label,
    disabled, helperText, Icon, placeholder,
    error,
    ...rest
  } = props;

  /**
   * @parem label: - target label for input
   * @parem name: - target name for input
   * @parem value:- target value
   * @parem onChange: - onChange functinality
   * @parem chidren : - expected type node better to pass options in the place of children
   * @parem options : - expected array of objects with label and value keys
   * ------------------
   * @parem Remaining all the props for input styles
   * */

  const styleClasses = classNames({
    [className]: className,
    [classes.defaultSelect]: defaultSelect,
    [classes.borderSelect]: borderSelect,
    [classes.yearSelect]: yearSelect,
    [classes.defaultYearSelect]: defaultYearSelect,
    [classes.borderWhiteSelect]: borderWhiteSelect,
    [classes.navYearSelect]: navYearSelect,
    [classes.defaultSelectOrg]: defaultSelectOrg,
    [classes.borderBlueSelectWidth]: borderBlueSelectWidth,
    [classes.backgroundBlue]: backgroundBlue,
    [classes.whiteSelect]: whiteSelect,
    [classes.cardSelect]: cardSelect,
    [classes.cardSelectGray]: cardSelectGray,
    [classes.commonSelect]: commonSelect,
    [classes.commonSelectBorderBlue]: commonSelectBorderBlue,
    [classes.viewDrop]: viewDrop,
    [classes.scrollTrue]: scrollTrue,
    [classes.viewDrop1]: viewDrop1
  });


  // const [searchVal, setSearchVal] = useState("");

  // const handleSearch = (e) => {
  //   setSearchVal(e.target.value)
  // }

  return (
    <div>
      {
        clearShow ?
          <FormControl fullWidth variant="filled">
            <InputLabel id='demo-simple-select-filled-label' sx={{ color: value || value == 0 ? '#0C75EB !important' : error ? 'red !important' : '#737373' }} className={classes.inputLabel}>{label}</InputLabel>            <Select
              labelId="demo-simple-select-filled-label"
              name={name}
              value={value}
              onChange={onChange}
              endAdornment={
                !icon ?
                  <IconButton sx={{
                    visibility: clearShow ? "visible" : "hidden", marginRight: '17px !important',
                    "&:hover": {
                      background: 'none !important'
                    }
                  }} onClick={handleClearClick}>
                    <img src={clear} alt="clear" sx={{ height: '13px !important', width: '13px !important', }} />
                  </IconButton> : ''
              }
              IconComponent={Icon ? Icon : KeyboardArrowDownTwoToneIcon}
              variant="filled"
              disableUnderline
              fullWidth
              disabled={disabled}
              {...rest}
              className={classes.clear}
              sx={{
                "& .MuiSelect-select": {
                  border: error ? '1px solid red !important' : '1px solid #C7CCD3'
                }
              }}
              MenuProps={Menu}
            >
              {options ? options.map((item) => (
                <MenuItem key={item.id} value={item.id} style={{ padding: "6px 6px 0px 6px" }}>
                  {/* {item.value ? item.value : item.days ? item.days : ""} */}
                  {item.value ? item.value : null}
                  {item.days ? item.days : null}
                  {item.name ? item.name : null}
                  {item.parameter ? item.parameter : null}
                  {item.date ? item.date : null}
                </MenuItem>
              )) : (<MenuItem ></MenuItem>)}
            </Select>
            <FormHelperText>{helperText}</FormHelperText>
          </FormControl>
          :
          <FormControl fullWidth variant="filled">
            <InputLabel id='demo-simple-select-filled-label' sx={{ color: value || (name == 'is_usc' && value === 0) ? '#0C75EB !important' : error ? 'red !important' : '#737373' }} className={classes.inputLabel}>{label}</InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              name={name}
              value={value}
              onChange={onChange}
              IconComponent={Icon ? Icon : KeyboardArrowDownTwoToneIcon}
              variant="filled"
              disableUnderline
              fullWidth
              disabled={disabled}
              sx={{
                "& .MuiSelect-select": {
                  border: error ? '1px solid red !important' : '1px solid #C7CCD3'
                }
              }}
              {...rest}
              className={styleClasses}
              MenuProps={Menu}
            >
              {options ? options.map((item) => (
                <MenuItem key={item.id} value={item.id} style={{ padding: "2px" }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between',width:"-webkit-fill-available" }}>
                    <span> {item.value ? item.value : null}</span>
                    {item.days ? item.days : null}
                    <span>{item.name ? item.name : null}</span>
                    {item.parameter ? item.parameter : null}
                    {item.date ? item.date : null}
                  </Box>
                </MenuItem>
              )) : (<MenuItem ></MenuItem>)}
            </Select>
            <FormHelperText>{helperText}</FormHelperText>
          </FormControl>
      }

    </div>
  );
}

export default CustomSelect;

CustomSelect.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  defaultSelect: PropTypes.bool,
  borderSelect: PropTypes.bool,
  yearSelect: PropTypes.bool,
  defaultYearSelect: PropTypes.bool,
  borderWhiteSelect: PropTypes.bool,
  navYearSelect: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.any,
  scrollTrue: PropTypes.any,
  defaultSelectOrg: PropTypes.any,
  handleClearClick: PropTypes.func,
  clearShow: PropTypes.bool,
  icon: PropTypes.bool,
  borderBlueSelectWidth: PropTypes.bool,
  backgroundBlue: PropTypes.bool,
  whiteSelect: PropTypes.bool,
  cardSelect: PropTypes.bool,
  cardSelectGray: PropTypes.bool,
  commonSelect: PropTypes.bool,
  commonSelectBorderBlue: PropTypes.bool,
  viewDrop: PropTypes.bool,
  label: PropTypes.string,
  viewDrop1: PropTypes.bool,
  helperText: PropTypes.any,
  Icon: PropTypes.bool,
  placeholder: PropTypes.bool,
  error: PropTypes.any
};
