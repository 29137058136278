import { Box, Grid, Hidden } from "@mui/material";
import React, { useState, useEffect } from 'react';
import { ForgotPasswordStyles } from "./ForgotPasswordStyles";
import Text from "../../components/customText/Text";
import CustomInput from '../../components/input/Input';
import { isValid, validates_emailId } from "../../components/Validation";
import ReusablePopup from "../../components/reuablePopup/ReusablePopup";
import { domain } from "../../config/Domain";
import LocalStorage from "../../utils/LocalStorage";
import CommonApi from "../../apis/CommonApi";
import { addErrorMsg, addSuccessMsg } from "../../utils/utils";
import OTP from "../otp/OTP";
import { LoadingButton } from "@mui/lab";
import Logo from '../../assets/logo.svg';
// import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Image1 from "../../assets/video/Images(1).png"
import Image2 from "../../assets/video/Images(2).png"
// import Image3 from "../../assets/video/Images(3).png"
import Image4 from "../../assets/video/Images(4).png"
import logo from '../../assets/svg/logoName.svg'
import attempts from '../../assets/svg/attempts.svg'

/*fun starts here*/
function ForgotPassword() {
  // const navigate = useNavigate()
  const classes = ForgotPasswordStyles();
  const [state, setState] = useState({
    emailId: "",
  });
  const location = useLocation()
  const [error, setError] = useState({});
  const [openPopup, setOpenPopup] = useState(false);
  const [status, setStatus] = useState('create')
  const [loader, setLoader] = useState(false);
  /*handle change fun*/
  const changeHandler = (e) => {
    setState(
      {
        ...state,
        [e.target.name]: e.target.value,
      },
      handlevalidate(e)
    );
  };

  const handlevalidate = (e) => {
    let input = e.target;
    let err = { ...error };
    switch (input.name || input.tagName) {
      case "emailId":
        error.emailId = validates_emailId(input.value);
        break;
      default:
        break;
    }
    setError(err);
  };

  /*email validation */
  const emailValidations = () => {
    let { emailId } = state;
    let errors = {};
    errors.emailId = validates_emailId(emailId);
    return errors;
  };

  /*submit fun*/
  const handleSubmit = (e) => {
    e.preventDefault()
    setLoader(true)
    let domainName = location.state
    const data = {
      request_id: LocalStorage.uid(),
      email_id: state.emailId,
      subdomain_name: domainName != null ? domainName.domain : domain,
    };
    let errors = emailValidations();
    if (isValid(errors)) {
      CommonApi.ForgotPassword(data)
        .then((response) => {
          if (response.data.statusCode == 1003) {
            // setOpenPopup(true);
            addSuccessMsg("Email sent"); //success msg Alert
            setLoader(false)
            setStatus('read')
          } else {
            if (response.data.message == 'Reset Password Link can be sent only 3 times within a 24-hour period.') {
              setStatus('fail')
            }
            else {
              addErrorMsg(response.data.message);
            }
            setLoader(false)
            // warning msg Alert
          }
        })
    } else {
      setLoader(false)
      let err = { error };
      err = errors;
      setError(err);
    }
  };

  const ImagesList = [
    Image1,
    Image2,
    // Image3,
    Image4
  ];
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    // Set an interval to update the current image every 3 seconds
    const intervalId = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % ImagesList.length);
    }, 3000);

    // Clear the interval when component unmounts
    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, []);


  return (
    <div>
      {
        status == 'fail' ?
          <Grid container>
            <Grid item xs={12}>
              <img src={logo} alt='logo' />
            </Grid>
            <Grid item xs={12}>
              <Box height={'15vh'} />
            </Grid>
            <Grid item container xs={12} justifyContent={'center'} alignItems={'center'}>
              <img src={attempts} alt='attempts' />
            </Grid>
            <Grid item container sm={12} xs={12} justifyContent={'center'}>
              <Box p={1} pt={4}>
                <Text popupHead1 sx={{ color: '#090914 !important' }}>Out of attempts!</Text>
              </Box>
            </Grid>
            <Grid item container sm={12} xs={12} justifyContent={'center'}>
              <Box p={1} pb={0}>
                <Text grey16>It looks like you've exceeded the verification limit.</Text>
              </Box>
            </Grid>
            <Grid item container sm={12} xs={12} justifyContent={'center'}>
              <Box >
                <Text grey16>Please wait for 24hrs or provide a detailed explanation</Text>
              </Box>
            </Grid>
            <Grid item container sm={12} xs={12} justifyContent={'center'}>
              <Box >
                <Text grey16>of the problem in E-mail so that our sales staff can </Text>
              </Box>
            </Grid>
            <Grid item container sm={12} xs={12} justifyContent={'center'}>
              <Box >
                <Text grey16>respond asap.</Text>
              </Box>
            </Grid>
            <Grid item container sm={12} xs={12} justifyContent={'center'} mt={1}>
              <Box className={classes.buttonStyles}>
                <LoadingButton
                  className={classes.loginButton1}
                  loading={loader}
                  variant="contained"
                  type="submit"
                  href={'mailto:support@japfu.ai'}
                >
                  Send E-mail
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
          :
          <Grid container sx={{ height: '100vh', overflow: 'hidden' }}>
            <Hidden mdDown>
              <Grid item lg={6} md={6} sm={12} xs={12} sx={{
                "@media (min-width: 300px) and (max-width: 1110px)": {
                  height: 'fit-content'
                },
                height: '100vh',
                overflow: 'hidden'
              }}>
                <img src={ImagesList[currentImage]} alt={`img-${currentImage}`} width="100%" />
              </Grid>
            </Hidden>
            <Grid item container xl={1.5} lg={1.5} md={1.5} sm={12} xs={12}></Grid>
            <Grid item container xl={4} lg={4} md={4} sm={12} xs={12}>
              {
                status == 'create' ?
                  <form onSubmit={(e) => handleSubmit(e)}>
                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                      <Box height={'25vh'} />
                    </Grid>
                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                      <Box p={1}>
                        <img src={Logo} alt='logo' />
                      </Box>
                    </Grid>
                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                      <Box p={1}>
                        <Text popupHead1>Forgot Password</Text>
                      </Box>
                    </Grid>
                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                      <Box p={1} pb={0}>
                        <Text grey16>Please provide your registered email address to</Text>
                      </Box>
                    </Grid>
                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                      <Box pb={1}>
                        <Text grey16>change password.</Text>
                      </Box>
                    </Grid>
                    <Grid item sm={12} xs={12} pt={2}>
                      <Box p={1}>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            name: "emailId",
                            value: state.emailId,
                          }}
                          clientInput
                          handleChange={changeHandler}
                          labelText={'Email Address'}
                          error={error.emailId}
                        />
                        {error.emailId ? <Text red>{error.emailId}</Text> : ""}
                      </Box>

                    </Grid>
                    <Grid item sm={12} xs={12} p={1}>
                      <Box className={classes.buttonStyles}>
                        <LoadingButton
                          className={classes.loginButton}
                          loading={loader}
                          variant="contained"
                          type="submit"
                          onClick={(e) => { handleSubmit(e) }}
                          fullWidth
                        >
                          Submit
                        </LoadingButton>
                      </Box>
                    </Grid>
                  </form>
                  :

                  <form>
                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                      <Box height={'25vh'} />
                    </Grid>
                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                      <Box p={1}>
                        <img src={Logo} alt='logo' />
                      </Box>
                    </Grid>
                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                      <Box p={1}>
                        <Text popupHead1>Forgot Password</Text>
                      </Box>
                    </Grid>
                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                      <Box p={1} pb={0}>
                        <Text grey16>We have sent an E-mail to your registered E-mail Id to</Text>
                      </Box>
                    </Grid>
                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                      <Box pb={0}>
                        <Text grey16>change your password , You can change your</Text>
                      </Box>
                    </Grid>
                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                      <Box pb={1}>
                        <Text grey16>password from there.</Text>
                      </Box>
                    </Grid>
                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                      <Box pb={1}>
                        <Text mediumBoldGrey>{state.emailId}</Text>
                      </Box>
                    </Grid>
                    <Grid item container sm={12} xs={12} justifyContent={'center'}>
                      <Box p={1}>
                        <Text grey16 sx={{ color: '#AEAEAE !important' }}>Haven’t received the  mail? <span onClick={(e) => { handleSubmit(e) }} style={{ color: '#0C75EB', cursor: 'pointer' }}>Resend</span></Text>
                      </Box>
                    </Grid>
                    <Grid item >
                      <Box height={'30vh'} />
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', position: 'absolute', bottom: 3 }}>
                      <Box p={1} pl={10}>
                        <Text mediumBoldBlack sx={{ fontWeight: '400 !important', display: 'flex', flexDirection: 'row' }}><span onClick={() => { setStatus('create') }} style={{ color: '#0C75EB', paddingRight: '10px', cursor: 'pointer' }}>Change E-mail ID</span>
                          <span style={{
                            height: '6px',
                            width: '6px',
                            borderRadius: '50%',
                            background: '#D9D9D9',
                            display: 'flex',
                            margin: '8px 5px 2px 0px'
                          }}> </span>
                          <a href={'mailto:support@japfu.ai'} style={{ color: '#0C75EB', paddingLeft: '5px', cursor: 'pointer' }}>Contact Support</a> </Text>
                      </Box>
                    </Grid>
                  </form>
              }

            </Grid>
          </Grid>
      }
      <ReusablePopup
        title="Enter OTP"
        dialogTitleSmaller
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <OTP email={state.emailId} />
      </ReusablePopup>

    </div>
  );
}

export default ForgotPassword;

