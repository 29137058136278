import React, { useRef } from 'react';
import ClientStyles from './ClientStyles';
import { Box, Grid, Menu, MenuItem, Skeleton, Typography, Stack, IconButton, Divider, Accordion, AccordionSummary, AccordionDetails, Tooltip, tooltipClasses, Backdrop } from '@mui/material';
import Text from '../../../components/customText/Text';
import Button from '../../../components/customButton/Button';
// import menu from '../../../assets/client/ActionMenu.svg';
import Search from '../../../assets/svg/search1.svg';
import cloud from '../../../assets/svg/download-cloud-blue.svg';
import Userplus from '../../../assets/svg/clients/user-plus-bold.svg';// eslint-disable-next-line
import disablePlus from '../../../assets/client/disableAdd.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import ClientsApi from '../../../apis/admin/clients/ClientsApi';
import VendorApi from '../../../apis/admin/clients/VendorApi';
import EndClientApi from '../../../apis/admin/clients/EndClientApi';
import ReusablePopup from '../../../components/reuablePopup/ReusablePopup';
import deactivateImg from '../../../assets/client/deactivateImg.svg';
import CustomButton from '../../../components/customButton/Button';
import LocalStorage from '../../../utils/LocalStorage';
import { BlackToolTip, NoDataFound, addErrorMsg, NoPermission, addLoader, addSuccessMsg, capitalizeAndAddSpace, removeLoader, addLoaderPlanExpired, removeExpiredLoader } from '../../../utils/utils';
import { styled } from '@mui/system';
import FilterListIcon from '@mui/icons-material/FilterList';
import arrowDown from '../../../assets/svg/clients/blackdownArrow.svg';
import arrowUp from '../../../assets/svg/clients/blackarrowUp.svg';
import Component87 from '../../../assets/svg/Component87.svg';
import disableVerified from '../../../assets/client/disableVerified.svg';
import info from '../../../assets/svg/clients/grey-info-bold.svg';
import Loader from '../../../assets/gif/japfuLoader.gif';
import CustomCircularProgress from '../../../components/progressbar/CircularProgress';
import FileSaver from 'file-saver';
import greenUp from '../../../assets/svg/clients/greenupArrow.svg'
import greendown from '../../../assets/svg/clients/greendownArrow.svg'
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import ClearIcon from '../../../assets/client/search-cross.svg';
import ClearIcon from '@mui/icons-material/Clear';
import DashboardAPI from '../../../apis/admin/DashboardAPI';
import { domain } from '../../../config/Domain';

export default function ClientsDashboard() {
    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "#696969",
            padding: "6px 14px",
            minWidth: 100,
            border: "1px solid #696969",
            borderRadius: '4px'
        },
        [`& .${tooltipClasses.arrow}`]: {
            color: "#696969",
            "&::before": {
                backgroundColor: "#696969",
                border: "1px solid #696969"
            }
        },
    }));

    const classes = ClientStyles();
    const navigate = useNavigate();
    const location = useLocation();
    var rolePermission = LocalStorage.getRolesData() ? LocalStorage.getRolesData().role_permissions.permissions : '';
    const [anchorEl, setAnchorEl] = useState(null);
    const [openStatusArrow, setOpenStatusArrow] = useState(false);
    const open = Boolean(anchorEl);
    const data = location && location.state
    const [clientRows, setClientRows] = useState([]);
    const [vendorRows, setVendorRows] = useState([]);
    const [endCltRows, setEndCltRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [rowData, setRowData] = useState({});
    const [deletePopup, setDeletePopup] = useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const [activeState, setActiveState] = useState('clients');
    const messagesEndRef = useRef(null);
    const [filter, setFilter] = useState({
        request_id: LocalStorage.uid(),
        search: "",
        sort_column: "created_at",
        sort_order: "desc",
        limit: 10,
        page: 1,
        status: ""
    })
    const [endfilter, setEndFilter] = useState({
        request_id: LocalStorage.uid(),
        search: "",
        sort_column: "created_at",
        sort_order: "desc",
        limit: 10,
        page: 1,
        status: ""
    })
    const [vendorfilter, setVendorFilter] = useState({
        request_id: LocalStorage.uid(),
        search: "",
        sort_column: "created_at",
        sort_order: "desc",
        limit: 10,
        page: 1,
        status: ""
    })
    const [loader, setLoader] = useState(false);
    const scrollBox = useRef(null);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const [pagination, setPagination] = useState(
        {
            total: "",
            currentPage: 0,
            perPage: 10,
            totalPages: ""
        }
    );

    const [vendorPagination, setVendorPagination] = useState(
        {
            total: "",
            currentPage: 1,
            perPage: 10,
            totalPages: ""
        }
    );
    const [endClientPagination, setEndClientPagination] = useState(
        {
            total: "",
            currentPage: 1,
            perPage: 10,
            totalPages: ""
        }
    );

    useEffect(() => {
        setLoading(true);
        clientListingApi(filter, pagination);
        vendorsListingApi(vendorfilter, vendorPagination);
        EndCltListingApi(endfilter, endClientPagination);
        if (data && data.page == 'vendors') {
            setActiveState('vendors')
        } else if (data && data.page == 'end-Client') {
            setActiveState('end-clients')
        } else {
            setActiveState('clients')
        }
        navigate("/clients", { replace: true })
        // eslint-disable-next-line  
    }, [])

    useEffect(() => {
        getPlanCheck();
            // eslint-disable-next-line
    }, [])

    const getPlanCheck = () => {
        addLoader(true);
        let data = {
            request_id: LocalStorage.uid(),
            subdomain_name: domain
        }
        DashboardAPI.planCheck(data).then((res) => {
            if (res.data.statusCode === 1003) {
                removeLoader();
                let data = LocalStorage.getUserData()
                data.plan_name = res.data.plan_name
                data.plan_not_set = res.data.plan_not_set
                LocalStorage.setDateFormat(res.data.date_format)
                LocalStorage.setUserData(data);
                LocalStorage.setPlanCheckLocal(res.data)
                if (res.data.plan_not_set) {
                    navigate('/billing', { state: { renew: true } })
                }
                if (res.data.plan_expired) {
                    if (location.pathname != '/billing' || location.pathname != '/plan-status') {
                        addLoaderPlanExpired()
                    }
                    else {
                        removeExpiredLoader()
                    }
                }
            }
        })
    }

    const download = () => {
        addLoader(true);
        ClientsApi.downloadExport(activeState == 'clients' ? 'client' : activeState == 'vendors' ? 'vendor' : activeState == 'end-clients' ? 'end-client' : '').then((res) => {
            removeLoader();
            if (res.data.statusCode == 1003) {
                FileSaver.saveAs(res.data.data[0].file_path);
            } else {
                addErrorMsg(res.data.message);
            }
        })
    }

    const viewAnalytics = () => {
        LocalStorage.setAnalyticsRoute('client')
        navigate('/client/timesheets-analytics', { state: { activeState: 'client', from: 'client' } })
    }
    // eslint-disable-next-line
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    const loadMore = () => {
        if (activeState == "clients") {
            addLoader(true)
            filter.limit = Number(filter.limit) + 6;
            setFilter({ ...filter });
            clientListingApi(filter, pagination);
        } else if (activeState == 'vendors') {
            vendorfilter.limit = Number(vendorfilter.limit) + 6;
            setVendorFilter({ ...vendorfilter });
            addLoader(true)
            vendorsListingApi(vendorfilter, vendorPagination);
        } else {
            endfilter.limit = Number(endfilter.limit) + 6;
            setEndFilter({ ...endfilter });
            addLoader(true)
            EndCltListingApi(endfilter, endClientPagination);
        }
    }

    const handleScroll = () => {
        const { current } = scrollBox;
        if (current) {
            const { scrollTop, scrollHeight, clientHeight } = current;
            if (scrollTop + clientHeight >= scrollHeight - 5) {
                if (activeState == 'clients') {
                    if (Number(pagination.total) >= filter.limit) {
                        loadMore();
                    } else {
                        setLoader(false);
                    }
                } else if (activeState == 'vendors') {
                    if (Number(vendorPagination.total) >= vendorfilter.limit) {
                        loadMore();
                    } else {
                        setLoader(false);
                    }
                } else if (activeState == 'end-clients') {
                    if (Number(endClientPagination.total) >= endfilter.limit) {
                        loadMore();
                    } else {
                        setLoader(false);
                    }
                }
            }
        }
    };

    const handleClick = (event, args) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSort = (sort_column, sort_order) => {
        setAnchorEl(null);
        setExpanded(false);
        if (activeState == "clients") {
            filter.sort_column = sort_column;
            filter.sort_order = sort_order;
            setFilter({ ...filter });
            setLoading(true);
            clientListingApi(filter, pagination);
        } else if (activeState == "vendors") {
            vendorfilter.sort_column = sort_column;
            vendorfilter.sort_order = sort_order;
            setVendorFilter({ ...vendorfilter });
            setLoading(true);
            vendorsListingApi(vendorfilter, vendorPagination);
        } else if (activeState == "end-clients") {
            endfilter.sort_column = sort_column;
            endfilter.sort_order = sort_order;
            setEndFilter({ ...endfilter });
            setLoading(true);
            EndCltListingApi(endfilter, endClientPagination);
        }
    }

    const handleStatus = (args) => {
        if (activeState == 'clients') {
            filter.status = args
            setFilter({ ...filter })
            clientListingApi({ ...filter, status: args }, pagination);
        } else if (activeState == 'vendors') {
            vendorfilter.status = args
            setVendorFilter({ ...vendorfilter })
            vendorsListingApi({ ...vendorfilter, status: args }, vendorPagination)
        } else if (activeState == 'end-clients') {
            endfilter.status = args
            setEndFilter({ ...endfilter })
            EndCltListingApi({ ...endfilter, status: args }, endClientPagination)
        }
        setOpenStatusArrow(null);
    }

    const handleView = (args) => {
        setRowData(args);
        if (activeState == 'clients') {
            LocalStorage.removeRedirectedModule();
            LocalStorage.removeNotificationId();
            navigate('/clients/clients-user-profile', { state: { id: args.id, data: args, name: args.name } })
        } else if (activeState == 'vendors') {
            navigate('/vendor/user-profile', { state: { id: args.id, data: args } })
        } else if (activeState == 'end-clients') {
            navigate('/clients/end-clients-user-profile', { state: { id: args.id, data: args, name: args.name } })
        }
    }

    const deleteRow = () => {
        setDeletePopup(false);
        let disableClientRow = clientRows.filter(item => item.id == rowData.id);
        let disableVendorRow = vendorRows.filter(item => item.id == rowData.id);
        let disableEndClientRow = endCltRows.filter(item => item.id == rowData.id);
        // setOpenStatus(null);
        const updateData = {
            request_id: LocalStorage.uid(),
            status: rowData.status == 'Active' ? 'In Active' : 'Active'
        }
        if (activeState == 'clients') {
            ClientsApi.updateStatus(disableClientRow[0].id, updateData).then((res) => {
                if (res.data.statusCode === 1003) {
                    addSuccessMsg(`User Status has been ${updateData.status == 'Active' ? 'In Activated' : 'Activated'} Successfully`);
                    clientListingApi(filter, pagination);
                } else {
                    if(res.data.message == `You don't have access to perform this action. Please contact admin`){

                    }
                    else{
                        addErrorMsg(res.data.message);
                    }
                }
            })
        } else if (activeState == 'vendors') {
            ClientsApi.updateStatus('vendor', disableVendorRow[0].id, updateData).then((res) => {
                if (res.data.statusCode === 1003) {
                    addSuccessMsg(`User Status has been ${updateData.status == 'Active' ? 'In Activated' : 'Activated'} Successfully`);
                    vendorsListingApi(vendorfilter, vendorPagination);
                } else {
                    if(res.data.message == `You don't have access to perform this action. Please contact admin`){

                    }
                    else{
                        addErrorMsg(res.data.message);
                    }
                }
            })
        } else if (activeState == 'end-clients') {
            ClientsApi.updateStatus('end-client', disableEndClientRow[0].id, updateData).then((res) => {
                if (res.data.statusCode === 1003) {
                    addSuccessMsg(`User Status has been ${updateData.status == 'Active' ? 'In Activated' : 'Activated'} Successfully`);
                    EndCltListingApi(endfilter, endClientPagination);
                } else {
                    if(res.data.message == `You don't have access to perform this action. Please contact admin`){

                    }
                    else{
                        addErrorMsg(res.data.message);
                    }
                }
            })
        }
    }

    const handleStatusOpen = (e) => {
        setOpenStatusArrow(e.currentTarget);
    }

    const handleSearchClear = () => {
        if (activeState == 'clients') {
            filter.search = ''
            setFilter({ ...filter })
            clientListingApi(filter, { ...pagination, currentPage: 1 });
        } else if (activeState == 'vendors') {
            vendorfilter.search = ''
            setVendorFilter(vendorfilter);
            vendorsListingApi(vendorfilter, { ...vendorPagination, currentPage: 1 })
        } else {
            endfilter.search = ''
            setEndFilter(endfilter);
            EndCltListingApi(endfilter, { ...endClientPagination, currentPage: 1 })
        }
    }

    const changeHandler = (e) => {
        if (activeState === 'clients') {
            setFilter({ ...filter, search: e.target.value });
            setLoading(true);
            clientListingApi({ ...filter, search: e.target.value }, { ...pagination, currentPage: 1 });
        } else if (activeState === 'vendors') {
            setVendorFilter({ ...vendorfilter, search: e.target.value });
            setLoading(true);
            vendorsListingApi({ ...vendorfilter, search: e.target.value }, { ...vendorPagination, currentPage: 1 })
        } else if (activeState === 'end-clients') {
            setEndFilter({ ...endfilter, search: e.target.value });
            setLoading(true);
            EndCltListingApi({ ...endfilter, search: e.target.value }, { ...endClientPagination, currentPage: 1 })
        }
    }

    const addForm = () => {
        if (activeState == 'clients') {
            navigate('/addClient')
        } else if (activeState == 'vendors') {
            navigate('/addVendor')
        } else if (activeState == 'end-clients') {
            navigate('/addEnd-Client')
        }
    }

    const clientListingApi = (data, paginationData) => {
        if (clientRows.length < 6) {
            setLoading(true);
        }
        ClientsApi.listing(data, paginationData).then((res) => {
            setLoading(false);
            removeLoader();
            if (res.data.statusCode == 1003) {
                setClientRows(res.data.data);
                setPagination(res.data.pagination);
            } else {
                if(res.data.message == `You don't have access to perform this action. Please contact admin`){

                }
                else{
                    addErrorMsg(res.data.message);
                }
            }
        })
    }

    const vendorsListingApi = (data, paginationData) => {
        if (vendorRows.length < 6) {
            setLoading(true);
        }
        VendorApi.listing(data, paginationData).then((res) => {
            setLoading(false);
            removeLoader();
            if (res.data.statusCode === 1003) {
                setVendorRows(res.data.data);
                setVendorPagination(res.data.pagination);
            } else {
                if(res.data.message == `You don't have access to perform this action. Please contact admin`){

                }
                else{
                    addErrorMsg(res.data.message);
                }
            }
        })
    }

    const EndCltListingApi = (data, paginationData) => {
        if (endCltRows.length < 6) {
            setLoading(true);
        }
        EndClientApi.listing(data, paginationData).then((res) => {
            setLoading(false);
            removeLoader();
            if (res.data.statusCode === 1003) {
                setEndCltRows(res.data.data);
                setEndClientPagination(res.data.pagination);
            } else {
                if(res.data.message == `You don't have access to perform this action. Please contact admin`){

                }
                else{
                    addErrorMsg(res.data.message);
                }
            }
        })
    }
    // eslint-disable-next-line
    const handleChangePagination = (e, page) => {
        if (activeState == 'clients') {
            pagination['currentPage'] = page
            setPagination(pagination);
            clientListingApi(filter, pagination);
        } else if (activeState == 'vendors') {
            vendorPagination['currentPage'] = page
            setEndClientPagination(vendorPagination);
            vendorsListingApi(vendorfilter, vendorPagination);
        }
        else if (activeState == 'end-clients') {
            endClientPagination['currentPage'] = page
            setEndClientPagination(endClientPagination);
            EndCltListingApi(endfilter, endClientPagination);
        }
    }

    const handleTab = (args) => {
        setActiveState(args);
        setExpanded(false);
        if (args == 'clients') {
            if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "client_view" && item.is_allowed == true))) {
                filter.limit = 10
                filter.status = ''
                setFilter({ ...filter })
                pagination['perPage'] = 10
                setPagination({ ...pagination })
                setLoading(true);
                clientListingApi(filter, pagination);
            }
        } else if (args == 'vendors') {
            if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "vendor_view" && item.is_allowed == true))) {
                vendorfilter.limit = 10
                vendorfilter.status = ''
                setVendorFilter({ ...vendorfilter })
                vendorPagination['perPage'] = 10
                setVendorPagination({ ...vendorPagination })
                setLoading(true);
                vendorsListingApi(vendorfilter, vendorPagination);
            }
        } else if (args == 'end-clients') {
            if (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "end_client_view" && item.is_allowed == true))) {
                endfilter.limit = 10
                endfilter.status = ''
                setEndFilter({ ...endfilter })
                endClientPagination['perPage'] = 10
                setEndClientPagination({ ...endClientPagination })
                setLoading(true);
                EndCltListingApi(endfilter, endClientPagination);
            }
        }
    }

    const completeProfile = (args) => {
        if (activeState == 'clients') {
            LocalStorage.removeRedirectedModule();
            LocalStorage.removeNotificationId();
            navigate('/clients/clients-user-profile', { state: { id: args.id, data: args, name: args.name, complete: 'contact' } })
        } else if (activeState == 'vendors') {
            navigate('/vendor/user-profile', { state: { id: args.id, data: args, name: args.name, complete: 'contact' } })
        } else if (activeState == 'end-clients') {
            navigate('/clients/end-clients-user-profile', { state: { id: args.id, data: args, name: args.name, complete: 'contact' } })
        }
    }

    return (
        <Grid container lg={12} pl={13} pt={2} justifyContent='center'>
            {
                (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => (item.slug == "end_client_view" || item.slug == "client_view" || item.slug == "vendor_view") && item.is_allowed == true))) ?
                    <Grid item container lg={11} md={12} sm={12} xs={12} pt={2} justifyContent='center' spacing={2}>
                        <Grid item container xl={5} lg={4} md={4} sm={12} xs={12}>
                            <Grid item lg={6} md={9} sm={6} xs={12}>
                                <Box className={classes.tabBg}>
                                    <Box className={activeState == 'clients' ? classes.ActiveBg : classes.inactiveBg} onClick={() => handleTab('clients')}>
                                        {
                                            activeState == 'clients' ?
                                                <Text largeWhite400>Clients</Text> :
                                                <Text blackHeader>Clients</Text>
                                        }
                                    </Box>
                                    <Box className={activeState == 'vendors' ? classes.ActiveBg : classes.inactiveBg} onClick={() => handleTab('vendors')}>
                                        {
                                            activeState == 'vendors' ?
                                                <Text largeWhite400>Vendors</Text> :
                                                <Text blackHeader>Vendors</Text>
                                        }
                                    </Box>
                                    <Box className={activeState == 'end-clients' ? classes.ActiveBg : classes.inactiveBg} onClick={() => handleTab('end-clients')}>
                                        {
                                            activeState == 'end-clients' ?
                                                <Text largeWhite400>End Clients</Text> :
                                                <Text blackHeader>End Clients</Text>
                                        }
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item container xl={7} lg={8} md={8} sm={12} xs={12} spacing={2} justifyContent='end'>
                            {
                                activeState == "clients" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "client_view" && item.is_allowed !== true))) ? "" :
                                    activeState == "vendors" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "vendor_view" && item.is_allowed !== true))) ? "" :
                                        activeState == "end-clients" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "end_client_view" && item.is_allowed !== true))) ? "" :
                                            <>
                                                <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                                                    <Box style={{ height: "44px", border: "1.5px solid #E2E5E6", borderRadius: "6px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                        <input
                                                            className={classes.EmployeesSearchInput}
                                                            name="text"
                                                            onChange={(e) => changeHandler(e)}
                                                            placeholder='Search by Name / ID'
                                                            value={activeState == 'clients' ? filter.search : activeState == 'end-clients' ? endfilter.search : vendorfilter.search}
                                                        />
                                                        <Box sx={{ paddingRight: "15px !important" }}>
                                                            {
                                                                (activeState == 'clients' ? filter.search == '' : activeState == 'end-clients' ? endfilter.search == '' : vendorfilter.search == '') ?
                                                                    // ((activeState == 'clients' && filter.search == '') || (activeState == 'end-clients' && endfilter.search == '') || (activeState == "vendors" && vendorfilter.search == '')) ?
                                                                    <img src={Search} alt="Search" /> : <ClearIcon sx={{ cursor: "pointer" }} onClick={() => handleSearchClear()} />
                                                            }
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                                <Grid item xl={1} lg={1.2} md={1.3} sm={1.3} xs={2}>
                                                <button title="Export" type="button" className={classes.borderButton}
                                                    onClick={() => download()}
                                                >
                                                    <img src={cloud} alt="Userplus" />
                                                </button>
                                                </Grid>
                                                {(LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "client_view" && item.is_allowed == true))) ?
                                                    <Grid item xl={3} lg={3.5} md={2.7} sm={2.5} xs={12} >
                                                        <Button style={{ width: '100%', fontSize: `12px !important` }} analyticsView onClick={viewAnalytics}>View Analytics</Button>
                                                    </Grid>
                                                    : ""}
                                                <Grid item xl={3} lg={3} md={4} sm={4} xs={4}>
                                                    {/* {
                            ((activeState == 'clients' && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "client_create" && item.is_allowed == true)))) ||
                                (activeState == 'vendors' && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "vendor_create" && item.is_allowed == true)))) ||
                                (activeState == 'end-clients' && (LocalStorage.getUserData().super_admin || (rolePermission !== "" && rolePermission.some(item => item.slug == "end_client_create" && item.is_allowed == true))))) ? */}
                                                    <Button style={{ width: '94%' }} addButton onClick={addForm}><img src={Userplus} alt='plus' className={classes.addIcon} />Add {activeState == "clients" ? 'Client' : activeState == "vendors" ? 'Vendor' : activeState == "end-clients" ? 'End Client' : ""}</Button>
                                                    {/* : */}
                                                    {/* //         <Button addButtonDisable><img src={disablePlus} alt='plus' className={classes.addIcon} />Add {activeState == "clients" ? 'Client' : activeState == "vendors" ? 'Vendor' : 'End-Client'}</Button>
                        // } */}
                                                </Grid>
                                            </>}
                        </Grid>
                        <Grid item container lg={11.6} md={11} sm={11} xs={11} pt={4}>
                            {
                                activeState == "clients" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "client_view" && item.is_allowed !== true))) ? "" :
                                    activeState == "vendors" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "vendor_view" && item.is_allowed !== true))) ? "" :
                                        activeState == "end-clients" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "end_client_view" && item.is_allowed !== true))) ? "" :
                                            <Grid item container lg={12} md={12} sm={12} xs={12} textAlign='center' alignItems='center' sx={{ borderBottom: "1px solid #F3EFEF", padding: "16px 12px 5px 12px" }}>
                                                <Grid item lg={4} md={4} sm={4} xs={4} textAlign={'left'} display='flex' flexDirection='row' alignItems='center'>
                                                    <Text mediumBoldBlack2>{activeState == 'clients' ? 'Clients' : activeState == 'vendors' ? 'Vendors' : activeState == 'end-clients' ? 'End Clients' : ''}</Text>
                                                    <IconButton style={{ padding: "1px", margin: "8px", backgroundColor: "#ffffff", border: "0", minWidth: "22px" }} onClick={handleClick}>
                                                        <FilterListIcon sx={{
                                                            height: "19px", color: ((activeState == 'clients' && clientRows.length == 0) || (activeState == 'vendors' && vendorRows.length == 0) || (activeState == 'end-clients' && endCltRows.length == 0)) ? '#777A8E' : '#2BEA2B'
                                                        }} />
                                                    </IconButton>
                                                    <Menu
                                                        anchorEl={anchorEl}
                                                        id="account-menu"
                                                        open={open}
                                                        onClose={() => setAnchorEl(null)}
                                                        // onClick={handleCloseFilter}
                                                        PaperProps={{
                                                            elevation: 0,
                                                            sx: {
                                                                overflow: "visible",
                                                                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                                mt: 0.5,
                                                                ml: -2,
                                                                padding: "5px",
                                                                "& .MuiMenuItem-root:hover": {
                                                                    backgroundColor: "#EAECF0 !important",
                                                                }
                                                            },
                                                        }}
                                                        transformOrigin={{ horizontal: "left", vertical: "top" }}
                                                        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                                                    >
                                                        <>
                                                            <MenuItem sx={{
                                                                background: activeState == 'clients' ? filter.sort_column == 'created_at' && filter.sort_order == 'desc' ? '#EAECF0' : ""
                                                                    :
                                                                    activeState == 'end-clients' ? endfilter.sort_column == 'created_at' && endfilter.sort_order == 'desc' ? '#EAECF0' : ""
                                                                        :
                                                                        vendorfilter.sort_column == 'created_at' && vendorfilter.sort_order == 'desc' ? '#EAECF0' : ""
                                                            }} onClick={() => handleSort('created_at', 'desc')}> <Text mediumBoldBlack400>Newest First</Text>
                                                            </MenuItem>
                                                            <MenuItem sx={{
                                                                background: activeState == 'clients' ? filter.sort_column == 'created_at' && filter.sort_order == 'asc' ? '#EAECF0' : ""
                                                                    :
                                                                    activeState == 'end-clients' ? endfilter.sort_column == 'created_at' && endfilter.sort_order == 'asc' ? '#EAECF0' : ""
                                                                        :
                                                                        vendorfilter.sort_column == 'created_at' && vendorfilter.sort_order == 'asc' ? '#EAECF0' : ""
                                                            }} onClick={() => handleSort('created_at', 'asc')}> <Text mediumBoldBlack400>Oldest First</Text>
                                                            </MenuItem>
                                                            <MenuItem sx={{
                                                                background: activeState == 'clients' ? filter.sort_column == 'name' && filter.sort_order == 'asc' ? '#EAECF0' : ""
                                                                    :
                                                                    activeState == 'end-clients' ? endfilter.sort_column == 'name' && endfilter.sort_order == 'asc' ? '#EAECF0' : ""
                                                                        :
                                                                        vendorfilter.sort_column == 'name' && vendorfilter.sort_order == 'asc' ? '#EAECF0' : ""
                                                            }} onClick={() => handleSort('name', 'asc')}><Text mediumBoldBlack400>Name (A-Z)</Text>
                                                            </MenuItem>
                                                            <MenuItem sx={{
                                                                background: activeState == 'clients' ? filter.sort_column == 'name' && filter.sort_order == 'desc' ? '#EAECF0' : ""
                                                                    :
                                                                    activeState == 'end-clients' ? endfilter.sort_column == 'name' && endfilter.sort_order == 'desc' ? '#EAECF0' : ""
                                                                        :
                                                                        vendorfilter.sort_column == 'name' && vendorfilter.sort_order == 'desc' ? '#EAECF0' : ""
                                                            }} onClick={() => handleSort('name', 'desc')}> <Text mediumBoldBlack400>Name (Z-A)</Text>
                                                            </MenuItem>
                                                        </>
                                                    </Menu>
                                                </Grid>
                                                <Grid item lg={2} md={2} sm={2} xs={2} textAlign='left'>
                                                    <Text mediumBoldBlack2>Primary Contact</Text>
                                                </Grid>
                                                <Grid item lg={2} md={2} sm={2} xs={2} textAlign='left'>
                                                    <Text mediumBoldBlack2>Phone Number</Text>
                                                </Grid>
                                                <Grid item container lg={1.5} md={2} sm={2} xs={2} justifyContent='center'>
                                                    <Grid aria-controls="status-menu" item lg={6} onClick={handleStatusOpen} className={classes.statusGrid}>
                                                        <Text mediumBoldBlack2 sx={{ paddingRight: '5px' }}>Status</Text>
                                                        {
                                                            openStatusArrow ?
                                                                ((activeState == 'clients' && filter.status == '')
                                                                    ||
                                                                    (activeState == 'end-clients' && endfilter.status == '')
                                                                    ||
                                                                    (activeState == 'vendors' && vendorfilter.status == '')) ? <img src={arrowUp} alt='up' className={classes.cursor} /> : <img src={greenUp} alt='up' className={classes.cursor} />
                                                                :
                                                                ((activeState == 'clients' && filter.status == '')
                                                                    ||
                                                                    (activeState == 'end-clients' && endfilter.status == '')
                                                                    ||
                                                                    (activeState == 'vendors' && vendorfilter.status == '')) ? <img src={arrowDown} alt='down' className={classes.cursor} /> : <img src={greendown} alt='down' className={classes.cursor} />
                                                        }
                                                    </Grid>
                                                    <Menu
                                                        anchorEl={openStatusArrow}
                                                        id="status-menu"
                                                        open={openStatusArrow}
                                                        onClose={() => { setOpenStatusArrow(null) }}
                                                        PaperProps={{
                                                            elevation: 0,
                                                            sx: {
                                                                overflow: "visible",
                                                                boxShadow: '0px 2px 24px 0px #919EAB1F !important',
                                                                mt: 1,
                                                                width: '200px'
                                                            }
                                                        }}
                                                        transformOrigin={{ horizontal: "center", vertical: "top" }}
                                                        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                                                    >
                                                        <MenuItem onClick={() => handleStatus('')} className={((activeState == 'clients' && filter.status == '') || (activeState == 'end-clients' && endfilter.status == '') || (activeState == 'vendors' && vendorfilter.status == '')) ? classes.activeMenuItem : classes.menuItem}><Text smallBlack>All</Text></MenuItem> <Divider className={classes.divider} />
                                                        <MenuItem onClick={() => handleStatus('Active')} className={((activeState == 'clients' && filter.status == 'Active') || (activeState == 'end-clients' && endfilter.status == 'Active') || (activeState == 'vendors' && vendorfilter.status == 'Active')) ? classes.activeMenuItem : classes.menuItem}> <Text smallBlack>Active  {activeState == 'clients' ? 'Clients' : activeState == 'vendors' ? 'Vendors' : activeState == 'end-clients' ? 'End Clients' : ''} </Text></MenuItem> <Divider className={classes.divider} />
                                                        <MenuItem onClick={() => handleStatus('In Active')} className={((activeState == 'clients' && filter.status == 'In Active') || (activeState == 'end-clients' && endfilter.status == 'In Active') || (activeState == 'vendors' && vendorfilter.status == 'In Active')) ? classes.activeMenuItem : classes.menuItem}> <Text smallBlack>Inactive {activeState == 'clients' ? 'Clients' : activeState == 'vendors' ? 'Vendors' : activeState == 'end-clients' ? 'End Clients' : ''}</Text></MenuItem>
                                                    </Menu>
                                                </Grid>
                                                <Grid item lg={2.5} md={2} sm={2} xs={2}>
                                                    <Text offBoardBodyFont>Count
                                                        <BlackToolTip arrow placement='bottom' title={
                                                            <Box p={'4px'}>
                                                                <Text mediumWhite400>Total {activeState == 'clients' ? 'Clients' : activeState == 'vendors' ? 'Vendors' : activeState == 'end-clients' ? 'End-Clients' : ''} count is based<br /> on the filters applied</Text>
                                                            </Box>
                                                        }>
                                                            <img src={info} alt='info' className={classes.infoIcon} />
                                                        </BlackToolTip>
                                                        &nbsp;&nbsp;- {activeState == 'clients' ? clientRows.length > 0 ? pagination.total : '0' : activeState == 'vendors' ? vendorRows.length > 0 ? vendorPagination.total : '0' : activeState == 'end-clients' ? endCltRows.length > 0 ? endClientPagination.total : '0' : ''} {activeState == 'clients' ? 'Clients' : activeState == 'vendors' ? 'Vendors' : activeState == 'end-clients' ? 'End Clients' : ''}</Text>
                                                </Grid>
                                            </Grid>
                            }
                            {
                                loading ?
                                    [1, 2, 3].map((item) => (
                                        <Stack key={item} direction="row" my={3} px={4} py={2} spacing={2} sx={{ width: "100%", boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.05)", borderRadius: "8px" }}>
                                            <Box sx={{ width: "70%", display: "flex", alignItems: "center", gap: 2, borderRight: "1px solid rgba(226, 229, 230, 1)" }}>
                                                <Skeleton variant="circular" sx={{ width: "64px", height: "56px" }} />
                                                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <Box>
                                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "10rem" }} />
                                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                    </Box>
                                                    <Box sx={{ width: "0%", alignSelf: "center" }}>
                                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "4rem" }} />
                                                    </Box>
                                                    <Box sx={{ width: "20%", alignSelf: "center" }}>
                                                        <Skeleton variant="text" sx={{ fontSize: '1rem', width: "4rem" }} />
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box sx={{ width: "30%", display: "flex", alignItems: "center", gap: 2 }}>
                                                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                    <Skeleton variant="text" sx={{ fontSize: '1rem', width: "5rem" }} />
                                                </Box>
                                            </Box>
                                        </Stack>
                                    )) :
                                    <>
                                        {
                                            activeState == "clients" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "client_view" && item.is_allowed !== true))) ? <>{NoPermission()}</> :
                                                activeState == "vendors" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "vendor_view" && item.is_allowed !== true))) ? <>{NoPermission()}</> :
                                                    activeState == "end-clients" && (!LocalStorage.getUserData().super_admin && (rolePermission !== "" && rolePermission.some(item => item.slug == "end_client_view" && item.is_allowed !== true))) ? <>{NoPermission()}</> :
                                                        <>
                                                            {
                                                                (activeState == 'clients' ? clientRows.length > 0 : activeState == 'vendors' ? vendorRows.length > 0 : activeState == 'end-clients' ? endCltRows.length > 0 : '') ?
                                                                    <Grid container className={classes.scrollContent} ref={scrollBox} onScroll={handleScroll}>
                                                                        {
                                                                            (activeState == 'clients' ? clientRows.length > 0 && clientRows : activeState == 'vendors' ? vendorRows.length > 0 && vendorRows : activeState == 'end-clients' ? endCltRows.length > 0 && endCltRows : '').map((item, key) => (
                                                                                <Accordion
                                                                                    key={key}
                                                                                    className={classes.newCustomAccordion}
                                                                                    expanded={expanded === `panel${key}`} onChange={handleChange(`panel${key}`)}>
                                                                                    <AccordionSummary
                                                                                        // expandIcon={selectedOption === "All employees" && <ExpandMoreIcon style={{ '& svg path': { stroke: 'black', strokeWidth: 2 }, color: '#000000' }} />}
                                                                                        aria-controls="panel1bh-content"
                                                                                        id="panel1bh-header"
                                                                                        className={classes.AccordionSummary}                                                    >
                                                                                        <Grid item container lg={12} md={12} sm={12} xs={12} textAlign='center' alignItems='center'>
                                                                                            <Grid item lg={4.1} md={4} sm={4} xs={4} textAlign='start'>
                                                                                                <Box display='flex' alignItems='center' gap={2}>
                                                                                                    <CustomCircularProgress
                                                                                                        percentage={item.profile_percentage}
                                                                                                        color={item.profile_percentage >= 76 ? 'green' : item.profile_percentage <= 75 && item.profile_percentage >= 51 ? 'yellow' : item.profile_percentage <= 50 ? "#FFBF00" : ''}
                                                                                                        src={item.document_url}
                                                                                                        name={item.document_url == undefined || item.document_url == null || item.document_url == '' ? item.name && item.name[0] : ''}
                                                                                                        imgWidth='50px !important'
                                                                                                        imgHeight='50px !important'
                                                                                                        avatarWidth='50px !important'
                                                                                                        avatarHeight='50px !important'
                                                                                                        value={item.profile_percentage}
                                                                                                        size={58}
                                                                                                        tooltip
                                                                                                        tooltipContent={
                                                                                                            <Box p={'3px 0px'} width='100%'>
                                                                                                                <Text mediumBoldWhite400>{`Profile Completion - ${item.profile_percentage}%`}</Text>
                                                                                                            </Box>
                                                                                                        }
                                                                                                        dashboardContent={item.status == 'Active' && item.profile_percentage == 50 ? <Button addButtonTooltip sx={{ margin: '10px 0px 5px 0px' }} onClick={() => completeProfile(item)}>Complete Profile</Button> : ''}
                                                                                                    />
                                                                                                    <Box>
                                                                                                        {
                                                                                                            item.status == 'In Active' ?
                                                                                                                <Text mediumBoldBlack style={{ margin: '4px 0' }} noWrap>
                                                                                                                    {item.name === "" ? "--" : capitalizeAndAddSpace(item.name).length > 16 ?
                                                                                                                        <HtmlTooltip title={capitalizeAndAddSpace(item.name)} placement="right" arrow>
                                                                                                                            {capitalizeAndAddSpace(item.name).slice(0, 16) + (capitalizeAndAddSpace(item.name).length > 16 ? "..." : "")}
                                                                                                                        </HtmlTooltip>
                                                                                                                        :
                                                                                                                        capitalizeAndAddSpace(item.name)
                                                                                                                    }
                                                                                                                    {item.e_verified ?
                                                                                                                        <HtmlTooltip title="Profile Has Been E-Verified" placement="right" arrow>
                                                                                                                            <img src={disableVerified} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                                                                                                        </HtmlTooltip>
                                                                                                                        :
                                                                                                                        null}</Text>
                                                                                                                :
                                                                                                                <Text mediumBoldBlack style={{ margin: '4px 0px' }}>
                                                                                                                    {item.name === "" ? "--" :
                                                                                                                        capitalizeAndAddSpace(item.name).length > 16 ?
                                                                                                                            <BlackToolTip title={capitalizeAndAddSpace(item.name)} placement="right" arrow>
                                                                                                                                {capitalizeAndAddSpace(item.name).slice(0, 16) + (capitalizeAndAddSpace(item.name).length > 16 ? "..." : "")}
                                                                                                                            </BlackToolTip>
                                                                                                                            :
                                                                                                                            capitalizeAndAddSpace(item.name)
                                                                                                                    }
                                                                                                                    {item.e_verified ?
                                                                                                                        <HtmlTooltip title="Profile Has Been E-Verified" placement="right" arrow>
                                                                                                                            <img src={Component87} alt='svg' style={{ margin: "0px 6px -5px 6px" }} />
                                                                                                                        </HtmlTooltip>
                                                                                                                        : null}
                                                                                                                </Text>
                                                                                                        }
                                                                                                        <Text mediumLabel noWrap style={{ margin: '4px 0' }}>  {item.reference_id === "" ? "--" : item.reference_id}</Text>
                                                                                                    </Box>
                                                                                                </Box>
                                                                                            </Grid>
                                                                                            <Grid item lg={2} md={2} sm={2} xs={2} textAlign='left'>
                                                                                                <Text mediumBlack14>{item.primary_contact ?
                                                                                                    capitalizeAndAddSpace(item.primary_contact).length > 20 ?
                                                                                                        <HtmlTooltip title={capitalizeAndAddSpace(item.primary_contact)} placement="right" arrow>
                                                                                                            {capitalizeAndAddSpace(item.primary_contact).slice(0, 20) + (capitalizeAndAddSpace(item.primary_contact).length > 20 ? "..." : "")}
                                                                                                        </HtmlTooltip>
                                                                                                        :
                                                                                                        capitalizeAndAddSpace(item.primary_contact)
                                                                                                    : '--'}</Text>
                                                                                            </Grid>
                                                                                            <Grid item lg={2} md={2} sm={2} xs={2} textAlign='left' pl={'5px'}>
                                                                                                <Text mediumBlack14>{item.telephone_number ? item.telephone_number : '--'}</Text>
                                                                                            </Grid>
                                                                                            <Grid item container lg={2} md={2} sm={2} xs={2} justifyContent='space-around' display='flex' flexDirection='row' alignItems='center'>
                                                                                                <Divider orientation="vertical" flexItem style={{ height: '50px' }} />
                                                                                                <Box className={item.status == 'Active' ? classes.activeStatus : classes.inactiveStatus}>
                                                                                                    {item.status == 'Active' ? 'Active' : 'Inactive'}
                                                                                                </Box>
                                                                                            </Grid>
                                                                                            <Grid item lg={1.9} md={2} sm={2} xs={2}>
                                                                                                <Text mediumBlue onClick={() => handleView(item)}>View Profile</Text>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </AccordionSummary>
                                                                                    <AccordionDetails>
                                                                                        <Box className={classes.AccordionDetails} pt={1}>
                                                                                            <Grid container lg={12} md={12} sm={12} xs={12} justifyContent='center' alignItems='center'>
                                                                                                <Grid item lg={4} md={4} sm={4} xs={4} textAlign='center'>
                                                                                                    <Text mediumGrey2>Email ID</Text>
                                                                                                    <Text smallBoldBlack sx={{ paddingTop: '5px' }}>
                                                                                                        {
                                                                                                            item.contact_email ? item.contact_email.length > 16 ?
                                                                                                                <BlackToolTip title={item.contact_email} placement="top" arrow>
                                                                                                                    {(item.contact_email.slice(0, 16)) + (item.contact_email.length > 16 ? "..." : "")}
                                                                                                                </BlackToolTip>
                                                                                                                : (item.contact_email) : '--'
                                                                                                        }
                                                                                                    </Text>
                                                                                                </Grid>
                                                                                                <Grid item lg={4} md={4} sm={4} xs={4} textAlign='center'>
                                                                                                    <Text mediumGrey2>Mobile Number</Text>
                                                                                                    <Text smallBoldBlack sx={{ paddingTop: '5px' }}>{item.mobile_number ? item.mobile_number : '--'}</Text>
                                                                                                </Grid>
                                                                                                <Grid item lg={4} md={4} sm={4} xs={4} textAlign='center'>
                                                                                                    <Text mediumGrey2>Extension</Text>
                                                                                                    <Text smallBoldBlack sx={{ paddingTop: '5px' }}>{item.telephone_number_ext ? item.telephone_number_ext : '--'}</Text>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Box>
                                                                                    </AccordionDetails>
                                                                                </Accordion>
                                                                            ))
                                                                        }
                                                                        <div ref={messagesEndRef} />
                                                                    </Grid>
                                                                    :
                                                                    (activeState == 'clients' ? clientRows.length == 0 : activeState == 'vendors' ? vendorRows.length == 0 : activeState == 'end-clients' ? endCltRows.length == 0 : '') ?
                                                                        <Box justifyContent='center' width='100%'>
                                                                            {NoDataFound(((filter.status == 'In Active' && clientRows.length == 0) || (vendorfilter.status == 'In Active' && vendorRows.length == 0) || (endfilter.status == 'In Active' && endCltRows.length == 0)) ? '' : `To fetch the data. Please add ${activeState == 'clients' ? 'Client' : activeState == 'vendors' ? 'Vendor' : activeState == 'end-clients' ? 'End-Client' : ''}`, 'No Data Found')}
                                                                        </Box> : ''
                                                            }
                                                        </>
                                        }
                                    </>
                            }
                        </Grid>
                    </Grid >
                    :
                    <Grid container>
                        <Box>
                            <Text mediumViewAmt>Clients</Text>
                        </Box>
                        {NoPermission()}
                    </Grid>
            }
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loader}
            >
                <img src={Loader} alt="Loader" width={'100px'} height={'100px'} />
            </Backdrop>
            <ReusablePopup iconHide white openPopup={deletePopup} setOpenPopup={setDeletePopup} fullWidth>
                <Box sx={{ margin: "20px", }}>
                    <Box sx={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                        <img src={deactivateImg} alt="warning" />
                    </Box>
                    <Box my={3}>
                        <Typography my={1} sx={{ color: "#54595E", font: '18px  Quicksand', fontWeight: 600, textAlign: 'center' }}>
                            Are You Sure?
                        </Typography>
                        <Typography my={1} sx={{ color: "#54595E99", font: '14px  Nunito , Nunito Sans, sans-serif', fontWeight: 400, textAlign: 'center' }}>
                            Do you really wish to {rowData.status == 'Active' ? 'In Activate' : 'Activate'} the {activeState == 'clients' ? 'Client' : activeState == 'vendors' ? 'vendor' : activeState == 'end-clients' ? 'End - Client' : ''}.
                        </Typography>
                    </Box>
                    <Box my={2} sx={{ width: '100%', display: 'flex', justifyContent: 'center', gap: '20px' }} >
                        <CustomButton no onClick={() => setDeletePopup(false)}>
                            No
                        </CustomButton>
                        <CustomButton popupDelete onClick={deleteRow}>
                            Yes, {rowData.status == 'Active' ? 'In Activate' : 'Activate'}
                        </CustomButton>
                    </Box>

                </Box>
            </ReusablePopup>
        </Grid >
    )
};
