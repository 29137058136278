import React, { useEffect, useState } from 'react';
import { Box, Grid, Stack, } from '@mui/material';
import Text from '../../../../components/customText/Text';
import EditIcon from '../../../../assets/svg/newEdit.svg'
import LocalStorage from '../../../../utils/LocalStorage';
import { BlackToolTip } from '../../../../utils/utils';
import ReusablePopup from '../../../../components/reuablePopup/ReusablePopup';
import Button from '../../../../components/customButton/Button';
import Sure from '../../../../assets/svg/ExpenseDeleteIcon.svg'

export default function ViewGeneralDetails(props) {// eslint-disable-next-line
    const [employee, setEmployee] = useState(props.employee)
    const [clearPopup, setclearPopup] = useState(false);

    useEffect(() => {
        setEmployee(props.employee)
    }, [props])

    const editDetails = () =>{
        if(props.employee.pending_requests.general_details){
            setclearPopup(true)
        }
        else{
            props.setEdit(true); LocalStorage.setEditForm('edit'); props.setformEdit(true)
        }
    }

    return (
        <Grid container lg={12} p={2}>
            <Grid item lg={11} md={11} sm={11} xs={11}>
                <Box p={1} pt={2}>
                    <Text largeBlue>
                        Basic Details
                    </Text>
                </Box>
            </Grid>
            {
                employee.is_off_boarding_initiated === false &&
                <Grid item lg={1} md={1} sm={1} xs={1}>
                    <Box p={1} pr={0}>
                        <img src={EditIcon} alt='Edit' style={{ cursor: 'pointer' }} onClick={() => { editDetails() }} />
                    </Box>
                </Grid>
            }
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Text mediumLabel>
                        First Name
                    </Text>
                    <Text pt={1} mediumBlack14>
                        {
                            employee.first_name && employee.first_name.length > 16 ?
                                <BlackToolTip title={employee.first_name} placement="top" arrow>
                                    {employee.first_name.slice(0, 16) + (employee.first_name.length > 16 ? "..." : "")}
                                </BlackToolTip>
                                : employee.first_name
                        }

                    </Text>
                </Box>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Text mediumLabel>
                        Middle Name (Optional)
                    </Text>
                    <Text pt={1} mediumBlack14>
                        {
                            employee.middle_name && employee.middle_name.length > 16 ?
                                <BlackToolTip title={employee.middle_name} placement="top" arrow>
                                    {employee.middle_name.slice(0, 16) + (employee.middle_name.length > 16 ? "..." : "")}
                                </BlackToolTip>
                                : employee.middle_name
                        }
                    </Text>
                </Box>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Text mediumLabel>
                        Last Name
                    </Text>
                    <Text pt={1} mediumBlack14>
                        {
                            employee.last_name && employee.last_name.length > 16 ?
                                <BlackToolTip title={employee.last_name} placement="top" arrow>
                                    {employee.last_name.slice(0, 16) + (employee.last_name.length > 16 ? "..." : "")}
                                </BlackToolTip>
                                :
                                employee.last_name
                        }
                    </Text>
                </Box>
            </Grid>
            <Grid item lg={3} md={0} sm={0} xs={12}></Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Text mediumLabel>
                        Date of Birth
                    </Text>
                    <Text pt={1} mediumBlack14>
                        {employee.dob}
                    </Text>
                </Box>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Text mediumLabel>
                        Gender
                    </Text>
                    <Text pt={1} mediumBlack14>
                        {employee.gender}
                    </Text>
                </Box>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Text mediumLabel>
                        Blood Group
                    </Text>
                    <Text pt={1} mediumBlack14>
                    {employee.blood_group ? employee.blood_group : '-'}
                       
                    </Text>
                </Box>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Text mediumLabel>
                        Marital Status
                    </Text>
                    <Text pt={1} mediumBlack14>
                        {employee.marital_status}
                    </Text>
                </Box>
            </Grid>
            <Grid item lg={12} pt={1} pb={1} md={12} sm={12} xs={12}>
                <Box p={1} pt={2}>
                    <Text largeBlue>
                        Contact Details
                    </Text>
                </Box>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Text mediumLabel>
                        Mobile Number
                    </Text>
                    <Text pt={1} mediumBlack14>
                        {employee.contact_number}

                    </Text>
                </Box>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Text mediumLabel>
                        Alt Mobile Number
                    </Text>
                    <Text pt={1} mediumBlack14>
                    {employee.alternate_contact_number ? employee.alternate_contact_number : '-'}
                    </Text>
                </Box>
            </Grid>
            <Grid item lg={6} md={4} sm={0} xs={12}></Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Text mediumLabel>
                        Email ID
                    </Text>
                    <Text pt={1} mediumBlack14>
                        {
                            employee.email_id && employee.email_id.length > 40 ?
                                <BlackToolTip title={employee.email_id} placement="top" arrow>
                                    {employee.email_id.slice(0, 40) + (employee.email_id.length > 40 ? "..." : "")}
                                </BlackToolTip>
                                :
                                employee.email_id
                        }
                    </Text>
                </Box>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Text mediumLabel>
                        Alt Email Id
                    </Text>
                    <Text pt={1} mediumBlack14>
                        {
                            employee.alternate_email_id && employee.alternate_email_id.length > 40 ?
                                <BlackToolTip title={employee.alternate_email_id} placement="top" arrow>
                                    {employee.alternate_email_id.slice(0, 40) + (employee.alternate_email_id.length > 40 ? "..." : "")}
                                </BlackToolTip>
                                :
                                employee.alternate_email_id ? employee.alternate_email_id : '-'
                        }
                    </Text>
                </Box>
            </Grid>
            <Grid item lg={12} pt={1} pb={1} md={12} sm={12} xs={12}>
                <Box p={1} pt={2}>
                    <Text largeBlue>
                        Employment Details
                    </Text>
                </Box>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Text mediumLabel>
                        Employee ID
                    </Text>
                    <Text pt={1} mediumBlack14>
                        {employee.reference_id}
                    </Text>
                </Box>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Text mediumLabel>
                        Joining Date
                    </Text>
                    <Text pt={1} mediumBlack14>
                        {employee.date_of_joining}
                    </Text>
                </Box>
            </Grid>

            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Text mediumLabel>
                        Employment Type
                    </Text>
                    <Text pt={1} mediumBlack14>
                        {employee.employment_type}
                    </Text>
                </Box>
            </Grid>
            {
                employee.employment_type_id !== 1 &&
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        <Text mediumLabel>
                            Employment Category
                        </Text>
                        <Text pt={1} mediumBlack14>
                            {employee.employee_category}
                        </Text>
                    </Box>
                </Grid>
            }
            {
                employee.employment_type_id==3 && <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Text mediumLabel>
                        Vendor
                    </Text>
                    <Text pt={1} mediumBlack14>
                        {employee.company_name}
                    </Text>
                </Box>
            </Grid>
            }
            {
                employee.employment_type_id == 1 &&
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Box p={1} pt={2}>
                        <Text mediumLabel>
                            Role
                        </Text>
                        <Text pt={1} mediumBlack14>
                            {employee.role_name}
                        </Text>
                    </Box>
                </Grid>
            }

            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Text mediumLabel>
                        SSN
                    </Text>
                    <Text pt={1} mediumBlack14>
                        {employee.ssn ? employee.ssn : '-'}
                    </Text>
                </Box>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Text mediumLabel>
                        Is the employee USC?
                    </Text>
                    <Text pt={1} mediumBlack14>
                        {employee.is_usc == 0 ? 'No' : 'Yes'}
                    </Text>
                </Box>
            </Grid>
            {employee.is_usc == 0 ?
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Text mediumLabel>
                        Visa Type
                    </Text>
                    <Text pt={1} mediumBlack14>
                        {employee.visa_type}
                    </Text>
                </Box>
            </Grid>
            : ''}
            <Grid item lg={12} pt={1} pb={1} md={12} sm={12} xs={12}>
                <Box p={1} pt={2}>
                    <Text largeBlue>
                        Current Address
                    </Text>
                </Box>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Text mediumLabel>
                        Address Line 1
                    </Text>
                    <Text pt={1} mediumBlack14>
                        {
                            employee.address_line_one && employee.address_line_one.length > 30 ?
                                <BlackToolTip title={employee.address_line_one} placement="top" arrow>
                                    {employee.address_line_one.slice(0, 30) + (employee.address_line_one.length > 30 ? "..." : "")}
                                </BlackToolTip>
                                :
                                employee.address_line_one
                        }
                    </Text>
                </Box>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Text mediumLabel>
                        Address Line 2
                    </Text>
                    <Text pt={1} mediumBlack14>
                        {
                            employee.address_line_two && employee.address_line_two.length > 30 ?
                                <BlackToolTip title={employee.address_line_two} placement="top" arrow>
                                    {employee.address_line_two.slice(0, 30) + (employee.address_line_two.length > 30 ? "..." : "")}
                                </BlackToolTip>
                                :
                                employee.address_line_two ? employee.address_line_two : '-'
                        }
                    </Text>
                </Box>
            </Grid>
            <Grid item lg={6} md={4} sm={0} xs={12}></Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Text mediumLabel>
                        Country
                    </Text>
                    <Text pt={1} mediumBlack14>
                        {employee.country_name}
                    </Text>
                </Box>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Text mediumLabel>
                        State
                    </Text>
                    <Text pt={1} mediumBlack14>
                        {employee.state_name}
                    </Text>
                </Box>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Text mediumLabel>
                        City
                    </Text>
                    <Text pt={1} mediumBlack14>
                        {
                            employee.city && employee.city.length > 16 ?
                                <BlackToolTip title={employee.city} placement="top" arrow>
                                    {employee.city.slice(0, 16) + (employee.city.length > 16 ? "..." : "")}
                                </BlackToolTip>
                                :
                                employee.city
                        }
                    </Text>
                </Box>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
                <Box p={1} pt={2}>
                    <Text mediumLabel>
                        Zip Code
                    </Text>
                    <Text pt={1} mediumBlack14>
                        {employee.zip_code}
                    </Text>
                </Box>
            </Grid>
            <ReusablePopup openPopup={clearPopup} setOpenPopup={setclearPopup} white iconHide >
                <Box m={2} ml={10} mr={10}>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                        <img src={Sure} alt='noactive' />
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                        <Text offBoardHeadFont sx={{ fontSize: '18px !important', fontWeight: `${700} !important` }}>Editing Denied!</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>You have recently updated your details, Please</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>wait until your employer has taken any action to</Text>
                    </Grid>
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Text offBoardBodyFont>edit</Text>
                    </Grid>
                    <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                        <Stack direction={"row"} spacing={3}>
                            <Button finishFilledQuicksand onClick={() => { setclearPopup(false) }} >
                                Okay
                            </Button>
                        </Stack>
                    </Grid>
                </Box>

            </ReusablePopup>
        </Grid>
    );
}