import React, { useEffect, useState } from 'react'
import { addErrorMsg, capitalizeAndAddSpace } from '../../../utils/utils';
import EmployeesApi from '../../../apis/admin/employees/EmployeesApi';
import { useLocation, useNavigate } from 'react-router-dom';
import PlacementViewStyles from './PlacementViewStyles';
import { Box, Typography, Breadcrumbs, Stack, Grid, Divider, ListItemButton, Skeleton, Slide, DialogContent, Dialog, Avatar } from '@mui/material';
import Text from '../../../components/customText/Text';
import Button from '../../../components/customButton/Button';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import ClientDetails from './ClientDetails';
import PlacementDetails from './PlacementDetails';
import TimesheetConfiguration from './TimesheetConfiguration';
import LocalStorage from '../../../utils/LocalStorage';
import PlacementApi from '../../../apis/admin/placements/PlacementApi';
import offBoardSave from "../../../assets/svg/offBoardSave.svg"

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#404040",
        padding: "6px 14px",
        minWidth: 100,
        border: "1px solid #404040"
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#404040",
        "&::before": {
            backgroundColor: "#404040",
            border: "1px solid #404040"
        }
    },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper ": {
        borderRadius: "16px",
        width: "500px"
    }
}));// eslint-disable-next-line
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function PlacementView(props) {

    const { setformEdit, formEdit } = props;

    const location = useLocation();
    const navigate = useNavigate();
    const classes = PlacementViewStyles();
    const [loading, setLoading] = useState(false);
    const [employee, setEmployee] = useState(null);
    const dataArr = ["Client Details", "Placement Details", "Timesheet Configuration"];
    const [current, setCurrent] = useState("Client Details");// eslint-disable-next-line
    const [EmployeeProfile, setEmployeeProfile] = useState(null);
    const [navigateOpen, setNavigateOpen] = useState(false);
    const [status, setStatus] = useState(null);
    const [clientDetails, setclientDetails] = useState({});
    const [clientLogoDetails, setclientLogoDetails] = useState({
        logo_url: '',
        profile_progress: ''
    });
    const param = new URLSearchParams(window.location.search);
    const id = param.get('id');
    const employee_id = param.get('employee_id')
    const placementId = id ? id : LocalStorage.getRedirectedModule() == 'placement' ? LocalStorage.getNotificationId() : location.state.placement_id
    const empId = employee_id ? employee_id : LocalStorage.getRedirectedModule() == 'placement' ? LocalStorage.getNotificationEmpId() : location.state.id
    useEffect(() => {
        getEmployeeApi();
        getPlacementData(placementId);
        // eslint-disable-next-line
    }, []);


    const getEmployeeApi = () => {
        setLoading(true);
        if (empId !== '') {
            EmployeesApi.getEmployeeConsultant(empId).then((response) => {
                setTimeout(() => {
                    if (response.data.statusCode == 1003) {
                        setLoading(false);
                        setEmployee({ ...response.data.data });
                        setEmployeeProfile(response.data.data.profile_picture_url);
                    } else {
                        setLoading(false);
                        addErrorMsg(response.data.message);
                    }
                }, 400)
            });
        }
    }

    const getPlacementData = (id) => {
        PlacementApi.getPlacementIndexConsultant("placement-details", id).then((res) => {
            if (res.data.statusCode === 1003) {
                setStatus(res.data.data.employee_details.status);
                setclientLogoDetails({
                    ...clientLogoDetails,
                    logo_url: res.data.data.client_logo_url,
                    profile_progress: res.data.data.client_profile_progress
                })
            }
        })
    }


    const handleNavigate = () => {
        if (formEdit) {
            setNavigateOpen(true);
        } else {
            navigate("/placement/dashboard")
        }
    }

    const [tab, setTab] = useState('');
    const handleTabChange = (item) => {
        if (formEdit) {
            setTab(item);
            setNavigateOpen(true);
        } else {
            setTab('');
            setCurrent(item)
        }
    }

    const handleFinalCancel = () => {
        if (formEdit && tab !== "") {
            setCurrent(tab); setformEdit(false); setNavigateOpen(false);
        } else {
            setTab('');
            navigate('/placements'); setformEdit(false); setNavigateOpen(false);
        }
    }

    return (
        <Box className={classes.mainContainer} px={5} py={1}>
            <Box mx={2} pl={3}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography onClick={() => { handleNavigate() }} className={classes.breadcrumbsLink}>All Placements</Typography>
                    <Text sx={{ color: '#092333 !important', fontWeight: '700 !important' }} mediumBlack14>Placement Details</Text>
                </Breadcrumbs>
            </Box>
            <Box my={2} mx={2}>
                <Grid container columnSpacing={4}>
                    <Grid item container lg={3} md={3} sm={4} xs={12}>
                        <Box style={{ width: "100%", height: "78.5vh", borderRadius: "8px" }}>
                            <Box px={3} py={1} pt={4}>
                                <Box my={1} mt={2} style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                                    {
                                        loading ?
                                            <Skeleton variant="circular" width={50} height={50} /> :
                                            <Avatar src={clientLogoDetails.logo_url} alt={clientDetails && clientDetails.client_name ? clientDetails.client_name[0] : ''} className={classes.avatarView} />
                                    }

                                </Box>

                                {
                                    loading ?
                                        <Grid item lg={12} md={12} sm={12} xs={12} textAlign={"-webkit-center"}><Skeleton variant="rounded" animation="wave" width={150} height={25} /></Grid> :
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            {
                                                employee &&
                                                <Box display='flex' flexDirection='row' gap={1} justifyContent='center'>
                                                    <Text className={classes.profileName} noWrap>{employee &&
                                                        capitalizeAndAddSpace(clientDetails.client_name).length > 16 ?
                                                        <HtmlTooltip title={capitalizeAndAddSpace(clientDetails.client_name)} placement="right" arrow>
                                                            {capitalizeAndAddSpace(clientDetails.client_name).slice(0, 16) + (capitalizeAndAddSpace(clientDetails.client_name).length > 16 ? "..." : "")}
                                                        </HtmlTooltip>
                                                        :
                                                        capitalizeAndAddSpace(clientDetails.client_name)}</Text>
                                                </Box>
                                            }
                                        </Grid>
                                }
                                <Grid item lg={12} pt={0}>
                                    {loading ? <Grid item pt={1} lg={12} md={12} sm={12} xs={12} textAlign={"-webkit-center"}><Skeleton animation="wave" variant="rounded" width={100} height={20} /></Grid> :
                                        <Text className={classes.profileId}>{clientDetails && clientDetails.client_reference_id}</Text>
                                    }
                                </Grid>

                            </Box>
                            <Box px={3}>
                                <Divider />
                            </Box>
                            <Box py={3}>
                                <Box px={3} sx={{
                                    height: "30vh",
                                    overflow: "auto",
                                    '&::-webkit-scrollbar': {
                                        width: '4px',
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        '-webkit-box-shadow': 'inset 0 0 6px #ffffff',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        backgroundColor: '#C7CCD3',
                                        outline: '1px solid #C7CCD3',
                                        borderRadius: "4px",
                                    },
                                    "@media (min-height: 750px)": {
                                        height: '36vh'
                                    }

                                }}>
                                    {
                                        loading ?
                                            [1, 2, 3, 4].map((item) => (
                                                <Skeleton variant="rounded" height={40} width={250} sx={{ margin: "10px 0px" }} />
                                            ))
                                            :
                                            <>


                                                {
                                                    dataArr.map((item, key) => (
                                                        <ListItemButton
                                                            key={key}
                                                            className={`${classes.listItems} ${current === item ? classes.listItemsActive : null}`}
                                                            onClick={() => handleTabChange(item)}
                                                        >
                                                            <Grid container>
                                                                <Grid item xs={10}>{item}</Grid>
                                                            </Grid>
                                                        </ListItemButton>
                                                    ))}
                                            </>
                                    }
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item lg={9} md={9} sm={8} xs={12}>
                        <Box sx={{ width: '100%', height: "78vh", borderRadius: "8px", overflow: 'auto', boxShadow: current == "Client Details" ? "" : "0px 0px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB4D" }}>

                            {
                                current == "Client Details" ?
                                    <ClientDetails setclientDetails={setclientDetails} clientDetails={clientDetails} status={status} setformEdit={setformEdit} formEdit={formEdit} id={placementId ? placementId : ""} HtmlTooltip={HtmlTooltip} />
                                    :
                                    current == "Placement Details" ?
                                        <PlacementDetails status={status} setformEdit={setformEdit} id={placementId ? placementId : ""} />
                                        :
                                        current == "Timesheet Configuration" ?
                                            < TimesheetConfiguration status={status} setformEdit={setformEdit} id={placementId ? placementId : ""} /> : ""
                            }
                        </Box>
                    </Grid>


                    {/* endPlacementSubmitOpen */}


                    <BootstrapDialog
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="customized-dialog-title"
                        open={navigateOpen}
                        fullWidth={true}
                        maxWidth={"md"}
                    >
                        <DialogContent sx={{ margin: "50px", }}>
                            <Grid container justifyContent={'center'} alignContent={'center'} pt={1}>
                                <img src={offBoardSave} alt='noactive' />
                            </Grid>
                            <Grid container justifyContent={'center'} alignContent={'center'} pt={2}>
                                <Text offBoardHeadFont sx={{ fontSize: "18px !important" }}>Do you want to discontinue?</Text>
                            </Grid>
                            <Grid container justifyContent={'center'} alignContent={'center'}>
                                <Text offBoardBodyFont>Your progress will not be saved</Text>
                            </Grid>
                            <Grid container lg={12} justifyContent={'center'} alignContent={'center'} mt={3}>
                                <Stack direction={"row"} spacing={3}>
                                    <Button smallBlackOutline onClick={() => { setNavigateOpen(false) }} >
                                        Cancel
                                    </Button>
                                    <Button smallBlue redBackground onClick={() => handleFinalCancel()} >
                                        Yes
                                    </Button>
                                </Stack>
                            </Grid>
                        </DialogContent>
                    </BootstrapDialog>
                </Grid>
            </Box>
        </Box>
    )
}

export default PlacementView